import { createReducer, on } from '@ngrx/store';
import { OnboardingState } from './onboarding-state.model';
import { onboardingActions } from './onboarding.action';

export const OnboardingStepsInitialState: OnboardingState = {
  stepsConfig: null,
  businessData: null,
  businessName: null,
  selectedIndustry: null,
  selectedAgentTemplate: null,
};

export const OnboardingStepsReducer = createReducer(
  OnboardingStepsInitialState,
  on(
    onboardingActions.setOnboardingSteps,
    (state, { stepsConfig }): OnboardingState => {
      return {
        ...state,
        stepsConfig,
      };
    }
  ),
  on(
    onboardingActions.setBusinessData,
    (state, { businessData }): OnboardingState => {
      return {
        ...state,
        businessData,
      };
    }
  ),
  on(
    onboardingActions.setBusinessName,
    (state, { businessName }): OnboardingState => ({
      ...state,
      businessName,
    })
  ),
  on(
    onboardingActions.setSelectedAgentTemplate,
    (state, { selectedAgentTemplate }): OnboardingState => ({
      ...state,
      selectedAgentTemplate,
    })
  )
);
