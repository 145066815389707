import { Static, Type } from '@sinclair/typebox'


export const ResetPasswordRequest = Type.Object({
    email: Type.String(),
    token: Type.String(),
    newPassword: Type.String(),
})

export type ResetPasswordRequest = Static<typeof ResetPasswordRequest>

