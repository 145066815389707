import {
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  EachSliderStep,
  StepSliderConfig,
} from '../../models/step-slider-config-interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-slider',
  templateUrl: './step-slider.component.html',
  styleUrls: ['./step-slider.component.scss'],
})
export class StepSliderComponent implements OnInit {
  _config: StepSliderConfig;
  @ViewChild('dynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  dynamicComponentContainer!: ViewContainerRef;
  container: ViewContainerRef;
  currentStepIndex: number = 0;
  currentStep: EachSliderStep;
  displayedText = '';
  typingSpeed = 40;
  revealComponent = false;
  isOverflowed = false;
  isGoingBack = false;
  canSkip = false;
  isBackNavigationAllowed = false;

  @Input()
  set config(value: StepSliderConfig) {
    this._config = { ...value };
  }

  get config(): StepSliderConfig {
    return this._config;
  }

  constructor(private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {
    this.container = this.dynamicComponentContainer;
    this.loadStep(this.currentStepIndex);
  }

  loadStep(index: number) {
    this.currentStep = this._config.steps[index];
    this.displayedText = '';
    this.revealComponent = false;
    this.cdr.detectChanges(); // Reset component visibility
    this.typeText(this.currentStepIndex);
  }

  async typeText(stepIndex: number) {
    const titleText = this._config.steps[stepIndex]?.message ?? '';
    if (!this.isGoingBack) {
      let i = 0;

      const typeCharacter = () => {
        if (i < titleText.length) {
          this.displayedText += titleText.charAt(i);
          this.cdr.detectChanges();
          i++;
          const nextCharDelay = this.typingSpeed + Math.random() * 20 - 10;
          setTimeout(typeCharacter, Math.max(nextCharDelay, 20));
        } else {
          // Resolve after typing is complete
          Promise.resolve().then(() => {
            this.revealComponent = true;
            this.cdr.detectChanges();
            this.loadDynamicComponentWithAnimation();
          });
        }
      };

      typeCharacter();
    } else {
      Promise.resolve().then(() => {
        this.displayedText = titleText;
        this.revealComponent = true;
        this.cdr.detectChanges();
        this.loadDynamicComponentWithAnimation();
      });
    }
  }

  loadDynamicComponentWithAnimation() {
    if (
      this.revealComponent &&
      this.currentStep &&
      this.currentStep.component &&
      this.container
    ) {
      this.container.clear();
      const componentRef = this.container.createComponent<
        typeof this.currentStep.component
      >(this.currentStep.component);

      componentRef.instance.onNextStep = this.nextStep.bind(this);
      componentRef.instance.onPrevStep = this.prevStep.bind(this);
      this.canSkip = this.currentStep.canSkip ?? false;
      this.isBackNavigationAllowed =
        this.currentStep.isBackNavigationAllowed ?? false;

      if (this.currentStep.data) {
        componentRef.instance.data = this.currentStep.data;
        Object.assign(componentRef.instance, this.currentStep.data);
      }

      this.cdr.detectChanges(); // Ensure the component is shown
    }
  }

  prevStep() {
    this.isGoingBack = true;
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
      this.loadStep(this.currentStepIndex);
      if (this._config.onPrevStep) {
        this._config.onPrevStep();
      }
    }
  }

  nextStep() {
    this.isGoingBack = false;
    if (this.currentStepIndex < Object.keys(this._config.steps).length - 1) {
      this.currentStepIndex++;
      this.loadStep(this.currentStepIndex);
    }
  }

  handleSkip() {
    localStorage.setItem('onboarding', 'false');
    this.router.navigate(['/']);
  }
}
