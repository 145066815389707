<div class="ap-w-full ap-flex ap-items-center ap-justify-between">
  <div class="ap-flex ap-items-center ap-space-x-4 ap-px-4 ap-pb-6 ap-w-full">
    <ap-user-avatar-icon
      [imageUrl]="emailConfig.senderImage"
    ></ap-user-avatar-icon>
    <div class="ap-flex ap-flex-col">
      <div class="ap-text-sm">From: {{ emailConfig.from }}</div>
      <div class="ap-text-sm">CC: {{ emailConfig.cc }}</div>
      <div class="ap-text-sm">Date: {{ emailConfig.date }}</div>
      <div class="ap-text-sm ap-font-bold">
        Subject: {{ emailConfig.subject }}
      </div>
    </div>
  </div>
  <!-- <div
    class="ap-transition-transform ap-duration-300 {{
      expansionPanel.expanded ? 'ap-rotate-180' : ''
    }}"
  >
    <svg-icon
      src="assets/img/custom/chevron.svg"
      [applyClass]="true"
      class="ap-fill-primary ap-w-10 ap-h-10"
    ></svg-icon>
  </div> -->
</div>
<div
  class="ap-px-4 ap-py-6 ap-transition-transform ap-duration-300 ap-border-t"
>
  <div [innerHTML]="sanitizedContent" class="email-template"></div>
  <div class="ap-mt-4 ap-flex ap-flex-row ap-flex-wrap ap-gap-2">
    <div *ngFor="let attachment of attachments" class="ap-w-1/4">
      <ap-attachment
        [attachment]="attachment"
        (click)="setSelectedFileViewer(attachment.url, attachment.type)"
      ></ap-attachment>
    </div>
  </div>

  <div *ngIf="pdfUrl" class="ap-relative">
    <div>
      <div class="ap-absolute ap-right-2 ap-top-2 ap-z-10">
        <ap-icon-button
          [iconFilename]="'close.svg'"
          (buttonClicked)="clearPdfUrl()"
        ></ap-icon-button>
      </div>
      <ap-pdf-viewer [pdfUrl]="pdfUrl"></ap-pdf-viewer>
    </div>
  </div>

  <div *ngIf="imageUrl" class="ap-relative">
    <div>
      <div class="ap-absolute ap-right-2 ap-top-2 ap-z-10">
        <ap-icon-button
          [iconFilename]="'close.svg'"
          (buttonClicked)="clearPdfUrl()"
        ></ap-icon-button>
      </div>
      <img
        [src]="imageUrl"
        alt=""
        class="ap-w-full ap-h-[500px] ap-mt-3 ap-rounded-md"
      />
    </div>
  </div>
</div>
