import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const DEFAULT_FORBIDDEN_CHARACTERS: string[] = [];

const EMAIL_FORBIDDEN_CHARACTERS = [
  "'",
  '"',
  '`',
  '<',
  '>',
  ';',
  '&',
  '!',
  '#',
  '$',
  '%',
  '^',
  '(',
  ')',
  '{',
  '}',
  '[',
  ']',
  '~',
  '*',
  '|',
];

const NAME_FORBIDDEN_CHARACTERS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '+',
  '=',
  '-',
  '[',
  ']',
  '{',
  '}',
  '\\',
  '/',
  ':',
  ';',
  '"',
  "'",
  '<',
  '>',
  ',',
  '.',
  '!',
  '?',
];

const PASSWORD_FORBIDDEN_CHARACTERS = ['<', '>', ':', '&', '/'];

/**
 * forbiddenCharactersValidator
 * Ensure no code, URL, html/json data is entered into any fields in sign up and sign in forms
 */
export function forbiddenCharactersValidator(
  fieldType: 'name' | 'email' | 'password' | 'custom' = 'custom', // Default is custom, but can be specified for name, email, or password
  forbiddenChars: string[] = DEFAULT_FORBIDDEN_CHARACTERS
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const value = String(control.value);
    let charsToCheck: string[];

    // Select the appropriate forbidden characters based on fieldType
    switch (fieldType) {
      case 'name':
        charsToCheck = NAME_FORBIDDEN_CHARACTERS;
        break;
      case 'email':
        charsToCheck = EMAIL_FORBIDDEN_CHARACTERS;
        break;
      case 'password':
        charsToCheck = PASSWORD_FORBIDDEN_CHARACTERS;
        break;
      default:
        charsToCheck = forbiddenChars;
    }

    const invalidChars = charsToCheck.filter((char) => value.includes(char));

    return invalidChars.length > 0
      ? { forbiddenCharacters: { invalidChars } }
      : null;
  };
}
