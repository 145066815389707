export enum Sectors {
    INFORMATION_TECHNOLOGY = 'Information Technology',
    CLOUD_COMPUTING = 'Cloud Computing',
    CUSTOMER_SUPPORT = 'Customer Support',
}

export enum InputParameters {
    BUSINESS_URL = 'business_url',
}

export enum InboxToolsNextButton {
    CONTINUE = 'Continue',
    CONNECT = 'Connect',
}
