import { Injectable } from '@angular/core';
import { PlansInfoObjectType } from '@upbrains/shared';
import { SubscriptionService } from '@upbrains/ui/common';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export const SUBSCRIPTION_PLANS_RESOLVER_FLAG = 'subscriptionPlansResolver';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlansResolver {
  constructor(private subscriptionService: SubscriptionService) {}

  resolve(): Observable<PlansInfoObjectType[] | undefined | null> {
    return this.subscriptionService.getPlansInfo().pipe(
      map((userSubscriptionPlansResponse) => {
        const subscriptionPlansInfo =
          userSubscriptionPlansResponse?.results?.plans_info;

        return subscriptionPlansInfo || null;
      }),
      catchError((error) => {
        console.error('Error resolving subscription plans info:', error);
        return of(null);
      })
    );
  }
}
