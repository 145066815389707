<div
  class="ap-flex ap-flex-col ap-border ap-rounded-2xl ap-p-5 ap-text-[#344054] ap-font-inter ap-max-w-[305px] md:ap-h-[769px] ap-h-auto subscription-plan"
>
  <p class="ap-text-xl ap-font-semibold ap-font-inter ap-mb-[6px]">
    {{ initialPlanData.plan_name }}
    <ng-container
      *ngIf="!initialPlanData.plan_name.toLocaleLowerCase().includes('plan')"
    >
      Plan</ng-container
    >
  </p>

  <ng-container
    *ngIf="
      currentPlan &&
      initialPlanData.plan_name.toLocaleLowerCase().includes(currentPlan)
    "
  >
    <div
      class="ap-bg-[#039855] ap-rounded-3xl ap-py-1 ap-px-2 ap-text-white ap-w-fit ap-text-xs ap-font-inter ap-my-[6px] current-plan-shadow"
    >
      Current Plan
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      initialPlanData.fixed_amount &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise')
    "
  >
    <p
      class="ap-text-4xl ap-font-bold ap-font-inter ap-mt-[17px]"
      [ngClass]="{
        'current-plan-price':
          currentPlan &&
          initialPlanData.plan_name.toLocaleLowerCase().includes(currentPlan)
      }"
    >
      ${{ initialPlanData.fixed_amount }}
      <span class="ap-text-base ap-font-normal ap-text-[#667085] ap-ml-[5px]"
        >/ {{ monthYearStatus }}</span
      >
    </p>
  </ng-container>

  <ng-container
    *ngIf="
      initialPlanData.subscription_quantity &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise')
    "
  >
    <div class="ap-flex ap-flex-row ap-gap-2 ap-items-center ap-mt-[23px]">
      <mat-form-field
        class="ap-w-[80px] ap-font-inter"
        subscriptSizing="dynamic"
      >
        <input
          type="number"
          matInput
          class="ap-outline-none ap-w-full ap-appearance-auto"
          [(ngModel)]="initialPlanData.subscription_quantity"
          [min]="minUserSeats"
          [max]="initialPlanData.users_limit"
          (input)="onUserSeatsInputChange($event)"
        />
      </mat-form-field>
      <p class="ap-text-sm ap-font-normal">
        Users
        <span *ngIf="initialPlanData.users_limit"
          >( max {{ initialPlanData.users_limit }} )</span
        >
      </p>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (initialPlanData.subscription_credit ||
        initialPlanData.subscription_credit === 0) &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise') &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('starter') &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('free')
    "
  >
    <div
      class="ap-flex ap-flex-row ap-gap-2 ap-items-center ap-justify-between ap-text-sm ap-font-normal ap-mt-[23px]"
    >
      <p>Automation Credits</p>
      <mat-form-field
        class="ap-w-[86px] ap-font-inter"
        subscriptSizing="dynamic"
      >
        <input
          type="number"
          matInput
          class="ap-outline-none ap-w-full"
          [min]="minCredits"
          [max]="initialPlanData.subscription_credit_limit || 500000"
          [(ngModel)]="initialPlanData.subscription_credit"
          (input)="onCreditsInputChange($event)"
        />
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (initialPlanData.subscription_credit ||
        initialPlanData.subscription_credit === 0) &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise') &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('starter') &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('free')
    "
  >
    <mat-slider
      [min]="minCredits"
      [max]="initialPlanData.subscription_credit_limit || 500000"
      step="5000"
      discrete
      [displayWith]="formatLabel"
      (change)="onCreditsInputChange($event)"
    >
      <input matSliderThumb [value]="initialPlanData.subscription_credit" />
    </mat-slider>
    <div
      class="ap-flex ap-flex-row ap-gap-2 ap-items-center ap-justify-between ap-text-sm ap-font-normal -ap-mt-2"
    >
      <p>{{ minCredits }}</p>
      <p>{{ initialPlanData.subscription_credit_limit || '500,000' }}</p>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      totalPrice &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise')
    "
  >
    <p class="ap-text-sm ap-font-normal ap-mt-[23px] ap-mb-3">
      Total Price: <span>${{ totalPrice.toFixed(2) }}</span>
    </p>
  </ng-container>

  <mat-divider></mat-divider>

  <ng-container *ngIf="planFeatures.length">
    <mat-list role="list" class="ap-flex ap-flex-col custom-list">
      <mat-list-item
        *ngFor="let feature of planFeatures"
        role="listitem"
        class="!ap-h-auto !ap-px-0 !ap-py-1 custom-list-item"
      >
        <div class="ap-flex ap-items-center">
          <svg-icon
            [src]="
              feature.status
                ? 'assets/img/custom/subscription/check.svg'
                : 'assets/img/custom/subscription/close.svg'
            "
            class="ap-w-3 ap-h-3 ap-mr-3"
            [applyClass]="true"
          ></svg-icon>
          <span class="ap-flex-1 ap-break-words feature-description-style">
            {{ feature.description }}
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <mat-divider></mat-divider>

  <ng-container
    *ngIf="
      planPackages.length &&
      !initialPlanData.plan_name.toLocaleLowerCase().includes('enterprise')
    "
  >
    <mat-radio-group
      aria-label="Select an option"
      class="custom-selector"
      [(ngModel)]="selectedPackageValue"
      (change)="onSelectionChange($event)"
    >
      <mat-radio-button
        *ngFor="let package of planPackages"
        [value]="package.value"
        color="primary"
        >{{ package.title }}</mat-radio-button
      >
    </mat-radio-group>
  </ng-container>

  <ng-container *ngIf="initialPlanData.plan_name && findTheUpgradeButtonText()">
    <div
      class="ap-flex ap-flex-row ap-gap-3 ap-justify-between ap-items-center ap-mt-auto"
    >
      <ap-button
        actionButton
        (buttonClicked)="handleUpgrade()"
        btnSize="large"
        [btnColor]="findTheUpgradeButtonBgColor()"
        i18n
        class="ap-w-full"
        [btnStyle]="findTheUpgradeButtonBorder()"
        [fullWidthOfContainer]="true"
      >
        <div
          class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
        >
          <svg-icon
            src="assets/img/custom/loading.svg"
            class="loading-spin-animation white-loader"
            [applyClass]="true"
          >
          </svg-icon>
          {{ findTheUpgradeButtonText() }}
        </div>
      </ap-button>
    </div>
  </ng-container>
</div>
