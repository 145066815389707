import { createAction, props } from '@ngrx/store';
import { StepSliderConfig } from '../../models/step-slider-config-interface';
import {
  FlowTemplateWithIcons,
  PromptExecutorResponse,
} from '@upbrains/shared';

const setOnboardingSteps = createAction(
  '[Onboarding Steps Config] Set Onboarding steps config',
  props<{
    stepsConfig: StepSliderConfig | null;
  }>()
);

const setBusinessData = createAction(
  '[Onboarding Steps Business Data] Set Onboarding business data',
  props<{
    businessData: PromptExecutorResponse | null;
  }>()
);

const setBusinessName = createAction(
  '[Onboarding Steps Business Name] Set Onboarding business name',
  props<{
    businessName: string | null;
  }>()
);

const setSelectedAgentTemplate = createAction(
  '[Onboarding Steps Selected Agent Template] Set Onboarding selected agent template',
  props<{
    selectedAgentTemplate: FlowTemplateWithIcons | null;
  }>()
);

export const onboardingActions = {
  setOnboardingSteps,
  setBusinessData,
  setBusinessName,
  setSelectedAgentTemplate,
};
