import { createReducer, on } from '@ngrx/store';
import { UpbrainsUserState } from './upbrains-user-state.model';
import {
  removeUpbrainsUser,
  saveUpbrainsUser,
  setUserSubscriptionCheck,
} from './upbrains-user.action';

export const initialState: UpbrainsUserState = {
  upbrainsUser: null,
  subscriptionCheck: null,
};

export const upbrainsUserReducer = createReducer(
  initialState,
  on(saveUpbrainsUser, (state, { upbrainsUser }): UpbrainsUserState => {
    return { ...state, upbrainsUser };
  }),
  on(
    removeUpbrainsUser,
    (state): UpbrainsUserState => ({
      ...state,
      upbrainsUser: null,
      subscriptionCheck: null,
    })
  ),
  on(
    setUserSubscriptionCheck,
    (state, { subscriptionCheck }): UpbrainsUserState => {
      return { ...state, subscriptionCheck };
    }
  )
);
