import { Static, Type } from '@sinclair/typebox'
import { User } from './user'

export const UserResponse = Type.Omit(User, ['password'])

export type UserResponse = Static<typeof UserResponse>

export const IgniteUserResponse = Type.Object({
    status: Type.Number(),
    team_id: Type.String(),
    active_subscriptions: Type.Array(Type.Object({
        id: Type.Integer(),
        team_id: Type.Integer(),
        service_id: Type.Integer(),
        plan_id: Type.Integer(),
        stripe_subscription_id: Type.String(),
        quantity: Type.Integer(),
        exceeded_limit: Type.Boolean(),
        additional_credits: Type.Integer(),
        renewal_date: Type.String(),
        status: Type.String(),
        recurring_charge: Type.Number(),
        channel_type: Type.String(),
    })),
    user_id: Type.Integer(),
    user_name: Type.String(),
    user_email: Type.String(),
    user_password: Type.String(),
    company_name: Type.String(),
    api_key: Type.String(),
    user_email_confirmed: Type.Boolean(),
})

export type IgniteUserResponse = Static<typeof IgniteUserResponse>