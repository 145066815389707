<div class="ap-flex ap-flex-col">
  <div
    class="ap-px-4 ap-pt-7 ap-flex ap-flex-row ap-items-center ap-gap-2 ap-mb-[14px] ap-border-b ap-border-[#ebebeb]"
  >
    <ap-icon-button
      tooltipText="Previous"
      i18n-tooltipText
      iconUrl="assets/img/newDesign/onboarding/arrow-left.svg"
      [width]="24"
      [height]="24"
      class="ap-rounded-lg custom-skip-button"
      (buttonClicked)="redirectToInbox()"
    ></ap-icon-button>

    <div
      class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-w-full"
    >
      <div
        class="ap-flex ap-flex-col ap-gap-1 ap-justify-center ap-items-start"
      >
        <div
          class="ap-text-3xl ap-text-[#101828] ap-font-inter ap-font-semibold"
        >
          Review Form
        </div>
        <div
          class="ap-text-[#475467] ap-text-base ap-font-normal ap-font-inter ap-mb-[6px]"
        >
          {{ title }}
        </div>
      </div>

      <ng-container *ngIf="conversationId">
        <ap-button
          btnStyle="basic"
          btnColor="primary"
          btnSize="small"
          (click)="goToConversation()"
        >
          Go to Conversation
        </ap-button>
      </ng-container>
    </div>
  </div>

  <div class="ap-px-4 ap-h-[calc(100vh-161px)] ap-py-2 ap-pt-0">
    <div
      class="ap-grid ap-h-full"
      [ngClass]="handlingAgGridClassMainContainer()"
    >
      <div
        resizable
        [percent]="secondContainer || thirdContainer ? 40 : 100"
        *ngIf="firstContainer"
        [ngClass]="handlingAgGridClassFieldsContainer()"
      >
        <app-form-pdf-viewer
          [fileUrl]="fileUrl"
          [text]="text"
          [hasJsonButton]="hasJsonButton"
          [staticFormsData]="staticFormsData"
          [formPages]="formPages"
          [currentPageIndex]="currentPageIndex"
        />
      </div>

      <div
        class="ap-flex ap-flex-col ap-w-full ap-px-[10px] ap-py-3 ap-pt-0"
        resizable
        [percent]="firstContainer ? 60 : 100"
        *ngIf="secondContainer || thirdContainer"
      >
        <div
          resizable
          [percent]="thirdContainer ? 40 : 100"
          *ngIf="secondContainer"
          [ngClass]="handlingAgGridClassTextContainer()"
          class="ap-relative !ap-overflow-auto"
        >
          <ng-container *ngIf="integrateShortAndLongFields; else fieldLoading">
            <div
              class="ap-w-full ap-border-r ap-border-[#ebebeb]"
              [ngClass]="{
                '!ap-hidden':
                  !integrateShortAndLongFields ||
                  !integrateShortAndLongFields.length
              }"
            >
              <app-primary-keys
                [canAddNewField]="true"
                [hasMatchesInPrimaryKeys]="hasMatchesInPrimaryKeys"
                [keyValueData]="integrateShortAndLongFields"
                [onFieldsSubmit]="onFieldsSubmit.bind(this)"
                [onFieldChange]="onFieldChange.bind(this)"
                (updateChange)="onFieldsOrItemsChanged($event)"
                (highlightTextEvent)="
                  highlightText($event.content, $event.pageNumber, $event.form)
                "
              />
            </div>
          </ng-container>
          <ng-template #fieldLoading>
            <ap-extractor-test-fields-skeleton></ap-extractor-test-fields-skeleton>
          </ng-template>
        </div>

        <div
          resizable
          [percent]="secondContainer ? 60 : 100"
          *ngIf="thirdContainer"
          [ngClass]="handlingAgGridClassTableContainer()"
        >
          <ng-container *ngIf="showTable">
            <div
              class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-z-50 ap-sticky ap-top-0 ap-mb-[17px]"
            >
              <p
                class="ap-text-base ap-text-black ap-font-inter ap-font-semibold ap-flex ap-flex-row ap-items-center ap-justify-center ap-gap-2"
              >
                Line Items

                <ng-container *ngIf="hasMatchesInLineItems">
                  <svg-icon
                    src="assets/img/newDesign/forms/alert.svg"
                    [applyClass]="true"
                    class="ap-w-[16px] ap-h-[16px]"
                  ></svg-icon>
                </ng-container>
              </p>

              <ap-button
                btnStyle="basic"
                btnColor="primary"
                btnSize="small"
                (click)="addNewRow()"
              >
                + New Row
              </ap-button>
            </div>
            <div class="custom-table">
              <app-table
                [lineNumber]="lineNumber"
                [columns]="column"
                [data]="data"
                [config]="config"
                [contextmenu]="onRightClick"
                [cellValueChanged]="onItemChange"
                (tableUpdate)="TableUpdate($event)"
              ></app-table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="ap-w-[calc(100%+16px)] ap-border-t ap-border-[#E8EAED] ap-flex ap-flex-row ap-items-center ap-justify-between ap-px-6 ap-pt-[5px] ap-bg-white -ap-mx-4"
  >
    <ng-container *ngIf="isSaving">
      <div class="ap-flex ap-flex-row ap-gap-2 ap-items-center">
        <svg-icon
          class="ap-fill-body ap-w-[24px] ap-h-[24px]"
          [applyClass]="true"
          src="assets/img/newDesign/extractor/succeed.svg"
        >
        </svg-icon>
        Saved just now
      </div>
    </ng-container>

    <div class="ap-flex ap-flex-row ap-gap-2 ap-items-center ap-ml-auto">
      <ap-button
        actionButton
        btnSize="medium"
        i18n
        btnColor="basic"
        class="ap-rounded-lg"
        [fullWidthOfContainer]="true"
        (buttonClicked)="redirectToInbox()"
      >
        <div
          class="ap-flex ap-items-center ap-text-[15px] ap-font-semibold ap-rounded-lg ap-font-inter"
        >
          Back
        </div>
      </ap-button>
      <ng-container *ngIf="this.flowRunStatus === 'PAUSED'">
        <div class="ap-w-fit custom-publish">
          <ap-button
            actionButton
            btnSize="medium"
            i18n
            class="ap-rounded-lg"
            [fullWidthOfContainer]="true"
            (buttonClicked)="handleSaving()"
            [isLoading]="isSaving"
            [disabled]="isSaving"
          >
            <div
              class="ap-flex ap-items-center ap-text-sm ap-font-semibold ap-rounded-lg ap-font-inter"
            >
              Submit
            </div>
          </ap-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div
  #menuTrigger="matMenuTrigger"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  style="position: fixed"
  [matMenuTriggerFor]="contextMenu"
></div>

<!-- Standard Material Menu -->
<mat-menu #contextMenu="matMenu">
  <ng-container *ngIf="highlightedFieldObject">
    <button mat-menu-item (click)="copyToField()">
      <div class="ap-flex ap-gap-3 ap-items-center">
        <svg-icon
          [applyClass]="true"
          [src]="flowActionsUiInfo.copyToField.icon"
          [class]="flowActionsUiInfo.iconSizeTailWind"
        >
        </svg-icon>
        <span class="ap-text-body" i18n
          >{{ flowActionsUiInfo.copyToField.text
          }}{{ highlightedFieldObject.key }}</span
        >
      </div>
    </button>
  </ng-container>
  <button mat-menu-item (click)="copyToClipboard()">
    <div class="ap-flex ap-gap-3 ap-items-center">
      <svg-icon
        [applyClass]="true"
        [src]="flowActionsUiInfo.copyToClipboard.icon"
        [class]="flowActionsUiInfo.iconSizeTailWind"
      >
      </svg-icon>
      <span class="ap-text-body" i18n>{{
        flowActionsUiInfo.copyToClipboard.text
      }}</span>
    </div>
  </button>
</mat-menu>
