import { NavigationService } from '@upbrains/ui/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlansInfoObjectType } from '@upbrains/shared';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'lib-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss'],
})
export class SubscriptionPlansComponent implements OnInit, OnDestroy {
  subscriptionPlansList$: Observable<PlansInfoObjectType[] | undefined | null> =
    of(null);
  monthYearStatus = 'month';
  private monthYearStatusSubject = new BehaviorSubject<string>(
    'default-status'
  );
  private destroy$ = new Subject<void>();
  subscriptionPackagesList: PlansInfoObjectType[] | undefined | null = [];
  subscriptionPlansInfo: PlansInfoObjectType[] = [];

  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  planName: string | null = '';

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.planName = this.route.snapshot.paramMap.get('plan');

    const subscriptionPlansInfo =
      this.route.snapshot.data['subscriptionPlansResolver'];

    const subscriptionPlansInfo$ = of(subscriptionPlansInfo);

    this.subscriptionPlansList$ = combineLatest([
      subscriptionPlansInfo$,
      this.monthYearStatusSubject,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([plansInfo, status]) => {
        if (!plansInfo) {
          return [];
        }

        this.subscriptionPackagesList = plansInfo.filter(
          (plan: any) => plan.billing_period === 'OneTime'
        );
        return plansInfo
          .filter((plan: any) =>
            plan?.billing_period?.toLocaleLowerCase()?.includes(status)
          )
          .sort((a: any, b: any) => {
            const amountA = a.fixed_amount ?? 0;
            const amountB = b.fixed_amount ?? 0;
            return amountA - amountB;
          });
      })
    );

    this.monthYearStatusSubject.next(this.monthYearStatus);
  }

  updateMonthYearStatus(newStatus: string): void {
    this.monthYearStatus = newStatus;
    this.monthYearStatusSubject.next(newStatus);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectToHome(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  redirectToSubscriptions(newWindow: boolean) {
    this.navigationService.navigate('/subscriptions', newWindow);
  }
}
