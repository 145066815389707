import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApPaginatorComponent } from '../pagination/ap-paginator.component';
import { ConversationTableDataSource } from './conversation-listing-table.datasource';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  ApEdition,
  ExecutionOutputStatus,
  FlowRetryStrategy,
  FlowRun,
  NotificationStatus,
  ProjectId,
  SeekPage,
} from '@upbrains/shared';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  ConversationService,
  FlagService,
} from '../../service';
import { Store } from '@ngrx/store';
import {
  DATE_RANGE_END_QUERY_PARAM,
  DATE_RANGE_START_QUERY_PARAM,
  FLOW_QUERY_PARAM,
  STATUS_QUERY_PARAM,
} from '../../utils/tables.utils';
import { ProjectActions, ProjectSelectors } from '../../store';
import { MatDialog } from '@angular/material/dialog';
import {
  DeleteEntityDialogComponent,
  DeleteEntityDialogData,
} from '../dialogs/delete-enity-dialog/delete-entity-dialog.component';

const allOptionValue = 'all';

@Component({
  selector: 'app-conversation-listing-table',
  templateUrl: './conversation-listing-table.component.html',
  styleUrls: ['./conversation-listing-table.component.scss'],
})
export class ConversationListingTableComponent implements OnInit {
  @ViewChild(ApPaginatorComponent, { static: true })
  paginator!: ApPaginatorComponent;

  @Input() caseId: string = '';

  private functionIdSubject = new BehaviorSubject<{
    title: string | undefined;
    value: string | undefined;
  } | null>(this.selectedFunc);
  selectedFunc$ = this.functionIdSubject.asObservable();

  @Input() set selectedFunc(
    value: {
      title: string | undefined;
      value: string | undefined;
    } | null
  ) {
    this.functionIdSubject.next(value);
  }

  searchControl = new FormControl('');
  dataSource!: ConversationTableDataSource;
  refreshTableForReruns$ = new BehaviorSubject<boolean>(true);
  flowFilterControl = new FormControl<string>(allOptionValue, {
    nonNullable: true,
  });
  readonly allOptionValue = allOptionValue;
  runsPage$: Observable<SeekPage<FlowRun>> = new Observable<
    SeekPage<FlowRun>
  >();
  nonCommunityEdition$: Observable<boolean> = new Observable<boolean>();
  toggleNotificationFormControl: FormControl<boolean> = new FormControl();
  displayedColumns = ['from', 'date', 'subject', 'tags', 'status', 'action'];
  updateNotificationsValue$: Observable<boolean> = of(false);
  statusFilterControl: FormControl<
    ExecutionOutputStatus | typeof allOptionValue
  > = new FormControl(allOptionValue, { nonNullable: true });
  dateFormGroup = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  selectedFlowName$: Observable<string | undefined> = of(undefined);
  currentProject: ProjectId = '' as ProjectId;
  filtersChanged$: Observable<void> = of();
  readonly ExecutionOutputStatus = ExecutionOutputStatus;
  FlowRetryStrategy: typeof FlowRetryStrategy = FlowRetryStrategy;
  setInitialFilters$?: Observable<void>;
  flowMetaData: {
    [flowId: string]: { agentLogoUrl: string; triggerName: string };
  } = {};
  deleteConnectionDialogClosed$?: Observable<void>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private conversationService: ConversationService,
    private router: Router,
    private flagsService: FlagService,
    private store: Store,
    private authenticationService: AuthenticationService,
    private dialogService: MatDialog
  ) {
    this.flowFilterControl.setValue(
      this.activatedRoute.snapshot.queryParamMap.get(FLOW_QUERY_PARAM) ||
        this.allOptionValue
    );
    this.statusFilterControl.setValue(
      (this.activatedRoute.snapshot.queryParamMap.get(
        STATUS_QUERY_PARAM
      ) as ExecutionOutputStatus) || this.allOptionValue
    );
    const startDate = this.activatedRoute.snapshot.queryParamMap.get(
      DATE_RANGE_START_QUERY_PARAM
    );
    const endDate = this.activatedRoute.snapshot.queryParamMap.get(
      DATE_RANGE_END_QUERY_PARAM
    );
    this.dateFormGroup.setValue({
      start: startDate ? new Date(startDate) : null,
      end: endDate ? new Date(endDate) : null,
    });
  }
  ngOnInit() {
    this.currentProject = this.authenticationService.getProjectId();
    this.filtersChanged$ = combineLatest({
      flowId: this.flowFilterControl.valueChanges.pipe(
        startWith(this.flowFilterControl.value)
      ),
      status: this.statusFilterControl.valueChanges.pipe(
        startWith(this.statusFilterControl.value)
      ),
      date: this.dateFormGroup.valueChanges.pipe(
        startWith(this.dateFormGroup.value)
      ),
      search: this.searchControl.valueChanges.pipe(
        startWith(this.searchControl.value),
        debounceTime(500)
      ),
      selectedFunction: this.selectedFunc$,
    }).pipe(
      distinctUntilChanged(),
      tap((result) => {
        const createdAfter = new Date(result.date.start);
        const createdBefore = new Date(result.date.end);
        createdBefore.setHours(23, 59, 59, 999);

        const isInConversationRoute = this.router.url
          .toLowerCase()
          .includes('conversation');
        const url = isInConversationRoute
          ? 'conversations'
          : `cases/details/${this.currentProject}/${this.caseId}`;

        this.router.navigate([url], {
          queryParams: {
            flowId:
              result.flowId === this.allOptionValue ? undefined : result.flowId,
            status:
              result.status === this.allOptionValue ? undefined : result.status,
            createdAfter: result.date.start
              ? createdAfter.toISOString()
              : undefined,
            createdBefore: result.date.end
              ? createdBefore.toISOString()
              : undefined,
            searchValue: result.search || undefined,
            functionId: result.selectedFunction?.value || undefined,
            caseId: this.caseId || undefined,
          },
          queryParamsHandling: 'merge',
        });
      }),
      map(() => undefined)
    );

    this.filtersChanged$.subscribe();

    this.nonCommunityEdition$ = this.flagsService
      .getEdition()
      .pipe(map((res) => res !== ApEdition.COMMUNITY));
    this.updateNotificationsValue$ = this.store
      .select(ProjectSelectors.selectIsNotificationsEnabled)
      .pipe(
        take(1),
        tap((enabled) => {
          this.toggleNotificationFormControl.setValue(enabled);
        }),
        switchMap(() => {
          return this.toggleNotificationFormControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((value) => {
              this.store.dispatch(
                ProjectActions.updateNotifyStatus({
                  notifyStatus: value
                    ? NotificationStatus.ALWAYS
                    : NotificationStatus.NEVER,
                })
              );
            })
          );
        })
      );

    const queryParams$ = this.activatedRoute.queryParams;

    this.dataSource = new ConversationTableDataSource(
      queryParams$,
      this.paginator,
      this.conversationService,
      this.refreshTableForReruns$
    );
  }

  openInstanceRun(run: FlowRun) {
    this.router.navigate(['/conversations/details', run.conversationId], {
      queryParams: this.activatedRoute.snapshot.queryParams,
    });
  }

  onDelete(conversationId: string, conversationSubject: string): void {
    const dialogRef = this.dialogService.open(DeleteEntityDialogComponent, {
      data: {
        deleteEntity$: this.conversationService.deleteItem(conversationId),
        entityName: `'${conversationSubject}' Conversation`,
        note: `This will permanently delete the conversation. You can't undo this action.`,
      } as DeleteEntityDialogData,
    });
    this.deleteConnectionDialogClosed$ = dialogRef.beforeClosed().pipe(
      tap((res) => {
        if (res) {
          setTimeout(() => {
            this.refreshTableForReruns$.next(true);
          }, 3000);
        }
      }),
      map(() => {
        return void 0;
      })
    );
  }
}
