import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FlowTemplateWithIcons } from '@upbrains/shared';
import {
  AgentMetadataModelSummary,
  AgentService,
  AuthenticationService,
  CURRENT_FLOW_IS_NEW_KEY_IN_LOCAL_STORAGE,
  FlowService,
  onboardingActions,
  TemplatesService,
} from '@upbrains/ui/common';
import { AgentMetadataService } from '@upbrains/ui/feature-agents';
import {
  map,
  Observable,
  of,
  switchMap,
  tap,
  catchError,
  finalize,
  combineLatest,
  Subscription,
} from 'rxjs';

@Component({
  selector: 'app-select-your-agent',
  templateUrl: './select-your-agent.component.html',
})
export class SelectYourAgentComponent implements OnInit, OnDestroy {
  @Input() onNextStep!: () => void;
  agents$: Observable<AgentMetadataModelSummary[]> = of([]);
  loading = false;
  templatesList$: Observable<FlowTemplateWithIcons[]> = of([]);

  private subscriptions: Subscription = new Subscription();

  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private templatesService: TemplatesService,
    private agentMetadataService: AgentMetadataService,
    private store: Store,
    private flowService: FlowService,
    private authenticationService: AuthenticationService,
    private agentService: AgentService
  ) {}

  ngOnInit() {
    this.agents$ = this.getAgentsMetadata();
    this.getListOfTemplates();
  }

  getListOfTemplates() {
    this.loading = true;
    this.templatesList$ = combineLatest([
      this.templatesService.list({}),
      this.agents$,
    ]).pipe(
      map(([templates, agentsData]) => {
        if (templates && templates.length) {
          return templates.map((template) => {
            return {
              ...template,
              agentsIcons: template.agents.map((agent) => {
                const agentData = agentsData.find((a) => a.name === agent);
                this.loading = false;
                return {
                  agentName: agent,
                  iconUrl: agentData ? agentData.logoUrl : undefined,
                };
              }),
            };
          });
        }
        this.loading = false;
        return [];
      }),
      catchError((err) => {
        this.loading = false;
        this.snackbar.open(
          'There was a problem in sending data, please try again!'
        );
        throw new Error(err.message);
      }),
      finalize(() => {
        this.loading = false;
      })
    );

    // Subscribe separately to avoid overwriting templatesList$
    const templatesSubscription = this.templatesList$.subscribe();
    this.subscriptions.add(templatesSubscription);
  }

  getAgentsMetadata() {
    return this.agentMetadataService.getAgentsManifestFromServer({});
  }

  startBlankAgent() {
    const flowSubscription = this.flowService
      .create({
        projectId: this.authenticationService.getProjectId(),
        displayName: $localize`Untitled`,
        folderId: undefined,
      })
      .pipe(
        switchMap((flow) => {
          return this.agentService
            .create({
              displayName: $localize`Untitled`,
              projectId: this.authenticationService.getProjectId(),
              flowId: flow.id,
            })
            .pipe(
              tap((agent) => {
                localStorage.setItem(
                  CURRENT_FLOW_IS_NEW_KEY_IN_LOCAL_STORAGE,
                  'true'
                );
                this.router.navigate(['/agents', agent.id, 'flows', flow.id]);
              }),
              catchError((error) => {
                console.error('Error creating agent:', error);
                throw error;
              }),
              map((response) => {
                return response;
              })
            );
        }),
        catchError((error) => {
          console.error('Error creating flow:', error);
          throw error;
        }),
        finalize(() => {
          console.log('startBlankAgent completed');
        })
      )
      .subscribe();
    this.subscriptions.add(flowSubscription);
  }

  handleSelectAgentTemplate(template: FlowTemplateWithIcons) {
    this.store.dispatch(
      onboardingActions.setSelectedAgentTemplate({
        selectedAgentTemplate: template,
      })
    );
    this.onNextStep();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
