import { Static, Type } from '@sinclair/typebox'
import { TypeEnum } from './remote-files'

export const RemoteFilesType = Type.Object({
    fileName: Type.String(),
    url: Type.String(),
    header: Type.String(),
    type: TypeEnum,
    projectId: Type.String(),
    agentName: Type.Optional(Type.String()),
    connectionName: Type.Optional(Type.String()),
    accessToken: Type.Optional(Type.String()),
})

export const RemoteFilesResultType = Type.Object({
    fileName: Type.String(),
    header: Type.String(),
    url: Type.String(),
})

export type RemoteFilesType = Static<typeof RemoteFilesType>
export type RemoteFilesResultType = Static<typeof RemoteFilesResultType>
