export enum PropertyType {
    SHORT_TEXT = 'SHORT_TEXT',
    LONG_TEXT = 'LONG_TEXT',
    MARKDOWN = 'MARKDOWN',
    DROPDOWN = 'DROPDOWN',
    STATIC_DROPDOWN = 'STATIC_DROPDOWN',
    NUMBER = 'NUMBER',
    CHECKBOX = 'CHECKBOX',
    OAUTH2 = 'OAUTH2',
    SECRET_TEXT = 'SECRET_TEXT',
    ARRAY = 'ARRAY',
    OBJECT = 'OBJECT',
    BASIC_AUTH = 'BASIC_AUTH',
    JSON = 'JSON',
    MULTI_SELECT_DROPDOWN = 'MULTI_SELECT_DROPDOWN',
    STATIC_MULTI_SELECT_DROPDOWN = 'STATIC_MULTI_SELECT_DROPDOWN',
    DYNAMIC = 'DYNAMIC',
    CUSTOM_AUTH = 'CUSTOM_AUTH',
    DATE_TIME = 'DATE_TIME',
    FILE = 'FILE',
    DISPLAY_TEXT = 'DISPLAY_TEXT',
    SELECT = 'SELECT',
    DISAPPROVAL_ACTION = 'DISAPPROVAL_ACTION',
    APPROVAL_ACTION = 'APPROVAL_ACTION',
    CUSTOM_ACTION = 'CUSTOM_ACTION',
    SUBMIT_ACTION = 'SUBMIT_ACTION'
}