import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PropertyType } from '@upbrains/agents-framework';
import { FindTable, SearchFieldInputs } from '@upbrains/shared';

import { environment } from '../../../environments/environment';

export type TableColumn = {
  key: string;
  value: string;
  query?: string;
  order: number | undefined;
};
@Component({
  templateUrl: './search-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent implements OnInit {
  searchType: 'none' | 'spread_sheet' | 'custom_search_api' | undefined;
  listOfValues: { label: string; value: any }[] = [];
  rows: any[] = [];
  columns: TableColumn[] = [];
  spreadsheetLogData: SearchFieldInputs;
  spreadsheetLogDataTemp: SearchFieldInputs;
  spreadsheetList: {
    label: string;
    value: string | undefined;
    isBoolean: boolean;
  }[] = [];
  PropertyType: PropertyType;
  isLoading: boolean = false;
  table: any = [];
  dataSource: any[] = [];
  displayColumns: string[] = [];
  selectedRow: FindTable;
  selectedRowIndex: number | null = null;
  mainFormColumns: string[];
  mainFormRows: any[];
  mainRowIndex: number;
  errorMessage: string = '';

  constructor(
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private ref: ChangeDetectorRef, // we use this for resetting the state for special cases
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mainRowIndex: number;
      mainFormRows: any[];
      updateForm: (form: any[]) => void;
      updateItemValue: (
        rowIndex: number,
        columnKey: string,
        value: any,
        label?: string
      ) => void;
      spreadsheetLogData: SearchFieldInputs;
      updateCandidate: (
        value: string,
        rowOptions: any,
        rowIndex: number,
        columnKey: string
      ) => void;
    }
  ) {
    this.mainRowIndex = data.mainRowIndex;
    this.mainFormRows = data.mainFormRows;
    this.spreadsheetLogData = data.spreadsheetLogData;
  }

  ngOnInit(): void {
    this.spreadsheetLogDataTemp = { ...this.spreadsheetLogData };
  }

  onClose() {
    this.dialogRef.close();
  }

  handleSaving(): void {
    this.errorMessage = '';
    this.isLoading = true;
    const postUrl = `${environment.apiUrl}/step-run/execute-action`;
    this.http
      .post(postUrl, {
        connectionName: this.spreadsheetLogDataTemp.connectionName,
        sheet_id: this.spreadsheetLogDataTemp.sheet_id,
        spreadsheet_id: this.spreadsheetLogDataTemp.spreadsheet_id,
        matchCase: this.spreadsheetLogDataTemp?.matchCase,
        include_team_drives: this.spreadsheetLogDataTemp.include_team_drives,
        searchValue: this.spreadsheetLogDataTemp.searchValue,
        realLabels: true,
      })
      .subscribe((res: any) => {
        this.isLoading = false;
        if (res?.result?.length) {
          this.dataSource = res.result || [];
          this.displayColumns = [
            'row',
            ...Object.keys(this.dataSource[0].values),
          ];
        } else {
          this.errorMessage = 'There is no result found.';
        }
        this.ref.markForCheck();
      });
  }

  generateColumns(table: any): any {
    return Object.keys(table[0]);
  }

  toggleClicked(e: any) {
    this.spreadsheetLogDataTemp.matchCase = e.checked;
  }

  onRowClick(index: number): void {
    this.selectedRowIndex = index;
    this.selectedRow = this.dataSource[index];
  }

  handleImporting(): void {
    for (const [key, value] of Object.entries(this.selectedRow.values)) {
      this.mainFormRows[this.mainRowIndex][key] = { value };

      if (this.mainFormRows[this.mainRowIndex][key].options) {
        this.mainFormRows[this.mainRowIndex][key].options.push({
          label: value,
          value,
        });
      }

      this.mainFormRows[this.mainRowIndex][key].autoCompleteValue = value;

      if (this.mainFormRows[this.mainRowIndex][key]?.options) {
        this.data.updateCandidate(
          value,
          this.mainFormRows[this.mainRowIndex][key].options,
          this.mainRowIndex,
          key
        );
      }

      this.data.updateItemValue(this.mainRowIndex, key, value, value);
    }
    this.data.updateForm(this.mainFormRows);
    this.dialogRef.close();
  }
}
