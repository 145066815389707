import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../common/base-model'
import { Cursor } from '../common/seek-page'

export const Conversation = Type.Object({
    ...BaseModelSchema,
    Image: Type.String(),
    Title: Type.String(),
    Subject: Type.String(),
    Summery: Type.String(),
    Tasgs: Type.Array(Type.String()),
    Status: Type.String(),
})

export type Conversation = Static<typeof Conversation>

export const ListConversationRequest = Type.Object({
    projectId: Type.Optional(Type.String({})),
    limit: Type.Optional(Type.Number({})),
    cursor: Type.Optional(Type.String({})),
    createdAfter: Type.Optional(Type.String({})),
    createdBefore: Type.Optional(Type.String({})),
})

export type ListConversationRequest = Static<typeof ListConversationRequest> & {
    cursor: Cursor
}

export enum FileSizeUnit {
    B = 'B',
    KB = 'KB',
    MB = 'MB',
}
