import { AgentPropertyMap } from "./property";
import { WebhookRenewConfiguration, TriggerStrategy, WebhookHandshakeConfiguration } from "./trigger/trigger";
import { ErrorHandlingOptionsParam } from "./action/action";
import { AgentAuthProperty } from "./property/authentication";
import { Type } from "@sinclair/typebox";
import { AgentCategory, ProjectId, TriggerTestStrategy } from "@upbrains/shared";

export const AgentBase = Type.Object({
  id: Type.Optional(Type.String()),
  name: Type.String(),
  displayName: Type.String(),
  logoUrl: Type.String(),
  description: Type.String(),
  projectId: Type.Optional(Type.String()),
  platformId: Type.Optional(Type.String()),
  directoryPath: Type.Optional(Type.String()),
  auth: Type.Optional(AgentAuthProperty),
  version: Type.String(),
  categories: Type.Optional(Type.Array(Type.Enum(AgentCategory))),
  minimumSupportedRelease: Type.Optional(Type.String()),
  maximumSupportedRelease: Type.Optional(Type.String()),
})

export type AgentBase = {
  id?: string;
  name: string;
  displayName: string;
  logoUrl: string;
  description: string;
  projectId?: ProjectId;
  platformId?: string;
  directoryPath?: string;
  auth?: AgentAuthProperty;
  version: string;
  categories?: AgentCategory[];
  minimumSupportedRelease?: string;
  maximumSupportedRelease?: string;
}

export const ActionBase = Type.Object({
  name: Type.String(),
  displayName: Type.String(),
  description: Type.String(),
  props: AgentPropertyMap,
  requireAuth: Type.Boolean(),
  errorHandlingOptions: Type.Optional(ErrorHandlingOptionsParam),
})

export type ActionBase = {
  name: string,
  displayName: string,
  description: string,
  props: AgentPropertyMap,
  requireAuth: boolean;
  errorHandlingOptions?: ErrorHandlingOptionsParam;
}

export const TriggerBase = Type.Composite([
  Type.Omit(ActionBase, ["requireAuth"]),
  Type.Object({
    type: Type.Enum(TriggerStrategy),
    sampleData: Type.Unknown(),
    handshakeConfiguration: Type.Optional(WebhookHandshakeConfiguration),
    renewConfiguration: Type.Optional(WebhookRenewConfiguration),
    testStrategy: Type.Enum(TriggerTestStrategy),
  })
])
export type TriggerBase = Omit<ActionBase, "requireAuth"> & {
  type: TriggerStrategy;
  sampleData: unknown,
  handshakeConfiguration?: WebhookHandshakeConfiguration;
  renewConfiguration?: WebhookRenewConfiguration;
  testStrategy: TriggerTestStrategy;
};

export const AgentMetadata = Type.Composite([
  AgentBase,
  Type.Object({
    actions: Type.Record(Type.String(), ActionBase),
    triggers: Type.Record(Type.String(), TriggerBase),
  })
])

export type AgentMetadata = AgentBase & {
  actions: Record<string, ActionBase>;
  triggers: Record<string, TriggerBase>;
};

export const AgentMetadataSummary = Type.Composite([
  Type.Omit(AgentMetadata, ["actions", "triggers"]),
  Type.Object({
    actions: Type.Number(),
    triggers: Type.Number(),
    suggestedActions: Type.Optional(Type.Array(Type.Object({
      name: Type.String(),
      displayName: Type.String(),
    }))),
    suggestedTriggers: Type.Optional(Type.Array(Type.Object({
      name: Type.String(),
      displayName: Type.String(),
    }))),
  })
])
export type AgentMetadataSummary = Omit<AgentMetadata, "actions" | "triggers"> & {
  actions: number;
  triggers: number;
  suggestedActions?: { name: string, displayName: string }[];
  suggestedTriggers?: { name: string, displayName: string }[];
}
