import {
  AuthenticationService,
  BuilderAutocompleteMentionsDropdownService,
  ConfirmationDialogComponent,
  ExtractorFileDialogComponent,
  ExtractorTextDialogComponent,
  NavigationService,
  OpensearchService,
  SearchDialogComponent,
  TableConfig,
  TableHelper,
  environment,
  flowActionsUiInfo,
} from '@upbrains/ui/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Attachment,
  EmailConfig,
  ExecutionOutputStatus,
  FlowRun,
  SearchTypes,
  StaticFormsData,
  WebhookPauseMetadata,
} from '@upbrains/shared';
import {
  Observable,
  catchError,
  concatMap,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ColumnDescriptionComponent } from './components/column-description.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatMenuTrigger } from '@angular/material/menu';
import { Clipboard } from '@angular/cdk/clipboard';
import { PdfViewerComponent } from 'ng2-pdf-viewer';

export type TableColumn = {
  key: string;
  value: string;
  query?: string;
  order?: number;
};

type TableRow = {
  key: string;
  value: string;
  query?: string;
  options?: {
    label: string;
    value: string;
  }[];
  autoCompleteValue?: string | null | undefined;
};

type ExtractorInfo = {
  display_name?: string;
  extractor_name?: string;
};

type OptionsType = {
  value?: string;
  matched_value?: string;
  score?: number;
  data?: any;
  Description?: string;
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-custom-forms',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements OnInit, OnDestroy {
  @Input() selectedRun$: Observable<FlowRun | undefined> = of(undefined);
  @Input() title = '';
  @Input() staticFormsData: StaticFormsData | undefined = undefined;
  @Input() continueUrl = '';
  @Input() formType:
    | 'form_submission'
    | 'file_submission'
    | 'form-builder'
    | 'rfq_form'
    | 'document_review'
    | 'order_entry' = 'rfq_form';
  isOrder = false;
  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  columns: TableColumn[] = [];
  displayedColumns: string[] = ['item_num'];
  rows: any[] = [];
  extractorInfoObj: any = {};
  readonly flowActionsUiInfo = flowActionsUiInfo;
  selectedItem = '';
  items: any = {};
  copiedItems: any = {};
  showFields = false;
  runId: string | undefined = '';
  isDoc = false;
  fieldsForm: any = {};
  shortFieldsForm: any[] = [];
  longFieldsForm: any[] = [];
  integrateShortAndLongFields: any[] = [];
  ItemsForm: any = [];
  autoCompleteInputValue = '';
  emailConfig: EmailConfig | undefined = undefined;
  attachments: Attachment[] | undefined = [];
  fileUrl: string | undefined = undefined;
  text: string | undefined = undefined;
  isSaving = false;
  showTable = false;
  formPages: { page_number: number; text: string }[] = [];

  links: { approvalLink?: string; disapprovalLink?: string } | null = {};
  searchType: 'none' | 'spread_sheet' | 'custom_search_api' | undefined;
  @ViewChild('menuTrigger', { static: true }) matMenuTrigger!: MatMenuTrigger;
  menuTopLeftPosition = { x: '0px', y: '0px' };
  highlightedText = '';
  textToCopy = '';
  highlightedFieldObject: any;
  currentViewMode: 'email' | 'text' | 'file' | 'json' = 'email';
  isLoading = true;
  isError = false;
  isPdf = true;
  firstContainer = false;
  secondContainer = false;
  thirdContainer = false;
  convertedFieldsObj: {
    key: string;
    value: string;
  }[] = [];
  page = 1;
  totalPages = 1;
  hasPrevPage = false;
  hasNextPage = true;
  isFieldOrItemMutated = false;
  upbrainsToken = '';
  pdfUrl: SafeResourceUrl = '';
  currentPageIndex = 0;
  hasJsonButton = false;
  prevRoute = '/action-center';
  prevRouteTitle = 'Forms';
  isFieldsOrItemsChanged = false;
  flowRunStatus: ExecutionOutputStatus = ExecutionOutputStatus.PAUSED;
  queryResult$!: Observable<any>;
  conversationId = '';
  keyFieldsValidation: any = {};
  lineNumber: string[] | undefined = [];
  hasMatchesInPrimaryKeys = false;
  hasMatchesInLineItems = false;
  updateMatchesState = (hasMatches: boolean) => {
    this.hasMatchesInLineItems = hasMatches;
  };

  @ViewChild(PdfViewerComponent, { static: false })
  private pdfComponent?: PdfViewerComponent;
  constructor(
    private navigationService: NavigationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    public builderAutocompleteService: BuilderAutocompleteMentionsDropdownService,
    private http: HttpClient,
    private ref: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private authenticationService: AuthenticationService,
    private opensearchService: OpensearchService
  ) {}

  //Todo: I suposed definition Types
  column: any[] = [];
  data: any[] = [];
  config: TableConfig<any> = new TableConfig<any>('100%');

  ngOnInit(): void {
    this.selectedRun$.subscribe((selectedRun) => {
      if (selectedRun?.status) {
        this.flowRunStatus = selectedRun.status;
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.prevRoute = params['prevRoute']
        ? `/${params['prevRoute']}/details`
        : '/action-center';

      this.prevRouteTitle = params['prevRoute'] ?? 'Forms';
    });

    const urlSegments = this.route.snapshot.url;
    if (urlSegments.length > 0) {
      this.runId = urlSegments[urlSegments.length - 1].path;
    }

    this.hasJsonButton = this.staticFormsData?.hasJsonButton ?? false;

    this.route.queryParamMap.subscribe((params) => {
      const conversationIdFromProps = params.get('conversationId') || '';
      const conversationIdFromData = this.staticFormsData?.conversationId ?? '';
      this.conversationId =
        conversationIdFromProps || conversationIdFromData || '';
    });

    this.keyFieldsValidation = this.staticFormsData?.keyFieldsValidation ?? {};

    if (this.staticFormsData?.formData) {
      this.searchType = this.staticFormsData?.formSearchType;
      this.items =
        'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.documents?.[0]?.items
          : this.staticFormsData.formData?.extractor_result?.documents?.[0]
              ?.items;

      this.showTable = Boolean(this.items?.length);
      this.formPages =
        'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.pages
          : this.staticFormsData.formData?.extractor_result?.pages;

      this.fieldsForm =
        'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.documents?.[0]?.fields
          : this.staticFormsData.formData?.extractor_result?.documents?.[0]
              ?.fields;

      const fields =
        'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.documents?.[0]?.fields
          : this.staticFormsData.formData?.extractor_result?.documents?.[0]
              ?.fields;

      this.emailConfig = this.staticFormsData.formEmailConfig;
      this.attachments = this.staticFormsData.formAttachments;

      if (fields) {
        Object.entries(fields).forEach(([key, value], index) => {
          if (
            value.details?.['value'] &&
            value.details?.['value'].length > 30
          ) {
            if (
              this.staticFormsData?.formFieldsOrder?.fields &&
              Object.keys(this.staticFormsData?.formFieldsOrder?.fields)
                .length &&
              this.staticFormsData?.formFieldsOrder?.fields?.[key]
            ) {
              this.longFieldsForm.push({
                ...value,
                key: value.field_name,
                value: value.content,
                isFixedKey: true,
                order:
                  this.staticFormsData?.formFieldsOrder?.fields?.[key] || index,
              });
            } else if (
              !this.staticFormsData?.formFieldsOrder?.fields ||
              (this.staticFormsData?.formFieldsOrder?.fields &&
                !Object.keys(this.staticFormsData?.formFieldsOrder?.fields)
                  .length)
            ) {
              this.longFieldsForm.push({
                ...value,
                key: value.field_name,
                value: value.content,
                isFixedKey: true,
                order: index,
              });
            }

            this.longFieldsForm.sort((a, b) => {
              if (a.order === undefined && b.order === undefined) return 0;
              if (a.order === undefined) return 1;
              if (b.order === undefined) return -1;
              return a.order - b.order;
            });
          } else {
            if (
              this.staticFormsData?.formFieldsOrder?.fields &&
              Object.keys(this.staticFormsData?.formFieldsOrder?.fields)
                .length &&
              this.staticFormsData?.formFieldsOrder?.fields?.[key]
            ) {
              this.shortFieldsForm.push({
                ...value,
                key: value.field_name,
                value: value.content,
                isFixedKey: true,
                order: this.staticFormsData?.formFieldsOrder?.fields?.[key],
              });
            } else if (
              !this.staticFormsData?.formFieldsOrder?.fields ||
              (this.staticFormsData?.formFieldsOrder?.fields &&
                !Object.keys(this.staticFormsData?.formFieldsOrder?.fields)
                  .length)
            ) {
              this.shortFieldsForm.push({
                ...value,
                key: value.field_name,
                value: value.content,
                isFixedKey: true,
                order: index,
              });
            }

            this.shortFieldsForm.sort((a, b) => {
              if (a.order === undefined && b.order === undefined) return 0;
              if (a.order === undefined || typeof a.order !== 'number')
                return 1;
              if (b.order === undefined || typeof b.order !== 'number')
                return -1;
              return a.order - b.order;
            });
            this.integrateShortAndLongFields = [
              ...this.shortFieldsForm,
              ...this.longFieldsForm,
            ];
          }
        });
      }

      const targetKeys = [
        'Shipping To Address',
        'Shipping Address',
        'Ship To Address',
      ];
      const shippingAddress = this.integrateShortAndLongFields.find((item) =>
        targetKeys.includes(item.key)
      );

      if (shippingAddress?.content) {
        if (this.keyFieldsValidation?.['Ship To Address'] === 'false') {
          const tempIntegrateShortAndLongFields = [
            ...this.integrateShortAndLongFields,
          ];

          const shippingAddressIndex =
            tempIntegrateShortAndLongFields.findIndex((item) =>
              targetKeys.includes(item.key)
            );

          if (shippingAddressIndex >= 0) {
            tempIntegrateShortAndLongFields[shippingAddressIndex] = {
              ...shippingAddress,
              alternative: 'This shipping address is not found on file.',
            };
          }
          this.hasMatchesInPrimaryKeys = true;
          this.integrateShortAndLongFields = tempIntegrateShortAndLongFields;
        }
      }

      this.ItemsForm = this.staticFormsData.formData
        ? 'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.documents?.[0]?.items
          : this.staticFormsData.formData?.extractor_result?.documents?.[0]
              ?.items
        : null;
      this.copiedItems =
        this.items && JSON.stringify(this.items)
          ? JSON.parse(JSON.stringify(this.items))
          : [];

      this.rows = this.serializeTableRowData(this.items);

      if ('updated_extractor_payload' in this.staticFormsData.formData) {
        this.generateExtractorName(
          this.staticFormsData.formData.updated_extractor_payload
            ?.extractor_info
        );
      } else if (this.staticFormsData.formData?.extractor_info) {
        this.generateExtractorName(
          this.staticFormsData.formData.extractor_info
        );
      }

      this.showFields =
        this.formType.includes('document_review') ||
        this.formType.includes('order_entry');

      this.isDoc = this.formType.includes('document_review');

      this.text = this.staticFormsData.formText;
      this.fileUrl =
        typeof this.staticFormsData.formFileUrl === 'object' &&
        this.staticFormsData.formFileUrl !== null
          ? this.staticFormsData.formFileUrl.signedUrl
          : (this.staticFormsData.formFileUrl as string | undefined);

      if (this.fileUrl) {
        if (this.fileUrl.split('.').pop()?.toLowerCase() === 'png') {
          this.isPdf = false;
          this.isLoading = false;
        } else {
          this.isPdf = true;

          setTimeout(() => {
            const tempPdfUrl = String(this.fileUrl);
            this.pdfUrl =
              this.sanitizer.bypassSecurityTrustResourceUrl(tempPdfUrl);
            this.ref.detectChanges();
          }, 1000);
        }
      }

      //----------------------------------------------------------------
      this.TableUpdate();
      //----------------------------------------------------------------

      this.firstContainer = Boolean(
        this.text || this.fileUrl || this.hasJsonButton
      );
      this.secondContainer = Boolean(this.integrateShortAndLongFields.length);
      this.thirdContainer = Boolean(this.showTable);

      const fieldKeyValues = this.fieldsForm
        ? Object.keys(this.fieldsForm)
            .filter((field) => this.fieldsForm[field].content)
            .map((field) => ({
              key: field,
              value: this.fieldsForm[field].content,
            }))
        : [];

      if (this.ItemsForm) {
        const itemsKeyValue = this.ItemsForm.reduce((acc: any, item: any) => {
          Object.keys(item)
            .filter((key) => key !== 'page_number')
            .forEach((key) => {
              if (item[key]?.content) {
                acc.push({
                  key: key,
                  value: item[key].content,
                });
              }
            });
          return acc;
        }, []);
        this.convertedFieldsObj = [...fieldKeyValues, ...itemsKeyValue];
      }

      this.formPages = this.initialHighlightText();
      this.currentViewMode = this.emailConfig
        ? 'email'
        : this.text
        ? 'text'
        : 'file';
    }
  }

  getQueries(descList: string[], indexName: string) {
    const payload = {
      query_list: descList,
      project_id: this.authenticationService.getProjectId(),
      index_name: indexName,
    };

    return this.opensearchService.getQuery(payload);
  }

  openSearchModal(rowIndex: number, query?: string) {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.width = '50%';

      const displayedColumnsTemp = [...this.displayedColumns];
      displayedColumnsTemp.splice(
        displayedColumnsTemp.findIndex((item) => item === 'remove'),
        1
      );

      if (this.staticFormsData.spreadsheetLogData) {
        this.staticFormsData.spreadsheetLogData.searchValue = query;
      }
      const dataObj = {
        mainRowIndex: rowIndex,
        mainFormRows: this.rows,
        updateForm: this.updateForm,
        updateItemValue: this.updateItemValue,
        spreadsheetLogData: this.staticFormsData?.spreadsheetLogData,
        updateCandidate: this.handleKeyDown,
      };
      dialogConfig.data = dataObj;
      this.matDialog.open(SearchDialogComponent, dialogConfig);
    }
  }

  findDescriptionByProductNumber(productNumber: string) {
    for (const item of this.copiedItems) {
      for (const match of item.Description.matches) {
        const candidate = match.candidates.find(
          (candidate: any) =>
            candidate.data?.['Product Number'] === productNumber
        );
        if (candidate) {
          return candidate.data.Description;
        }
      }
    }
    return null;
  }

  TableUpdate(event?: { selectedValue: string; rowIndex: number }) {
    if (event) {
      const { selectedValue, rowIndex } = event;

      const description = this.findDescriptionByProductNumber(selectedValue);
      this.updateItemValue(rowIndex, 'Description', description);
      this.ref.detectChanges();
    }

    let allItemKeys = {};
    if (this.items?.length) {
      this.items.map((item: any) =>
        Object.keys(item)
          .filter((key) => key !== 'page_number')
          .map((key, index) => {
            allItemKeys = { ...allItemKeys, [key]: index };
          })
      );
    }

    this.lineNumber = this.items?.length
      ? this.items.map(
          (item: any, index: number) => item['Line No']?.content || index + 1
        )
      : [];

    const columns = TableHelper.convertToTableColumns(
      this.copiedItems,
      ColumnDescriptionComponent,
      this.staticFormsData?.formFieldsOrder?.columns || allItemKeys,
      {
        ...(this.searchType !== SearchTypes.None && {
          searchEvent: (data: any, index: number) => {
            this.openSearchModal(index, data);
          },
        }),
        onEdit: (index: number, colKey: string, value: string) => {
          this.updateItemValue(index, colKey, value);
          this.ref.detectChanges();
        },
      },
      (index, columnKey, value) => {
        this.updateItemValue(index, columnKey, value);
      }
    );

    const data = TableHelper.convertToTableData(
      this.copiedItems,
      this.updateMatchesState
    );

    if (columns) {
      columns.push({
        title: 'Operations',
        type: 'children',
        render: (params: any) => {
          const { element, setOnClick } = TableHelper.trashIcon(params);
          setOnClick((index) => {
            this.openConfirmationDialog(index);
          });
          return element;
        },
      });
    }

    this.column = columns ?? [];
    this.data = data;
    this.data.map((item) => {
      if (
        (item?.['Quantity'] && Object.keys(item?.['Quantity'])?.length) ||
        (item?.['Unit Price'] && Object.keys(item?.['Unit Price'])?.length)
      ) {
        this.column = this.column.map((item) =>
          item.name === 'Quantity' || item.name === 'Unit Price'
            ? { ...item, isMatched: true }
            : item
        );
      }
    });

    this.ref.markForCheck();
  }

  ngOnDestroy(): void {
    this.snackbar.dismiss();
  }

  getFilteredOptions(
    options: { label: string; value: string }[],
    inputValue: string
  ): any {
    if (!inputValue) return options;
    return this.filter(inputValue, options);
  }

  private filter(
    value: string,
    options: { label: string; value: string }[]
  ): any[] {
    const filterValue = value?.toLowerCase();
    return options.filter((option) =>
      option.label?.toLowerCase().includes(filterValue)
    );
  }

  handleKeyDown = (
    value: string,
    rowOptions: any,
    rowIndex: number,
    columnKey: string
  ) => {
    this.addOptionIfNotExists(value, rowOptions, rowIndex);
    this.updateItemValue(rowIndex, columnKey, value);
  };

  addOptionIfNotExists(
    value: string,
    options: { label: string; value: string }[],
    rowIndex: number
  ): any {
    if (!value) return;
    const exists = options.some((option) =>
      option.label?.toLowerCase().includes(value?.toLowerCase())
    );
    if (!exists) {
      const newOption = { label: value, value: value };
      options.push(newOption);
    }

    // set the input value for the specific row
    this.rows[rowIndex].autoCompleteInputValue = value;

    return options;
  }

  openFile(): void {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      // Set full width and height
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.panelClass = 'full-screen-dialog';
      dialogConfig.data = { fileUrl: this.fileUrl };
      this.matDialog.open(ExtractorFileDialogComponent, dialogConfig);
    }
  }
  openText(): void {
    if (this.staticFormsData?.formData) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;

      // Set full width and height
      dialogConfig.width = '100%';
      dialogConfig.height = '100%';
      dialogConfig.maxWidth = '80vw';
      dialogConfig.maxHeight = '80vh';
      dialogConfig.panelClass = 'full-screen-dialog';

      const formPages =
        'updated_extractor_payload' in this.staticFormsData.formData
          ? this.staticFormsData.formData.updated_extractor_payload
              ?.extractor_result?.pages
          : this.staticFormsData.formData?.extractor_result?.pages;

      const dataObj = {
        formFields: this.fieldsForm,
        formItems: this.ItemsForm,
        formPages: formPages,
      };
      dialogConfig.data = dataObj;
      this.matDialog.open(ExtractorTextDialogComponent, dialogConfig);
    }
  }

  onFieldChange(content: string, key: string) {
    const updatedFormData = this.staticFormsData?.formData;
    if (updatedFormData) {
      let currentFieldObject: any = {};
      if ('updated_extractor_payload' in updatedFormData) {
        // Handling FormDataType
        currentFieldObject =
          updatedFormData.updated_extractor_payload.extractor_result
            .documents[0]['fields']?.[key.replace(/\s+/g, '')];
      } else {
        // Handling UpdatedExtractorPayload
        currentFieldObject =
          updatedFormData.extractor_result.documents[0]['fields']?.[
            key.replace(/\s+/g, '')
          ];
      }

      currentFieldObject.content = content;
      currentFieldObject.content_updated = true;
      this.isFieldOrItemMutated = true;
    }
  }

  onItemChange = (event: any) => {
    const { newValue, rowIndex, colDef } = event;

    const updatedFormData = this.staticFormsData?.formData;
    if (updatedFormData) {
      let currentItemObject: any = {};
      if ('updated_extractor_payload' in updatedFormData) {
        // Handling FormDataType
        currentItemObject =
          updatedFormData.updated_extractor_payload.extractor_result
            .documents[0]['items']?.[rowIndex];
      } else {
        // Handling UpdatedExtractorPayload
        currentItemObject =
          updatedFormData.extractor_result.documents[0]['items']?.[rowIndex];
      }

      currentItemObject[colDef.field].content = newValue;
      currentItemObject[colDef.field].content_updated = true;
      this.isFieldOrItemMutated = true;
    }
  };

  handleSaving(): void {
    this.isSaving = true;
    const updatedFormData = this.staticFormsData?.formData;

    if (!updatedFormData) {
      this.isSaving = false;
      console.error('there is no change to save');
    } else if (this.isFieldOrItemMutated) {
      const postUrl = `${environment.igniteUrl}/integration-apis/update-extractor-payload-details`;

      this.upbrainsToken = this.authenticationService.getDecodedIgniteToken();

      this.http
        .post(postUrl, JSON.stringify({ extractor_payload: updatedFormData }), {
          headers: new HttpHeaders({
            Authorization: this.upbrainsToken,
          }),
        })
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            this.isSaving = false;
            this.snackbar.open(
              'There was an error while sending the data for extraction.'
            );
            return of(null);
          }),
          switchMap((res: any) => {
            if (!res) {
              return of(null); // Stop further execution if the response is null
            }

            return this.selectedRun$.pipe(
              take(1),
              switchMap((flowRun) => {
                if (!flowRun) {
                  console.error('No flow run found.');
                  return throwError('No flow run found.');
                }

                const requestId = (
                  flowRun.pauseMetadata as WebhookPauseMetadata
                ).requestId;
                const postUrl = `${environment.apiUrl}/flow-runs/${this.runId}/requests/${requestId}`;
                return this.http.post(postUrl, res.updated_payload).pipe(
                  catchError((error) => {
                    console.log('Error', error);
                    this.isSaving = false;
                    this.snackbar.open(
                      'There was an error while saving the form.'
                    );
                    return of(null);
                  })
                );
              })
            );
          }),
          // Only navigate after everything works successfully
          concatMap((res) => {
            // Ensure that the process is successful before navigating
            if (res !== null) {
              return of(null).pipe(
                // Delay if needed (e.g., 2 seconds)
                switchMap(
                  () => new Promise((resolve) => setTimeout(resolve, 2000))
                )
              );
            } else {
              // If any step failed, stop the chain
              return of(null);
            }
          })
        )
        .subscribe(() => {
          setTimeout(() => {
            this.isSaving = false;
            this.router.navigate([this.prevRoute]);
          }, 2000);
        });
    } else {
      this.selectedRun$.pipe(take(1)).subscribe((flowRun) => {
        if (!flowRun) {
          console.error('No flow run found.');
          return;
        }

        const requestId = (flowRun.pauseMetadata as WebhookPauseMetadata)
          .requestId;
        const postUrl = `${environment.apiUrl}/flow-runs/${this.runId}/requests/${requestId}`;

        this.http
          .post(postUrl, updatedFormData)
          .pipe(
            catchError((error) => {
              console.error('Error:', error);
              this.isSaving = false;
              this.snackbar.open('There was an error while saving the form.');
              return of(null);
            })
          )
          .subscribe(() => {
            setTimeout(() => {
              this.isSaving = false;
              this.router.navigate([this.prevRoute]);
            }, 2000);
          });
      });
    }
  }

  redirectHome(newWindow: boolean) {
    this.navigationService.navigate(this.prevRoute, newWindow);
  }

  redirectToInbox() {
    this.navigationService.navigate(
      this.prevRoute === '/action-center'
        ? '/action-center'
        : `${this.prevRoute}/${this.conversationId}`,
      false
    );
  }

  hasQuery(key: string): boolean {
    return !!key?.length;
  }

  hasProperties(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  setInputForCustomization(index: number, value: string): void {
    this.selectedItem = `${index}-${value}`;
  }

  isPointerEventsNone(index: number, value: string): boolean {
    return `${index}-${value}` === this.selectedItem;
  }

  generateExtractorName(extractorInfo: ExtractorInfo) {
    this.extractorInfoObj = {
      ...(extractorInfo.display_name && { Name: extractorInfo.display_name }),
      ...(extractorInfo.extractor_name && {
        Extractor: extractorInfo.extractor_name,
      }),
    };
  }

  serializeTableRowData(
    items: { [key: string]: any }[]
  ): { [key: string]: TableRow | string }[] {
    if (!items?.length) return [];
    return items.map((item) => {
      const serializedItem: { [key: string]: TableRow | string } = {};
      Object.entries(item).forEach(([key, value]) => {
        if (
          value?.matches &&
          Array.isArray(value.matches) &&
          value.matches.length > 0
        ) {
          serializedItem[key] = {
            key: key,
            value:
              value.matches[0]?.result[0]?.data?.['Description'] ||
              value.matches[0]?.result[0]?.[key] ||
              '',
            options: [
              ...(value.matches[0]?.result[0]?.data?.['Description']
                ? [
                    {
                      label: value.matches[0]?.result[0]?.data?.['Description']
                        ? value.matches[0].result[0].data?.['Description']
                        : value.matches[0]?.result[0]?.[key],
                      value: value.matches[0]?.result[0]?.data?.['Description']
                        ? value.matches[0].result[0].data?.['Description']
                        : value.matches[0]?.result[0]?.[key],
                    },
                  ]
                : []),
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...value.matches[0]?.candidates?.map(
                (candidate: {
                  matched_value?: string | null;
                  score: number;
                  data?: any;
                }) => ({
                  label:
                    candidate?.data?.['Description'] ||
                    (key === 'Description'
                      ? candidate.data?.['Description']
                      : ''),
                  value:
                    candidate?.data?.['Description'] ||
                    (key === 'Description'
                      ? candidate?.data?.['Description']
                      : ''),
                })
              ),
            ],
            query: value.matches[0]?.query,
            autoCompleteValue: value.matches[0]?.result[0]?.data?.[
              'Description'
            ]
              ? value.matches[0].result[0]?.data?.['Description']
              : value.matches[0].result[0]?.[key],
          };
        } else {
          serializedItem[key] = {
            key: key,
            value: value?.details?.value || value?.content || '',
          };
        }
      });
      return serializedItem;
    });
  }

  isObjectInArray(newObj: OptionsType, arr: OptionsType[]) {
    return arr.some(
      (obj) =>
        obj.value === newObj.value &&
        obj?.['Description'] === newObj?.Description &&
        obj.score === newObj.score
    );
  }

  updateItemValue = (
    rowIndex: number,
    columnKey: string,
    value: any,
    label?: string
  ): void => {
    const item = this.copiedItems[rowIndex];
    if (item[columnKey]?.matches && Array.isArray(item[columnKey].matches)) {
      const result = item[columnKey].matches[0].result[0];
      item[columnKey].matches[0].result[0].data = {
        ...item[columnKey].matches[0].result[0].data,
        value:
          value ||
          item[columnKey].matches[0]?.result[0]?.data?.[columnKey] ||
          '',
        [columnKey]:
          value ||
          item[columnKey].matches[0]?.result[0]?.data?.[columnKey] ||
          '',
      };
      if (!label) {
        const newOption = {
          data: { [columnKey]: value },
          score: 100,
        };
        if (
          !item[columnKey].matches[0].candidates.some(
            (candidate: any) =>
              candidate?.data?.[columnKey] == newOption?.data?.[columnKey]
          )
        ) {
          item[columnKey].matches[0].candidates.push(newOption);
        }
        if (
          result &&
          !item[columnKey].matches[0].candidates.some(
            (candidate: any) =>
              candidate?.data?.[columnKey] === result?.data?.[columnKey]
          )
        ) {
          item[columnKey].matches[0].candidates.push(result);
        }
      }
    } else {
      if (item[columnKey]?.content) {
        item[columnKey].content = value;
      }
      if (item[columnKey]?.details?.value) {
        item[columnKey].details.value = value;
      }
    }

    this.TableUpdate();
  };

  addNewRow(): void {
    const newItem = {} as { [key: string]: any };

    // Populate the new item with empty/default values based on the first existing item
    const sampleItem = (this.copiedItems[0] as { [key: string]: any }) || {};
    for (const key of Object.keys(sampleItem)) {
      if (Array.isArray(sampleItem[key])) {
        newItem[key] = [
          {
            query: '',
            result: [
              {
                score: 0,
                value: '',
                Description: '',
              },
            ],
            status: 200,
            metadata: {
              action: 'Find Partial Match',
              category: 'Find Partial Match',
            },
            uncertain: false,
            candidates: [],
          },
        ];
      } else if (typeof sampleItem[key] === 'object') {
        newItem[key] = {
          content: '',
          details: {
            value: 0,
          },
          field_name: key,
          type: typeof sampleItem[key]?.details?.value,
        };
      } else {
        newItem[key] = '';
      }
    }

    this.copiedItems.push(newItem);
    this.rows = this.serializeTableRowData(this.copiedItems);

    this.TableUpdate();
  }

  isLongText(value: string): boolean {
    return value.length > 30;
  }

  openConfirmationDialog(index: number): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Delete Row',
        description: 'Are you sure you want to delete this row?',
        onClose: () => {
          console.log('Dialog was closed');
        },
        onConfirm: () => {
          this.removeRow(index);
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Confirmed');
      } else {
        console.log('Cancelled');
      }
    });
  }

  removeRow(index: number): void {
    this.copiedItems.splice(index, 1);
    this.rows = this.serializeTableRowData(this.copiedItems);
    this.TableUpdate();
    this.ref.markForCheck();
  }

  updateForm = (form: any[]) => {
    this.rows = form;
    this.ref.detectChanges();
  };

  /**
   * Functionality for highlight whole fields and items at the first load of the text
   *
   * @returns {{ text: string; page_number: number }[]}
   */
  initialHighlightText(): { text: string; page_number: number }[] {
    if (!this.formPages?.length) return [];
    return this.formPages.map((originalTextObj) => {
      let modifiedText = originalTextObj.text;

      this.convertedFieldsObj.forEach((target) => {
        if (target.value) {
          const regex = new RegExp(
            `(?<=\\s|^|[^\\w])${target.value.replace(
              /[-/\\^$*+?.()|[\]{}]/g,
              '\\$&'
            )}(?=\\s|$|[^\\w])`,
            'g'
          );
          modifiedText = modifiedText.replace(
            regex,
            `<span class="ap-bg-yellow-50 ap-rounded-md">${target.value}</span>`
          );
        }
      });

      return { text: modifiedText, page_number: originalTextObj.page_number };
    });
  }

  /**
   * Functionality for highlighting the selected word(s)
   *
   * @param {string} modifiedText
   * @param {string} pageNumber
   * @param {any[]} mainArray
   * @returns {{ text: string; page_number: number }[] | undefined}
   */
  highlightText(
    modifiedText: string,
    pageNumber: string,
    mainArray: any[]
  ): { text: string; page_number: number }[] | undefined {
    this.findSelectedObject(modifiedText, mainArray);
    let modifiedPageNumber = pageNumber;
    if (pageNumber) {
      if (pageNumber === String(this.currentPageIndex + 1)) {
        modifiedPageNumber = pageNumber;
      } else if (pageNumber?.includes(String(this.currentPageIndex + 1))) {
        modifiedPageNumber = String(this.currentPageIndex + 1);
      } else {
        modifiedPageNumber = pageNumber;
        this.currentPageIndex = Number(pageNumber) - 1;
      }
    } else {
      return undefined;
    }

    const formPagesTemp = [...this.formPages];

    const currentTextObj = formPagesTemp.find(
      (page) => page.page_number === Number(modifiedPageNumber)
    );

    if (currentTextObj) {
      const indexOfCurrentTextObj = formPagesTemp.indexOf(currentTextObj);

      let currentText = formPagesTemp[indexOfCurrentTextObj].text;

      // refreshing the text if there is any highlighting since before
      const replacedRegex =
        /<span\s+id="highlighted"\s+class="ap-text-black ap-bg-yellow-300 ap-rounded-md"[^>]*>([^<]*)<\/span>/g;

      currentText = currentText.replace(replacedRegex, '$1');

      const regex = new RegExp(
        `(?<=\\s|^|[^\\w])${modifiedText.replace(
          /[-/\\^$*+?.()|[\]{}]/g,
          '\\$&'
        )}(?=\\s|$|[^\\w])`,
        'g'
      );
      currentText = currentText.replace(
        regex,
        `<span id="highlighted" class="ap-text-black ap-bg-yellow-300 ap-rounded-md">${modifiedText}</span>`
      );
      formPagesTemp[indexOfCurrentTextObj] = {
        text: currentText,
        page_number: this.currentPageIndex + 1,
      };

      this.formPages = formPagesTemp;
      this.ref.detectChanges();

      setTimeout(() => this.scrollToHighlightedWord(), 0);
      return this.formPages;
    }
    return undefined;
  }

  /**
   * Scroll to the highlighted word
   */
  scrollToHighlightedWord() {
    const element = document.getElementById('highlighted');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  /**
   * Functionality for getting the whole object of selected input of fields
   * we can use this functionality to copy the selected word(s) into the exact field input
   *
   * @param {string} content
   * @param {any[]} array
   */
  findSelectedObject(content: string, array: any[]) {
    const tempArray = [...array];
    this.highlightedFieldObject = tempArray.find(
      (obj) => obj.content === content
    );
  }

  /**
   * Functionality for context menu
   * this function is define the highlighted word(s) and
   * open the context menu
   * the position of the context menu is set 10px further from X and Y
   *
   * @param {MouseEvent} event
   */
  onRightClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.matMenuTrigger.menuOpen) {
      this.matMenuTrigger.closeMenu();
    }

    const selectedText = this.getSelectedText();
    const clickedText = this.getWordUnderMouse(event);

    if (selectedText) {
      this.textToCopy = selectedText;
    } else if (clickedText) {
      this.textToCopy = clickedText;
    }

    this.menuTopLeftPosition.x = event.clientX + 10 + 'px';
    this.menuTopLeftPosition.y = event.clientY + 10 + 'px';

    setTimeout(() => {
      this.matMenuTrigger.openMenu();
    }, 0);
  };

  /**
   * Functionality for defining and finding the highlighted word(s)
   * this functionality is working by mouse position after right click
   * we can select multiple words to copy
   *
   * @returns {string | null}
   */
  getSelectedText(): string | null {
    const selection = window.getSelection();

    if (selection && selection.toString().length > 0) {
      return selection.toString().trim();
    }

    return null;
  }

  /**
   * Functionality for defining and finding the exact selected word of text
   * this functionality is working by mouse position after right click
   *
   * @param {MouseEvent} event
   * @returns {string | null} - e.g. STATIONERY | null
   */
  getWordUnderMouse(event: MouseEvent): string | null {
    const range = document.caretRangeFromPoint(event.clientX, event.clientY);

    if (range && range.startContainer.nodeType === Node.TEXT_NODE) {
      const textContent = range.startContainer.textContent || '';
      const offset = range.startOffset;

      const before = textContent.slice(0, offset).split(/\s+/).pop() || '';
      const after = textContent.slice(offset).split(/\s+/).shift() || '';

      return before + after;
    }

    return null;
  }

  /**
   * Functionality for copying the highlighted text to the selected field of field inputs
   */
  copyToField() {
    if (this.textToCopy) {
      this.highlightedFieldObject.content = this.textToCopy;
    }
  }

  /**
   * Functionality for copying the highlighted text to the clipboard
   */
  copyToClipboard() {
    if (this.textToCopy) {
      this.clipboard.copy(this.textToCopy);
    }
  }

  /**
   * Functionality for switching between PDF and Text view
   *
   * @param {string} viewMode
   * @returns {string} - file / text
   */
  switchPdfTextMode(viewMode: string): string {
    if (viewMode === 'text') {
      return (this.currentViewMode = 'text');
    } else if (viewMode === 'file') {
      return (this.currentViewMode = 'file');
    } else if (viewMode === 'email') {
      return (this.currentViewMode = 'email');
    }
    return (this.currentViewMode = 'json');
  }

  /**
   * Functionality for doing some assignment after the Pdf loaded completely
   * This could be getting the pdf total pages and
   * checking for prev/next pages
   *
   * @param {any} pdf
   */
  onPdfLoadComplete(pdf: any) {
    this.isLoading = false;
    this.totalPages = pdf.numPages;
    if (this.totalPages === 1 || this.totalPages < 1) {
      this.hasNextPage = false;
      this.hasPrevPage = false;
    }
  }

  /**
   * Functionality for handling the errors of PDF
   *
   * @param {any} event
   */
  onPdfError(event: any) {
    this.isLoading = false;
    this.isError = true;
  }

  /**
   * Functionality for fitting the pdf to the largest scale of container
   */
  pageRendered() {
    if (this.pdfComponent) {
      this.pdfComponent.pdfViewer.currentScaleValue = 'page-fit';
    }
  }

  /**
   * Functionality for going to next page in pdf
   */
  goToNextPage() {
    if (this.page < this.totalPages) {
      this.page++;
      this.hasPrevPage = true;
    } else {
      this.hasNextPage = false;
      this.hasPrevPage = true;
    }
  }

  /**
   * Functionality for going to previous page in pdf
   */
  goToPreviousPage() {
    if (this.page > 1) {
      this.page--;
      this.hasNextPage = true;
    } else {
      this.hasNextPage = true;
      this.hasPrevPage = false;
    }
  }

  /**
   * handle main container ag-grid class
   */
  handlingAgGridClassMainContainer(): string[] {
    if (
      (!this.firstContainer && !this.secondContainer) ||
      (!this.firstContainer && !this.thirdContainer) ||
      (!this.secondContainer && !this.thirdContainer)
    ) {
      return ['ap-grid-cols-1'];
    }

    if (
      (this.firstContainer && this.thirdContainer) ||
      (this.secondContainer && this.thirdContainer) ||
      (this.firstContainer && this.secondContainer)
    ) {
      return ['ap-grid-cols-2'];
    }

    if (this.firstContainer && this.secondContainer && this.thirdContainer) {
      return ['ap-grid-cols-3'];
    }

    return [];
  }

  /**
   * handle first (fields) container ag-grid class
   */
  handlingAgGridClassFieldsContainer(): string[] {
    if (this.secondContainer && this.thirdContainer) {
      return ['ap-row-span-2'];
    }

    if (
      (this.secondContainer && !this.thirdContainer) ||
      (!this.secondContainer && this.thirdContainer)
    ) {
      return ['ap-col-span-1 ap-row-span-1'];
    }

    if (!this.secondContainer && !this.thirdContainer) {
      return ['ap-grid-cols-3'];
    }

    return [];
  }

  /**
   * handle second (text/pdf) container ag-grid class
   */
  handlingAgGridClassTextContainer(): string[] {
    if (this.firstContainer && this.thirdContainer) {
      return ['ap-col-span-2 ap-row-span-1'];
    }

    if (!this.firstContainer && this.thirdContainer) {
      return ['ap-col-span-2 ap-row-span-2'];
    }

    if (this.firstContainer && !this.thirdContainer) {
      return ['ap-col-span-1 ap-row-span-1'];
    }

    if (!this.firstContainer && !this.thirdContainer) {
      return ['ap-col-span-1 ap-row-span-1 ap-w-full ap-h-full'];
    }

    return [];
  }

  /**
   * handle third (table) container ag-grid class
   */
  handlingAgGridClassTableContainer(): string[] {
    if (this.firstContainer && this.secondContainer) {
      return ['ap-col-span-2 ap-row-span-1'];
    }

    if (!this.firstContainer && this.secondContainer) {
      return ['ap-col-span-2 ap-row-span-2'];
    }

    if (this.firstContainer && !this.secondContainer) {
      return ['ap-col-span-1 ap-row-span-1'];
    }

    if (!this.firstContainer && !this.secondContainer) {
      return ['ap-col-span-1 ap-row-span-1 ap-w-full ap-h-full'];
    }

    return [];
  }

  onFieldsSubmit(fields: any[]): void {
    this.integrateShortAndLongFields = fields;
  }

  onFieldsOrItemsChanged(updatedValue: boolean): void {
    this.isFieldsOrItemsChanged = updatedValue;
  }

  goToConversation() {
    this.router.navigate(['/conversations/details', this.conversationId]);
  }
}
