import { Static, Type } from '@sinclair/typebox'

export const GetOAuthProviderRequest = Type.Object({
    agentName: Type.String(),
})

export type GetOAuthProviderRequest = Static<typeof GetOAuthProviderRequest>

export const PostOAuthProviderRequest = Type.Object({
    agentName: Type.String({}),
    clientId: Type.String({}),
    clientSecret: Type.String({}),
    extraData: Type.Optional(Type.Object({})),
})

export type PostOAuthProviderRequest = Static<typeof PostOAuthProviderRequest>
