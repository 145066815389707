import { Component, Input, OnInit } from '@angular/core';

type Option = {
  label: string;
  value: number;
};

@Component({
  selector: 'app-type-of-inbox',
  templateUrl: './type-of-inbox.component.html',
  styleUrls: ['./type-of-inbox.component.scss'],
})
export class TypeOfInboxComponent implements OnInit {
  @Input() onNextStep!: () => void;
  @Input() onPrevStep!: () => void;

  listOfOptions: Option[] = [];
  selectedOption: Option | null = null;

  ngOnInit() {
    this.listOfOptions = [
      { label: 'Customer service inbox', value: 0 },
      { label: 'Quality and compliance team inbox', value: 1 },
      { label: 'Sales team inbox', value: 2 },
      { label: 'Another company/staff inbox', value: 3 },
    ];
  }

  onOptionSelected(option: Option) {
    this.selectedOption = option;
  }

  onConfirm() {
    if (!this.selectedOption) {
      console.error('No option selected.');
      return;
    }

    const emailHtml = `
      <h3>Selected Inbox Type</h3>
      <p><strong>Question:</strong> What type of inbox are you using?</p>
      <p><strong>Answer:</strong> ${this.selectedOption.label}</p>
      <br>
      <br>
    `;

    const existingEmailData = localStorage.getItem('emailData') || '';
    const updatedEmailHtml = existingEmailData + emailHtml;

    localStorage.setItem('emailData', updatedEmailHtml);

    this.onNextStep();
  }
}
