import { Static, Type } from '@sinclair/typebox'
import { agentType, PackageType } from '../../../agents'

export const CreateStepRunRequestBody = Type.Object({
    flowVersionId: Type.String(),
    stepName: Type.String(),
})

export const ExecuteActionRequestBody = Type.Object({
    connectionName: Type.String(),
    sheet_id: Type.Number(),
    matchCase: Type.Optional(Type.Boolean()),
    searchValue: Type.Optional(Type.String()),
    startingRow: Type.Optional(Type.String()),
    numberOfRows: Type.Optional(Type.String()),
    startingColumn: Type.Optional(Type.String()),
    numberOfColumns: Type.Optional(Type.String()),
    include_team_drives: Type.Optional(Type.Boolean()),
    spreadsheet_id: Type.String(),
    realLabels: Type.Optional(Type.Boolean()),
})

export const StepRunResponse = Type.Object({
    success: Type.Boolean(),
    output: Type.Unknown(),
    standardError: Type.String(),
    standardOutput: Type.String(),
})

export type StepRunResponse = Static<typeof StepRunResponse>

export const ExecuteStepRequestBody = Type.Object({
    connectionName: Type.String(),
    actionName: Type.String(),
    agentName: Type.String(),
    input: Type.Unknown(),
    agentType: Type.Enum(agentType),
    packageType: Type.Enum(PackageType),
    agentVersion: Type.String(),
    stepName: Type.String(),
    projectId: Type.String(),
    userId: Type.String(),
})

export type ExecuteStepRequestBody = Static<typeof ExecuteStepRequestBody>
