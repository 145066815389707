import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../../common/base-model'

export const OAuthProvidersListResponse = Type.Object({
    ...BaseModelSchema,
    agentName: Type.String(),
    clientId: Type.String(),
    extraData: Type.Optional(Type.Object({})),
})

export const OAuthProviderResponse = Type.Object({
    ...BaseModelSchema,
    agentName: Type.String(),
    clientId: Type.String(),
    clientSecret: Type.String(),
    extraData: Type.Optional(Type.Object({})),
})

export type OAuthProvidersListResponse = Static<typeof OAuthProvidersListResponse>
export type OAuthProviderResponse = Static<typeof OAuthProviderResponse>
