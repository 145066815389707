import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class SendEmailService {
  constructor(private auth: AuthenticationService) {}

  async sendEmail(
    email: string,
    subject: string,
    emailBody: string
  ): Promise<void> {
    const emailData = JSON.stringify({
      invitation_emails: [email],
      subject,
      body: emailBody,
    });

    const token = this.auth.getToken();

    const requestOptions: RequestInit = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: emailData,
      redirect: 'follow',
    };

    const fetchUrl = environment.emailSenderFlowUrl;

    try {
      await fetch(fetchUrl, requestOptions);
    } catch (error: any) {
      throw new Error('Failed to send verification email: ' + error.message);
    }
  }
}
