import { Static, Type } from '@sinclair/typebox'
import { AppConnectionType } from '../app-connection'
import { OAuth2AuthorizationMethod } from '../oauth2-authorization-method'

const commonAuthProps = {
    name: Type.String({}),
    agentName: Type.String({}),
    projectId: Type.String({}),
}

export enum OAuth2GrantType {
    AUTHORIZATION_CODE = 'authorization_code',
    CLIENT_CREDENTIALS = 'client_credentials',
    JWT_CODE = 'urn:ietf:params:oauth:grant-type:jwt-bearer', // for Docusign
}

export const UpsertCustomAuthRequest = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.CUSTOM_AUTH),
        value: Type.Object({
            type: Type.Literal(AppConnectionType.CUSTOM_AUTH),
            props: Type.Record(Type.String(), Type.Unknown()),
        }),
    },
    {
        title: 'Custom Auth',
        description: 'Custom Auth',
    },
)

export const UpsertPlatformOAuth2Request = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.PLATFORM_OAUTH2),
        value: Type.Object({
            client_id: Type.String(),
            authorization_method: Type.Optional(
                Type.Enum(OAuth2AuthorizationMethod),
            ),
            code: Type.String(),
            grant_type: Type.Optional(Type.Enum(OAuth2GrantType)),
            code_challenge: Type.Optional(Type.String()),
            props: Type.Optional(Type.Record(Type.String(), Type.String())),
            scope: Type.String(),
            optional_scope: Type.Optional(Type.String()),
            type: Type.Literal(AppConnectionType.PLATFORM_OAUTH2),
            redirect_url: Type.String({}),
            upbrains_token: Type.Optional(Type.String({})),
        }),
    },
    {
        title: 'Platform OAuth2',
        description: 'Platform OAuth2',
    },
)

export const UpsertCloudOAuth2Request = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.CLOUD_OAUTH2),
        value: Type.Object({
            client_id: Type.String(),
            authorization_method: Type.Optional(
                Type.Enum(OAuth2AuthorizationMethod),
            ),
            code: Type.String(),
            code_challenge: Type.Optional(Type.String()),
            props: Type.Optional(Type.Record(Type.String(), Type.String())),
            scope: Type.String(),
            optional_scope: Type.Optional(Type.String()),
            type: Type.Literal(AppConnectionType.CLOUD_OAUTH2),
            upbrains_token: Type.Optional(Type.String()),

        }),
    },
    {
        title: 'Cloud OAuth2',
        description: 'Cloud OAuth2',
    },
)

export const UpsertSecretTextRequest = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.SECRET_TEXT),
        value: Type.Object({
            type: Type.Literal(AppConnectionType.SECRET_TEXT),
            secret_text: Type.String({}),
        }),
    },
    {
        title: 'Secret Text',
        description: 'Secret Text',
    },
)

export const UpsertOAuth2Request = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.OAUTH2),
        value: Type.Object({
            client_id: Type.String({}),
            client_secret: Type.String({}),
            grant_type: Type.Optional(Type.Enum(OAuth2GrantType)),
            props: Type.Optional(Type.Record(Type.String(), Type.Any())),
            scope: Type.String(),
            optional_scope: Type.Optional(Type.String()),
            code: Type.String(),
            authorization_method: Type.Optional(
                Type.Enum(OAuth2AuthorizationMethod),
            ),
            code_challenge: Type.Optional(Type.String()),
            redirect_url: Type.String({}),
            type: Type.Literal(AppConnectionType.OAUTH2),
            upbrains_token: Type.Optional(Type.String({})),
        }),
    },
    {
        title: 'OAuth2',
        description: 'OAuth2',
    },
)

export const UpsertBasicAuthRequest = Type.Object(
    {
        ...commonAuthProps,
        type: Type.Literal(AppConnectionType.BASIC_AUTH),
        value: Type.Object({
            username: Type.String({}),
            password: Type.String({}),
            type: Type.Literal(AppConnectionType.BASIC_AUTH),
        }),
    },
    {
        title: 'Basic Auth',
        description: 'Basic Auth',
    },
)

export const UpsertAppConnectionRequestBody = Type.Union([
    UpsertSecretTextRequest,
    UpsertOAuth2Request,
    UpsertCloudOAuth2Request,
    UpsertPlatformOAuth2Request,
    UpsertBasicAuthRequest,
    UpsertCustomAuthRequest,
])

export type UpsertCloudOAuth2Request = Static<typeof UpsertCloudOAuth2Request>
export type UpsertPlatformOAuth2Request = Static<
    typeof UpsertPlatformOAuth2Request
>
export type UpsertOAuth2Request = Static<typeof UpsertOAuth2Request>
export type UpsertSecretTextRequest = Static<typeof UpsertSecretTextRequest>
export type UpsertBasicAuthRequest = Static<typeof UpsertBasicAuthRequest>
export type UpsertCustomAuthRequest = Static<typeof UpsertCustomAuthRequest>
export type UpsertAppConnectionRequestBody = Static<
    typeof UpsertAppConnectionRequestBody
>
