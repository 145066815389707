import { Static, Type } from '@sinclair/typebox'

export const UserSubscriptions = Type.Object({
    billing_period: Type.String(),
    excess_unit_price: Type.Number(),
    id: Type.Number(),
    monthly_transaction_count: Type.Number(),
    plan_name: Type.String(),
    service_feature: Type.String(),
    service_id: Type.Number(),
    service_name: Type.String(),
    stripe_price_id: Type.String(),
    stripe_subscription_id: Type.String(),
    subscription_exceeded_usage: Type.Boolean(),
    subscription_quantity: Type.Number(),
    subscription_renewal_date: Type.String(),
    subscription_status: Type.String(),
})

export type UserSubscriptions = Static<typeof UserSubscriptions>

export const UserSubscriptionData = Type.Object({
    has_subscription: Type.Boolean(),
    status: Type.Number(),
    team_id: Type.Number(),
    user_email: Type.String(),
    user_subscriptions: Type.Array(UserSubscriptions),
})

export type UserSubscriptionData = Static<typeof UserSubscriptionData>

export const UpbrainsUser = Type.Object({
    first_name: Type.String(),
    last_name: Type.String(),
    company_name: Type.String(),
    has_microsoft_cred: Type.Boolean(),
    has_zendesk_cred: Type.Boolean(),
    has_front_cred: Type.Boolean(),
    api_key: Type.String(),
    has_onedrive_cred: Type.Boolean(),
    has_google_cred: Type.Boolean(),
    is_admin: Type.Boolean(),
    password: Type.String(),
    team_id: Type.String(),
})

export type UpbrainsUser = Static<typeof UpbrainsUser> 
