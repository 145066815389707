<ap-dialog-title-template>
    <ng-container *ngIf="!dialogData.connectionToUpdate; else editConnectionHeader">
        <span i18n>New {{dialogData.agentDisplayName}} Connection</span>
    </ng-container>
    <ng-template #editConnectionHeader>
        <span i18n>Edit</span> {{dialogData.connectionToUpdate!.name}}
    </ng-template>
</ap-dialog-title-template>

<mat-dialog-content>
    <ap-markdown *ngIf="dialogData.agentAuthProperty.description"
        [data]="dialogData.agentAuthProperty.description"></ap-markdown>
    <form class="ap-pt-4 ap-flex ap-flex-col ap-gap-2 ap-max-w-[720px] ap-min-w-[420px]" [formGroup]="settingsForm"
        (keyup.enter)="submit()" (submit)="submit()">
        <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label i18n>Name</mat-label>
            <input [matTooltip]="keyTooltip" cdkFocusInitial [formControl]="settingsForm.controls.name" matInput
                type="text" />
            <mat-error *ngIf="settingsForm.controls.name.invalid">
                <ng-container *ngIf="settingsForm.controls.name.getError('required'); else patternErrorOrAlreadyUsed"
                    i18n>
                    Name is required
                </ng-container>
                <ng-template #patternErrorOrAlreadyUsed>
                    <ng-container *ngIf="settingsForm.controls.name.getError('pattern')" i18n>
                        Name can only contain letters, numbers and underscores
                    </ng-container>
                    <ng-container *ngIf="settingsForm.controls.name.getError('nameUsed');" i18n>
                        Name is already used
                    </ng-container>
                </ng-template></mat-error>
        </mat-form-field>

        <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label>{{dialogData.agentAuthProperty.username.displayName}}</mat-label>
            <input [matTooltip]="dialogData.agentAuthProperty.username.description || ''"
                [formControl]="settingsForm.controls.username" matInput type="text" />
            <mat-error *ngIf="settingsForm.controls.username.invalid">
                {{dialogData.agentAuthProperty.username.displayName}} <span i18n>is required</span>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label>{{dialogData.agentAuthProperty.password.displayName}}</mat-label>
            <input [matTooltip]="dialogData.agentAuthProperty.password.description || ''"
                [formControl]="settingsForm.controls.password" matInput type="password" />
            <mat-error *ngIf="settingsForm.controls.password.invalid">
                {{dialogData.agentAuthProperty.password.displayName}} <span i18n>is required</span>
            </mat-error>
        </mat-form-field>
        <mat-error *ngIf="settingsForm.getError('message')"
            class="ap-text-left">{{settingsForm.getError('message')}}</mat-error>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="ap-flex ap-gap-2.5">
        <ap-button btnColor="basic" mat-dialog-close btnSize="default" i18n>
            Cancel
        </ap-button>
        <ap-button btnSize="default" (click)="submit()" btnColor="primary" type="submit" [loading]="loading" i18n>
            Save
        </ap-button>
    </div>
</mat-dialog-actions>
<ng-container *ngIf="upsert$ |async"></ng-container>