<button mat-menu-item apTrackHover #hoverTracker="hoverTrackerDirective" class="!ap-px-5"
  (click)="_stepMention.step.settings.agentName !== CORE_SCHEDULE? expandSample? expandSample=false : expandSample=true :mentionClicked.emit(_stepMention) ">
  <div class="ap-flex ap-items-center">


    <app-generic-step-mention-item [stepIndex]="stepIndex" [stepMention]="_stepMention"></app-generic-step-mention-item>
    <div class="ap-flex-grow"></div>
    <ap-button *ngIf="hoverTracker.isHovered && !!(sampleData$ | async)" btnColor="primary" btnStyle="basic"
      type="button" btnSize="small" [buttonIsInsideAutocomplete]="true"
      (click)="mentionClicked.emit(_stepMention)">Insert
    </ap-button>

    <mat-icon class="mat-icon-rtl-mirror !ap-mr-[5px]"
      [svgIcon]="expandSample ? 'custom_expand_less' : 'custom_expand_more'"
      *ngIf="(isScheduleTrigger$ | async) === false || (isScheduleTrigger$ | async) === undefined">
    </mat-icon>
  </div>

</button>
<ng-container *ngIf="expandSample">
  <ng-container *ngIf="sampleData$ | async as result">
    <app-step-mentions-tree *ngIf="result.children && result.children.length>0"
      [stepOutputObjectChildNodes]="result.children" [markedNodesToShow]="result.markedNodesToShow"
      [stepDisplayName]="_stepMention.step.displayName"
      (mentionClicked)="mentionClicked.emit($event)"></app-step-mentions-tree>

    <ng-container *ngIf="result.error">
      <div class="ap-pl-6 ap-py-2">
        {{result.error}}
      </div>
    </ng-container>

    <ng-container *ngIf="result.value !==undefined && expandSample">
      <button apTrackHover #resultNodeHoverTracker="hoverTrackerDirective" class="!ap-pl-0" mat-menu-item
        (click)="mentionClicked.emit(_stepMention)">
        <span class=" ap-flex-grow ap-transition-all" [style.padding-left]="FIRST_LEVEL_PADDING_IN_MENTIONS_LIST+ 'px'"
          i18n>result:
          <span class="ap-transition-all ap-text-primary" [matTooltip]="'' + result.value || ''">{{result.value}}
          </span></span>
        <ap-button *ngIf="resultNodeHoverTracker.isHovered" btnColor="primary" btnStyle="basic" type="button"
          btnSize="small" class="ap-absolute ap-right-[10px]" [buttonIsInsideAutocomplete]="true"
          [darkLoadingSpinner]="true" (click)="$event.stopPropagation();"
          (buttonClicked)="mentionClicked.emit(_stepMention)" i18n>Insert
        </ap-button>
      </button>
    </ng-container>

  </ng-container>
  <ng-container *ngIf="!(sampleData$) && _stepMention.step.settings.agentName !== CORE_SCHEDULE">
    <div class="ap-my-3 ap-px-2">
      <div class="ap-typography-body-1 ap-text-center ap-mb-1">
        {{
        (isPollingTrigger$| async)? 'Load sample data first' : 'Test trigger first'
        }}
      </div>
      <div class="ap-typography-body-2 ap-text-center ap-mb-3" i18n>
        This trigger needs to have data loaded from your account, to use as sample data
      </div>
      <div class="ap-text-center">
        <ap-button btnColor="primary" btnStyle="flat" type="button" btnSize="default" [darkLoadingSpinner]="false"
          (click)="$event.stopPropagation();selectStep() " i18n>Go to trigger
        </ap-button>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="search$ | async"></ng-container>