<div
  class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-4 ap-min-w-[132px]"
>
  <div
    class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-2 custom-paginator-dropdown"
  >
    <mat-form-field class="ap-w-15" subscriptSizing="dynamic">
      <mat-select [formControl]="pageSizeControl">
        <mat-option
          *ngFor="let size of pageSizes"
          [value]="size"
          class="ap-text-[#344054] ap-text-xs ap-font-medium ap-font-inter"
        >
          {{ size }}
        </mat-option>
      </mat-select>
      <mat-icon
        matSuffix
        class="ap-text-[18px] !ap-flex ap-items-center ap-justify-center"
        >keyboard_arrow_down</mat-icon
      >
    </mat-form-field>
  </div>

  <div class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-3">
    <button
      matTooltip="Back"
      matTooltipPosition="below"
      [disabled]="!previous"
      (click)="pageChanged.emit(previous!); setCursor(previous!)"
      [ngClass]="{ 'ap-text-[#667085]': !previous }"
    >
      <svg-icon
        [svgStyle]="{ width: '14px', height: '14px' }"
        class="ap-flex ap-justify-center ap-items-center"
        src="assets/img/newDesign/forms/left-arrow.svg"
        [ngClass]="{ 'custom-svg-color': !previous }"
      >
      </svg-icon>
    </button>

    <ng-container *ngIf="currentPageIndex">
      <p class="ap-text-[#344054] ap-text-xs ap-font-medium ap-font-inter">
        {{ currentPageIndex }}
      </p>
    </ng-container>

    <button
      matTooltip="Next"
      matTooltipPosition="below"
      [disabled]="!next"
      (click)="pageChanged.emit(next!); setCursor(next!)"
      [ngClass]="{ 'ap-text-[#667085]': !next }"
      class
    >
      <svg-icon
        [svgStyle]="{ width: '14px', height: '14px' }"
        class="ap-flex ap-justify-center ap-items-center"
        src="assets/img/newDesign/forms/right-arrow.svg"
        [ngClass]="{ 'custom-svg-color': !next }"
      >
      </svg-icon>
    </button>
  </div>
</div>
<ng-container *ngIf="pageSizeChanged$ | async"></ng-container>
