<ng-container *ngIf="!loading; else loadSkeleton">
  <ng-container *ngIf="agentsWithAuth$ | async as agentsWithAuth">
    <div
      class="ap-w-full ap-max-w-[892px] ap-max-h-full ap-overflow-auto ap-flex ap-flex-col ap-justify-center ap-items-center ap-mx-auto custom-agent-connection ap-mt-10"
    >
      <div
        class="ap-mx-auto ap-flex ap-flex-row ap-flex-wrap ap-gap-3 ap-mt-6 ap-max-w-full scroll-container"
      >
        <ng-container *ngFor="let agent of agentsWithAuth">
          <app-agent-connection-card
            [agent]="agent"
            [getConnections]="getConnectionAndUpdateAgents.bind(this)"
            [handleTriggerSelectedInbox]="handleTriggerSelectedInbox.bind(this)"
            [selectedStatus]="selectedStatus"
          />
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadSkeleton>
  <div
    class="ap-w-full ap-max-w-[892px] ap-max-h-full ap-overflow-auto ap-flex ap-flex-col ap-justify-center ap-items-center ap-mx-auto custom-agent-connection ap-mt-10"
  >
    <div
      class="ap-mx-auto ap-flex ap-flex-row ap-flex-wrap ap-gap-3 ap-mt-6 ap-max-w-full scroll-container"
    >
      <ng-container *ngFor="let item of skeletonItems">
        <ap-connection-skeleton />
      </ng-container>
    </div>
  </div>
</ng-template>
<p
  class="ap-text-[#344054] ap-font-inter ap-text-sm ap-font-normal ap-mt-3 ap-text-center"
>
  Skip this step if you use another tool or your automation does not involve an
  inbox.
</p>

<div class="ap-w-[230px] ap-mt-8 ap-mx-auto declare-steps">
  <ap-button
    actionButton
    btnSize="large"
    i18n
    class="ap-rounded-lg"
    [fullWidthOfContainer]="true"
    (buttonClicked)="openNewConnectionDialog()"
  >
    <div
      class="ap-flex ap-items-center ap-justify-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
    >
      {{ connectText }}
    </div>
  </ap-button>
</div>
