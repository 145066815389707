<div class="ap-w-full ap-flex ap-flex-col declare-steps">
  <div class="ap-w-fit ap-mt-[72px] ap-mx-auto">
    <ap-button
      actionButton
      btnSize="large"
      i18n
      class="ap-rounded-lg"
      [fullWidthOfContainer]="true"
      (buttonClicked)="onContinue()"
    >
      <div
        class="ap-px-7 ap-py-4 ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
      >
        Start Exploring
      </div>
    </ap-button>
  </div>
</div>
