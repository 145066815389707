<div
  class="ap-flex ap-flex-col ap-bg-[#F9FAFB] ap-border ap-border-[#E4E7EC] ap-rounded-[20px] ap-w-[270px] ap-h-[316px] ap-text-[#344054] ap-font-inter ap-p-6 custom-onboarding-package"
>
  <div
    class="ap-flex ap-flex-row ap-gap-1 ap-items-center ap-font-bold ap-text-2xl"
  >
    <ngx-skeleton-loader [theme]="{ height: '28px', width: '60px' }" />
  </div>

  <p class="ap-text-2xl ap-font-bold ap-mt-3">
    <ngx-skeleton-loader [theme]="{ height: '28px', width: '30px' }" />
  </p>

  <div class="ap-flex ap-flex-col ap-gap-2 ap-mt-3">
    <ngx-skeleton-loader [theme]="{ height: '80px', width: '100%' }" />
  </div>

  <div class="ap-w-[222px] ap-mx-auto declare-steps ap-mt-auto">
    <ngx-skeleton-loader [theme]="{ height: '48px', width: '220px' }" />
  </div>
</div>
