import { Static, Type } from '@sinclair/typebox'
import { Nullable } from '../common'

export const Permission = Nullable(
    Type.Object({
        menu: Type.Object({
            agents: Type.Boolean(),
            forms: Type.Boolean(),
            sessions: Type.Boolean(),
            templates: Type.Boolean(),
            team: Type.Boolean(),
            connections: Type.Boolean(),
            token: Type.Boolean(),
            support: Type.Boolean(),
            docs: Type.Boolean(),
            subscriptions: Type.Boolean(),
        }),
        agent: Type.Object({
            createTemplate: Type.Boolean(),
        }),
        template: Type.Object({
            create: Type.Boolean(),
        }),
        role: Type.String(),
    }),
)

export type Permission = Static<typeof Permission>
