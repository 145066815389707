import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { multipleFile, StaticFormsData } from '@upbrains/shared';
import {
  AppConnectionsService,
  AuthenticationService,
  environment,
  FileUploadService,
} from '@upbrains/ui/common';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { BehaviorSubject, tap } from 'rxjs';

@Component({
  selector: 'app-form-pdf-viewer',
  templateUrl: './form-pdf-viewer.component.html',
  styleUrls: ['./form-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPdfViewerComponent implements OnInit, OnChanges {
  @Input() fileUrl: string | undefined = undefined;
  @Input() hasJsonButton = false;
  @Input() text: string | undefined = undefined;
  @Input() staticFormsData: StaticFormsData | undefined = undefined;
  @Input() formPages: { page_number: number; text: string }[] = [];
  @Input() currentPageIndex = 0;

  @ViewChild('viewerContainer', { static: false }) viewerContainer!: ElementRef;

  iconSrc$ = new BehaviorSubject<string | null>(null);
  isOpen = false;
  currentViewing = '';
  isFullScreen = false;
  selectedFileName = 'test';
  multipleFiles: multipleFile[] = [];
  attachmentUrl = '';
  selectedAttachmentType?: 'FILE' | 'TEXT' | 'JSON' = undefined;
  connectionName = '';
  projectId = '';
  agentName = '';
  uploadError: string | null = null;
  fileName = '';
  signedFileUrl: string | undefined = undefined;
  pdfObject!: {
    url: string;
    httpHeaders: { Authorization: string };
    withCredentials: boolean;
  };

  @ViewChild(FormPdfViewerComponent) pdfViewer!: PdfViewerComponent;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private appConnectionService: AppConnectionsService,
    private fileUploadService: FileUploadService,
    private auth: AuthenticationService
  ) {
    this.appConnectionService
      .list({ projectId: this.auth.getProjectId(), limit: 50 })
      .pipe(
        tap((value) => {
          let tempConnectionName = '';
          let tempAgentName = '';
          let tempProjectId = '';

          if (this.fileUrl?.toLowerCase()?.includes('microsoft')) {
            const microsoftConnection = value.data.find((item) =>
              item.agentName.toLowerCase().includes('microsoft')
            );

            tempConnectionName = microsoftConnection?.name || '';
            tempProjectId = microsoftConnection?.projectId || '';
            tempAgentName = microsoftConnection?.agentName || '';
          } else if (this.fileUrl?.toLowerCase()?.includes('front')) {
            const microsoftConnection = value.data.find((item) =>
              item.agentName.toLowerCase().includes('front')
            );

            tempConnectionName = microsoftConnection?.name || '';
            tempProjectId = microsoftConnection?.projectId || '';
            tempAgentName = microsoftConnection?.agentName || '';
          } else if (this.fileUrl?.toLowerCase()?.includes('zendesk')) {
            const microsoftConnection = value.data.find((item) =>
              item.agentName.toLowerCase().includes('zendesk')
            );

            tempConnectionName = microsoftConnection?.name || '';
            tempProjectId = microsoftConnection?.projectId || '';
            tempAgentName = microsoftConnection?.agentName || '';
          }

          if (tempConnectionName && tempAgentName && tempProjectId) {
            this.connectionName = tempConnectionName;
            this.agentName = tempAgentName;
            this.projectId = tempProjectId;
            this.uploadFileWithType();
          } else {
            this.uploadFileWithType('ACCESS_TOKEN');
          }
        })
      )
      .subscribe();
  }

  ngOnInit() {
    if (this.fileUrl) {
      this.loadPdf();
    } else if (this.text) {
      this.loadText(this.formPages[this.currentPageIndex].text);
    } else if (this.hasJsonButton) {
      this.loadJson();
    }
  }

  ngOnChanges(): void {
    this.updateContent();
  }

  toggleFullScreen(): void {
    this.isFullScreen = !this.isFullScreen;

    // Wait for the DOM to update and then trigger resize
    setTimeout(() => {
      if (this.pdfViewer) {
        this.pdfViewer.updateSize();
      }
    });
  }

  private updateContent(): void {
    if (this.signedFileUrl) {
      this.loadPdf();
    } else if (this.text) {
      this.loadText(this.formPages[this.currentPageIndex]?.text || '');
    } else if (this.hasJsonButton) {
      this.loadJson();
    }

    if (this.staticFormsData) {
      this.multipleFiles = this.staticFormsData.multipleFiles;
    }

    if (this.multipleFiles?.length) {
      this.selectedFileName = this.multipleFiles[0].fileName;
      this.attachmentUrl = this.multipleFiles[0].Attachment;
    }
  }

  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  changeViewMode(mode: 'text' | 'file' | 'json'): void {
    this.currentPageIndex = 0;
    if (mode === 'text' && this.text) {
      this.selectedAttachmentType = 'TEXT';
      this.loadText(this.formPages[this.currentPageIndex].text);
    } else if (mode === 'file') {
      this.loadPdf();
      this.cdr.detectChanges();
    } else if (mode === 'json' && this.staticFormsData?.formData) {
      this.selectedAttachmentType = 'JSON';
      this.loadJson();
    }

    return;
  }

  getFileType(): void {
    if (!this.signedFileUrl) return;
    const extension = this.signedFileUrl.split('.').pop()?.toLowerCase();
    if (
      extension === 'png' ||
      extension === 'jpg' ||
      extension === 'jpeg' ||
      extension === 'webp'
    ) {
      this.loadImage(this.signedFileUrl);
    } else {
      this.loadPdf();
    }

    this.cdr.detectChanges();
  }

  private loadPdf(): void {
    this.iconSrc$.next(null); // Clear previous icon
    this.cdr.detectChanges();

    const viewerContainer =
      this.el.nativeElement.querySelector('.viewer-container');
    viewerContainer.innerHTML = '';

    // Simulating async operation
    setTimeout(() => {
      this.iconSrc$.next('assets/img/newDesign/extractor/pdf.svg'); // Set new icon
      this.selectedAttachmentType = 'FILE';
      this.currentViewing = 'pdf';
    }, 10);
    this.cdr.detectChanges();
  }

  private loadImage(imageUrl: string): void {
    this.iconSrc$.next(null); // Clear previous icon
    this.cdr.detectChanges();

    const viewerContainer =
      this.el.nativeElement.querySelector('.viewer-container');

    viewerContainer.innerHTML = '';

    const img = this.renderer.createElement('img');
    this.renderer.setAttribute(img, 'alt', 'image content');
    this.renderer.setAttribute(img, 'src', imageUrl);
    this.renderer.addClass(img, 'img-viewer');

    this.renderer.appendChild(viewerContainer, img);

    setTimeout(() => {
      this.iconSrc$.next('assets/img/newDesign/extractor/png.svg'); // Set new icon
      this.currentViewing = 'img';
    }, 10);
    this.cdr.detectChanges();
  }

  private loadText(text: string): void {
    this.iconSrc$.next(null); // Clear previous icon
    this.cdr.detectChanges();

    const viewerContainer =
      this.el.nativeElement.querySelector('.viewer-container');
    viewerContainer.innerHTML = ''; // Clear existing content

    const textElement = this.renderer.createElement('pre');
    this.renderer.setAttribute(textElement, 'class', 'text-viewer');

    // Sanitize and safely bind innerHTML
    const sanitizedText = this.sanitizeHtmlToString(text);
    this.renderer.appendChild(viewerContainer, textElement);

    // Safely bind sanitized HTML to innerHTML
    this.renderer.setProperty(textElement, 'innerHTML', sanitizedText);

    setTimeout(() => {
      this.iconSrc$.next('assets/img/newDesign/extractor/txt.svg'); // Set new icon
      this.currentViewing = 'text';
    }, 10);
    this.cdr.detectChanges();
  }

  private sanitizeHtmlToString(input: string): string {
    return input
      .replace(/\r\n|\r|\n/g, '\n') // Normalize newlines
      .replace(/^"|"$/g, '') // Remove leading/trailing quotes
      .replace(/\\n/g, '\n') // Convert escaped newlines
      .replace(/\n/g, '<br>'); // Replace newlines with <br>
  }

  private loadJson(): void {
    this.iconSrc$.next(null); // Clear previous icon
    this.cdr.detectChanges();

    const viewerContainer =
      this.el.nativeElement.querySelector('.viewer-container');
    viewerContainer.innerHTML = '';

    setTimeout(() => {
      this.iconSrc$.next('assets/img/newDesign/forms/json.svg'); // Set new icon
      this.currentViewing = 'json';
    }, 10);
    this.cdr.detectChanges();
  }

  downloadHandler(event: MouseEvent): void {
    event.stopPropagation();

    let fileContent: string | null = null;
    let fileType: string | null = null;
    let fileName = 'download';

    switch (this.currentViewing) {
      case 'text':
        if (this.text) {
          fileContent = this.text;
          fileType = 'text/plain';
          fileName = 'file.txt';

          this.snackbar.open('The file downloaded successfully.', '', {
            duration: 5000,
            panelClass: ['custom-success-snackbar'],
          });
        }
        break;

      case 'pdf': // PDF
        if (this.signedFileUrl) {
          this.downloadFileFromUrl(this.signedFileUrl, 'file.pdf');
          return;
        }
        break;

      case 'img':
        if (this.signedFileUrl) {
          this.downloadFileFromUrl(this.signedFileUrl, 'image.png'); // Or .jpg based on your file
          return;
        }
        break;

      case 'json':
        if (this.staticFormsData?.formData) {
          fileContent = JSON.stringify(this.staticFormsData.formData, null, 2);
          fileType = 'application/json';
          fileName = 'file.json';
        }
        break;

      default:
        console.error('Unsupported file type for download.');
        return;
    }

    if (fileContent && fileType) {
      this.downloadFileAsBlob(fileContent, fileType, fileName);
    }
  }

  private downloadFileFromUrl(url: string, fileName: string): void {
    const anchor = this.renderer.createElement('a');
    this.renderer.setAttribute(anchor, 'href', url);
    this.renderer.setAttribute(anchor, 'download', fileName);
    this.renderer.setAttribute(anchor, 'target', '_blank');
    this.renderer.setStyle(anchor, 'display', 'none');

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    this.snackbar.open('The file downloaded successfully.', '', {
      duration: 5000,
      panelClass: ['custom-success-snackbar'],
    });
  }

  private downloadFileAsBlob(
    content: string,
    type: string,
    fileName: string
  ): void {
    const blob = new Blob([content], { type });
    const url = URL.createObjectURL(blob);

    this.downloadFileFromUrl(url, fileName);

    // Release the object URL after download
    URL.revokeObjectURL(url);

    this.snackbar.open('The file downloaded successfully.', '', {
      duration: 5000,
      panelClass: ['custom-success-snackbar'],
    });
  }

  selectTheSelectedFile(option: multipleFile) {
    const { fileName, Attachment } = option;
    this.attachmentUrl = Attachment;
    this.selectedFileName = fileName;
    this.isOpen = false;

    if (this.currentViewing === 'text' && this.text) {
      this.loadText(this.formPages[this.currentPageIndex].text);
    } else if (
      this.currentViewing === 'file' ||
      this.currentViewing === 'pdf' ||
      this.currentViewing === 'img'
    ) {
      this.selectedAttachmentType = 'FILE';

      // this.getFileType();
    } else if (
      this.currentViewing === 'json' &&
      this.staticFormsData?.formData
    ) {
      this.loadJson();
    }
  }

  /**
   * Go to Previous text page
   */
  goToPreviousTextPage() {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex--;

      if (this.currentViewing === 'pdf') {
        this.loadPdf();
      } else if (this.currentViewing === 'text') {
        this.loadText(this.formPages[this.currentPageIndex].text);
      }
    }
  }

  /**
   * Go to Next text page
   */
  goToNextTextPage() {
    if (this.currentPageIndex < this.formPages.length - 1) {
      this.currentPageIndex++;

      if (this.currentViewing === 'pdf') {
        this.loadPdf();
      } else if (this.currentViewing === 'text') {
        this.loadText(this.formPages[this.currentPageIndex].text);
      }
    }
  }

  uploadFileWithType(accessToken?: string): void {
    const token = this.auth.getToken();

    const fileData = {
      url: this.fileUrl || '',
      fileName: this.fileName || 'test.pdf',
      header: '{}',
      type: accessToken
        ? 'ACCESS_TOKEN'
        : ('CONNECTION' as 'ACCESS_TOKEN' | 'CONNECTION'),
      projectId: this.projectId,
      ...(!accessToken && { agentName: this.agentName }),
      ...(!accessToken && { connectionName: this.connectionName }),
      ...(accessToken && { accessToken: `${token}` }),
    };

    this.fileUploadService.postSignedWithType(fileData).subscribe({
      next: (res) => {
        this.signedFileUrl = res.url;

        this.pdfObject = {
          url: this.signedFileUrl.replace(
            'https://backend.ai-copilot.dev/v1',
            environment.apiUrl
          ),
          httpHeaders: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        };
        this.uploadError = null;
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.uploadError = error.message;
        this.signedFileUrl = undefined;
      },
    });
  }
}
