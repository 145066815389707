<ng-container *ngIf="subscription$ | async as subscription">
  <ap-button
    btnColor="basic"
    mat-dialog-close
    btnSize="medium"
    btnColor="white"
    class="ap-flex ap-flex-row ap-mr-4 custom-button"
    (click)="handleUpgrade($event)"
    i18n
  >
    <svg-icon
      [svgStyle]="{ width: '11.14px', height: '12.38px' }"
      src="assets/img/newDesign/header/upgrade.svg"
    >
    </svg-icon>
    <div>Upgrade now</div>
  </ap-button>
</ng-container>
