import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, tap, catchError, map } from 'rxjs';
import {
  FlagService,
  GenericSnackbarTemplateComponent,
  AgentMetadataModel,
} from '@upbrains/ui/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ApFlagId,
  EXACT_VERSION_PATTERN,
  PackageType,
  AgentScope,
} from '@upbrains/shared';
import { AgentMetadataService } from '../services/agent-meta.service';

type AddPackageFormControl = {
  packageType: FormControl<PackageType>;
  agentName: FormControl<string>;
  agentVersion: FormControl<string>;
  agentArchive: FormControl<File | null>;
};

@Component({
  selector: 'ap-install-community-agent-modal',
  templateUrl: './install-community-agent-modal.component.html',
})
export class InstallCommunityAgentModalComponent {
  risksMarkdown = $localize`
  Use this to install a <a href="https://www.activepieces.com/docs/developers/building-agents/create-action" target="_blank" rel="noopener">custom agent</a> that you (or someone else) created.
  Once the agent is installed, you can use it in the flow builder.
 <br><br>**Warning:**
 Make sure you trust the author as the agent will have access to your flow data and it might not be compatible with the current version of activepieces.
  `;

  packageTypeOptions$: Observable<
    {
      name: string;
      value: PackageType;
    }[]
  >;

  loading = false;
  submitted = false;

  addAgentForm: FormGroup<AddPackageFormControl>;
  addAgentRequest$!: Observable<AgentMetadataModel | null>;
  agentNameControlChanged$!: Observable<string>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { scope: AgentScope },
    private fb: FormBuilder,
    private agentMetadataService: AgentMetadataService,
    private dialogRef: MatDialogRef<InstallCommunityAgentModalComponent>,
    private snackBar: MatSnackBar,
    private flagService: FlagService
  ) {
    this.addAgentForm = this.fb.group({
      packageType: this.fb.nonNullable.control(PackageType.REGISTRY, [
        Validators.required,
      ]),
      agentName: this.fb.nonNullable.control('', [Validators.required]),
      agentVersion: this.fb.nonNullable.control('', [
        Validators.required,
        Validators.pattern(EXACT_VERSION_PATTERN),
      ]),
      agentArchive: this.fb.control<File | null>(null),
    });
    this.packageTypeOptions$ = this.flagService
      .isFlagEnabled(ApFlagId.PRIVATE_AGENTS_ENABLED)
      .pipe(
        map((enabled) => {
          if (enabled) {
            return [
              {
                name: 'NPM Registry',
                value: PackageType.REGISTRY,
              },
              {
                name: 'Packed Archive (.tgz)',
                value: PackageType.ARCHIVE,
              },
            ];
          }
          return [
            {
              name: 'NPM Registry',
              value: PackageType.REGISTRY,
            },
          ];
        })
      );
  }

  get isPackageArchive(): boolean {
    return this.addAgentForm.controls.packageType.value === PackageType.ARCHIVE;
  }

  hide() {
    this.dialogRef.close();
  }

  addAgent() {
    this.submitted = true;
    if (this.addAgentForm.valid && !this.loading) {
      this.loading = true;
      const agentInfo = this.addAgentForm.getRawValue();

      this.addAgentRequest$ = this.agentMetadataService
        .installCommunityAgent({
          ...agentInfo,
          scope: this.data.scope,
        })
        .pipe(
          catchError((err) => {
            this.loading = false;
            this.addAgentForm.setErrors({
              failedInstall: true,
            });

            throw err;
          }),
          tap(() => {
            this.snackBar.openFromComponent(GenericSnackbarTemplateComponent, {
              data: `<b>${agentInfo.agentName}@${agentInfo.agentVersion}</b> added`,
            });

            this.dialogRef.close(agentInfo);
          })
        );
    }
  }
}
