export type Collection = {
    collectionId: number
    flowDisplayName: string
    updated: Date
    status: string
    type: string
}
export type CollectionsListResponse<T> = {
    totalItems: number
    data: T[]
    next: string | null
    previous: string | null
}

export type CollectionFunctions = {
    collectionFunctionId: string
    collectionFunctionName: string
}
export type CollectionsList = {
    collectionId: string
    lastUpdateTimestamp: number
    inboxIds: string[]
    teamId: number
    creationTimestamp: number
    collectionName: string
    projectId: string
    collectionSummary: string
    collectionType: string
    collectionFunctions: CollectionFunctions[]
}
export type QualityDocsList = {
    collectionId: string
    lastUpdateTimestamp: number
    inboxIds: string[]
    teamId: number
    creationTimestamp: number
    collectionName: string
    projectId: string
    collectionSummary: string
    collectionType: string
    collectionFunctions: CollectionFunctions[]
}

/*export type Highlights = {
    _id: string
    identifiers: Identifier[]
    collectionSummary: string | undefined
}*/
/*export type Identifier = {
    identifierName: string
    identifierId: string
}*/

/*export type HighlightsRouter = {
    collectionId: string
    projectId: string
}*/
/*export type FilteringItem = {
    fieldName: string
    fieldValue: string
    comparisonType:
    | 'EQUAL'
    | 'NOT_EQUAL'
    | 'GREATER_THAN'
    | 'LESS_THAN'
    | 'LIKE'
}*/

/*export type Filters = {
    filterOperation: 'AND' | 'OR'
    filteringItems: FilteringItem[]
}*/
/*

export type SortCriteria = {
    fieldName: string
    ordering: 'ASC' | 'DESC'
}
*/

/*
export type TableRequestModel = {
    searchValue?: string | null
    functionId?: string | null
    functionName?: string | null
    pageNumber: number
    pageSize: number
    filters?: Filters
    sort?: SortCriteria[]
}
*/

