import { Static, Type } from '@sinclair/typebox'
import { MemberInviter } from '../../member'

export const InviteVerificationResponse = Type.Object({
    email: Type.String(),
    registered: Type.Boolean(),
    userId: Type.Optional(Type.String()),
    projectId: Type.String(),
    platformId: Type.Union([Type.String(), Type.Null()]),
    invitedBy: Type.Union([MemberInviter, Type.Null()]),
    invitedAt: Type.String(),
    inviteSecret: Type.String(),
    submittedAt: Type.String(),
    companyName: Type.String(),
    teamId: Type.String(),
})

export type InviteVerificationResponse = Static<
    typeof InviteVerificationResponse
>
