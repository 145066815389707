<div
  class="ap-relative ap-h-full ap-overflow-auto ap-px-[80px] ap-py-[60px] ap-w-full ap-flex ap-flex-col"
>
  <ap-icon-button
    tooltipText="Previous Step"
    i18n-tooltipText
    iconUrl="assets/img/newDesign/onboarding/arrow-left.svg"
    [width]="24"
    [height]="24"
    class="ap-rounded-lg ap-fixed ap-top-14 ap-left-10 custom-skip-button"
    (buttonClicked)="prevStep()"
  ></ap-icon-button>

  <div
    class="ap-flex ap-flex-col ap-justify-between ap-items-center ap-font-inter ap-text-[#344054]"
  >
    <div class="ap-flex ap-flex-col ap-justify-center ap-items-center">
      <div class="ap-font-bold ap-text-5xl">Pick your Plan</div>
      <div class="ap-font-normal ap-text-xl ap-mt-5">
        Start automating your workflows with UpBrains AI
      </div>
    </div>
    <div
      class="ap-relative ap-flex ap-flex-row ap-justify-between ap-bg-[#F2F4F7] ap-p-[6px] ap-mt-7 ap-mb-14 ap-h-[56px] ap-w-[410px] ap-rounded-2xl ap-cursor-pointer monthly-yearly-container"
    >
      <div
        (click)="updateMonthYearStatus('month')"
        class="ap-w-[190px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-font-semibold ap-text-sm ap-text-[#98A2B3]"
      >
        Monthly
      </div>
      <div
        (click)="updateMonthYearStatus('year')"
        class="ap-w-[190px] ap-h-[44px] ap-bg-[#F2F4F7] ap-flex ap-justify-center ap-items-center ap-gap-2 ap-font-semibold ap-text-sm ap-text-[#98A2B3]"
      >
        Yearly
        <span
          class="ap-bg-[#ECFDF3] ap-rounded-3xl ap-px-6 ap-text-[#039855] !ap-text-base ap-font-inter !ap-font-light"
          >Save 20%</span
        >
      </div>
      <div
        (click)="
          updateMonthYearStatus(monthYearStatus === 'month' ? 'month' : 'year')
        "
        class="ap-w-[190px] ap-h-[44px] ap-bg-[#FFFFFF] ap-flex ap-justify-center ap-gap-2 ap-items-center ap-font-semibold ap-text-sm ap-text-[#1D2939] ap-absolute ap-rounded-xl"
        [ngStyle]="{ left: monthYearStatus === 'month' ? '6px' : '208px' }"
      >
        <!-- [ngClass]="{ 'ap-w-[190px]': monthYearStatus !== 'month' }" -->
        {{ monthYearStatus === 'month' ? 'Monthly' : 'Yearly' }}
        <ng-container *ngIf="monthYearStatus !== 'month'">
          <span
            class="ap-bg-[#ECFDF3] ap-rounded-3xl ap-px-6 ap-text-[#039855] !ap-text-base ap-font-inter !ap-font-light"
            >Save 20%</span
          >
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isLoading; else plansAreLoaded">
    <app-subscription-skeleton />
  </ng-container>

  <ng-template #plansAreLoaded>
    <div
      class="ap-flex ap-flex-wrap ap-justify-center ap-items-center ap-gap-4"
    >
      <ng-container *ngIf="subscriptionPlansList$ | async as subscriptionPlans">
        <ng-container *ngFor="let subscriptionPlan of subscriptionPlans">
          <ap-subscription-plan
            *ngIf="allUserSubscriptions$ | async as allUserSubscriptions"
            [planData]="subscriptionPlan"
            [monthYearStatus]="monthYearStatus"
            [subscriptionName]="planName"
            [packagesList]="subscriptionPackagesList"
            [isFromOnboarding]="true"
            [allUserSubscriptions]="allUserSubscriptions"
            [isOnboarding]="true"
          />
        </ng-container>
      </ng-container>
    </div>
  </ng-template>

  <app-onboarding-subscription-plans-features-table />

  <div
    class="ap-max-w-[1280px] ap-mx-auto ap-w-full ap-flex ap-flex-row ap-justify-between ap-items-center ap-mt-10 ap-mb-15 ap-rounded-3xl custom-limit-bg ap-py-11 ap-px-[34px]"
  >
    <div
      class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-max-w-[550px]"
    >
      <p class="ap-text-white ap-text-2xl ap-font-inter ap-font-bold">
        Need higher limits?
      </p>
      <p
        class="ap-text-[#E4E7EC] ap-text-base ap-font-inter ap-font-medium ap-mt-4"
      >
        Our new business plans provide access to advanced features and increased
        limits, making them ideal for scale ups and enterprises.
      </p>
    </div>
    <div class="ap-flex ap-items-center ap-justify-center">
      <div
        class="ap-flex ap-flex-row ap-gap-3 ap-justify-between ap-items-center ap-mt-auto custom-popular-plan"
      >
        <ap-button
          actionButton
          btnSize="large"
          btnColor="primary"
          i18n
          class="ap-w-[220px]"
          btnStyle="flat"
          [fullWidthOfContainer]="true"
          (click)="openContactPage()"
        >
          <div
            class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
          >
            <svg-icon
              src="assets/img/custom/loading.svg"
              class="loading-spin-animation white-loader"
              [applyClass]="true"
            >
            </svg-icon>
            Contact Us
          </div>
        </ap-button>
      </div>
    </div>
  </div>
</div>
