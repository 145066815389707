import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ap-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
})
export class CancelSubscriptionComponent implements OnInit {
  issuesList: string[] = [];
  selectedIssue: string = '';
  message: string = '';
  isCanceling: boolean = false;
  isContacting: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CancelSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isCanceling: boolean;
      handleCancel: () => void;
      handleContact: (message: string) => void;
    }
  ) {}

  ngOnInit() {
    this.issuesList = [
      "I don't use it enough",
      "It's too expensive",
      "It doesn't do what i need",
      'I could not configure it',
      'It did not work for me',
      'I found a better alternative',
    ];
  }

  confirmCancel() {
    this.data.handleCancel();
  }

  keepSubscription() {
    this.dialogRef.close();
  }

  contactMe() {
    this.data.handleContact(this.message);
  }
}
