<div class="ap-flex ap-items-center bar-container">
  <ng-container *ngIf="selectedRun$ | async as selectedRun; else InitialisingRunTemplate">
    <ap-state-icon *ngIf="selectedRun.status !== instanceRunStatus.PAUSED" [status]="selectedRun.status"
      [showStatusText]="false"></ap-state-icon>
    <ng-container [ngSwitch]="selectedRun.status">
      <ng-container *ngSwitchCase="instanceRunStatus.SUCCEEDED">
        <span class="ap-text-white" i18n>Run succeeded ({{ selectedRun.id }})</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.STOPPED">
        <span class="ap-text-white" i18n>Run succeeded ({{ selectedRun.id }})</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.FAILED">
        <span class="ap-text-white" i18n>Run failed ({{ selectedRun.id }})</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.RUNNING">
        <span class="ap-text-white" i18n>Flow is running</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.PAUSED">
        <span class="ap-text-white" i18n>Flow is paused ...</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.TIMEOUT">
        <span class="ap-text-white" i18n>Run exceeded {{sandboxTimeoutSeconds$ | async}} seconds, try to optimize your
          steps.</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
      <ng-container *ngSwitchCase="instanceRunStatus.INTERNAL_ERROR">
        <span class="ap-text-white" i18n>Run failed for an unknown reason, contact support.</span>
        <div class="ap-flex-grow"></div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #InitialisingRunTemplate> Initialising run...</ng-template>
  <ap-button [hidden]="hideExit$ | async" btnColor="basic" btnStyle="basic" btnSize="medium"
    (buttonClicked)="exitButtonClicked.next()" i18n> Exit Run </ap-button>
</div>

<ng-container *ngIf="exitRun$|async"></ng-container>
