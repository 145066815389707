<div class="ap-flex ap-flex-col">
    <ng-container *ngFor="let val of formArray.controls; let idx = index; let isLast = last; let isFirst=first">
        <div class="ap-flex" *ngIf="!(isLast && formArray.disabled && idx>0)">
            <div class="delete-btn-container">
                <ap-icon-button *ngIf="formArray.enabled" [width]="9" [height]="9" iconFilename="delete.svg"
                    [tooltipText]=" !deleteButton.isHovered && !valueInput.isHovered ?'' :'Remove Item'"
                    class="delete-btn ap-z-40" [class.opacity-0]="!deleteButton.isHovered  && !valueInput.isHovered"
                    apTrackHover #deleteButton="hoverTrackerDirective"
                    (buttonClicked)="removeValue(idx)"></ap-icon-button>
            </div>

            <div class="form-control value-control !ap-py-1" [class.first]="isFirst && formArray.controls.length>1"
                [class.last]="isLast && formArray.controls.length>1" [class.only-one]="formArray.controls.length === 1"
                apTrackHover #valueInput="hoverTrackerDirective" #interpolatingTextControlContainer>
                <input [formControl]="formArray.controls[idx]" class="ap-w-full ap-outline-none !ap-bg-white"
                    [class.ap-text-disable]="formArray.disabled" [placeholder]="placeholder" (focus)="onTouched()">
            </div>
        </div>

    </ng-container>
    <div class="ap-flex">
        <ap-button btnColor="primary" btnStyle="basic" *ngIf="formArray.enabled" (buttonClicked)="addValue()"
            type="button" class="add-btn" btnSize="medium" i18n> + Add Item
        </ap-button>
        <div class="ap-flex-grow" #spacer></div>
    </div>

</div>

<ng-container *ngIf="valueChanges$|async"></ng-container>