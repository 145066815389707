import { Static, Type } from '@sinclair/typebox'
import {
    UpBrainsOpenAITokenConstant,
    FullIgniteURLConstant,
    FullChatURLConstant,
    FullXtractURLConstant,
    FullFrontendURLConstant,
} from './constants'

export const test_env = ''
export enum PackageType {
    ARCHIVE = 'ARCHIVE',
    REGISTRY = 'REGISTRY',
}

export enum agentType {
    CUSTOM = 'CUSTOM',
    OFFICIAL = 'OFFICIAL',
}

export const PrivateAgentPackage = Type.Object({
    packageType: Type.Literal(PackageType.ARCHIVE),
    agentType: Type.Enum(agentType),
    agentName: Type.String(),
    agentVersion: Type.String(),
    archiveId: Type.String(),
})

export type PrivateAgentPackage = Static<typeof PrivateAgentPackage>

export const PublicAgentPackage = Type.Object({
    packageType: Type.Literal(PackageType.REGISTRY),
    agentType: Type.Enum(agentType),
    agentName: Type.String(),
    agentVersion: Type.String(),
})

export type PublicAgentPackage = Static<typeof PublicAgentPackage>

export type AgentPackage = PrivateAgentPackage | PublicAgentPackage

export type AgentConnection = {
    agentName: string
    name: string
}

export enum AgentCategory {
    ARTIFICIAL_INTELLIGENCE = 'ARTIFICIAL_INTELLIGENCE',
    COMMUNICATION = 'COMMUNICATION',
    COMMERCE = 'COMMERCE',
    CORE = 'CORE',
    BUSINESS_INTELLIGENCE = 'BUSINESS_INTELLIGENCE',
    ACCOUNTING = 'ACCOUNTING',
    PRODUCTIVITY = 'PRODUCTIVITY',
    CONTENT_AND_FILES = 'CONTENT_AND_FILES',
    DEVELOPER_TOOLS = 'DEVELOPER_TOOLS',
    CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
    FORMS_AND_SURVEYS = 'FORMS_AND_SURVEYS',
    HUMAN_RESOURCES = 'HUMAN_RESOURCES',
    PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
    MARKETING = 'MARKETING',
    SALES_AND_CRM = 'SALES_AND_CRM',
}

export const DefaultUpbrainsAgentsConnection: AgentConnection[] = [
    {
        agentName: '@upbrains/agent-conversation-skills',
        name: 'UpBrains AI Connection for Conversation Skills',
    },
    {
        agentName: '@upbrains/agent-document-skills',
        name: 'UpBrains AI Connection for Document Skills',
    },
    {
        agentName: '@upbrains/agent-response-assist',
        name: 'UpBrains AI Connection for Response Assist',
    },
    {
        agentName: '@upbrains/agent-save-in-upbrains',
        name: 'UpBrains AI Connection for Save in Database',
    },
    {
        agentName: '@upbrains/agent-smart-classifier',
        name: 'UpBrains AI Connection for Smart Classifier',
    },
]

export const UpbrainsServicesAgentConnections: AgentConnection[] = [
    {
        agentName: '@upbrains/agent-microsoft-outlook',
        name: 'Microsoft Connection in UpBrains AI',
    },
    {
        agentName: '@upbrains/agent-microsoft-onedrive',
        name: 'Microsoft OneDrive Connection in UpBrains AI',
    },
]

const stringOrFalseType = Type.Union([Type.String(), Type.Literal(false)])

export const UpbrainsUserConnections = Type.Object({
    has_conversation_skills: stringOrFalseType,
    has_document_skills: stringOrFalseType,
    has_response_assist: stringOrFalseType,
    has_save_in_database: stringOrFalseType,
    has_smart_classifier: stringOrFalseType,
})

export type UpbrainsUserConnections = Static<typeof UpbrainsUserConnections>

export const UpbrainsAgentsToBuild: Record<string, string> = {
    frontapp: '@upbrains/agent-frontapp',
    zendesk: '@upbrains/agent-zendesk',
    'microsoft-outlook': '@upbrains/agent-microsoft-outlook',
    schedule: '@upbrains/agent-schedule',
    delay: '@upbrains/agent-delay',
    sftp: '@upbrains/agent-sftp',
    smtp: '@upbrains/agent-smtp',
    'conversation-skills': '@upbrains/agent-conversation-skills',
    'response-assist': '@upbrains/agent-response-assist',
    'document-skills': '@upbrains/agent-document-skills',
    'smart-classifier': '@upbrains/agent-smart-classifier',
    'google-drive': '@upbrains/agent-google-drive',
    'google-sheets': '@upbrains/agent-google-sheets',
    'microsoft-excel-365': '@upbrains/agent-microsoft-excel-365',
    hubspot: '@upbrains/agent-hubspot',
    box: '@upbrains/agent-box',
    airtable: '@upbrains/agent-airtable',
    imap: '@upbrains/agent-imap',
}

export const A_MIN_TIMEOUT_IN_MILLISECONDS = 60000

export const FullXtractURL = FullXtractURLConstant
export const FullChatURL = FullChatURLConstant
export const FullIgniteURL = FullIgniteURLConstant
export const UpBrainsOpenAIToken = UpBrainsOpenAITokenConstant
export const FrontendURL = FullFrontendURLConstant
