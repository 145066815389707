import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, StepSliderConfig } from '@upbrains/ui/common';
import { Subscription } from 'rxjs';
import { DeclareStepsComponent } from '../../steps/declare-steps/declare-steps.component';
import { CompanyUrlComponent } from '../../steps/company-url/company-url.component';
import { ExtractedInsightsComponent } from '../../steps/extracted-insights/extracted-insights.component';
import { SelectYourAgentComponent } from '../../steps/select-your-agent/select-your-agent.component';
import { AgentConnectionComponent } from '../../steps/agent-connection/agent-connection.component';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent implements OnInit, OnDestroy {
  stepsConfig: StepSliderConfig;
  isManual = false;
  private queryParamSubscription: Subscription | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.stepsConfig = {
      title: '',
      subTitle: '',
      onClose: () => undefined,
      onPrevStep: () => undefined,
      onNextStep: () => undefined,
      steps: {},
    };
  }

  ngOnInit() {
    const { firstName } = this.authenticationService.currentUser;

    this.queryParamSubscription = this.route.queryParamMap.subscribe(
      (params) => {
        this.isManual = params.get('isManual') === 'true';

        this.stepsConfig = {
          title: 'Onboarding Agent',
          onClose: () => undefined,
          onPrevStep: () => undefined,
          steps: {
            0: {
              message: `Hi there, ${firstName}\n Let's complete these steps together`,
              component: DeclareStepsComponent,
              confirmButtonText: "Let's Get Started",
            },
            1: {
              message: `Provide your company URL\n This gives me useful information for workspace`,
              component: CompanyUrlComponent,
              confirmButtonText: 'Next',
              subConfirmButtonText: 'Fill out the form manually',
              isBackNavigationAllowed: true,
            },
            2: {
              message: this.isManual
                ? 'Give me valuable information about yourself'
                : `I have extracted insights about you`,
              component: ExtractedInsightsComponent,
              confirmButtonText: 'Confirm',
              isBackNavigationAllowed: true,
            },
            3: {
              message: `Now, please select your agent\n I will prepare your workspace`,
              component: SelectYourAgentComponent,
              confirmButtonText: 'Configure',
              isBackNavigationAllowed: true,
              canSkip: true,
            },
            4: {
              message: `Let's add your connections`,
              component: AgentConnectionComponent,
              confirmButtonText: 'Connect',
              isBackNavigationAllowed: true,
            },
          },
        };
      }
    );
  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }
}
