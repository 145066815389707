<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="step-result-tree ap-typography-body-2">
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->


  <mat-tree-node apTrackHover #treeNodeHoverTracker="hoverTrackerDirective"
    [style.display]="markedNodesToShow?.get(node.propertyPath) || (searchContainsStepDisplayName$|async) ? '':'none'"
    #treeNode="matTreeNode" *matTreeNodeDef="let node;">

    <div matTreeNodeToggle mat-menu-item (click)="mentionTreeNodeClicked(node)" class=" ap-min-w-[0] ap-relative"
      (keyup.enter)="mentionTreeNodeClicked(node)"
      [style.padding-left]="treeNode.level === 0? FIRST_LEVEL_PADDING_IN_MENTIONS_LIST + 'px': (treeNode.level * CHEVRON_SPACE_IN_MENTIONS_LIST + FIRST_LEVEL_PADDING_IN_MENTIONS_LIST)+'px'">
      <div class=" ap-flex ap-gap-1 ap-overflow-x-hidden  ap-min-h-[27px]">
        {{node.key}} <span class="ap-text-primary ap-truncate  ap-flex-grow ap-transition-all">:
          <span class="ap-transition-all" [matTooltip]="node.value">{{node.value}}
          </span></span>
        <ap-button class="ap-absolute ap-right-[10px]" *ngIf="treeNodeHoverTracker.isHovered" btnColor="primary"
          btnStyle="basic" type="button" [buttonIsInsideAutocomplete]="true" btnSize="small" i18n>Insert
        </ap-button>


      </div>
    </div>
  </mat-tree-node>



  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" #nestedTreeNode="matTreeNode"
    [style.display]="markedNodesToShow?.get(node.propertyPath) || (searchContainsStepDisplayName$|async) ? '':'none'">
    <div matTreeNodeToggle apTrackHover #nestedTreeNodeHoverTracker="hoverTrackerDirective"
      (keyup.enter)="treeControl.isExpanded(node)? treeControl.collapse(node): treeControl.expand(node)"
      class="mat-tree-node ap-min-w-[0] ap-min-h-[27px] ap-relative  ap-flex ap-items-center ap-typography-body-2"
      [style.padding-left]="nestedTreeNode.level === 0? FIRST_LEVEL_PADDING_IN_MENTIONS_LIST + 'px': (nestedTreeNode.level * CHEVRON_SPACE_IN_MENTIONS_LIST + FIRST_LEVEL_PADDING_IN_MENTIONS_LIST)+'px'"
      mat-menu-item>

      <div class="ap-truncate" [innerHTML]="node.key" [matTooltip]="node.key">
      </div>

      <div class="ap-flex-grow "></div>
      <ap-button *ngIf="nestedTreeNodeHoverTracker.isHovered && !node.isSlice" class="ap-absolute ap-right-[50px]"
        [buttonIsInsideAutocomplete]="true" btnColor="primary" btnStyle="basic" type="button" btnSize="small"
        (click)="$event.stopPropagation(); mentionTreeNodeClicked(node)" i18n>Insert
      </ap-button>
      <mat-icon class="ap-absolute ap-right-[10px]"
        [svgIcon]="treeControl.isExpanded(node) ? 'custom_expand_less' : 'custom_expand_more'">

      </mat-icon>
    </div>

    <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
    <div [class.step-result-tree-invisible]="!treeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-tree-node>
</mat-tree>

<ng-container *ngIf="search$ | async"></ng-container>