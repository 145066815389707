import { createAction, props } from '@ngrx/store';
import { UpbrainsUser } from './upbrains-user-state.model';
import { SubscriptionCheckRequest } from '@upbrains/shared';

export const saveUpbrainsUser = createAction(
  '[Upbrains User] Save UpBrains User',
  props<{ upbrainsUser: UpbrainsUser }>()
);
export const removeUpbrainsUser = createAction(
  '[Upbrains User] Remove UpBrains User'
);

export const setUserSubscriptionCheck = createAction(
  '[User Info] Set User Info',
  props<{
    subscriptionCheck: Partial<SubscriptionCheckRequest> | undefined | null;
  }>()
);
