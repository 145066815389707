<div
  class="ap-w-[320px] md:ap-w-[716px] ap-mx-auto custom-company-url ap-mt-[72px] ap-mb-24"
>
  <div class="ap-relative">
    <form
      class="ap-w-full ap-flex ap-flex-row ap-justify-between ap-items-center ap-mb-8"
      [formGroup]="form"
      (submit)="onNextButtonClick()"
    >
      <div class="ap-max-w-[342px] ap-w-full">
        <div class="ap-text-sm ap-font-medium ap-mb-[6px]">Company Website</div>
        <mat-form-field class="ap-w-full">
          <input
            #companyUrlInput
            matInput
            type="text"
            placeholder="Your company url: https://..."
            class="ap-w-full ap-h-[44px] ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-border-[#D0D5DD] focus:ap-outline-none focus:ap-ring-0 focus:ap-shadow-[0px_0px_0px_4px_[#1570EF]]"
            formControlName="companyUrl"
          />
          <mat-error *ngIf="form.controls.companyUrl.hasError('invalidUrl')">
            Company Url is not valid
          </mat-error>
        </mat-form-field>
      </div>

      <div class="ap-max-w-[342px] ap-w-full">
        <div class="ap-text-sm ap-font-medium ap-mb-[6px]">
          Company Industry
        </div>
        <mat-form-field appearance="outline" class="ap-w-full">
          <mat-select
            formControlName="selectedIndustry"
            placeholder="Select Industry"
            required
          >
            <mat-option *ngFor="let option of industryList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="form.controls.selectedIndustry.hasError('required')"
          >
            Industry selection is required.
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-3">
      <div class="ap-w-[230px] custom-button">
        <mat-dialog-actions>
          <ap-button
            actionButton
            btnSize="large"
            i18n
            class="ap-w-[230px] ap-rounded-lg"
            [fullWidthOfContainer]="true"
            (buttonClicked)="onNextButtonClick()"
            [loading]="isLoading"
            [disabled]="isLoading"
          >
            <div
              class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
            >
              Continue
            </div>
          </ap-button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>
