import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ap-add-new-line-business',
  templateUrl: './add-new-line-business.component.html',
})
export class AddNewLineBusinessComponent {
  form: FormGroup<{
    lineBusiness: FormControl<string | null>;
  }>;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewLineBusinessComponent>
  ) {
    this.form = this.fb.group({
      lineBusiness: new FormControl<string | null>('', {
        validators: [Validators.required],
      }),
    });
  }

  submit() {
    if (this.form.valid) {
      const lineBusinessValue = this.form.get('lineBusiness')?.value;
      this.dialogRef.close(lineBusinessValue);
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}
