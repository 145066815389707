import { Static, Type } from '@sinclair/typebox'
import { FlowVersion } from '../flow-version'
import { BaseModelSchema } from '../../common'
export const FlowVersionTemplate = Type.Omit(FlowVersion, [
    'id',
    'created',
    'updated',
    'flowId',
    'state',
    'updatedBy',
])

export type FlowVersionTemplate = Static<typeof FlowVersionTemplate>

export enum TemplateType {
    PLATFORM = 'PLATFORM',
    PROJECT = 'PROJECT',
}

export const FlowTemplate = Type.Object({
    ...BaseModelSchema,
    name: Type.String(),
    description: Type.String(),
    type: Type.Enum(TemplateType),
    tags: Type.Array(Type.String()),
    agents: Type.Array(Type.String()),
    blogUrl: Type.Optional(Type.String()),
    template: FlowVersionTemplate,
    projectId: Type.String(),
    platformId: Type.String(),
})

export type FlowTemplate = Static<typeof FlowTemplate>

export const FlowTemplateWithIcons = Type.Intersect([
    FlowTemplate,
    Type.Object({
        agentsIcons: Type.Array(
            Type.Object({
                agentName: Type.String(),
                iconUrl: Type.Optional(Type.String()),
            }),
        ),
    }),
])

export type FlowTemplateWithIcons = Static<typeof FlowTemplateWithIcons>

export const FlowTemplateWithoutProjectInformation = Type.Omit(FlowTemplate, [
    'projectId',
    'platformId',
    'id',
    'type',
])
export type FlowTemplateWithoutProjectInformation = Static<
    typeof FlowTemplateWithoutProjectInformation
>

export const ListFlowTemplatesRequest = Type.Object({
    agents: Type.Optional(Type.Array(Type.String())),
    tags: Type.Optional(Type.Array(Type.String())),
    search: Type.Optional(Type.String()),
})

export type ListFlowTemplatesRequest = Static<typeof ListFlowTemplatesRequest>
