import {
    FlowVersionTemplate,
    TemplateType,
} from '../../flows/dto/flow-template-request'
import { Static, Type } from '@sinclair/typebox'

export const CreateWorkflowTemplateRequest = Type.Object({
    description: Type.Optional(Type.String()),
    template: FlowVersionTemplate,
    blogUrl: Type.Optional(Type.String()),
    type: Type.Enum(TemplateType),
    tags: Type.Optional(Type.Array(Type.String())),
    id: Type.Optional(Type.String()),
})

export type CreateWorkflowTemplateRequest = Static<
    typeof CreateWorkflowTemplateRequest
>
