<ag-grid-angular
  class="ag-theme-alpine ap-w-full ap-min-[100px]"
  [ngStyle]="{ height: config?.height ?? '400px' }"
  [animateRows]="false"
  [columnDefs]="columnDefs"
  [rowData]="rowData"
  (cellContextMenu)="onCellContextMenu($event)"
  (cellValueChanged)="cellValueChanged($event)"
>
</ag-grid-angular>
