import semverMajor from 'semver/functions/major'
import semverMinor from 'semver/functions/minor'
import semverMinVersion from 'semver/ranges/min-version'
import { UpbrainsError, ErrorCode } from '../common/upbrains-error'
import { PackageType, AgentPackage } from './agent'
// import { HttpRequest, httpClient } from '@upbrains/agents-common'

export const getPackageAliasForAgent = (
    params: GetPackageAliasForAgentParams,
): string => {
    const { agentName, agentVersion } = params
    return `${agentName}-${agentVersion}`
}

export const getPackageSpecForAgent = (
    packageArchivePath: string,
    params: AgentPackage,
): string => {
    const { packageType, agentName, agentVersion } = params

    switch (packageType) {
        case PackageType.REGISTRY: {
            return `npm:${agentName}@${agentVersion}`
        }

        case PackageType.ARCHIVE: {
            const archivePath = getPackageArchivePathForAgent({
                archiveId: params.archiveId,
                archivePath: packageArchivePath,
            })

            return `file:${archivePath}`
        }
    }
}

export const getPackageArchivePathForAgent = (
    params: GetPackageArchivePathForAgentParams,
): string => {
    return `${params.archivePath}/${params.archiveId}.tgz`
}

export const extractAgentFromModule = <T>(
    params: ExtractAgentFromModuleParams,
): T => {
    const { module, agentName, agentVersion } = params
    const exports = Object.values(module)

    for (const e of exports) {
        if (e !== null && e !== undefined && e.constructor.name === 'Agent') {
            return e as T
        }
    }

    throw new UpbrainsError({
        code: ErrorCode.AGENT_NOT_FOUND,
        params: {
            agentName,
            agentVersion,
        },
    })
}

export const getAgentMajorAndMinorVersion = (agentVersion: string): string => {
    const minimumSemver = semverMinVersion(agentVersion)
    return minimumSemver
        ? `${semverMajor(minimumSemver)}.${semverMinor(minimumSemver)}`
        : `${semverMajor(agentVersion)}.${semverMinor(agentVersion)}`
}

type GetPackageAliasForAgentParams = {
    agentName: string
    agentVersion: string
}

type GetPackageArchivePathForAgentParams = {
    archiveId: string
    archivePath: string
}

type ExtractAgentFromModuleParams = {
    module: Record<string, unknown>
    agentName: string
    agentVersion: string
}

// export const agentRequestFunction = async (
//     url: string,
//     requestOptions: HttpRequest,
//     agentName: string,
// ) => {
//     try {
//         const res = await httpClient.sendRequest(requestOptions)

//         if (res.status === 401) {
//             throw new Error('Request failed:\nUnauthorized')
//         } else if (res.status !== 200) {
//             throw new Error(
//                 'Request failed:' +
//                     ' \n\nUrl: ' +
//                     url +
//                     '\n\nAgent Name: ' +
//                     agentName +
//                     '\n\nResponse status: ' +
//                     res.status.toString() +
//                     '\n\nResponse text:\n' +
//                     res.statusText,
//             )
//         }

//         const res_json = await res.body.json()

//         return [res_json]
//     } catch (error) {
//         throw new Error(error.message)
//     }
// }

export const getIntegrationName = async (
    url: string,
    requestOptions: RequestInit,
    flowID: string,
) => {
    try {
        const Response = await fetch(
            `${url}/workflow-apis/get-integration-name?flow_id=${flowID}`,
            requestOptions,
        )

        if (Response.status === 401) {
            throw new Error('Request failed:\nUnauthorized')
        }
        else if (Response.status !== 200) {
            throw new Error(
                'Request failed:' +
                ' \n\nUrl: ' +
                url +
                '\n\nResponse status: ' +
                Response.status.toString() +
                '\n\nResponse text:\n' +
                Response.statusText,
            )
        }

        const res_json = await Response.json()

        return res_json?.integration_name
    }
    catch (error) {
        throw new Error(error.message)
    }
}
