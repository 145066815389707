import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-thanks-booking',
  templateUrl: './thanks-booking.component.html',
  styleUrls: ['./thanks-booking.component.scss'],
})
export class ThanksBookingComponent {
  @Input() onNextStep!: () => void;

  onContinue() {
    this.onNextStep();
  }
}
