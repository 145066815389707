import { Static, Type } from '@sinclair/typebox'
import { UpbrainMessageFormat } from '../../upbrains-mapper/dto/upbrains-message-format'

export const MessageItemParamDto = Type.Object({
    projectId: Type.String({}),
    messageId: Type.String({}),
})

export type MessageItemParamDto = Static<typeof MessageItemParamDto>


export const MessageCreationParamDto = Type.Object({
    projectId: Type.String({}),
})
export type MessageCreationParamDto = Static<typeof MessageCreationParamDto>

export const PartialMessageCreationBody = Type.Partial(UpbrainMessageFormat)


export const MessageCreationWithUserInfoBodyDto = Type.Composite(
    [
        Type.Omit(
            UpbrainMessageFormat,
            [
                'comment_or_message_id',
                'inbox_or_group_id',
                'from',
            ],
        ),
        Type.Object({
            from: Type.String(),
            teamId: Type.String(),
        }),
    ],
) 

export const MessageCreationWithoutUserInfoBodyDto = Type.Omit(
    UpbrainMessageFormat,
    [
        'comment_or_message_id',
        'inbox_or_group_id',
        'from',
    ],
)


export const MessageCreationBodyDto = Type.Union([
    MessageCreationWithUserInfoBodyDto,
    MessageCreationWithoutUserInfoBodyDto,
])

export type MessageCreationBodyDto = Static<typeof MessageCreationBodyDto>

export const MessageDeleteParamDto = Type.Object({
    projectId: Type.String({}),
    messageId: Type.String({}),  
})

export type MessageDeleteParamDto = Static<typeof MessageDeleteParamDto>