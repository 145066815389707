import { Static, Type } from '@sinclair/typebox'


export const VerifyUserEmailRequest = Type.Object({
    email: Type.String(),
    token: Type.String(),
})

export type VerifyUserEmailRequest = Static<typeof VerifyUserEmailRequest>

