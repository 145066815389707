<div
  class="ap-flex ap-flex-col ap-w-[320px] md:ap-w-[678px] ap-max-w-[768px] ap-full ap-gap-3 ap-mt-[72px]"
>
  <app-checkbox-button-option [optionsList]="listOfOptions" />
</div>

<p class="ap-text-[#344054] ap-font-inter ap-text-sm ap-font-normal ap-mt-3">
  Please select at least one option.
</p>

<div class="ap-w-[230px] ap-my-7 ap-mx-auto declare-steps">
  <ap-button
    actionButton
    btnSize="large"
    i18n
    class="ap-rounded-lg"
    [fullWidthOfContainer]="true"
    (buttonClicked)="onConfirm()"
  >
    <div
      class="ap-flex ap-items-center ap-justify-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
    >
      Continue
    </div>
  </ap-button>
</div>
