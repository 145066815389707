<div
  class="ap-relative ap-rounded-md ap-shadow-[0px_0px_15px_#0000001a] ap-w-full ap-px-4 ap-py-3 ap-cursor-pointer"
>
  <div class="ap-flex ap-flex-row ap-gap-3 ap-items-center">
    <div
      class="ap-rounded-full ap-w-[30px] ap-h-[30px] ap-flex ap-justify-center ap-items-center"
    >
      <svg-icon
        [src]="iconUrl[checkFileType(attachment.type)]"
        [applyClass]="true"
        class="ap-fill-primary ap-w-10 ap-h-10"
      ></svg-icon>
    </div>
    <div class="ap-flex ap-flex-col ap-overflow-hidden">
      <div class="truncate !ap-font-semibold ap-typography-body-1 ap-mr-2">
        {{ attachment.name }}
      </div>
      <div class="truncate !ap-font-thin">
        Size: <strong>{{ attachment.size }}</strong>
      </div>
    </div>
  </div>
  <div
    class="ap-absolute ap-h-full ap-w-full ap-left-0 ap-top-0 ap-z-50"
    [matTooltip]="attachment.name"
  ></div>
</div>
