<ap-dialog-title-template i18n
  >Add New Line of Business</ap-dialog-title-template
>
<mat-dialog-content>
  <div class="md:ap-max-w-[500px]">
    <div class="ap-typography-body-1 ap-text-body ap-mb-4" i18n>
      Type the new line of business
    </div>
    <form
      class="ap-flex ap-flex-col ap-gap-3"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Line of Business"
          class="ap-w-full ap-h-[44px] ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-font-normal focus:ap-text-sm focus:ap-border-[#D0D5DD] focus:ap-outline-none focus:ap-ring-0 focus:ap-shadow-[0px_0px_0px_4px_[#1570EF]]"
          formControlName="lineBusiness"
          required
        />
        <mat-error *ngIf="form.controls.lineBusiness.invalid"
          >Your input is not valid</mat-error
        >
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="ap-flex ap-gap-2.5">
    <ap-button btnColor="basic" (click)="cancel()" btnSize="default" i18n>
      Cancel
    </ap-button>
    <ap-button
      btnSize="default"
      type="submit"
      btnColor="primary"
      i18n
      (click)="submit()"
    >
      Add
    </ap-button>
  </div>
</mat-dialog-actions>
