<ap-dialog-title-template i18n>{{ data.title }} </ap-dialog-title-template>

<mat-dialog-content>
  <p>{{ data.description }}</p>
</mat-dialog-content>

<mat-dialog-actions
  class="ap-w-full ap-flex !ap-items-end !ap-justify-end ap-gap-2"
>
  <ap-button
    btnColor="basic"
    btnSize="default"
    (click)="onClose()"
    mat-dialog-close
    >Cancel</ap-button
  >
  <ap-button
    btnColor="warn"
    btnSize="default"
    (click)="onConfirm()"
    mat-dialog-close
    >Confirm</ap-button
  >
</mat-dialog-actions>
