import { Static, Type } from '@sinclair/typebox'

export const FileResponseInterface = Type.Object({
    base64Url: Type.String(),
    fileName: Type.String(),
    extension: Type.Optional(Type.String()),
})

export type FileResponseInterface = Static<typeof FileResponseInterface>

type MetaData = Record<string, string>

export type Attachment = {
    url: string
    name: string
    size: string
    type: 'pdf' | 'image'
}

export type FieldType =
    | {
          content: string | number
          details?: Record<string, any>
          field_name: string
          page_number?: string
          type: string
          matches?: any
      }
    | {
          candidates: {
              score: string
              value: number
              header_value: string
          }[]
          metadata: MetaData
          query: string
          result: {
              score: string
              value: number
              header_value: string
          }[]
          status: number
          uncertain: boolean
      }[]

export type UpdatedExtractorPayload = {
    extractor_info: Record<string, string | boolean | number>
    status_summary?: string
    extractor_result: {
        [key: string]: string | {} | number | null
        documents: {
            content: string
            fields: Record<
                string,
                {
                    content: string | number
                    details: any
                    field_name: string
                    page_number?: string
                    type: string
                }
            >
            items: Record<string, FieldType | string>[]
        }[]
        pages: {
            text: string
            page_number: number
        }[]
    }
    metadata: MetaData
    result_id: string | number | null
}

export type FormDataType = {
    updated_extractor_payload: UpdatedExtractorPayload
    status: number
}

type FormFileUrl = {
    signedUrl?: string
}

export type StaticFormsData = {
    formData: UpdatedExtractorPayload | FormDataType
    formEmailConfig?: EmailConfig
    formFileUrl?: FormFileUrl | string
    formText?: string
    formAttachments?: Attachment[]
    formSearchType?: 'none' | 'spread_sheet' | 'custom_search_api'
    formSearchBody?: {
        label: string
        value: string
    }[]
    formFieldsOrder?: {
        fields: Record<string, number>
        columns: Record<string, number>
    }
    spreadsheetLogData?: SearchFieldInputs
    hasJsonButton?: boolean
    multipleFiles: multipleFile[]
    conversationId?: string
    keyFieldsValidation?: any
}

export type multipleFile = {
    Attachment: string
    InputType: string
    fileName: string
}

export type EmailConfig = {
    from: string
    cc: string
    to: string
    date: string
    content: string
    subject: string
    senderImage: string
}

export type FormType =
    | 'form_submission'
    | 'file_submission'
    | 'form-builder'
    | 'rfq_form'
    | 'document_review'
    | 'order_entry'

export type SearchFieldInputs = {
    auth?: string
    columnName?: string
    include_team_drives?: boolean
    matchCase?: boolean
    searchValue?: string
    sheet_id?: number
    sheet_name?: string
    skipStep?: boolean
    spreadsheet_id?: string
    spreadsheet_name?: string
    connectionName?: string
}

export type FindTable = {
    row: number
    values: Record<string, string>
}

export enum ColumnNames {
    Quantity = 'Quantity',
    UnitPrice = 'Unit Price',
    ProductNumber = 'Product Number',
}

export enum ColumnTypes {
    Select = 'select',
    Component = 'component',
}

export enum SearchTypes {
    None = 'none',
    SpreadSheet = 'spread_sheet',
    CustomSpreadSheet = 'custom_search_api',
}
