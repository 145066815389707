<form *ngIf="form" [formGroup]="form" class="ap-flex  ap-flex-col">
  <div *ngFor="let property of requiredProperties|objectToArray; let i = index">
    <ng-container
      *ngTemplateOutlet="formFieldsTemplate;context:{$implicit:property, propertyIndex:i,formGroup:form}"></ng-container>
  </div>

  <div *ngFor="let property of optionalProperties|objectToArray; let i = index">
    <ng-container *ngTemplateOutlet="formFieldsTemplate;context:{$implicit:property, propertyIndex:i, formGroup:form}">
    </ng-container>
  </div>
</form>

<ng-template let-property let-propertyIndex="propertyIndex" #formFieldsTemplate let-formGroup="formGroup"
  let-prefix="prefix">

  <ng-container [ngSwitch]="property.value.type" [formGroup]="formGroup">

    <ng-container *ngSwitchCase="PropertyType.MARKDOWN">
      <ng-container *ngIf="convertMarkdown(property.value.description) | async as markdown">
        <div class="ap-mb-8">
          <ap-markdown [fullWidth]="true" [data]="markdown"> </ap-markdown>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="PropertyType.CHECKBOX">
      <ng-container
        *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? dynamicValueTemplate:checkboxInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
      </ng-container>

      <ng-template #checkboxInputTemplate>
        <div class="ap-flex  ap-mr-[5px] ap-items-center ap-gap-2 ">

          <div apTrackHover #slider="hoverTrackerDirective" class="ap-flex ap-items-center ap-gap-2 ap-align-center">
            <mat-slide-toggle [formControlName]="property.key" color="primary"
              class="ap-flex-grow-1">{{property.value.displayName}}</mat-slide-toggle>
          </div>
          <div class="ap-flex-grow"></div>
          <div class="ap-cursor-pointer">
            <ng-container
              *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template #dynamicValueTemplate let-property>
        <div class="ap-flex ap-justify-end ap-mb-1 ap-mx-1 ap-text-primary">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
          </ng-container>
        </div>
        <ng-container>
          <ng-container *ngTemplateOutlet="textInputTemplate;context:{$implicit:property, formGroup:formGroup}">
          </ng-container>
        </ng-container>
      </ng-template>
      <ng-container *ngIf="!customizedInputs || !customizedInputs[property.key]">
        <ng-container *ngTemplateOutlet="description; context:{$implicit:property}">
        </ng-container>

      </ng-container>
    </ng-container>
    <div
      *ngIf="property.value.type === PropertyType.FILE || property.value.type === PropertyType.SHORT_TEXT || property.value.type === PropertyType.NUMBER || property.value.type === PropertyType.LONG_TEXT || property.value.type === PropertyType.DATE_TIME || property.value.type === PropertyType.DEFAULT_ACCESS_TOKEN">
      <ng-container *ngTemplateOutlet="textInputTemplate;context:{$implicit:property, formGroup:formGroup}">
      </ng-container>
    </div>

    <ng-container *ngSwitchCase="PropertyType.DROPDOWN">
      <div>
        <div class="ap-flex ap-justify-end ap-mb-1 ap-mr-1 ap-text-primary ">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property}">
          </ng-container>
        </div>
        <ng-container
          *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:dropdownInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
        </ng-container>
        <ng-template #dropdownInputTemplate>
          <mat-form-field subscriptSizing="dynamic" class="ap-w-full" appearance="outline" #dropdown>

            <mat-label> {{ (refreshableConfigsLoadingFlags$[property.key] | async)? 'Loading...' :
              property.value.displayName
              }}</mat-label>
            <mat-select #matSelect [formControlName]="property.key" (closed)="searchControl.setValue('')"
              [compareWith]="dropdownCompareWithFunction">


              <ng-container *ngIf="dropdownOptionsObservables$[property.key]| async as state">


                <ng-container
                  *ngIf="(((refreshableConfigsLoadingFlags$[property.key] | async) === false || !refreshableConfigsLoadingFlags$[property.key]))">
                  <ng-container *ngIf="(state.options | dropdownSearch:searchControl) | async as options">
                    <ng-container *ngIf="!state.disabled">
                      <ng-container *ngTemplateOutlet="searchInputForDropdowns"></ng-container>
                    </ng-container>

                    <cdk-virtual-scroll-viewport *ngIf="matSelect.panelOpen && !state.disabled" [itemSize]="48"
                      minBufferPx="200" maxBufferPx="400" class="ap-max-h-[195px] thin-scrollbars  ap-overflow-y-auto"
                      [class.ap-h-[50px]]="options.length === 1 " [class.ap-h-[100px]]="options.length === 2 "
                      [class.ap-h-[150px]]="options.length === 3 " [class.ap-h-[195px]]="options.length >= 4 ">


                      <mat-option *cdkVirtualFor="let opt of options" [value]="opt.value"
                        [class.mdc-list-item--selected]="(opt.value| isDropdownItemSelected:property.key:formGroup ) | async">
                        <div class="ap-w-full ap-flex ap-justify-between ap-items-center">
                          {{opt.label}}
                          <mat-pseudo-checkbox
                            *ngIf="(opt.value| isDropdownItemSelected:property.key:formGroup) | async" state="checked"
                            aria-hidden="true" appearance="minimal"
                            class="mat-pseudo-checkbox virtual-scroll-check-mark mat-mdc-option-pseudo-checkbox mat-pseudo-checkbox-checked mat-pseudo-checkbox-minimal"></mat-pseudo-checkbox>
                        </div>
                      </mat-option>
                    </cdk-virtual-scroll-viewport>

                    <mat-option
                      *ngFor="let init of (state.options | dropdownInitialValue:property.key:formGroup)|async "
                      class="!ap-hidden" [value]="init.value">
                      {{init.label}}
                    </mat-option>

                    <mat-option [disabled]="true"
                      *ngIf="state.disabled && ((refreshableConfigsLoadingFlags$[property.key] | async) === false || !refreshableConfigsLoadingFlags$[property.key])">
                      <div> {{state.placeholder}} </div>
                    </mat-option>

                    <!-- need at least 1 pre-existing option to open the select -->
                    <mat-option class="!ap-hidden"></mat-option>
                  </ng-container>
                </ng-container>


              </ng-container>

            </mat-select>






          </mat-form-field>
          <ng-container *ngTemplateOutlet=" description; 
          context:{$implicit:property,
          hasError:formGroup.get(property.key)?.invalid, 
          errorMessage:(property.value.displayName+' is required'),
          isTouched:formGroup.get(property.key)?.touched}">
          </ng-container>
        </ng-template>

      </div>

    </ng-container>
    <ng-container *ngSwitchCase="PropertyType.MULTI_SELECT_DROPDOWN">
      <div>
        <span class="ap-flex ap-justify-end ap-mb-1 ap-mr-1 ap-text-primary r">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
          </ng-container>
        </span>
        <ng-container
          *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:multiDropdownInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
        </ng-container>
        <ng-template #multiDropdownInputTemplate>
          <mat-form-field subscriptSizing="dynamic" class="ap-w-full" appearance="outline" #dropdown>

            <mat-label> {{ (refreshableConfigsLoadingFlags$[property.key] | async)? 'Loading...' :
              property.value.displayName
              }}</mat-label>


            <mat-select #matSelect (closed)="searchControl.setValue('')" [style.display]="((dropdownOptionsObservables$[property.key] | async) === undefined 
                || (dropdownOptionsObservables$[property.key] | async) === null || 
                 (dropdownOptionsObservables$[property.key] | async)?.disabled === true)? 'none':'block' " multiple
              [formControlName]="property.key" [compareWith]="dropdownCompareWithFunction">


              <mat-select-trigger>
                <ng-container *ngIf="dropdownOptionsObservables$[property.key]| async as state">
                  <ng-container *ngIf="(state.options | dropdownInitialValue:property.key:formGroup)|async as init">
                    <ng-container *ngFor="let opt of init;let isLast = last">
                      {{opt.label}}<ng-container *ngIf="!isLast">,</ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-select-trigger>
              <ng-container *ngIf="dropdownOptionsObservables$[property.key]| async as state">

                <ng-container
                  *ngIf="(((refreshableConfigsLoadingFlags$[property.key] | async) === false || !refreshableConfigsLoadingFlags$[property.key])) && !state.disabled">
                  <ng-container *ngTemplateOutlet="searchInputForDropdowns"></ng-container>

                  <cdk-virtual-scroll-viewport *ngIf="matSelect.panelOpen" [itemSize]="48" minBufferPx="200"
                    maxBufferPx="400" class="ap-max-h-[195px] thin-scrollbars  ap-overflow-y-auto"
                    [class.ap-h-[50px]]="state.options.length === 1 " [class.ap-h-[100px]]="state.options.length === 2"
                    [class.ap-h-[150px]]="state.options.length === 3 " [class.ap-h-[195px]]="state.options.length >= 4">
                    <mat-option *cdkVirtualFor="let opt of state.options" [value]="opt.value">
                      {{opt.label}}
                    </mat-option>
                  </cdk-virtual-scroll-viewport>

                  <mat-option *ngFor="let init of (state.options | dropdownInitialValue:property.key:formGroup)|async "
                    class="!ap-hidden" [value]="init.value">
                    {{init.label}}
                  </mat-option>
                </ng-container>
                <!-- need at least 1 pre-existing option to open the select -->
                <mat-option *ngIf="!state.disabled" class="!ap-hidden"></mat-option>
              </ng-container>
            </mat-select>




            <ng-container
              *ngIf="!!(dropdownOptionsObservables$[property.key] | async) &&  (dropdownOptionsObservables$[property.key] | async)?.disabled === true">
              <mat-select [formControlName]="property.key" [compareWith]="dropdownCompareWithFunction">
                <ng-container *ngIf="dropdownOptionsObservables$[property.key] | async as state">
                  <mat-option [disabled]="true"
                    *ngIf="state.disabled && ((refreshableConfigsLoadingFlags$[property.key] | async) === false || !refreshableConfigsLoadingFlags$[property.key])">
                    <div> {{state.placeholder}} </div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </ng-container>

          </mat-form-field>
          <ng-container *ngTemplateOutlet=" description; 
          context:{$implicit:property,
          hasError:formGroup.get(property.key)?.invalid, 
          errorMessage:(property.value.displayName+' is required'),
          isTouched:formGroup.get(property.key)?.touched}">
          </ng-container>
        </ng-template>

      </div>

    </ng-container>


    <ng-container *ngSwitchCase="PropertyType.STATIC_DROPDOWN">
      <div>
        <div class="ap-mb-1 ap-mr-1 ap-text-primary ap-flex ap-justify-end">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
          </ng-container>
        </div>
        <ng-container
          *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:staticDropdownInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
        </ng-container>
        <ng-template #staticDropdownInputTemplate>
          <mat-form-field subscriptSizing="dynamic" class="ap-w-full" appearance="outline">

            <mat-label> {{ property.value.displayName }} </mat-label>



            <mat-select #matSelect [matTooltip]="property.value.description" [formControlName]="property.key"
              (closed)="searchControl.setValue('')" [compareWith]="dropdownCompareWithFunction">
              <ng-container *ngTemplateOutlet="searchInputForDropdowns"></ng-container>
              <ng-container *ngIf="(property.value.options.options | dropdownSearch:searchControl) | async as options">

                <cdk-virtual-scroll-viewport *ngIf="matSelect.panelOpen" [itemSize]="48" minBufferPx="200"
                  maxBufferPx="400" class="ap-max-h-[195px] thin-scrollbars  ap-overflow-y-auto"
                  [class.ap-h-[50px]]="options.length === 1 " [class.ap-h-[100px]]="options.length === 2 "
                  [class.ap-h-[150px]]="options.length === 3 " [class.ap-h-[195px]]="options.length >= 4 ">


                  <mat-option *cdkVirtualFor="let opt of options" [value]="opt.value"
                    [class.mdc-list-item--selected]="(opt.value| isDropdownItemSelected:property.key:formGroup ) | async">
                    <div class="ap-w-full ap-flex ap-justify-between ap-items-center">
                      {{opt.label}}
                      <mat-pseudo-checkbox *ngIf="(opt.value| isDropdownItemSelected:property.key:formGroup) | async"
                        state="checked" aria-hidden="true" appearance="minimal"
                        class="mat-pseudo-checkbox virtual-scroll-check-mark mat-mdc-option-pseudo-checkbox mat-pseudo-checkbox-checked mat-pseudo-checkbox-minimal"></mat-pseudo-checkbox>
                    </div>
                  </mat-option>
                </cdk-virtual-scroll-viewport>

                <mat-option
                  *ngFor="let init of (property.value.options.options | dropdownInitialValue:property.key:formGroup)|async "
                  class="!ap-hidden" [value]="init.value">
                  {{init.label}}
                </mat-option>
                <!-- need at least 1 pre-existing option to open the select -->
                <mat-option class="!ap-hidden"></mat-option>
              </ng-container>
            </mat-select>






          </mat-form-field>
          <ng-container *ngTemplateOutlet=" description; 
          context:{$implicit:property,
          hasError:formGroup.get(property.key)?.invalid, 
          errorMessage:(property.value.displayName+' is required'),
          isTouched:formGroup.get(property.key)?.touched}">
          </ng-container>
        </ng-template>

      </div>

    </ng-container>

    <ng-container *ngSwitchCase="PropertyType.STATIC_MULTI_SELECT_DROPDOWN">
      <div>
        <span class="ap-flex ap-justify-end ap-mb-1 ap-mr-1 ap-text-primary ">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property}">
          </ng-container>
        </span>
        <ng-container
          *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:staticMultiSelectDropdownInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
        </ng-container>
        <ng-template #staticMultiSelectDropdownInputTemplate>
          <mat-form-field subscriptSizing="dynamic" #dropdown class="ap-w-full" appearance="outline"
            #dropdownUiContainer="hoverTrackerDirective" apTrackHover>

            <mat-label> {{ property.value.displayName }} </mat-label>

            <mat-select #matSelect [matTooltip]="property.value.description" [formControlName]="property.key"
              (closed)="searchControl.setValue('')" [compareWith]="dropdownCompareWithFunction" multiple>

              <mat-select-trigger>

                <ng-container
                  *ngIf="(property.value.options.options | dropdownInitialValue:property.key:formGroup)|async as init">
                  <ng-container *ngFor="let opt of init;let isLast = last">
                    {{opt.label}}<ng-container *ngIf="!isLast">,</ng-container>
                  </ng-container>
                </ng-container>


              </mat-select-trigger>

              <ng-container *ngTemplateOutlet="searchInputForDropdowns"></ng-container>
              <ng-container *ngIf="(property.value.options.options | dropdownSearch:searchControl) | async as options">
                <cdk-virtual-scroll-viewport *ngIf="matSelect.panelOpen" [itemSize]="48" minBufferPx="200"
                  maxBufferPx="400" class="ap-max-h-[195px] thin-scrollbars  ap-overflow-y-auto"
                  [class.ap-h-[50px]]="options.length === 1 " [class.ap-h-[100px]]="options.length === 2"
                  [class.ap-h-[150px]]="options.length === 3 " [class.ap-h-[195px]]="options.length >= 4">
                  <mat-option *cdkVirtualFor="let opt of options" [value]="opt.value">
                    {{opt.label}}
                  </mat-option>
                </cdk-virtual-scroll-viewport>

                <mat-option
                  *ngFor="let init of (property.value.options.options | dropdownInitialValue:property.key:formGroup)|async "
                  class="!ap-hidden" [value]="init.value">
                  {{init.label}}
                </mat-option>
                <!-- need at least 1 pre-existing option to open the select -->
                <mat-option class="!ap-hidden"></mat-option>

              </ng-container>
            </mat-select>


          </mat-form-field>
          <ng-container *ngTemplateOutlet=" description; 
          context:{$implicit:property,
          hasError:formGroup.get(property.key)?.invalid, 
          errorMessage:(property.value.displayName+' is required'),
          isTouched:formGroup.get(property.key)?.touched}">
          </ng-container>
        </ng-template>

      </div>
    </ng-container>


    <ng-container *ngSwitchCase="PropertyType.ARRAY">

      <ng-container #dynamicToggleIconForTextInput *ngIf="customizedInputs && customizedInputs[property.key]">
        <div class="ap-flex ap-items-center ap-justify-end ap-mb-1 ap-mx-1">
          <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
          </ng-container>
        </div>
      </ng-container>

      <ng-container
        *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:arrayInputTemplate ;context:{$implicit:property, formGroup:formGroup}">
      </ng-container>
      <ng-template #arrayInputTemplate>
        <div [class.ap-mb-5]="form.disabled" #hoverContainer="hoverTrackerDirective" class="ap-mx-1" apTrackHover>
          <ng-template #dynamicInputTemplate>
            <ng-container
              *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
            </ng-container>
          </ng-template>
          <app-array-form-control [dynamicInputTemplate]="dynamicInputTemplate" #array [formControlName]="property.key"
            [property]="property.value" [formFieldsTemplate]="formFieldsTemplate" [prefix]="property.key">
          </app-array-form-control>
        </div>
        <ng-container *ngTemplateOutlet="description; context:{$implicit:property}">
        </ng-container>
      </ng-template>

    </ng-container>
    <ng-container *ngSwitchCase="PropertyType.OBJECT">
      <span class="ap-justify-end ap-flex ap-mb-1 ap-mr-1 ap-text-primary ">
        <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
        </ng-container>
      </span>

      <ng-container
        *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:objectInputTemplate ;context:{$implicit:property, formGroup:form}">
      </ng-container>

      <ng-template #objectInputTemplate>
        <div #hoverContainer="hoverTrackerDirective" apTrackHover>
          <div class=" ap-flex ap-gap-2 ap-items-center">
            <div class="ap-cursor-pointer" (click)="dictonary.focusFirstKeyInput()">{{property.value.displayName}}
            </div>
          </div>
          <app-dictonary-form-control #dictonary [formControlName]="property.key"></app-dictonary-form-control>
        </div>
        <ng-container *ngTemplateOutlet="description; context:{$implicit:property}">
        </ng-container>
      </ng-template>

    </ng-container>


    <ng-container
      *ngIf="property.value.type === PropertyType.OAUTH2 || property.value.type === PropertyType.SECRET_TEXT || property.value.type === PropertyType.BASIC_AUTH || property.value.type === PropertyType.CUSTOM_AUTH">
      <div class="ap-flex ap-justify-end  ap-mb-1 ap-mx-1">

        <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
        </ng-container>

      </div>

      <ng-container
        *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:connectionDropdownInputTemplate ;context:{$implicit:property, formGroup:form}">
      </ng-container>
      <ng-template #connectionDropdownInputTemplate>
        <div class="ap-relative">

          <mat-form-field subscriptSizing="dynamic" class="ap-w-full" appearance="outline" #dropdown
            #dropdownUiContainer="hoverTrackerDirective" apTrackHover>
            <mat-label> Connection </mat-label>
            <mat-select [formControlName]="property.key" [compareWith]="dropdownCompareWithFunction">
              <mat-option
                [style.display]="((allAuthConfigs$ | async)!| authConfigsForAgent:agentName)?.length === 0 ? 'none': 'flex'"
                (click)="addConnectionBtn.buttonClicked()" class="add-auth">
                <div class="ap-flex">
                  <div class="ap-flex-grow ap-text-primary ap-flex ap-gap-2 ap-font-bold ap-items-center"
                    #noConnectionDiv>
                    <svg-icon src="assets/img/custom/add.svg" [applyClass]="true"
                      class="ap-fill-primary ap-w-[13px]  ap-h-[13px] "></svg-icon> New Connection
                  </div>
                </div>
              </mat-option>
              <mat-option *ngFor="let opt of ((allAuthConfigs$ | async)!| authConfigsForAgent:agentName)"
                [value]="opt.value">
                {{opt.label.name}}
              </mat-option>
              <mat-option
                [style.display]="((allAuthConfigs$ | async)!| authConfigsForAgent:agentName)?.length === 0 ? 'flex': 'none'"
                (click)="addConnectionBtn.buttonClicked()" class="add-auth">
                <div class="ap-flex">
                  <div class="ap-flex-grow ap-text-primary ap-flex ap-gap-2 ap-font-bold ap-items-center"
                    #noConnectionDiv>
                    <svg-icon src="assets/img/custom/add.svg" [applyClass]="true"
                      class="ap-fill-primary ap-w-[13px]  ap-h-[13px] "></svg-icon> New Connection
                  </div>
                </div>
              </mat-option>

              <mat-select-trigger>
                <div class="ap-flex ap-gap-[6px] connections-dropdown-container ap-pr-1 ap-items-center">
                  <div class="ap-w-full ap-truncate">
                    {{((allAuthConfigs$ | async)!|
                    selectedAuthConfig:agentName:formGroup.value[property.key])?.label?.name
                    ||
                    ''}}
                  </div>
                  <div>
                    <app-add-edit-connection-button *ngIf="formGroup.enabled && formGroup.get(property.key)!.value"
                      (click)="$event.stopPropagation()" btnSize="extraSmall" [isEditConnectionButton]="true"
                      [authProperty]="property.value" [agentName]="agentName" [agentVersion]="agentVersion"
                      [propertyKey]="property.key" [agentDisplayName]="agentDisplayName"
                      [selectedConnectionInterpolatedString]="formGroup.get(property.key)!.value"
                      (connectionPropertyValueChanged)="connectionValueChanged($event)"
                      [triggerName]="actionOrTriggerName">
                      <div class="ap-px-2" i18n>
                        Reconnect
                      </div>
                    </app-add-edit-connection-button>
                  </div>
                </div>
              </mat-select-trigger>
            </mat-select>

          </mat-form-field>
          <app-add-edit-connection-button btnSize="medium" class="ap-hidden" *ngIf="formGroup.enabled"
            [isEditConnectionButton]="false" #addConnectionBtn [authProperty]="property.value" [agentName]="agentName"
            [agentVersion]="agentVersion" [propertyKey]="property.key"
            (connectionPropertyValueChanged)="connectionValueChanged($event)" [triggerName]="actionOrTriggerName"
            [agentDisplayName]="agentDisplayName">

          </app-add-edit-connection-button>
        </div>
        <ng-container *ngTemplateOutlet=" description; 
        context:{$implicit:{value:{description:''}},
        hasError:formGroup.get(property.key)?.invalid, 
        errorMessage:('Connection is required'),
        isTouched:formGroup.get(property.key)?.touched}">
        </ng-container>
      </ng-template>

    </ng-container>
    <ng-container *ngSwitchCase="PropertyType.JSON">
      <div class="ap-flex ap-justify-end">
        <ng-container *ngTemplateOutlet="customizedInputs? dynamicVariableIcon: null; context:{$implicit:property} ">
        </ng-container>
      </div>

      <ng-container
        *ngTemplateOutlet="customizedInputs && customizedInputs[property.key]? textInputTemplate:jsonInputTemplate ;context:{$implicit:property, formGroup:form}">
      </ng-container>

      <ng-template #jsonInputTemplate>
        <div #interpolatingTextControlContainer #hoverContainer="hoverTrackerDirective" apTrackHover
          (click)="$event.stopImmediatePropagation()">
          <div class="ap-py-2 ap-px-4 ap-flex bar-containing-beautify-button">
            <div class="ap-flex-grow">

              <span class="ap-text-white ap-flex ap-gap-2 ap-items-center">
                {{property.value.displayName}}
              </span>
            </div>
            <div>
              <svg-icon src="/assets/img/custom/beautify.svg" [svgStyle]="{width:'16px', height:'16px'}"
                (click)="beautify(property.key)" matTooltip="beautify" class="ap-cursor-pointer"> </svg-icon>
            </div>
          </div>
          <div class="ap-h-[300px]">
            <ngx-monaco-editor (onInit)="onInit($event)" class="!ap-h-full !ap-w-full" [formControlName]="property.key"
              (click)="handler.showMentionsDropdown()" [options]="codeEditorOptions"></ngx-monaco-editor>
          </div>

          <app-builder-autocomplete-dropdown-handler #handler [container]="interpolatingTextControlContainer"
            (mentionEmitted)="addMentionToJsonControl($event)">
          </app-builder-autocomplete-dropdown-handler>
        </div>
        <div *ngIf="formGroup.get(property.key)!.invalid" class="ap-py-2">
          <p *ngIf="formGroup.get(property.key)!.hasError('required');else invalidJson"
            class="ap-text-danger ap-typography-caption">
            {{property.value.displayName}} is required
          </p>
          <ng-template #invalidJson>
            <p class="ap-text-danger ap-typography-caption">
              {{property.value.displayName}} is not a valid JSON object.
            </p>
          </ng-template>
        </div>
        <ng-container *ngTemplateOutlet="description; context:{$implicit:property}">
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="PropertyType.DYNAMIC">
      <ng-container *ngIf="(refreshableConfigsLoadingFlags$[property.key] | async) === false ||
      (refreshableConfigsLoadingFlags$[property.key] | async) === undefined ||
      (refreshableConfigsLoadingFlags$[property.key] | async) === null; else loading">
        <div class="ap-flex ap-flex-col ap-gap-2">
          <div *ngFor="let cp of (dynamicPropsObservables$[property.key] | async)| objectToArray; let i = index">
            <ng-container
              *ngTemplateOutlet="formFieldsTemplate;context:{$implicit:cp, configIndex:i, formGroup:formGroup.get(property.key)}">
            </ng-container>
          </div>
        </div>




      </ng-container>

      <ng-template #loading>
        <div class="ap-flex ap-flex-grow ap-justify-center ap-items-center ap-h-[250px]">
          <ap-loading-icon> </ap-loading-icon>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>


</ng-template>

<ng-container *ngIf="updateValueOnChange$ | async"></ng-container>
<ng-container *ngFor="let obs$  of 	dynamicPropsObservables$ | keyvalue">
  <ng-container *ngIf=" obs$.value | async "></ng-container>
</ng-container>
<ng-container *ngIf="configDropdownChanged$  | keyvalue"></ng-container>

<ng-template #textInputTemplate let-property let-formGroup="formGroup">

  <div [formGroup]="formGroup" #interpolatingTextComponentContainer (click)="$event.stopImmediatePropagation()"
    apTrackHover #textInputContainer="hoverTrackerDirective">
    <mat-form-field subscriptSizing="dynamic" class="ap-w-full" appearance="outline" #textInput
      (click)="isTriggerAgentForm?null: handler.showMentionsDropdown()">
      <div matTooltip="File Input" *ngIf="property.value.type === PropertyType.FILE" matSuffix
        class="material-suffix-icon">
        <svg-icon src="assets/img/custom/file.svg" [applyClass]="true"
          class="ap-w-[24px] a-h-[24px] ap-fill-description ">

        </svg-icon>
      </div>

      <div matTooltip="Date/Time Input" *ngIf="property.value.type === PropertyType.DATE_TIME" matSuffix
        class="material-suffix-icon">
        <svg-icon [applyClass]="true" src="assets/img/custom/calendar.svg" matToolip
          class="ap-w-[24px] a-h-[24px] ap-fill-description">
        </svg-icon>
      </div>


      <mat-label>{{property.value.displayName}}</mat-label>
      <app-interpolating-text-form-control #textControl [formControlName]="property.key" [attr.name]="property.key"
        (editorFocused)="handler.showMentionsDropdown()"></app-interpolating-text-form-control>
    </mat-form-field>
    <ng-container *ngTemplateOutlet="
        description; 
        context:{$implicit:property,
        hasError:formGroup.get(property.key)?.invalid, 
        errorMessage:(property.value.displayName+' is required'),
        isTouched:formGroup.get(property.key)?.touched}">

    </ng-container>
    <app-builder-autocomplete-dropdown-handler #handler [container]="interpolatingTextComponentContainer"
      (mentionEmitted)="addMention(textControl,$event)">
    </app-builder-autocomplete-dropdown-handler>

  </div>
</ng-template>


<ng-template #dynamicVariableIcon let-config>
  <svg-icon [svgStyle]="{width:'20px', height:'20px'}" *ngIf="form.enabled" class="ap-mb-[3px] ap-cursor-pointer"
    apTrackHover [class.!ap-opacity-100]="hoverTracker.isHovered" #hoverTracker="hoverTrackerDirective"
    [class.ap-opacity-40]="customizedInputs && !customizedInputs[config.key]" src="assets/img/custom/variable.svg"
    matTooltip="Dynamic Value" (click)="toggleCustomizedInputFlag(config.key)">
  </svg-icon>
</ng-template>

<ng-container *ngIf="setDefaultValue$ | async"></ng-container>


<ng-template #searchInputForDropdowns>
  <mat-form-field subscriptSizing="dynamic" subscriptSizing="dynamic" appearance="fill"
    (click)="$event.stopPropagation();" class="ap-w-full ">
    <mat-icon matPrefix color="placeholder-icon" svgIcon="search"></mat-icon>
    <input #searchInput [formControl]="searchControl" matInput placeholder="Search" autocomplete="off"
      (keydown.Space)="$event.stopPropagation()">
  </mat-form-field>
</ng-template>


<!--Line clamp is not working with prefix on tailwind for some reason  -->
<ng-template #description let-property let-hasError="hasError" let-errorMessage="errorMessage"
  let-isTouched="isTouched">
  <div class="ap-flex ap-justify-between ap-markdown "
    [style.marginBottom]="BOTTOM_MARGIN_FOR_DESCRIPTION_IN_AGENT_PROPERTIES_FORM"
    *ngIf="property.value.description || isTouched && hasError">
    <div class="ap-line-clamp-1" [style.display]="descriptionExpandedMap[property.key]? 'block': '-webkit-box'"
      [class.ap-h-[24px]]="!descriptionExpandedMap[property.key]">
      <div class="ap-typography-caption " apCheckOverflow (isOverflowed)="descriptionOverflownMap[property.key]=$event">
        <markdown
          [data]="isTouched && hasError? '<span class=\'ap-text-danger\'>'+errorMessage+'</span>. '+ (property.value.description? property.value.description: '') : property.value.description? property.value.description:''">
        </markdown>
      </div>
    </div>
    <div class="ap-flex ap-items-start">
      <button class="ap-underline ap-typography-caption ap-mt-2"
        *ngIf="descriptionOverflownMap[property.key] || descriptionExpandedMap[property.key]"
        (click)="descriptionExpandedMap[property.key] = !descriptionExpandedMap[property.key]">
        {{descriptionExpandedMap[property.key]? 'less' : 'more'}} </button>
    </div>


  </div>

  <div *ngIf="!property.value.description && (!isTouched || !hasError)" [style.height]="MIN_SPACING_BETWEEN_INPUTS">

  </div>


</ng-template>