import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  environment,
  SendEmailService,
  SubscriptionService,
} from '@upbrains/ui/common';

type Option = {
  label: string;
  features: string[];
  isRecommended?: boolean;
  price?: number;
};

@Component({
  selector: 'app-choose-onboarding-package',
  templateUrl: './choose-onboarding-package.component.html',
  styleUrls: ['./choose-onboarding-package.component.scss'],
})
export class ChooseOnboardingPackageComponent implements OnInit {
  @Input() onNextStep!: () => void;
  @Input() onPrevStep!: () => void;

  listOfOptions: Option[] = [];
  isLoading = false;

  constructor(
    private router: Router,
    private sendEmailService: SendEmailService,
    private authenticationService: AuthenticationService,
    private subscriptionService: SubscriptionService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadSubscriptionPlans();
  }

  loadSubscriptionPlans(): void {
    this.isLoading = true;
    this.subscriptionService.getPlansInfo().subscribe({
      next: (data) => {
        let standardPrice = 0;
        let premiumPrice = 0;

        data?.results?.plans_info.map(({ name, fixed_amount }) => {
          if (name === 'Standard-Onboarding' && fixed_amount) {
            standardPrice = fixed_amount;
          } else if (name === 'Premium-Onboarding' && fixed_amount) {
            premiumPrice = fixed_amount;
          }
        });

        this.listOfOptions = [
          {
            label: 'Free',
            isRecommended: true,
            features: [
              '30 mins consultation',
              '1 hour configuration',
              '30 mins testing / training',
            ],
          },
          {
            label: 'Standard',
            price: standardPrice,
            features: [
              '1 hour consultation',
              '2 hours configuration',
              '1 hour testing / training',
            ],
          },
          {
            label: 'Premium',
            price: premiumPrice,
            features: [
              '2 hours consultation',
              '8 hours configurations',
              '2 hours testing / training',
            ],
          },
          {
            label: 'Custom',
            features: [
              'Custom consultation',
              'Custom configuration',
              'Custom testing / training ',
            ],
          },
        ];

        this.isLoading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('Error loading subscription plans:', err);
        this.isLoading = false;
      },
    });
  }

  onConfirm(title: string) {
    localStorage.removeItem('onboarding-selected-package');
    localStorage.setItem('onboarding-selected-package', title);

    this.callUpdateUserInfo();
    this.sendEmail();
    this.onNextStep();
  }

  onSelfConfirm() {
    this.callUpdateUserInfo();
    this.sendEmail();
    this.router.navigate(['/subscriptions/onboarding-plans']);
  }

  callUpdateUserInfo() {
    return this.subscriptionService
      .updateUserInfo({ onboarded: true })
      .subscribe({
        next: (response) => {
          console.log('User info updated successfully', response);
        },
        error: (error) => {
          console.error('Error updating user info:', error);
        },
        complete: () => {
          console.log('User info update process complete.');
        },
      });
  }

  sendEmail() {
    const emailData = localStorage.getItem('emailData') || '';
    const generatedEmailHtml = this.generateEmailHtml(emailData);

    this.sendEmailService
      .sendEmail(
        environment.onboarding_email_recipient,
        'Onboarding User Info',
        generatedEmailHtml
      )
      .then(() => console.log('Email sent successfully'))
      .catch((error) => console.error('Error sending email:', error));
  }

  private generateEmailHtml(email: string): string {
    const { email: userEmail } = this.authenticationService.currentUser;

    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <title>Selected Automation Options</title>
        <style>
          body { font-family: Arial, sans-serif; background-color: #f9f9f9; padding: 20px; color: #333; }
          .container { max-width: 600px; margin: auto; background-color: #fff; border-radius: 8px; padding: 20px; border: 1px solid #e0e0e0; }
          h2,h3,h4 { color: #344054; }
          ul { list-style-type: none; padding: 0; }
          li { background-color: #f4f4f4; margin: 5px 0; padding: 10px; border-radius: 4px; }
          .footer { margin-top: 20px; font-size: 12px; color: #888; text-align: center; }
        </style>
      </head>
      <body>
        <div class="container">
          <h2>User Email:</h2>
          <h4>${userEmail}</h4>
          <br>
          <h3>Selected Package:</h3>
          <h4>${localStorage.getItem('onboarding-selected-package')}</h4>
          <br>
          <br>
          ${email}
          <p>Thank you for your selection! 🎉</p>
          <div class="footer">&copy; ${new Date().getFullYear()} Upbrains. All rights reserved.</div>
        </div>
      </body>
      </html>
    `;
  }
}
