<div
  class="box-shadow ap-p-8 ap-flex-1 ap-overflow-hidder ap-rounded ap-shadow-step-container-ds ap-bg-white ap-w-full ap-h-full ap-m-auto"
>
  <div class="ap-flex ap-flex-row ap-items-center ap-justify-between ap-mb-2">
    <ap-button
      actionButton
      (buttonClicked)="search(keyword)"
      [disabled]="isError || !isPdf"
      btnSize="medium"
      btnColor="primary"
      i18n
      >Search</ap-button
    >
    <ap-icon-button
      [iconFilename]="'close.svg'"
      (click)="onClose()"
    ></ap-icon-button>
  </div>
  <div class="ap-relative">
    <div class="ap-h-[70vh] ap-overflow-auto">
      <pdf-viewer
        *ngIf="isPdf"
        [src]="fileUrl"
        [render-text]="true"
        [original-size]="true"
        class="ap-w-full pdf-height"
        [fit-to-page]="true"
        (after-load-complete)="onLoadComplete()"
        (error)="onError($event)"
      ></pdf-viewer>

      <img *ngIf="!isPdf" [src]="fileUrl" alt="file" class="ap-mx-auto" />
    </div>
    <div
      *ngIf="isError && isPdf"
      class="ap-absolute ap-top-4 ap-left-1/2 ap-transform -ap-translate-x-1/2 -ap-translate-y-1/2 ap-text-red-500"
    >
      There was a problem in loading the PDF
    </div>
    <div
      *ngIf="isLoading"
      class="ap-w-full ap-h-[50%] ap-absolute ap-flex ap-justify-center ap-items-center ap-top-7"
    >
      <ap-loading-icon
        height="50px"
        width="51px"
        [outlineLoader]="false"
        [whiteLoader]="false"
      >
      </ap-loading-icon>
    </div>
  </div>
</div>
