<div class="ap-relative" #relativeDiv>
  <button
    mat-icon-button
    [matTooltip]="tooltipText"
    [color]="color"
    [attr.aria-label]="ariaLabel || tooltipText"
    type="button"
    [disabled]="buttonDisabled"
    (click)="emit()"
    [tabIndex]="tabIndex"
  >
    <svg-icon
      *ngIf="iconFilename && !iconUrl"
      src="assets/img/custom/{{ iconFilename }}"
      [class]="
        'svgClasses ap-flex ap-items-center ap-justify-center ' +
        extraClasses +
        (buttonDisabled ? disabledClass : '')
      "
      [applyClass]="true"
    ></svg-icon>
    <div
      *ngIf="iconUrl"
      [class]="'ap-flex ap-items-center ap-justify-center ' + extraClasses"
    >
      <img [src]="iconUrl" [class]="imgClasses" />
    </div>
  </button>
  <div
    *ngIf="buttonDisabled"
    class="ap-absolute ap-h-full ap-w-full ap-left-0 ap-top-0 ap-z-50"
    [matTooltip]="tooltipText"
  ></div>
</div>
