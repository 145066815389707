import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type Option = {
  label: string;
  value: number;
};

@Component({
  selector: 'app-radio-button-option',
  templateUrl: './radio-button-option.component.html',
  styleUrls: ['./radio-button-option.component.scss'],
})
export class RadioButtonOptionComponent implements OnInit {
  @Input() optionsList: Option[];
  @Output() optionSelected = new EventEmitter<Option>();

  selectedOption: string | number | undefined;

  ngOnInit(): void {
    if (this.optionsList.length > 0) {
      this.selectedOption = this.optionsList[0].value;
      this.optionSelected.emit(this.optionsList[0]);
    }
  }

  onSelect(option: Option) {
    this.optionSelected.emit(option);
  }
}
