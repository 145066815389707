<section>
  <div class="ap-flex ap-flex-col ap-gap-3">
    <ng-container *ngFor="let option of optionsList">
      <label
        class="ap-border ap-border-[#E4E7EC] ap-rounded-lg ap-p-[7px] ap-bg-white ap-cursor-pointer"
      >
        <mat-checkbox
          [(ngModel)]="option.checked"
          (change)="onCheckboxChange()"
          color="primary"
          class="ap-text-[#344054] ap-text-base ap-font-inter ap-font-medium custom-checkbox"
        >
          {{ option.label }}
        </mat-checkbox>
      </label>
    </ng-container>
  </div>
</section>
