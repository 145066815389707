import { Injectable } from '@angular/core';
import { Permission } from '@upbrains/shared';


@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(
    
  ) {}

  private _permission?:Permission|null; 


  initialPermissions(permission?:Permission) {
      this._permission = permission;
  }


 private updatePermision() {
  if(!this._permission) {
    const currentUserStr = localStorage.getItem("currentUser");
    if (currentUserStr) {
      const currentUser = JSON.parse(currentUserStr);
      const per = currentUser?.permission || null;
      if (per){
        this._permission  = per;
      }
    }
  }
 } 

  getMenuPermissionByName(route: string): boolean {
    if (!route) return false;
  
    let per = this._permission;
  
    // Attempt to retrieve permission from localStorage if not already set
    if (!per) {
      const currentUserStr = localStorage.getItem("currentUser");
  
      if (currentUserStr) {
        const currentUser = JSON.parse(currentUserStr);
        per = currentUser?.permission || null;
      }
  
      if (!per) return false;
    }
  
    // Normalize the route name and check permission
    const menu = per.menu?.[route.toLowerCase() as keyof typeof per.menu];
  
    return !!menu;
  }

  getRole(): string| null {
  
    let per = this._permission;
  
    // Attempt to retrieve permission from localStorage if not already set
    if (!per) {
      const currentUserStr = localStorage.getItem("currentUser");
  
      if (currentUserStr) {
        const currentUser = JSON.parse(currentUserStr);
        per = currentUser?.permission || null;
      }
  
      if (!per) return null;
    }
  
    // Normalize the route name and check permission
    return per.role;
  
  }

  getPermisonAgentPage() {
    this.updatePermision();
    return this._permission?.agent;
  }
  getPermisonTemplatePage() {
    this.updatePermision();
    return this._permission?.template;
  }
  
}

