import { Type } from '@sinclair/typebox';
import {
  ApprovalActionButtonProperty,
  CustomActionButtonProperty,
  DefaultAccessToken,
  DisapprovalActionButtonProperty,
  DisplayTextProperty,
  LongTextProperty,
  SelectProperty,
  ShortTextProperty,
  SubmitActionButtonProperty,
} from './text-property';
import { NumberProperty } from './number-property';
import { ArrayProperty } from './array-property';
import { ObjectProperty } from './object-property';
import { JsonProperty } from './json-property';
import { DateTimeProperty } from './date-time-property';
import { FileProperty } from './file-property';
import { PropertyType } from './property-type';
import { MarkDownProperty } from './markdown-property';
import { CheckboxProperty } from './checkbox-property';
import {
  StaticDropdownProperty,
  StaticMultiSelectDropdownProperty,
} from './dropdown/static-dropdown';
import { Processors } from '../../processors/processors';
import { Validators } from '../../validators/validators';
import { DynamicProperties } from './dynamic-prop';
import {
  DropdownProperty,
  MultiSelectDropdownProperty,
} from './dropdown/dropdown-prop';

export const InputProperty = Type.Union([
  ShortTextProperty,
  LongTextProperty,
  MarkDownProperty,
  CheckboxProperty,
  StaticDropdownProperty,
  StaticMultiSelectDropdownProperty,
  DropdownProperty,
  MultiSelectDropdownProperty,
  DynamicProperties,
  NumberProperty,
  ArrayProperty,
  ObjectProperty,
  JsonProperty,
  DateTimeProperty,
  FileProperty,
  DisplayTextProperty,
  SelectProperty,
  ApprovalActionButtonProperty,
  DisapprovalActionButtonProperty,
  CustomActionButtonProperty,
  SubmitActionButtonProperty,
  DefaultAccessToken,
]);

export type InputProperty =
  | DefaultAccessToken<boolean>
  | DisapprovalActionButtonProperty<boolean>
  | CustomActionButtonProperty<boolean>
  | SubmitActionButtonProperty<boolean>
  | ApprovalActionButtonProperty<boolean>
  | SelectProperty<boolean>
  | DisplayTextProperty<boolean>
  | ShortTextProperty<boolean>
  | LongTextProperty<boolean>
  | MarkDownProperty
  | CheckboxProperty<boolean>
  | DropdownProperty<any, boolean>
  | StaticDropdownProperty<any, boolean>
  | NumberProperty<boolean>
  | ArrayProperty<boolean>
  | ObjectProperty<boolean>
  | JsonProperty<boolean>
  | MultiSelectDropdownProperty<unknown, boolean>
  | StaticMultiSelectDropdownProperty<unknown, boolean>
  | DynamicProperties<boolean>
  | DateTimeProperty<boolean>
  | FileProperty<boolean>;

type Properties<T> = Omit<
  T,
  'valueSchema' | 'type' | 'defaultValidators' | 'defaultProcessors'
>;

export const Property = {
  ShortText<R extends boolean>(
    request: Properties<ShortTextProperty<R>>
  ): R extends true ? ShortTextProperty<true> : ShortTextProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.SHORT_TEXT,
      defaultProcessors: [Processors.string],
      defaultValidators: [Validators.string],
    } as unknown as R extends true
      ? ShortTextProperty<true>
      : ShortTextProperty<false>;
  },
  Checkbox<R extends boolean>(
    request: Properties<CheckboxProperty<R>>
  ): R extends true ? CheckboxProperty<true> : CheckboxProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.CHECKBOX,
    } as unknown as R extends true
      ? CheckboxProperty<true>
      : CheckboxProperty<false>;
  },
  LongText<R extends boolean>(
    request: Properties<LongTextProperty<R>>
  ): R extends true ? LongTextProperty<true> : LongTextProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.LONG_TEXT,
    } as unknown as R extends true
      ? LongTextProperty<true>
      : LongTextProperty<false>;
  },
  MarkDown(request: { value: string }): MarkDownProperty {
    return {
      displayName: 'Markdown',
      required: false,
      description: request.value,
      type: PropertyType.MARKDOWN,
      valueSchema: undefined as never,
    };
  },
  Number<R extends boolean>(
    request: Properties<NumberProperty<R>>
  ): R extends true ? NumberProperty<true> : NumberProperty<false> {
    return {
      ...request,
      defaultProcessors: [Processors.number],
      defaultValidators: [Validators.number],
      valueSchema: undefined,
      type: PropertyType.NUMBER,
    } as unknown as R extends true
      ? NumberProperty<true>
      : NumberProperty<false>;
  },

  Json<R extends boolean>(
    request: Properties<JsonProperty<R>>
  ): R extends true ? JsonProperty<true> : JsonProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.JSON,
      defaultProcessors: [Processors.json],
    } as unknown as R extends true ? JsonProperty<true> : JsonProperty<false>;
  },
  Array<R extends boolean>(
    request: Properties<ArrayProperty<R>>
  ): R extends true ? ArrayProperty<true> : ArrayProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.ARRAY,
    } as unknown as R extends true ? ArrayProperty<true> : ArrayProperty<false>;
  },
  Object<R extends boolean>(
    request: Properties<ObjectProperty<R>>
  ): R extends true ? ObjectProperty<true> : ObjectProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.OBJECT,
    } as unknown as R extends true
      ? ObjectProperty<true>
      : ObjectProperty<false>;
  },
  Dropdown<T, R extends boolean = boolean>(
    request: Properties<DropdownProperty<T, R>>
  ): R extends true ? DropdownProperty<T, true> : DropdownProperty<T, false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.DROPDOWN,
    } as unknown as R extends true
      ? DropdownProperty<T, true>
      : DropdownProperty<T, false>;
  },
  StaticDropdown<T, R extends boolean = boolean>(
    request: Properties<StaticDropdownProperty<T, R>>
  ): R extends true
    ? StaticDropdownProperty<T, true>
    : StaticDropdownProperty<T, false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.STATIC_DROPDOWN,
    } as unknown as R extends true
      ? StaticDropdownProperty<T, true>
      : StaticDropdownProperty<T, false>;
  },
  MultiSelectDropdown<T, R extends boolean = boolean>(
    request: Properties<MultiSelectDropdownProperty<T, R>>
  ): R extends true
    ? MultiSelectDropdownProperty<T, true>
    : MultiSelectDropdownProperty<T, false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.MULTI_SELECT_DROPDOWN,
    } as unknown as R extends true
      ? MultiSelectDropdownProperty<T, true>
      : MultiSelectDropdownProperty<T, false>;
  },
  DynamicProperties<R extends boolean = boolean>(
    request: Properties<DynamicProperties<R>>
  ): R extends true ? DynamicProperties<true> : DynamicProperties<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.DYNAMIC,
    } as unknown as R extends true
      ? DynamicProperties<true>
      : DynamicProperties<false>;
  },
  StaticMultiSelectDropdown<T, R extends boolean = boolean>(
    request: Properties<StaticMultiSelectDropdownProperty<T, R>>
  ): R extends true
    ? StaticMultiSelectDropdownProperty<T, true>
    : StaticMultiSelectDropdownProperty<T, false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.STATIC_MULTI_SELECT_DROPDOWN,
    } as unknown as R extends true
      ? StaticMultiSelectDropdownProperty<T, true>
      : StaticMultiSelectDropdownProperty<T, false>;
  },
  DateTime<R extends boolean>(
    request: Properties<DateTimeProperty<R>>
  ): R extends true ? DateTimeProperty<true> : DateTimeProperty<false> {
    return {
      ...request,
      defaultProcessors: [Processors.datetime],
      defaultValidators: [Validators.datetimeIso],
      valueSchema: undefined,
      type: PropertyType.DATE_TIME,
    } as unknown as R extends true
      ? DateTimeProperty<true>
      : DateTimeProperty<false>;
  },
  File<R extends boolean>(
    request: Properties<FileProperty<R>>
  ): R extends true ? FileProperty<true> : FileProperty<false> {
    return {
      ...request,
      defaultProcessors: [Processors.file],
      defaultValidators: [Validators.file],
      valueSchema: undefined,
      type: PropertyType.FILE,
    } as unknown as R extends true ? FileProperty<true> : FileProperty<false>;
  },
  DisplayText<R extends boolean>(
    request: Properties<DisplayTextProperty<R>>
  ): R extends true ? DisplayTextProperty<true> : DisplayTextProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.DISPLAY_TEXT,
    } as unknown as R extends true
      ? DisplayTextProperty<true>
      : DisplayTextProperty<false>;
  },
  Select<R extends boolean>(
    request: Properties<SelectProperty<R>>
  ): R extends true ? SelectProperty<true> : SelectProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.SELECT,
    } as unknown as R extends true
      ? SelectProperty<true>
      : SelectProperty<false>;
  },
  ApprovalActionButton<R extends boolean>(
    request: Properties<ApprovalActionButtonProperty<R>>
  ): R extends true
    ? ApprovalActionButtonProperty<true>
    : ApprovalActionButtonProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.APPROVAL_ACTION,
    } as unknown as R extends true
      ? ApprovalActionButtonProperty<true>
      : ApprovalActionButtonProperty<false>;
  },
  DisapprovalActionButton<R extends boolean>(
    request: Properties<DisapprovalActionButtonProperty<R>>
  ): R extends true
    ? DisapprovalActionButtonProperty<true>
    : DisapprovalActionButtonProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.DISAPPROVAL_ACTION,
    } as unknown as R extends true
      ? DisapprovalActionButtonProperty<true>
      : DisapprovalActionButtonProperty<false>;
  },
  CustomActionButton<R extends boolean>(
    request: Properties<CustomActionButtonProperty<R>>
  ): R extends true
    ? CustomActionButtonProperty<true>
    : CustomActionButtonProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.CUSTOM_ACTION,
    } as unknown as R extends true
      ? CustomActionButtonProperty<true>
      : CustomActionButtonProperty<false>;
  },
  SubmitActionButton<R extends boolean>(
    request: Properties<SubmitActionButtonProperty<R>>
  ): R extends true
    ? SubmitActionButtonProperty<true>
    : SubmitActionButtonProperty<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.SUBMIT_ACTION,
    } as unknown as R extends true
      ? SubmitActionButtonProperty<true>
      : SubmitActionButtonProperty<false>;
  },
  DefaultAccessToken<R extends boolean>(
    request: Properties<DefaultAccessToken<R>>
  ): R extends true ? DefaultAccessToken<true> : DefaultAccessToken<false> {
    return {
      ...request,
      valueSchema: undefined,
      type: PropertyType.DEFAULT_ACCESS_TOKEN,
    } as unknown as R extends true
      ? DefaultAccessToken<true>
      : DefaultAccessToken<false>;
  },
};
