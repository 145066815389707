import { debounceTime, Subject, takeUntil, tap } from 'rxjs';
import { isEqual } from 'lodash';
import { AddNewExtractorRowComponent } from '../add-new-extractor-row/add-new-extractor-row.component';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { KeyValueType } from '@upbrains/shared';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-primary-keys',
  templateUrl: './primary-keys.component.html',
  styleUrls: ['./primary-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrimaryKeysComponent implements OnInit, OnChanges, OnDestroy {
  @Input() canAddNewField: boolean = false;
  @Input() hasMatchesInPrimaryKeys: boolean = false;
  @Input() keyValueData: KeyValueType[] | null = [];
  @Input() onFieldsSubmit: (data: KeyValueType[]) => void = () => null;
  @Input() onFieldChange!: (value: string, key: string) => void;

  @Output() updateChange = new EventEmitter<boolean>();
  @Output() highlightTextEvent = new EventEmitter<{
    content: string;
    pageNumber: string;
    form: KeyValueType[];
  }>();

  form!: FormGroup;
  private submitDebounce$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initializeForm(this.keyValueData || []);
    this.setupDebouncedSubmit();
    this.emitFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['keyValueData']?.currentValue &&
      changes['keyValueData']?.previousValue &&
      isEqual(
        changes['keyValueData']?.currentValue,
        changes['keyValueData']?.previousValue
      )
    ) {
      this.initializeForm(changes['keyValueData'].currentValue || []);
      this.emitFormChanges();
    }
  }

  private initializeForm(
    data: {
      key: string;
      value: string;
      isFixedKey?: boolean;
      alternative?: string;
    }[]
  ): void {
    this.form = this.fb.group({
      rows: this.fb.array(data.map((item) => this.createRow(item))),
    });
  }

  private setupDebouncedSubmit(): void {
    this.submitDebounce$
      .pipe(
        debounceTime(300),
        tap(() => this.emitFormChanges())
      )
      .subscribe();
  }

  private emitFormChanges(): void {
    const formData = this.getFormArrayData();
    this.onFieldsSubmit(formData);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createRow(item: {
    key: string;
    value: string;
    isFixedKey?: boolean;
    page_number?: number;
    alternative?: string;
    [key: string]: any;
  }): FormGroup {
    const { key, value, isFixedKey, page_number, alternative, ...rest } = item;

    return this.fb.group({
      key,
      value,
      isEditMode: [false],
      isFixedKey: isFixedKey || false,
      ...(page_number && { page_number }),
      ...(alternative && { alternative }),
      ...rest,
    });
  }

  get rows(): FormArray {
    return this.form.get('rows') as FormArray;
  }

  getValueControl(row: AbstractControl): FormControl {
    return (row as FormGroup).get('value') as FormControl;
  }

  toggleEditMode(event: Event, index: number): void {
    event.stopPropagation();
    const row = this.rows.at(index) as FormGroup;
    const isEditMode = row.get('isEditMode')?.value;
    row.patchValue({ isEditMode: !isEditMode });

    // Resize textarea when entering edit mode
    if (!isEditMode) {
      setTimeout(() => {
        const textarea = document.querySelector(
          `textarea[data-index="${index}"]`
        ) as HTMLTextAreaElement;

        if (textarea) {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      });
    }
  }

  getKeyControl(row: any): FormControl {
    const control = row.get('key');
    return control instanceof FormControl ? control : new FormControl('');
  }

  addNewRow(): void {
    this.matDialog
      .open(AddNewExtractorRowComponent, {
        data: {
          title: 'Primary Key',
          firstInputTitle: 'Field Name',
          hasCheckbox: true,
        },
      })
      .afterClosed()
      .pipe(
        tap((result) => {
          if (result?.inputValue) {
            this.rows.insert(
              0,
              this.createRow({ key: result.inputValue, value: '' })
            );
            this.cdr.detectChanges();
            this.submitDebounce$.next();
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  getFormArrayData(): KeyValueType[] {
    return this.form.value.rows.map((row: any) => ({
      key: row.key,
      value: row.value,
    }));
  }

  onSubmit(): KeyValueType[] {
    const data = this.getFormArrayData();
    this.emitFormChanges();
    return data;
  }

  autoResize(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  onValueChange(value: string, key: string): void {
    this.updateChange.emit(true);

    if (this.onFieldChange) {
      this.onFieldChange(value, key);
    }
  }

  triggerHighlightText(content: string, pageNumber: string, form: any): void {
    this.highlightTextEvent.emit({
      content,
      pageNumber: String(pageNumber),
      form,
    });
  }
}
