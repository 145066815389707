export type SideMenuItems = {
    sideMenuIconSrc: string
    sideMenuItemName: string
    route: string
    children: SideMenuItemsChildren[]
}

export type SideMenuItemsChildren = {
    title: string
    route: string
}
