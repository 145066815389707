<div
  class="ap-border ap-border-[#E4E7EC] ap-bg-[#F9FAFB] ap-w-[186px] ap-h-[186px] ap-rounded-[20px] ap-p-6 ap-flex ap-flex-col ap-justify-center ap-items-center ap-cursor-pointer"
>
  <div class="ap-w-[48px] ap-h-[48px]">
    <ngx-skeleton-loader count="1" appearance="circle" />
  </div>

  <div
    class="ap-font-inter ap-font-semibold ap-text-xl ap-text-[#344054] ap-text-center"
  >
    <ngx-skeleton-loader [theme]="{ height: '28px', width: '60px' }" />
  </div>

  <ngx-skeleton-loader [theme]="{ height: '38px', width: '150px',  }" />
</div>
