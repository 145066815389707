import { createSelector } from '@ngrx/store';
import { selectCommonState } from '../common.selector';
import { CommonStateModel } from '../common-state.model';
import { StepSliderConfig } from '../../models/step-slider-config-interface';
import { OnboardingState } from './onboarding-state.model';
import { FlowTemplateWithIcons } from '@upbrains/shared';

const selectOnboardingState = createSelector(
  selectCommonState,
  (state: CommonStateModel): OnboardingState => {
    return state.onboardingState;
  }
);

const selectOnboardingSteps = createSelector(
  selectOnboardingState,
  (state: OnboardingState): StepSliderConfig | null => {
    return state.stepsConfig;
  }
);

const selectOnboardingBusinessData = createSelector(
  selectOnboardingState,
  (
    state: OnboardingState
  ): {
    lineItems: string[] | undefined;
    description: string | undefined;
    businessName: string | null;
    selectedIndustry: string | null;
  } => {
    return {
      lineItems: state.businessData?.result.Sectors,
      description: state.businessData?.result.Description,
      businessName: state.businessName,
      selectedIndustry: state.selectedIndustry,
    };
  }
);

const selectBusinessName = createSelector(
  selectOnboardingState,
  (state: OnboardingState): string | null => {
    return state.businessName;
  }
);

const selectSelectedAgentTemplate = createSelector(
  selectOnboardingState,
  (state: OnboardingState): FlowTemplateWithIcons | null => {
    return state.selectedAgentTemplate;
  }
);

export const onboardingSelectors = {
  selectOnboardingState,
  selectOnboardingSteps,
  selectOnboardingBusinessData,
  selectBusinessName,
  selectSelectedAgentTemplate,
};
