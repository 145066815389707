<div
  class="ap-w-[270px] ap-h-[239px] ap-rounded-[20px] ap-p-6 ap-bg-[#F9FAFB] ap-border ap-border-[#D0D5DD] ap-flex ap-flex-col"
>
  <div class="ap-text-[#98A2B3] ap-text-lg ap-font-medium ap-font-inter">
    STEP {{ stepNum }}
  </div>
  <div
    class="ap-text-[#344054] ap-text-2xl ap-font-medium ap-font-inter ap-mt-3"
  >
    {{ description }}
  </div>
  <div class="ap-w-6 ap-h-6 ap-mt-auto">
    <svg-icon
      [svgStyle]="{ width: '100%', height: '100%' }"
      class="ap-flex ap-justify-center ap-items-center"
      [src]="stepIcon"
    >
    </svg-icon>
  </div>
</div>
