import { Routes } from '@angular/router';
import { OnboardingComponent } from './pages';

export const OnboardingLayoutRouting: Routes = [
  {
    path: 'onboarding',
    component: OnboardingComponent,
    runGuardsAndResolvers: 'always',
  },
];
