<ap-dialog-title-template>
  <ng-container *ngIf="!dialogData.connectionToUpdate; else editConfigHeader">
    <span i18n>New {{ dialogData.agentDisplayName }} Connection</span>
  </ng-container>
  <ng-template #editConfigHeader>
    <span i18n>Edit</span> {{ dialogData.connectionToUpdate!.name }}
  </ng-template>
</ap-dialog-title-template>

<mat-dialog-content>
  <ap-markdown
    *ngIf="dialogData.agentAuthProperty.description"
    [data]="dialogData.agentAuthProperty.description"
  ></ap-markdown>
  <form
    class="ap-pt-4 ap-flex ap-flex-col ap-gap-2 ap-max-w-[720px] ap-min-w-[420px]"
    [formGroup]="settingsForm"
    (keyup.enter)="submit()"
  >
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label i18n>Name</mat-label>
      <input
        cdkFocusInitial
        [matTooltip]="keyTooltip"
        formControlName="name"
        matInput
        type="text"
      />
      <mat-error *ngIf="settingsForm.controls.name.invalid">
        <ng-container
          *ngIf="
            settingsForm.controls.name.getError('required');
            else patternErrorOrAlreadyUsed
          "
          i18n
        >
          Name is required
        </ng-container>
        <ng-template #patternErrorOrAlreadyUsed>
          <ng-container
            *ngIf="settingsForm.controls.name.getError('pattern')"
            i18n
          >
            Name can only contain letters, numbers and underscores
          </ng-container>
          <ng-container
            *ngIf="settingsForm.controls.name.getError('nameUsed')"
            i18n
          >
            Name is already used
          </ng-container>
        </ng-template></mat-error
      >
    </mat-form-field>

    <mat-form-field
      class="ap-w-full"
      appearance="outline"
      *ngIf="
        dialogData.agentAuthProperty.grantType !==
        OAuth2GrantType.CLIENT_CREDENTIALS
      "
    >
      <mat-label>Redirect URL</mat-label>
      <input
        placeholder="Redirect URL"
        [matTooltip]="redirectUrlTooltip"
        formControlName="redirect_url"
        matInput
        type="text"
      />
      <mat-error *ngIf="settingsForm.controls.redirect_url.invalid" i18n>
        Redirect URL is required</mat-error
      >
    </mat-form-field>

    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label i18n>Client ID</mat-label>
      <input
        placeholder="Client ID"
        [matTooltip]="clientIdTooltip"
        formControlName="client_id"
        matInput
        type="text"
      />
      <mat-error *ngIf="settingsForm.controls.client_id.invalid" i18n>
        Client ID is required</mat-error
      >
    </mat-form-field>
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label i18n>Client Secret</mat-label>
      <input
        [matTooltip]="clientSecretTooltip"
        formControlName="client_secret"
        matInput
        type="password"
        placeholder="Client Secret"
      />
      <mat-error *ngIf="settingsForm.controls.client_secret.invalid" i18n>
        Client Secret is required</mat-error
      >
    </mat-form-field>
    <div
      *ngIf="dialogData.agentAuthProperty.props"
      [formGroup]="settingsForm.controls.props"
    >
      <ng-container
        *ngFor="let prop of dialogData.agentAuthProperty.props | objectToArray"
      >
        <mat-form-field
          *ngIf="prop.value['type'] !== PropertyType.STATIC_DROPDOWN"
          class="ap-w-full"
          appearance="outline"
        >
          <mat-label>{{ prop.value.displayName }}</mat-label>
          <input
            [matTooltip]="prop.value.description || ''"
            [formControlName]="prop.key"
            matInput
            type="text"
          />
          <mat-error
            *ngIf="settingsForm.controls.props.controls[prop.key]!.invalid"
          >
            {{ prop.value.displayName }} <span i18n>is required</span>
          </mat-error>
        </mat-form-field>
        <ng-container
          *ngIf="prop.value['type'] === PropertyType.STATIC_DROPDOWN"
        >
          <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label> {{ prop.value.displayName }}</mat-label>
            <mat-select
              [formControlName]="prop.key"
              [matTooltip]="prop.value.description || ''"
              [compareWith]="dropdownCompareWithFunction"
            >
              <mat-option
                *ngFor="
                  let opt of castToStaticDropdown(prop.value).options.options
                "
                [value]="opt.value"
              >
                {{ opt.label }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="settingsForm.controls.props.controls[prop.key]!.invalid"
            >
              {{ prop.value.displayName }} <span i18n>is required</span>
            </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </div>

    <div
      *ngIf="
        dialogData.agentAuthProperty.grantType !==
        OAuth2GrantType.CLIENT_CREDENTIALS
      "
    >
      <app-o-auth2-connect-control
        [popupParams]="getOAuth2Settings()"
        [settingsValid]="authenticationSettingsControlsValid"
        formControlName="value"
      ></app-o-auth2-connect-control>
      <p
        @fadeInUp
        class="ap-typography-caption ap-text-danger"
        *ngIf="
          settingsForm.controls.value.invalid &&
          settingsForm.controls.value.touched
        "
        i18n
      >
        You have to connect an account to continue
      </p>
    </div>
  </form>
  <a
    class="ap-cursor-pointer ap-mt-2"
    *ngIf="(hasCloudAuthCred$ | async) && !dialogData.connectionToUpdate"
    (click)="useCloudCreds()"
    i18n
    >I would like to use predefined app credentials
  </a>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="ap-flex ap-gap-2.5">
    <ap-button
      (click)="closeAllDialogs()"
      btnColor="basic"
      mat-dialog-close
      btnSize="default"
      i18n
    >
      Cancel
    </ap-button>
    <ap-button
      btnSize="default"
      (click)="submit()"
      btnColor="primary"
      type="submit"
      [loading]="loading"
      i18n
    >
      Save
    </ap-button>
  </div>
</mat-dialog-actions>
<ng-container *ngIf="upsert$ | async"></ng-container>
