import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { AgentMetadataModelSummary } from '@upbrains/ui/common';
import { AddEditConnectionButtonComponent } from '@upbrains/ui/feature-connections';

@Component({
  selector: 'app-agent-connection-card',
  templateUrl: './agent-connection-card.component.html',
})
export class AgentConnectionCardComponent {
  @ViewChildren('addConnectionBtn')
  addConnectionBtns!: QueryList<AddEditConnectionButtonComponent>;
  @Input() agent!: AgentMetadataModelSummary & {
    connected: boolean;
    isSelected?: boolean;
  };
  @Input() getConnections!: () => void;
  @Input() handleTriggerSelectedInbox!: (
    agentTrigger: number,
    name: string,
    button: any
  ) => void;

  handleSelectionInbox() {
    this.handleTriggerSelectedInbox(
      this.agent.triggers,
      this.agent.name,
      !this.agent.connected && this.addConnectionBtns
    );
  }
}
