export class OpenAIError extends Error {
    constructor(public error: ErrorParams, message?: string) {
        super(error.code + (message ? `: ${message}` : ''))
    }
}

type ErrorParams =
    | MissingUpbrainsTokenParams
    | MissingOpenAITokenParams
    | InvalidUpbrainsTokenParams
    | UsageReportFailureParams
    | AskAssistantBadRequestParams
    | AskAssistantFailureParams
    | GenerateImageBadRequestParams
    | GenerateImageFailureParams
    | SendPromptBadRequestParams
    | SendPromptFailureParams
    | TranscriptionBadRequestParams
    | TranscriptionFailureParams
    | TranslationBadRequestParams
    | TranslationFailureParams
    | VisionPromptBadRequestParams
    | VisionPromptFailureParams
    | ListModelsFailureParams
    | ListAsisstantFailureParams
    | TexttoSpeechFailureParams
    | TextToSpeechBadRequestParams


type BaseErrorParams<T, V> = {
    code: T
    params: V
}


export type AskAssistantBadRequestParams = BaseErrorParams<
OpenAIErrorCode.ASK_ASSISTANT_BAD_REQUEST,
{ assistant: string, prompt: string }
>

export type TextToSpeechBadRequestParams = BaseErrorParams<
OpenAIErrorCode.TEXT_TO_SPEECH_BAD_REQUEST,
{ text: string, model: string }
>

export type GenerateImageBadRequestParams = BaseErrorParams<
OpenAIErrorCode.GENERATE_IMAGE_BAD_REQUEST,
{ prompt: string }
>

export type SendPromptBadRequestParams = BaseErrorParams<
OpenAIErrorCode.SEND_PROMPT_BAD_REQUEST,
{ model: string, history: any[] }
>

export type TranscriptionBadRequestParams = BaseErrorParams<
OpenAIErrorCode.TRANSCRIPTION_BAD_REQUEST,
{ message?: string }
>

export type TranslationBadRequestParams = BaseErrorParams<
OpenAIErrorCode.TRANSLATION_BAD_REQUEST,
{ message?: string }
>

export type VisionPromptBadRequestParams = BaseErrorParams<
OpenAIErrorCode.VISION_PROMPT_BAD_REQUEST,
{ message?: string }
>

export type MissingUpbrainsTokenParams = BaseErrorParams<
OpenAIErrorCode.MISSING_UPBRAINS_TOKEN,
{ message?: string }
>

export type MissingOpenAITokenParams = BaseErrorParams<
OpenAIErrorCode.MISSING_OPENAI_TOKEN,
{ message?: string }
>

export type InvalidUpbrainsTokenParams = BaseErrorParams<
OpenAIErrorCode.INVALID_UPBRAINS_TOKEN,
{ message?: string }
>

export type UsageReportFailureParams = BaseErrorParams<
OpenAIErrorCode.USAGE_REPORT_FAILURE,
{ message?: string }
>

export type AskAssistantFailureParams = BaseErrorParams<
OpenAIErrorCode.ASK_ASSISTANT_FAILURE,
{ message?: string }
>

export type GenerateImageFailureParams = BaseErrorParams<
OpenAIErrorCode.GENERATE_IMAGE_FAILURE,
{ message?: string }
>

export type SendPromptFailureParams = BaseErrorParams<
OpenAIErrorCode.SEND_PROMPT_FAILURE,
{ message?: string }
>

export type TranscriptionFailureParams = BaseErrorParams<
OpenAIErrorCode.TRANSCRIPTION_FAILURE,
{ message?: string }
>

export type TranslationFailureParams = BaseErrorParams<
OpenAIErrorCode.TRANSLATION_FAILURE,
{ message?: string }
>

export type VisionPromptFailureParams = BaseErrorParams<
OpenAIErrorCode.VISION_PROMPT_FAILURE,
{ message?: string }
>

export type ListModelsFailureParams = BaseErrorParams<
OpenAIErrorCode.LIST_MODELS_FAILURE,
{ message?: string }
>

export type ListAsisstantFailureParams = BaseErrorParams<
OpenAIErrorCode.LIST_ASSISTANTS_FAILURE,
{ message?: string }
>
export type TexttoSpeechFailureParams = BaseErrorParams<
OpenAIErrorCode.TEXT_TO_SPEECH_FAILURE,
{ message?: string }
>

export enum OpenAIErrorCode {
    ASK_ASSISTANT_BAD_REQUEST = 'ASK_ASSISTANT_BAD_REQUEST',
    GENERATE_IMAGE_BAD_REQUEST = 'GENERATE_IMAGE_BAD_REQUEST',
    SEND_PROMPT_BAD_REQUEST = 'SEND_PROMPT_BAD_REQUEST',
    TRANSCRIPTION_BAD_REQUEST = 'TRANSCRIPTION_BAD_REQUEST',
    TRANSLATION_BAD_REQUEST = 'TRANSLATION_BAD_REQUEST',
    VISION_PROMPT_BAD_REQUEST = 'VISION_PROMPT_BAD_REQUEST',
    MISSING_UPBRAINS_TOKEN = 'MISSING_UPBRAINS_TOKEN',
    MISSING_OPENAI_TOKEN = 'MISSING_OPENAI_TOKEN',
    INVALID_UPBRAINS_TOKEN = 'INVALID_UPBRAINS_TOKEN',
    USAGE_REPORT_FAILURE = 'USAGE_REPORT_FAILURE',
    ASK_ASSISTANT_FAILURE = 'ASK_ASSISTANT_FAILURE',
    GENERATE_IMAGE_FAILURE = 'GENERATE_IMAGE_FAILURE',
    SEND_PROMPT_FAILURE = 'SEND_PROMPT_FAILURE',
    TRANSCRIPTION_FAILURE = 'TRANSCRIPTION_FAILURE',
    TRANSLATION_FAILURE = 'TRANSLATION_FAILURE',
    VISION_PROMPT_FAILURE = 'VISION_PROMPT_FAILURE',
    LIST_MODELS_FAILURE = 'LIST_MODELS_FAILURE',
    LIST_ASSISTANTS_FAILURE = 'LIST_ASSISTANTS_FAILURE',
    TEXT_TO_SPEECH_BAD_REQUEST = 'TEXT_TO_SPEECH_BAD_REQUEST',
    TEXT_TO_SPEECH_FAILURE = 'TEXT_TO_SPEECH_FAILURE',
}
