import { ICellRendererParams } from 'ag-grid-community';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <div class="ap-flex ap-flex-col ap-items-start">
      <p class="ap-w-full">
        <strong class="ap-text-sm">Request: </strong>{{ label }}
      </p>

      <div class="ap-w-full ap-flex ap-flex-row ap-gap-2">
        <mat-form-field class="ap-flex-1 ap-w-full ap-max-h-[60px]">
          <input
            [value]="model"
            type="text"
            placeholder="Choose/Add new option"
            aria-label="Request Description"
            matInput
            [matAutocomplete]="auto"
            (keydown)="handleKeydown($event)"
            (keyup)="keypress($event)"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of options"
              [value]="option"
              (click)="setModel(option)"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <ap-icon-button
          (click)="searchClick(this.model)"
          extraClasses="ap-fill-body"
          iconFilename="magnifying.svg"
          [width]="14"
          tooltipText="Search in Description"
          i18n-tooltipText
          [height]="14"
        ></ap-icon-button>
      </div>
    </div>
  `,
})
export class ColumnDescription implements ICellRendererAngularComp {
  public options: string[] = [];
  public label?: string | null = '';
  public params: any = null;
  public model = '';
  @Output() customEvent = new EventEmitter<string>();
  @ViewChild('auto') matAutocomplete?: any;

  getFilteredOptions(options: string[], inputValue: string): any {
    if (!inputValue) return options;
    return this.filter(inputValue, options);
  }

  private filter(value: string, options: string[]): any[] {
    const filterValue = value?.toLowerCase();
    return options.filter((option) =>
      option?.toLowerCase().includes(filterValue)
    );
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'a') {
      const inputElement = event.target as HTMLInputElement;
      inputElement.select();
      event.preventDefault();
    }
  }

  searchClick(text: string) {
    if (this.label) {
      this.params.searchEvent(this.label, this.params.node.rowIndex);
    } else {
      this.params.searchEvent(text, this.params.node.rowIndex);
    }
  }

  setModel(text: string) {
    this.params.onEdit(
      this.params.node.rowIndex,
      this.params.column.colId,
      text
    );
  }

  keypress(event: any): void {
    if (event.key === 'Enter') {
      if (!this.options.some((dr) => dr == this.model)) {
        this.params.onEdit(
          this.params.node.rowIndex,
          this.params.column.colId,
          this.model
        );
      }
    } else {
      this.model = event.target.value;
    }
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.options = params.value.options;

    if (!this.options) {
      this.options = [];
    }

    if (params?.value?.select) {
      this.model = params?.value?.select;
    }

    this.label = params?.value?.value ?? '';

    setTimeout(() => {
      const find = this.matAutocomplete?.options?._results?.find(
        (dr: any) => dr.value == this.model
      );

      if (find) {
        find._selected = true;
      }
    }, 100);
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
