import { isKeyValueObject, isNil } from '../common'
import { TriggerPayload } from '../engine/engine-operation'

export const WEBHOOK_TEST_MODE_PARAM = '__upbrains__webhook_test_mode__'
export const WEBHOOK_TEST_MODE_PARAM_value = 'true'
export const WEBHOOK_RUN_GROUP_ID_QUERY_PARAM = '__upbrains__webhook_run_group_id__'

export function injectParamToPayload({
    payload,
    key,
    value,
}: {
    payload: TriggerPayload
    key: string
    value: string

}): void {
    if (isNil(payload.body)) {
        payload.body = {
            [key]: value,
        }
    }
    else if (!isKeyValueObject(payload.body)) {
        // if body is not a key value object, we will convert it to a key value object and place the original value as value for `body_data` key
        payload.body = {
            body_data: payload.body,
            [key]: value,
        }
    }
    else {
        (payload.body as Record<string, unknown>)[key] = value
    }
}


// IMPORTANT: this function can make development little harder but it is for the time we may want to change
//              the strategy. It will help us to find the relevant places to do our changes
export function extractParamFromPayload<T>({
    payload,
    key,
}: {
    payload: TriggerPayload | Record<string, unknown>
    key: string
}): T {
    return isTriggerPayload(payload) ? 
        (payload.body as Record<string, unknown>)[key] as T :
        payload[key] as T

}

function isTriggerPayload(obj: TriggerPayload | Record<string, unknown>): obj is TriggerPayload {
    const checkingObject = obj as TriggerPayload
    return ('body' in checkingObject) && ('headers' in checkingObject ) && ('queryParams' in checkingObject)
}
