import { Static, Type } from '@sinclair/typebox'

export const ProjectConversationsItemParamsDto = Type.Object({
    projectId: Type.String({}),
    conversationId: Type.String({}),
})

export type ProjectConversationsItemParamsDto = Static<typeof ProjectConversationsItemParamsDto>

export const ProjectConversationsDeleteParamsDto = Type.Object({
    projectId: Type.String({}),
    conversationId: Type.String({}),
})