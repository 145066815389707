import { Static, Type } from '@sinclair/typebox';
import { ActionContext } from '../context';
import { ActionBase } from '../agent-metadata';
import { InputPropertyMap } from '../property';
import { AgentAuthProperty } from '../property/authentication';

export type ActionRunner<AgentAuth extends AgentAuthProperty, ActionProps extends InputPropertyMap> =
  (ctx: ActionContext<AgentAuth, ActionProps>) => Promise<unknown | void>

export const ErrorHandlingOptionsParam = Type.Object({
  retryOnFailure: Type.Object({
    defaultValue: Type.Optional(Type.Boolean()),
    hide: Type.Optional(Type.Boolean()),
  }),
  continueOnFailure: Type.Object({
    defaultValue: Type.Optional(Type.Boolean()),
    hide: Type.Optional(Type.Boolean()),
  }),
  hideInConversation: Type.Optional(Type.Object({
    defaultValue: Type.Optional(Type.Boolean()),
    hide: Type.Optional(Type.Boolean()),
  })),
})
export type ErrorHandlingOptionsParam = Static<typeof ErrorHandlingOptionsParam>

export const HideInConversation = Type.Object({
  defaultValue: Type.Optional(Type.Boolean()),
  hide: Type.Optional(Type.Boolean()),
})

export type HideInConversation = Static<typeof HideInConversation>

type CreateActionParams<AgentAuth extends AgentAuthProperty, ActionProps extends InputPropertyMap> = {
  /**
   * A dummy parameter used to infer {@code AgentAuth} type
   */
  name: string
  auth?: AgentAuth
  displayName: string
  description: string,
  summary?: string,
  props: ActionProps
  run: ActionRunner<AgentAuth, ActionProps>
  test?: ActionRunner<AgentAuth, ActionProps>
  requireAuth?: boolean
  errorHandlingOptions?: ErrorHandlingOptionsParam,
  hideInConversation?: HideInConversation
}

export class IAction<AgentAuth extends AgentAuthProperty, ActionProps extends InputPropertyMap> implements ActionBase {
  constructor(
    public readonly name: string,
    public readonly displayName: string,
    public readonly description: string,
    public readonly summary: string,
    public readonly props: ActionProps,
    public readonly run: ActionRunner<AgentAuth, ActionProps>,
    public readonly test: ActionRunner<AgentAuth, ActionProps>,
    public readonly requireAuth: boolean,
    public readonly errorHandlingOptions: ErrorHandlingOptionsParam
  ) { }
}

export type Action<
  AgentAuth extends AgentAuthProperty = any,
  ActionProps extends InputPropertyMap = any,
> = IAction<AgentAuth, ActionProps>

export const createAction = <
  AgentAuth extends AgentAuthProperty = AgentAuthProperty,
  ActionProps extends InputPropertyMap = any
>(
  params: CreateActionParams<AgentAuth, ActionProps>,
) => {
  return new IAction(
    params.name,
    params.displayName,
    params.description,
    params.summary ?? '',
    params.props,
    params.run,
    params.test ?? params.run,
    params.requireAuth ?? true,
    params.errorHandlingOptions ?? {
      continueOnFailure: {
        defaultValue: false,
      },
      retryOnFailure: {
        defaultValue: false,
      },
      hideInConversation:{
        defaultValue: false
      }
    }
  )
}
