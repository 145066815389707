import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { UiCommonModule } from '@upbrains/ui/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OnboardingComponent } from './pages';
import { OnboardingLayoutRouting } from './onboarding.routing';
import { UiFeatureConnectionsModule } from '@upbrains/ui/feature-connections';
import {
  AgentConnectionComponent,
  CompanyUrlComponent,
  DeclareStepsComponent,
  ExtractedInsightsComponent,
  SelectYourAgentComponent,
} from './steps';
import { AgentConnectionCardComponent } from './components';

const exportedComponents = [
  OnboardingComponent,
  AgentConnectionComponent,
  CompanyUrlComponent,
  DeclareStepsComponent,
  ExtractedInsightsComponent,
  SelectYourAgentComponent,
  AgentConnectionCardComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    RouterModule.forChild(OnboardingLayoutRouting),
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AsyncPipe,
    UiFeatureConnectionsModule,
  ],

  declarations: [...exportedComponents],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...exportedComponents],
})
export class FeatureOnboardingModule {}
