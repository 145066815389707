<div
  class="ap-flex ap-flex-col ap-bg-[#F9FAFB] ap-border ap-border-[#E4E7EC] ap-rounded-[20px] ap-w-[270px] ap-h-[316px] ap-text-[#344054] ap-font-inter ap-p-6 custom-onboarding-package"
  [ngClass]="{
    '!ap-text-[#EFF8FF] !ap-bg-[#1570EF] !ap-border-[#175CD3]': isRecommended
  }"
>
  <div
    class="ap-flex ap-flex-row ap-gap-1 ap-items-center ap-font-bold ap-text-2xl"
  >
    {{ title }}
    <ng-container *ngIf="isRecommended">
      <span
        class="ap-flex ap-items-center ap-justify-center ap-bg-[#039855] ap-px-2 ap-py-[2px] custom-title-shadow ap-text-[#FFFFFF] ap-text-xs ap-font-medium ap-rounded-3xl"
        >Recommended</span
      >
    </ng-container>
  </div>

  <ng-container *ngIf="price">
    <p class="ap-text-2xl ap-font-bold ap-mt-3">${{ price }}</p>
  </ng-container>

  <div class="ap-flex ap-flex-col ap-gap-2 ap-mt-3">
    <ng-container *ngFor="let feature of features">
      <div class="ap-flex ap-flex-row ap-gap-2 ap-items-center">
        <svg-icon
          class="ap-w-3 ap-h-[10px]"
          [applyClass]="true"
          [src]="
            isRecommended
              ? 'assets/img/newDesign/onboarding/white-tick.svg'
              : 'assets/img/newDesign/onboarding/green-tick.svg'
          "
        >
        </svg-icon>
        <p class="ap-text-base ap-font-normal">{{ feature }}</p>
      </div>
    </ng-container>
  </div>

  <div class="ap-w-[222px] ap-mx-auto declare-steps ap-mt-auto">
    <ap-button
      actionButton
      btnSize="large"
      i18n
      class="ap-rounded-lg"
      [fullWidthOfContainer]="true"
      (buttonClicked)="onConfirm(title)"
    >
      <div
        class="ap-flex ap-items-center ap-justify-center loading-template ap-text-lg ap-font-semibold ap-rounded-xl ap-font-inter ap-text-[#344054]"
      >
        {{ title === 'Custom' ? 'Contact Sale' : 'Continue' }}
      </div>
    </ap-button>
  </div>
</div>
