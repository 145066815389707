<div
  class="ap-max-w-[1280px] ap-mx-auto ap-w-full ap-flex ap-flex-col ap-mt-10 ap-rounded-xl ap-bg-white custom-feature-shadow"
>
  <div
    class="ap-w-full ap-rounded-lg ap-border ap-border-[#E4E7EC] ap-overflow-hidden"
  >
    <div class="ap-overflow-x-auto">
      <table
        class="ap-min-w-full ap-border-collapse ap-text-xs custom-feature-table"
      >
        <thead>
          <tr class="ap-bg-[#1570EF] ap-h-11">
            <th
              class="ap-font-inter ap-font-medium ap-text-sm ap-text-[#EFF8FF] ap-px-4 ap-py-2 ap-rounded-tl-lg ap-text-left"
            >
              Features
            </th>
            <th
              class="ap-font-inter ap-font-medium ap-text-sm ap-text-[#EFF8FF] ap-px-4 ap-py-2 ap-text-left"
            >
              Starter Plan
            </th>
            <th
              class="ap-font-inter ap-font-medium ap-text-sm ap-text-[#EFF8FF] ap-px-4 ap-py-2 ap-text-left"
            >
              Professional Plan
            </th>
            <th
              class="ap-font-inter ap-font-medium ap-text-sm ap-text-[#EFF8FF] ap-px-4 ap-py-2 ap-rounded-tr-lg ap-text-left"
            >
              Premium Plan
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            class="ap-font-inter ap-font-normal ap-text-[#475569] ap-h-[72px]"
          >
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                Free Automation Credits (per seat/month)
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                1500
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                3000
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-border-r-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                4500
              </div>
            </td>
          </tr>
          <tr
            class="ap-font-inter ap-font-normal ap-text-[#475569] ap-h-[72px]"
          >
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                Automated AI agents with workflows
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                0
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                10
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-border-r-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                25
              </div>
            </td>
          </tr>
          <tr
            class="ap-font-inter ap-font-normal ap-text-[#475569] ap-h-[72px]"
          >
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-border-b-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                Support (Email)
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-border-b-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                48 Hours
              </div>
            </td>
            <td
              class="ap-border ap-border-[#E4E7EC] ap-border-l-0 ap-border-t-0 ap-border-b-0 ap-px-4 ap-py-2"
            >
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                24 Hours
              </div>
            </td>
            <td class="ap-border-0 ap-px-4 ap-py-2">
              <div
                class="ap-flex ap-flex-col ap-items-start ap-justify-start ap-gap-0 ap-text-[#344054] ap-text-sm ap-font-inter ap-font-normal"
              >
                24 Hours with Priority
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
