<a href="https://activepieces.com/" target="_blank" rel="noopener noreferrer" class="no-link-styling">
    <div
        class="ap-w-[12.7rem] ap-justify-center ap-cursor-pointer ap-p-2 ap-items-center ap-flex ap-gap-1.5 ap-transition ap-group">
        <div class="ap-typography-caption ap-text-description group-hover:!ap-text-title ap-transition">
            Powered by
        </div>
        <div class="ap-justify-center ap-flex ap-items-center ap-gap-1">
            <svg-icon src="assets/img/custom/logo/logo.svg"
                class="ap-fill-[#c3adf9] group-hover:ap-fill-[#8142e3] ap-w-[15px] ap-h-[13.64px] ap-transition"
                [applyClass]="true">
            </svg-icon>
            <div
                class="ap-typography-caption ap-text-description !ap-font-bold group-hover:!ap-text-title ap-transition">
                activepieces
            </div>
        </div>
    </div>
</a>