<div class="ap-flex ap-flex-col">
 
  <ng-container *ngIf="isAnArrayOfObjects(); then arrayOfObjects else arrayOfStrings"></ng-container>
  
  <ng-template #arrayOfObjects>
    <div class="ap-mb-2 ap-flex ap-justify-between ap-items-center">
      <div class="ap-select-none">
        {{ property.displayName }}
      </div>
      <ng-container *ngTemplateOutlet="dynamicInputTemplate"></ng-container>
    </div>
    <ng-container *ngFor="
        let objectControl of formArray.controls;
        let idx = index;
        let isFirst = first
      ">
      <mat-card appearance="outlined" class="ap-my-2">
        <mat-card-header class="ap-w-full  !ap-p-3 ap-mb-1 !ap-block">
          <mat-card-title>
            <div class="ap-flex ap-items-center ap-select-none ap-justify-between  ap-h-[3rem] ap-w-full">
              <div class=" ap-mx-3 ap-typography-body-1">
                #{{ idx+1 }}
              </div>
              <ap-icon-button *ngIf="itemsCanBeDeleted()" [width]="9" [height]="9" iconFilename="delete.svg"
                [tooltipText]="!deleteButton.isHovered ? '' : removeItemTooltip" (buttonClicked)="removeValue(idx)"
                apTrackHover #deleteButton="hoverTrackerDirective"></ap-icon-button>
            </div>

          </mat-card-title>

        </mat-card-header>
        <mat-card-content>
          <div class="ap-flex ap-flex-col ap-gap-3">
            <div *ngFor="
            let property of property.properties | objectToArray;
            let i = index
          ">
              <ng-container *ngTemplateOutlet="
              formFieldsTemplate;
              context: {
                $implicit: property,
                propertyIndex: i,
                formGroup: objectControl,
                prefix: prefix
              }
            ">
              </ng-container>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-container *ngIf="formArray.controls.length === 0">
      <div class="ap-border-dashed ap-border-2 ap-border-gray-500 ap-rounded ap-p-3 ap-my-2 ap-text-center">
        {{ property.displayName }} is empty
      </div>
    </ng-container>
  </ng-template>

  <ng-template #arrayOfStrings>
    <div class="ap-mb-2 ap-flex ap-gap-2 ap-justify-between ap-items-center">
      <div class="ap-cursor-pointer" (click)="focusFirstInput()">
        {{ property.displayName }}
      </div>
      <ng-container *ngTemplateOutlet="dynamicInputTemplate"></ng-container>
    </div>
    <ng-container *ngFor="
        let val of formArray.controls;
        let idx = index;
        let isLast = last;
        let isFirst = first
      ">
      <div class="ap-flex" *ngIf="!(isLast && formArray.disabled && idx > 0)">
        <div class="delete-btn-container">
          <ap-icon-button *ngIf="itemsCanBeDeleted()" [width]="9" [height]="9" iconFilename="delete.svg" [tooltipText]="
              !deleteButton.isHovered && !valueInput.isHovered
                ? ''
                : 'Remove Item'
            " class="delete-btn ap-z-40" [class.opacity-0]="!deleteButton.isHovered && !valueInput.isHovered"
            apTrackHover #deleteButton="hoverTrackerDirective" (buttonClicked)="removeValue(idx)"></ap-icon-button>
        </div>

        <div class="form-control value-control" [class.first]="isFirst && formArray.controls.length > 1"
          [class.last]="isLast && formArray.controls.length > 1" [class.only-one]="formArray.controls.length === 1"
          apTrackHover #valueInput="hoverTrackerDirective" #interpolatingTextControlContainer>
          <app-interpolating-text-form-control #textControl [insideMatField]="false"
            [formControl]="getFormControlAtIndex(idx)" placeholder="Value"
            (click)="formArray.enabled ? handler.showMentionsDropdown() : null"></app-interpolating-text-form-control>

          <app-builder-autocomplete-dropdown-handler #handler [container]="interpolatingTextControlContainer"
            (mentionEmitted)="addMention(textControl, $event)">
          </app-builder-autocomplete-dropdown-handler>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formArray.controls.length === 0">
      <div class="ap-border-dashed ap-border-2 ap-border-gray-500 ap-rounded ap-p-3 ap-my-2 ap-text-center">
        {{ property.displayName }} is empty
      </div>
    </ng-container>
  </ng-template>

  <ap-button btnColor="primary" btnStyle="basic" *ngIf="formArray.enabled" (buttonClicked)="addValue()" type="button"
    class="add-btn" btnSize="medium">
    + Add Item
  </ap-button>
  <div class="ap-flex-grow" #spacer></div>

</div>

<ng-container *ngIf="updateValueOnChange$ | async"></ng-container>