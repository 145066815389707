import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Attachment, EmailConfig } from '@upbrains/shared';

@Component({
  selector: 'ap-email-showcase',
  templateUrl: './email-showcase.component.html',
  styleUrls: ['./email-showcase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailShowCaseComponent {
  @Input() emailConfig: EmailConfig = {
    from: '',
    cc: '',
    to: '',
    date: '',
    content: '',
    subject: '',
    senderImage: '',
  };

  @Input() attachments: Attachment[] | undefined;

  pdfUrl: string;
  imageUrl: string;

  sanitizedContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    if (this.emailConfig && this.emailConfig.content) {
      this.sanitizeEmailContent(this.emailConfig.content);
    }
  }

  sanitizeEmailContent(content: string) {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  setSelectedFileViewer(attachmentUrl: string, type: string) {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const pdfExtensions = ['pdf'];
    const textExtensions = ['txt', 'md', 'rtf', 'html', 'xml'];

    if (imageExtensions.includes(type)) {
      this.imageUrl = attachmentUrl;
      return this.imageUrl;
    } else if (pdfExtensions.includes(type)) {
      this.pdfUrl = attachmentUrl;
      return this.pdfUrl;
    } else if (textExtensions.includes(type)) {
      return 'txt';
    } else {
      return 'unknown';
    }
  }

  clearPdfUrl(): void {
    this.pdfUrl = '';
    this.imageUrl = '';
  }
}
