import { Injectable } from '@angular/core';

import 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-javascript';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { SnackbarService } from './snackbar.service';
import { environment } from '../environments/environment';
import { catchError, of } from 'rxjs';

declare let Prism: { highlightAll: () => void };

@Injectable({
  providedIn: 'root',
})
export class OpensearchService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    public router: Router,
    private snackbarService: SnackbarService
  ) {}

  getQuery(payload: {
    query_list: string[];
    project_id: string;
    index_name: string;
  }): any {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .post<any>(
          `${environment.igniteUrl}/integration-apis/opensearch/query`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            this.snackbarService.showError(
              'There was a problem in getting opensearch query!'
            );
            console.error('Error getting opensearch query:', error);
            return of(null);
          })
        );
    }

    return of(null);
  }
}
