import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { AuthenticationService } from '@upbrains/ui/common';
import { OtpType } from '@upbrains/ee-shared';
import { Router } from '@angular/router';
import { ErrorCode } from '@upbrains/shared';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  loading = false;
  readonly OtpType = OtpType;
  showVerificationNote = false;
  emailFormControl: FormControl<string>;
  sendPasswordReset$!: Observable<void>;
  readonly notFoundErrorName = 'notFound';
  errorMessage: string | null = null;

  constructor(
    private authService: AuthenticationService,

    private router: Router
  ) {
    this.emailFormControl = new FormControl('', {
      nonNullable: true,
      validators: [Validators.email, Validators.required],
    });
  }

  sendPasswordReset() {
    if (!this.loading && !this.emailFormControl.invalid) {
      this.loading = true;
      this.errorMessage = null;
      this.sendPasswordReset$ = this.authService
        .forgotPassword({
          email: this.emailFormControl.value,
        })
        .pipe(
          catchError(err => {
              this.handleError(err);
              return of(void 0);
          }), 
          tap(() => {
            if (!this.errorMessage) { 
              this.handleSuccess();
            }
          }),
          map(() => void 0)
        );
    }
  }

  private handleError(err: any) {
    console.error(err);
    this.showVerificationNote = false;
    this.loading = false;
    if (err.error.code === ErrorCode.UPBRAINS_USER_NOT_FOUND) {
      this.errorMessage = 'There is no such user with this email.';
    } else {
      this.errorMessage = 'An error occurred. Please try again later.'
    }
    return of(void 0);
  }

  private handleSuccess() {

    this.showVerificationNote = true;
    this.loading = false;
  }

  goBackToSignIn() {
    this.router.navigate(['/sign-in']);
  }
}
