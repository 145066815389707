import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  // ApEdition,
  FlowTemplate,
  ListFlowTemplatesRequest,
  SeekPage,
} from '@upbrains/shared';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CreateFlowTemplateRequest } from '@upbrains/ee-shared';
import { FlagService } from './flag.service';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  constructor(private http: HttpClient, private flagsService: FlagService) { }

  list(params: ListFlowTemplatesRequest): Observable<FlowTemplate[]> {
    let httpParams = new HttpParams();
    if (params.agents && params.agents.length > 0) {
      httpParams = httpParams.appendAll({ agents: params.agents });
    }
    if (params.tags && params.tags.length > 0) {
      httpParams = httpParams.appendAll({ tags: params.tags });
    }
    if (params.search) {
      httpParams = httpParams.append('search', params.search);
    }
    httpParams.append('limit', '1000');
    const url = environment.apiUrl + '/upbrains-workflow-templates';
    return this.flagsService.getEdition().pipe(() =>
      this.http
        .get<SeekPage<FlowTemplate>>(url, {
          params: httpParams,
        })
        .pipe(map((res) => res.data))
    );
  }

  create(request: CreateFlowTemplateRequest): Observable<FlowTemplate> {
    return this.http.post<FlowTemplate>(
      environment.apiUrl + '/upbrains-workflow-templates',
      request
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(
      environment.apiUrl + `/upbrains-workflow-templates/${id}`
    );
  }

  getTemplate(flowId: string) {
    return this.http.get<FlowTemplate>(
      environment.apiUrl + `/upbrains-workflow-templates/${flowId}`
    );
  }
}
