<div
  class="ap-max-w-[674px] ap-mx-auto custom-company-url ap-mt-[72px] ap-mb-24"
>
  <div class="ap-text-sm ap-font-medium ap-mb-2">Company Website*</div>
  <div class="ap-relative">
    <form
      class="ap-flex ap-flex-col ap-gap-1 ap-w-full"
      [formGroup]="form"
      (submit)="onNextButtonClick()"
    >
      <mat-form-field>
        <input
          #companyUrlInput
          matInput
          type="text"
          placeholder="Your company url: https://..."
          class="ap-w-full ap-h-[44px] ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-font-normal focus:ap-text-sm focus:ap-border-[#D0D5DD] focus:ap-outline-none focus:ap-ring-0 focus:ap-shadow-[0px_0px_0px_4px_[#1570EF]]"
          formControlName="companyUrl"
          required
        />
        <mat-error *ngIf="form.controls.companyUrl.hasError('required')">
          Company Url is required
        </mat-error>
        <mat-error *ngIf="form.controls.companyUrl.hasError('invalidUrl')"
          >Company Url is not valid</mat-error
        >
      </mat-form-field>
    </form>

    <div
      class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-3 ap-mt-7"
    >
      <ap-button
        actionButton
        btnSize="large"
        i18n
        btnColor="basic"
        class="ap-rounded-lg"
        [fullWidthOfContainer]="true"
        (buttonClicked)="onManualButtonClick()"
      >
        <div
          class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
        >
          Fill out the form manually
        </div>
      </ap-button>

      <div class="ap-w-[230px] custom-button">
        <mat-dialog-actions>
          <ap-button
            actionButton
            btnSize="large"
            i18n
            class="ap-w-[230px] ap-rounded-lg"
            [fullWidthOfContainer]="true"
            (buttonClicked)="onNextButtonClick()"
            [loading]="isLoading"
          >
            <div
              class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
            >
              Next
            </div>
          </ap-button>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>
