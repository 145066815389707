import { Type } from '@sinclair/typebox';
import { BasePropertySchema, TPropertyValue } from './common';
import { PropertyType } from './property-type';
import { ValidationInputType } from '../../validators/types';

export const ShortTextProperty = Type.Composite([
  BasePropertySchema,
  TPropertyValue(Type.String(), PropertyType.SHORT_TEXT),
]);

export type ShortTextProperty<R extends boolean> = BasePropertySchema & {
  value?: string;
} & TPropertyValue<
    string,
    PropertyType.SHORT_TEXT,
    ValidationInputType.STRING,
    R
  >;

export const LongTextProperty = Type.Composite([
  BasePropertySchema,

  TPropertyValue(Type.String(), PropertyType.LONG_TEXT),
]);

export type LongTextProperty<R extends boolean> = BasePropertySchema & {
  value?: string;
} & TPropertyValue<
    string,
    PropertyType.LONG_TEXT,
    ValidationInputType.STRING,
    R
  >;

export type DisplayTextProperty<R extends boolean> = BasePropertySchema & {
  value: string; // This ensures the property has a 'value' of type string
} & TPropertyValue<
    string,
    PropertyType.LONG_TEXT,
    ValidationInputType.STRING,
    R
  >;

// Define the TypeBox schema for DisplayTextProperty
export const DisplayTextProperty = Type.Intersect([
  Type.Object({
    value: Type.String(), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.DISPLAY_TEXT) as any, // Adjust this according to your actual schema
]) as any; // TypeBox schema needs proper handling for compatibility

export type ApprovalActionButtonProperty<R extends boolean> =
  BasePropertySchema & {
    value: string; // This ensures the property has a 'value' of type string
  } & TPropertyValue<
      string,
      PropertyType.LONG_TEXT,
      ValidationInputType.STRING,
      R
    >;

// Define the TypeBox schema for DisplayTextProperty
export const ApprovalActionButtonProperty = Type.Intersect([
  Type.Object({
    value: Type.String(), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.APPROVAL_ACTION) as any, // Adjust this according to your actual schema
]) as any;
export type DisapprovalActionButtonProperty<R extends boolean> =
  BasePropertySchema & {
    value: string; // This ensures the property has a 'value' of type string
  } & TPropertyValue<
      string,
      PropertyType.LONG_TEXT,
      ValidationInputType.STRING,
      R
    >;

// Define the TypeBox schema for DisplayTextProperty
export const DisapprovalActionButtonProperty = Type.Intersect([
  Type.Object({
    value: Type.String(), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.DISAPPROVAL_ACTION) as any, // Adjust this according to your actual schema
]) as any;
export type CustomActionButtonProperty<R extends boolean> =
  BasePropertySchema & {
    value: string; // This ensures the property has a 'value' of type string
  } & TPropertyValue<
      string,
      PropertyType.LONG_TEXT,
      ValidationInputType.STRING,
      R
    >;

// Define the TypeBox schema for DisplayTextProperty
export const CustomActionButtonProperty = Type.Intersect([
  Type.Object({
    value: Type.String(), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.CUSTOM_ACTION) as any, // Adjust this according to your actual schema
]) as any;
export type SubmitActionButtonProperty<R extends boolean> =
  BasePropertySchema & {
    value: string; // This ensures the property has a 'value' of type string
  } & TPropertyValue<
      string,
      PropertyType.LONG_TEXT,
      ValidationInputType.STRING,
      R
    >;

// Define the TypeBox schema for DisplayTextProperty
export const SubmitActionButtonProperty = Type.Intersect([
  Type.Object({
    value: Type.String(), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.SUBMIT_ACTION) as any, // Adjust this according to your actual schema
]) as any;

export type SelectProperty<R extends boolean> = BasePropertySchema & {
  value: string[]; // This ensures the property has a 'value' of type string
} & TPropertyValue<
    string,
    PropertyType.LONG_TEXT,
    ValidationInputType.STRING,
    R
  >;

// Define the TypeBox schema for DisplayTextProperty
export const SelectProperty = Type.Intersect([
  Type.Object({
    value: Type.Array(Type.String()), // TypeBox schema for 'value' property as string
  }),
  TPropertyValue(Type.String(), PropertyType.SELECT) as any, // Adjust this according to your actual schema
]) as any;

export const DefaultAccessToken = Type.Composite([
  BasePropertySchema,

  TPropertyValue(Type.String(), PropertyType.LONG_TEXT),
]);

export type DefaultAccessToken<R extends boolean> = BasePropertySchema & {
  value?: string;
} & TPropertyValue<
    string,
    PropertyType.DEFAULT_ACCESS_TOKEN,
    ValidationInputType.STRING,
    R
  >;
