import { Routes } from '@angular/router';
import { CustomFormComponent } from './page/custom-form/custom-form.component';

export const FormsLayoutRouting: Routes = [
  {
    path: 'forms/:id',
    component: CustomFormComponent,
    runGuardsAndResolvers: 'always',
  },
];
