export function toJson(json: string) {
  if (json) {
    try {
      return JSON.parse(json);
    } catch (err) {
      return null;
    }
  }
  return null;
}
