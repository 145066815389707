<mat-radio-group
  aria-labelledby="radio-button-options"
  class="radio-button-options"
  [(ngModel)]="selectedOption"
>
  <div class="ap-flex ap-flex-col ap-gap-3">
    <ng-container *ngFor="let option of optionsList">
      <label
        class="ap-border ap-border-[#E4E7EC] ap-rounded-lg ap-p-[7px] ap-bg-white ap-cursor-pointer"
      >
        <mat-radio-button
          [value]="option.value"
          (change)="onSelect(option)"
          color="primary"
          class="ap-text-[#344054] ap-text-base ap-font-inter ap-font-medium"
        >
          {{ option.label }}
        </mat-radio-button>
      </label>
    </ng-container>
  </div>
</mat-radio-group>
