<div
  class="box-shadow ap-p-8 ap-flex-1 ap-overflow-auto ap-rounded ap-shadow-step-container-ds ap-bg-white ap-min-w-[600px] ap-max-h-[500px] ap-h-full"
>
  <div class="ap-flex ap-flex-row ap-items-center ap-justify-end ap-mb-2">
    <ap-icon-button
      [iconFilename]="'close.svg'"
      (click)="onClose()"
    ></ap-icon-button>
  </div>

  <div class="ap-w-full pdf-height">
    <div class="ap-flex ap-flex-row ap-gap-3">
      <div
        class="ap-text-base ap-font-inter ap-font-semibold ap-min-w-[150px] ap-w-[150px] ap-pt-[11px]"
      >
        Search Keywords
      </div>
      <div class="ap-w-full ap-flex ap-flex-col ap-gap-2">
        <div class="ap-w-full">
          <input
            type="text"
            placeholder="Search your value"
            [(ngModel)]="spreadsheetLogDataTemp.searchValue"
            class="ap-w-full ap-h-[44px] ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-font-normal focus:ap-text-sm focus:ap-border-[#D0D5DD] focus:ap-outline-none focus:ap-ring-0 focus:ap-shadow-[0px_0px_0px_4px_[#1570EF]]"
          />
        </div>

        <div class="ap-flex ap-flex-row ap-gap-2">
          <div class="ap-font-inter ap-font-semibold ap-text-xs ap-pt-[6px]">
            Exact Match
          </div>
          <div>
            <mat-slide-toggle
              (change)="toggleClicked($event)"
              [checked]="spreadsheetLogData.matchCase"
              color="primary"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="ap-px-8">
        <ap-button
          actionButton
          (buttonClicked)="handleSaving()"
          btnSize="large"
          btnColor="primary"
          [loading]="isLoading"
          i18n
        >
          <div class="ap-flex ap-items-center loading-template">Find</div>
        </ap-button>
      </div>
    </div>

    <div class="ap-relative ap-max-w-full ap-h-[290px] ap-overflow-auto">
      <table
        *ngIf="dataSource.length && !errorMessage"
        mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="row">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ap-top-0 ap-z-10 ap-sticky"
          >
            Row
          </th>
          <td mat-cell *matCellDef="let element">{{ element.row }}</td>
        </ng-container>

        <ng-container
          *ngFor="let column of displayColumns.slice(1)"
          [matColumnDef]="column"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ap-top-0 ap-z-10 ap-sticky"
          >
            {{ column }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.values[column] }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayColumns; let i = index"
          [ngClass]="{ 'selected-row': selectedRowIndex === i }"
          (click)="onRowClick(i)"
        ></tr>
      </table>

      <div
        *ngIf="!dataSource.length && isLoading"
        class="ap-w-full ap-h-[50%] ap-absolute ap-flex ap-justify-center ap-items-center ap-top-7"
      >
        <ap-loading-icon
          height="50px"
          width="51px"
          [outlineLoader]="false"
          [whiteLoader]="false"
        >
        </ap-loading-icon>
      </div>

      <div *ngIf="errorMessage" class="ap-w-full ap-text-danger">
        <p>{{ errorMessage }}</p>
      </div>
    </div>

    <div
      *ngIf="dataSource.length"
      class="ap-flex ap-flex-row ap-justify-end ap-items-center ap-gap-2 ap-my-8 ap-px-8"
    >
      <ap-button
        actionButton
        (buttonClicked)="handleImporting()"
        btnSize="large"
        btnColor="primary"
        i18n
        [disabled]="!selectedRowIndex && selectedRowIndex !== 0"
      >
        <div class="ap-flex ap-items-center loading-template">Import</div>
      </ap-button>
    </div>
  </div>
</div>
