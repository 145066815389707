import { createReducer, on } from '@ngrx/store';
import { setSubscriptionPlan } from './subscription-plan.action';
import { SubscriptionPlansState } from './subscription-plan-state.model';

export const SubscriptionPlansInitialState: SubscriptionPlansState = {
  subscriptionPlan: null,
};

export const subscriptionPlanReducer = createReducer(
  SubscriptionPlansInitialState,
  on(
    setSubscriptionPlan,
    (state, { subscriptionPlan }): SubscriptionPlansState => {
      return { ...state, subscriptionPlan };
    }
  )
);
