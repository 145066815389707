import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AllUserSubscriptionsType,
  PlansInfoObjectType,
} from '@upbrains/shared';
import { SubscriptionService } from '@upbrains/ui/common';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

@Component({
  selector: 'app-onboarding-subscription-plans',
  templateUrl: './onboarding-subscription-plans.component.html',
  styleUrls: ['./onboarding-subscription-plans.component.scss'],
})
export class OnboardingSubscriptionPlansComponent implements OnInit, OnDestroy {
  @Input() onNextStep!: () => void;

  subscriptionPlansList$: Observable<PlansInfoObjectType[] | undefined | null> =
    of(null);
  monthYearStatus = 'year';
  private monthYearStatusSubject = new BehaviorSubject<string>(
    'default-status'
  );
  private destroy$ = new Subject<void>();
  subscriptionPackagesList: PlansInfoObjectType[] | undefined | null = [];

  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  planName: string | null = '';
  isLoading = false;
  allUserSubscriptions$: Observable<
    AllUserSubscriptionsType[] | null | undefined
  > = of(undefined);

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    const subscriptionPlans$ = combineLatest([
      this.subscriptionService.getPlansInfo(),
      this.monthYearStatusSubject.asObservable(), // Ensure it's an observable
    ]).pipe(
      map(([plansInfo, status]) => {
        if (!plansInfo?.results.plans_info) {
          return [];
        }
        return plansInfo.results.plans_info
          .filter((plan) =>
            plan.billing_period?.toLocaleLowerCase()?.includes(status)
          )
          .sort((a, b) => (a.fixed_amount ?? 0) - (b.fixed_amount ?? 0));
      }),
      takeUntil(this.destroy$)
    );

    const allSubscriptions$ = this.subscriptionService
      .getAllSubscriptions()
      .pipe(
        map((subscriptions) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          return subscriptions?.all_user_subscriptions || null;
        }),
        takeUntil(this.destroy$)
      );

    // Use combineLatest so it reacts to changes in monthYearStatus
    combineLatest([subscriptionPlans$, allSubscriptions$]).subscribe(
      ([subscriptionPlans, allUserSubscriptions]) => {
        this.subscriptionPlansList$ = of(subscriptionPlans);
        this.allUserSubscriptions$ = of(allUserSubscriptions);
        this.isLoading = false;
        this.cdr.detectChanges();
      }
    );

    // Set initial month/year status
    this.monthYearStatusSubject.next(this.monthYearStatus);
  }

  updateMonthYearStatus(newStatus: string): void {
    this.monthYearStatus = newStatus;
    this.monthYearStatusSubject.next(newStatus);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  prevStep() {
    this.router.navigate(['/onboarding'], { queryParams: { currentIndex: 5 } });
  }

  openContactPage(): void {
    window.open('https://upbrains.ai/contact#form', '_blank');
  }
}
