import { Component, Input } from '@angular/core';

@Component({
  selector: 'ap-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() pdfUrl: string;
  currentPage: number = 1;
  totalPages: number = 0;
  isLoading = true;
  isError = false;

  onError(event: any) {
    this.isLoading = false;
    this.isError = true;
  }

  onLoadComplete(pdf: any) {
    this.currentPage = 1;
    this.totalPages = pdf.numPages;
    this.isLoading = false;
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  onTextLayerRendered(event: any) {
    console.log('Text layer rendered:', event);
  }
}
