import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCommonModule } from '@upbrains/ui/common';
import { InstallCommunityAgentModalComponent } from './install-community-agent/install-community-agent-modal.component';
import { CommunityAgentsTableComponent } from './community-agents-table/community-agents-table.component';
import { AgentIconContainerComponent } from './agents-icons/agent-icon-container/agent-icon-container.component';
import { AgentsIconsFromFlowComponent } from './agents-icons-from-flow/agents-icons-from-flow.component';

const exportedComponents = [
  InstallCommunityAgentModalComponent, CommunityAgentsTableComponent, AgentIconContainerComponent, AgentsIconsFromFlowComponent
]
@NgModule({
  imports: [CommonModule, UiCommonModule],
  declarations: [...exportedComponents],
  exports: [...exportedComponents]
})
export class UiFeatureAgentsModule { }