<div
  class="ap-bg-[#F9FAFB] ap-border ap-border-[#D0D5DD] ap-w-[270px] ap-h-[239px] ap-rounded-[20px] ap-p-6 ap-flex ap-flex-col ap-items-center ap-font-inter"
>
  <div class="ap-w-[30px] ap-h-[26.25px]">
    <img
      class="ap-flex ap-justify-center ap-items-center ap-w-full ap-h-full"
      [src]="agent.logoUrl"
      alt="{{ agent.displayName }} icon"
    />
  </div>

  <div class="ap-font-inter ap-font-medium ap-text-2xl ap-mt-3">
    {{ agent.displayName }}
  </div>

  <ap-button
    actionButton
    btnSize="large"
    i18n
    [btnStyle]="agent.connected ? 'basic' : 'stroked'"
    btnColor="basic"
    [fullWidthOfContainer]="true"
    class="ap-mt-auto transition-all duration-300"
    [ngClass]="{ 'ap-pointer-events-none': agent.connected }"
    (click)="connectAgent()"
  >
    <div
      class="ap-flex ap-items-center ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter ap-gap-3"
    >
      <svg-icon
        *ngIf="!agent.connected"
        class="ap-w-[24px] ap-h-[24px] transition-all duration-300"
        [applyClass]="true"
        src="assets/img/NewLayout/onboarding/electricity.svg"
      ></svg-icon>

      <svg-icon
        *ngIf="agent.connected"
        class="ap-w-[24px] ap-h-[24px] transition-all duration-300"
        [applyClass]="true"
        src="assets/img/NewLayout/onboarding/check.svg"
      ></svg-icon>

      {{ agent.connected ? 'Connected' : 'Connect' }}
    </div>
  </ap-button>
</div>

<div class="ap-hidden">
  <app-add-edit-connection-button
    *ngIf="agent"
    #addConnectionBtn
    btnSize="extraSmall"
    [isEditConnectionButton]="false"
    [authProperty]="agent.auth!"
    [agentName]="agent.name"
    [agentVersion]="agent.version"
    [propertyKey]="''"
    [agentDisplayName]="agent.displayName"
  ></app-add-edit-connection-button>
</div>
