import { ApEdition, ApFlagId, AppConnectionType } from '@upbrains/shared';
import {
  FlagService,
  OAuth2AppsService,
  UpbrainsOAuthApps,
} from '@upbrains/ui/common';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import {
  AgentOAuth2DetailsMap,
  handleErrorForGettingAgentsOAuth2Details,
} from '../add-edit-connection-button/utils';

@Injectable({
  providedIn: 'root',
})
export class CloudAuthConfigsService {
  constructor(
    // private http: HttpClient,
    private flagsService: FlagService,
    private oAuth2AppsService: OAuth2AppsService,
    private upbrainsOAuthServices: UpbrainsOAuthApps
  ) { }

  private getPlatformAuth(
    edition: ApEdition
  ): Observable<AgentOAuth2DetailsMap> {
    if (edition === ApEdition.COMMUNITY) {
      return of({});
    }
    return this.oAuth2AppsService.listOAuth2AppsCredentials().pipe(
      map((res) => {
        const platformAppsClientIdMap: AgentOAuth2DetailsMap = {};
        res.data.forEach((app) => {
          platformAppsClientIdMap[app.agentName] = {
            clientId: app.clientId,
            connectionType: AppConnectionType.PLATFORM_OAUTH2,
          };
        });
        return platformAppsClientIdMap;
      }),
      handleErrorForGettingAgentsOAuth2Details
    );
  }

  private getCloudAuth(
    cloudAuthEnabled: boolean,
    edition: ApEdition
  ): Observable<AgentOAuth2DetailsMap> {
    if (!cloudAuthEnabled) {
      return of({});
    }
    return this.upbrainsOAuthServices.getAppsOAuthProviders().pipe(
      map((res) => {
        const cloudManagedOAuth2Apps: AgentOAuth2DetailsMap = {};
        res.forEach((providerData) => {
          cloudManagedOAuth2Apps[providerData.agentName] = {
            clientId: providerData.clientId,
            connectionType: AppConnectionType.PLATFORM_OAUTH2,
          };
        });
        return cloudManagedOAuth2Apps;
      }),
      handleErrorForGettingAgentsOAuth2Details
    );
  }

  getAppsAndTheirClientIds(): Observable<AgentOAuth2DetailsMap> {
    return forkJoin({
      edition: this.flagsService.getEdition(),
      cloudAuthEnabled: this.flagsService.isFlagEnabled(
        ApFlagId.CLOUD_AUTH_ENABLED
      ),
    }).pipe(
      switchMap((flags) => {
        const platformAuth$ = this.getPlatformAuth(flags.edition);
        const cloudAuth$ = this.getCloudAuth(
          flags.cloudAuthEnabled,
          flags.edition
        );
        return forkJoin({
          cloudAuth: cloudAuth$,
          platformAuth: platformAuth$,
        }).pipe(
          map((res) => {
            return {
              ...res.cloudAuth,
              ...res.platformAuth,
            };
          })
        );
      })
    );
  }
}
