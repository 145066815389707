import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@upbrains/ui/common';

@Component({
  selector: 'app-book-a-time',
  templateUrl: './book-a-time.component.html',
})
export class BookATimeComponent implements OnInit {
  calendlyScriptUrl = environment.calendlyScriptUrl; // Read from environment
  calendlyDataUrl = environment.calendlyDataUrl; // Read from environment

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.loadCalendlyScript();
    this.setCalendlyUrl();
  }

  private loadCalendlyScript(): void {
    if (!this.calendlyScriptUrl) {
      console.error('Calendly script URL is missing in environment');
      return;
    }

    const existingScript = document.querySelector(
      `script[src="${this.calendlyScriptUrl}"]`
    );
    if (existingScript) {
      existingScript.remove();
    }

    const script = this.renderer.createElement('script');
    script.src = this.calendlyScriptUrl;
    script.async = true;

    script.onload = () => {
      // Ensure Calendly object is available before calling init
      setTimeout(() => {
        if ((window as any).Calendly?.initInlineWidgets) {
          (window as any).Calendly.initInlineWidgets();
        } else {
          console.error('Calendly.initInlineWidgets is not available yet.');
        }
      }, 500);
    };

    this.renderer.appendChild(document.body, script);
  }

  private setCalendlyUrl(): void {
    if (!this.calendlyDataUrl) {
      console.error('Calendly data URL is missing in environment');
      return;
    }

    const calendlyWidget = this.el.nativeElement.querySelector(
      '.calendly-inline-widget'
    );
    if (calendlyWidget) {
      calendlyWidget.setAttribute('data-url', this.calendlyDataUrl);
    }
  }
}
