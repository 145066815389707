import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-object-cell-editor',
  templateUrl: './custom-object-cell-editor.component.html',
  styleUrls: ['./custom-object-cell-editor.component.scss'],
})
export class CustomObjectCellEditor implements ICellEditorComp {
  private params!: ICellEditorParams;
  editingValue: string = '';

  @ViewChild('input', { static: false }) input!: ElementRef;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    if (typeof params.value === 'object' && params.value !== null) {
      this.editingValue = params.value.value;
    } else {
      this.editingValue = params.value ?? '';
    }
  }

  getValue(): any {
    return {
      value: this.editingValue,
      alternative: this.params.value?.alternative || '',
    };
  }

  getGui(): HTMLElement {
    return this.input.nativeElement;
  }

  afterGuiAttached(): void {
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

  isPopup(): boolean {
    return false;
  }

  onEnter(): void {
    this.params.stopEditing();
  }
}
