import { Action, createReducer, on } from '@ngrx/store';
import { conversationActions } from './conversation.action';
import { ConversationState } from './conversation-state.model';

const initialState: ConversationState = {
  loaded: false,
  details: null,
  messages: null,
  selectedMessage: null, // New property to store the selected message
  agents:null,
  itemmessage:null,
};

const _conversationReducer = createReducer(
  initialState,

  // Handle success action for loading conversation details
  on(
    conversationActions.loadDetailsSuccess,
    (state, { details }): ConversationState => ({
      ...state,
      loaded: true,
      details,
    })
  ),

  // Handle success action for loading conversation messages
  on(
    conversationActions.loadMessagesSuccess,
    (state, { messages }): ConversationState => ({
      ...state,
      messages,
    })
  ),

  // Handle action for selecting a message
  on(
    conversationActions.selectMessage,
    (state, { selectedMessage }): ConversationState => ({
      ...state,
      selectedMessage,
    })
  ),
  on(
    conversationActions.loadSelectedMessageSuccess,
    (state, { selectedMessage }): ConversationState => ({
      ...state,
      selectedMessage,
    })
  ),
  on(
    conversationActions.setConversationAgents,
    (state, { agents }): ConversationState => ({
      ...state,
      agents,
    })
  ),
  on(
    conversationActions.setItemMessage,
    (state, { itemmessage }): ConversationState => ({
      ...state,
      itemmessage,
    })
  )
);

export function conversationReducer(
  state: ConversationState | undefined,
  action: Action
) {
  return _conversationReducer(state, action);
}
