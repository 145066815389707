import { Component, Input } from '@angular/core';

@Component({
  selector: 'ap-step-card',
  templateUrl: './step-card.component.html',
})
export class StepCardComponent {
  @Input() stepNum: string;
  @Input() description: string;
  @Input() stepIcon: string;
}
