<div class="ap-flex ap-justify-end">
  <div
    class="ap-typography-body-2 !ap-font-[600] !ap-leading-[1.375rem] ap-text-white ap-bg-avatar ap-rounded-full ap-px-[12px] ap-py-[7px] ap-cursor-pointer ap-text-center ap-min-w-[36px] ap-min-h-[36px]"
    [matMenuTriggerFor]="menu"
  >
    {{ currentUserEmail[0] | uppercase }}
  </div>
</div>
<mat-menu #menu="matMenu">
  <!-- BEGIN EE -->
  <ng-container *ngIf="projectEnabled$ | async">
    <ng-container *ngIf="projects$ | async as projects">
      <ng-container *ngIf="selectedProject$ | async as currentProject">
        <ng-container *ngIf="projects.length > 0">
          <mat-menu #projectsMenu="matMenu">
            <ng-container *ngIf="projects$ | async as projects">
              <ng-container *ngIf="projects.length > 0">
                <ng-container
                  *ngFor="let project of projects$ | async; let i = index"
                >
                  <div mat-menu-item (click)="switchProject(project.id)">
                    <div
                      class="ap-flex ap-items-center ap-w-full ap-min-w-[200px]"
                    >
                      <div
                        class="ap-max-w-[220px] ap-truncate"
                        apCheckOverflow
                        (isOverflowed)="
                          $event
                            ? (overflownProjectsNames[project.displayName] =
                                project.displayName)
                            : null
                        "
                        [matTooltip]="
                          overflownProjectsNames[project.displayName]
                        "
                      >
                        {{ project.displayName }}
                      </div>
                      <ng-container *ngIf="currentProject.id === project.id">
                        <div class="ap-flex-grow"></div>
                        <svg-icon
                          src="assets/img/custom/check.svg"
                          [applyClass]="true"
                          class="ap-h-[20px] ap-w-[20px] ap-fill-primary"
                        ></svg-icon>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </mat-menu>
          <div mat-menu-item [matMenuTriggerFor]="projectsMenu">
            <div
              class="ap-max-w-[220px] ap-truncate"
              [matTooltip]="overflownProjectsNames[currentProject.displayName]"
            >
              Projects ({{ currentProject.displayName }})
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- END EE -->

  <div mat-menu-item [matTooltip]="currentUserEmail">
    <div
      class="ap-cursor-default ap-flex ap-items-center ap-gap-2 ap-overflow-hidden ap-typography-body-2 ap-select-none ap-text-body"
      #userName
    >
      <svg-icon
        src="assets/img/custom/person.svg"
        class="ap-w-[20px] ap-h-[20px] ap-fill-disable"
        [applyClass]="true"
      >
      </svg-icon>
      {{ user.firstName }} {{ user.lastName }}
    </div>
  </div>

  <!-- <div mat-menu-item [matMenuTriggerFor]="languagesList" class="ap-flex ap-items-center">

        <div class="ap-flex ap-items-center ap-gap-2">
            <svg-icon src='assets/img/custom/language.svg' class="ap-w-[20px] ap-h-[20px] ap-fill-disable"
                [applyClass]="true">
            </svg-icon> {{selectedLanguage.languageName}}
        </div>

    </div> -->
  <!-- <div mat-menu-item (click)="goToDeveloperPage()" *ngIf="myAgentsEnabled$ | async">
        <div i18n>My Agents</div>
    </div> -->
  <!-- <div mat-menu-item (click)="viewPlans()" *ngIf="billingEnabled$ | async" i18n>
        Subscription
    </div> -->

  <!-- <div mat-menu-item (click)="showWhatIsNew()" *ngIf="showCommunity$ | async" i18n>
        <div class="ap-flex ap-items-center ap-min-w-[214px]">What's new <div class="ap-flex-grow"></div><svg-icon
                [applyClass]="true" src="assets/img/custom/external-link.svg"
                class="ap-h-[16px] ap-w-[16px] ap-fill-[#989898]"></svg-icon>
        </div>

    </div> -->
  <!-- <div mat-menu-item (click)="goToCommunity()" *ngIf="showCommunity$ | async" i18n>
        <div class="ap-flex ap-items-center ap-min-w-[214px]">Community <div class="ap-flex-grow"></div><svg-icon
                [applyClass]="true" src="assets/img/custom/external-link.svg"
                class="ap-h-[16px] ap-w-[16px] ap-fill-[#989898]"></svg-icon>
        </div>

    </div> -->
  <div mat-menu-item (click)="logout()">
    <div class="ap-text-danger" i18n>Logout</div>
  </div>
</mat-menu>

<!-- BEGIN EE -->
<ng-container *ngIf="switchProject$ | async"></ng-container>
<!-- END EE -->

<mat-menu #languagesList="matMenu">
  <div
    mat-menu-item
    *ngFor="let pair of locales | objectToArray"
    (click)="redirectToLocale(pair.key)"
  >
    <div class="ap-flex ap-gap-2 ap-items-center ap-min-w-[120px]">
      {{ pair.value }}
      <svg-icon
        *ngIf="pair.key === selectedLanguage.locale"
        src="assets/img/custom/check.svg"
        [applyClass]="true"
        class="ap-h-[20px] ap-w-[20px] ap-fill-primary"
      ></svg-icon>
    </div>
  </div>
  <a
    href="https://www.activepieces.com/docs/about/i18n"
    target="_blank"
    rel="noopener noreferrer"
    mat-menu-item
    *ngIf="showCommunity$ | async"
  >
    <div class="ap-flex ap-items-center ap-gap-2 ap-min-w-[140px]">
      Help us translate
      <svg-icon
        src="assets/img/custom/external-link.svg"
        [applyClass]="true"
        class="ap-h-[16px] ap-w-[16px] ap-fill-[#989898]"
      ></svg-icon>
    </div>
  </a>
</mat-menu>
