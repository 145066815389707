<div
  class="ap-flex ap-flex-col ap-w-[306px] ap-h-[274px] ap-bg-[#FCFCFD] ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-p-6"
>
  <ngx-skeleton-loader count="5" appearance="circle" />
  <ngx-skeleton-loader
    [theme]="{ height: '18px', width: '60px', marginTop: '24px' }"
  />
  <ngx-skeleton-loader [theme]="{ height: '18px', marginTop: '10px' }" />
  <ngx-skeleton-loader
    [theme]="{ height: '28px', width: '110px', marginTop: '40px' }"
  />
</div>
