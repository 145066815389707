import { AsyncPipe, CommonModule } from '@angular/common';
import { UiCommonModule } from '@upbrains/ui/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CustomFormComponent } from './page/custom-form/custom-form.component';
import { MainFormComponent } from './page/main-form/main-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ColumnDescription } from './page/custom-form/components/column-description.component';
import { ResizableDirective } from '@hhangular/resizable';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

const exportedComponents = [MainFormComponent, CustomFormComponent];
@NgModule({
  imports: [
    CommonModule,
    UiCommonModule,
    FormsModule, // Add FormsModule here
    ReactiveFormsModule, // Add ReactiveFormsModule if needed
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AsyncPipe,
    PdfViewerModule,
    ResizableDirective,
    NgxJsonViewerModule,
  ],

  declarations: [ColumnDescription, ...exportedComponents],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...exportedComponents],
})
export class FeatureFormsModule {}
