<div
  class="ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-w-full ap-max-w-[892px] ap-max-h-full ap-overflow-auto ap-p-6 ap-flex ap-flex-col ap-justify-center ap-items-center ap-mx-auto custom-agent-connection ap-mt-10 ap-mb-24"
>
  <ngx-skeleton-loader count="5" appearance="circle" />
  <ngx-skeleton-loader
    [theme]="{ height: '28px', width: '60px', marginTop: '16px' }"
  />
  <ngx-skeleton-loader
    [theme]="{ height: '18px', width: '200px', marginTop: '4px' }"
  />

  <div
    class="ap-mx-auto ap-flex ap-flex-row ap-flex-wrap ap-gap-3 ap-mt-6 ap-max-w-full scroll-container"
  >
    <ng-container *ngFor="let item of [1, 2, 3]">
      <div
        class="ap-bg-[#F9FAFB] ap-border ap-border-[#D0D5DD] ap-w-[270px] ap-h-[239px] ap-rounded-[20px] ap-p-6 ap-flex ap-flex-col ap-items-center ap-font-inter"
      >
        <ngx-skeleton-loader count="1" appearance="circle" />
        <ngx-skeleton-loader
          [theme]="{ height: '28px', width: '60px', marginTop: '16px' }"
        />
        <ngx-skeleton-loader
          [theme]="{ height: '38px', width: '150px', marginTop: '40px' }"
        />
      </div>
    </ng-container>
  </div>
</div>
