import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-onboarding-package',
  templateUrl: './onboarding-package.component.html',
  styleUrls: ['./onboarding-package.component.scss'],
})
export class OnboardingPackageComponent {
  @Input() title = '';
  @Input() price?: number;
  @Input() isRecommended?: boolean;
  @Input() features: string[] = [];
  @Input() onConfirm!: (title: string) => void;
}
