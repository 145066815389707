import { ActionType, TriggerType } from '@upbrains/shared';

export function getDisplayNameForTrigger(triggerType: TriggerType) {
  switch (triggerType) {
    case TriggerType.WEBHOOK: {
      return $localize`Webhook Trigger`;
      break;
    }
    case TriggerType.EMPTY: {
      return $localize`Empty Trigger`;
    }
  }
  return $localize`Trigger`;
}

export function getDefaultDisplayNameForAgent(
  agentType: ActionType,
  agentName: string
) {
  switch (agentType) {
    case ActionType.CODE: {
      return $localize`Code`;
    }
    case ActionType.LOOP_ON_ITEMS: {
      return $localize`Loop on Items`;
    }
    case ActionType.AGENT: {
      return agentName;
    }
    case ActionType.BRANCH: {
      return $localize`Binary Choice`;
    }
  }
}

export function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export function getLeftDaysOfSubscription(
  timestamp: number | null | undefined
): number {
  if (timestamp) {
    const targetDate: Date = new Date(timestamp * 1000);
    const currentDate: Date = new Date();
    const timeDifference: number = targetDate.getTime() - currentDate.getTime();
    let daysLeft: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysLeft > 0) {
      return daysLeft;
    }
    return 0;
  }
  return 0;
}
