import { Component, Input, OnInit } from '@angular/core';
import {
  ActionType,
  FlowVersion,
  FlowVersionTemplate,
  TriggerType,
  flowHelper,
} from '@upbrains/shared';
import { Observable, map, of, tap } from 'rxjs';
import {
  CORE_AGENTS_ACTIONS_NAMES,
  CORE_AGENTS_TRIGGERS,
  AgentMetadataService,
  coreAgentIconUrl,
} from '../services/agent-meta.service';

@Component({
  selector: 'ap-agents-icons-from-flow',
  templateUrl: './agents-icons-from-flow.component.html',
})
export class AgentsIconsFromFlowComponent implements OnInit {
  @Input({ required: true }) flowVersion!: FlowVersionTemplate | FlowVersion;
  numberOfStepsLeft = 0;
  loadedIcons: Record<number, boolean> = {};
  urlsToLoad$: Observable<string>[] = [];
  tooltipText = '';
  stepNamesMap: Record<string, string> = {};
  agentsMetadata$: Observable<string>[] = [];
  constructor(private actionMetaDataService: AgentMetadataService) { }
  ngOnInit(): void {
    const icons$ = this.extractIconUrlsAndTooltipText();
    this.loadIconUrls(Object.values(icons$));
  }
  extractIconUrlsAndTooltipText() {
    const steps = flowHelper.getAllSteps(this.flowVersion.trigger);
    const stepsIconsUrls: Record<string, Observable<string>> = {};
    steps.forEach((s) => {
      if (s.type === ActionType.AGENT || s.type === TriggerType.AGENT) {
        const agentMetaData$ = this.actionMetaDataService
          .getAgentMetadata(s.settings.agentName, s.settings.agentVersion)
          .pipe(
            tap((md) => {
              this.stepNamesMap[s.name] = md.displayName;
              this.extractTooltipText();
            }),
            map((md) => {
              if (
                CORE_AGENTS_ACTIONS_NAMES.find(
                  (n) => s.settings.agentName === n
                ) ||
                CORE_AGENTS_TRIGGERS.find((n) => s.settings.agentName === n)
              ) {
                return coreAgentIconUrl(s.settings.agentName);
              }
              return md.logoUrl;
            })
          );
        this.stepNamesMap[s.name] = '';
        stepsIconsUrls[s.settings.agentName] = agentMetaData$;
      } else if (s.type !== TriggerType.EMPTY) {
        const icon = this.actionMetaDataService.findNonAgentStepIcon(s.type);
        const displayName =
          [
            ...this.actionMetaDataService.coreFlowItemsDetails,
            ...this.actionMetaDataService.triggerItemsDetails,
          ].find((d) => d.type === s.type)?.name || '';
        this.stepNamesMap[s.name] = displayName;
        stepsIconsUrls[icon.key] = of(icon.url);
      }
    });
    this.extractTooltipText();
    return stepsIconsUrls;
  }

  loadIconUrls(urls$: Observable<string>[]) {
    this.numberOfStepsLeft = Math.min(urls$.length - 5, 9);
    this.urlsToLoad$ = urls$.slice(0, 5);
    this.agentsMetadata$ = urls$;
  }
  extractTooltipText() {
    const stepsAppsNames = Array.from(
      new Set(Object.values(this.stepNamesMap).filter((v) => v !== ''))
    );
    if (stepsAppsNames.length === 1) {
      this.tooltipText = stepsAppsNames[0];
    } else if (stepsAppsNames.length < 7) {
      this.tooltipText =
        stepsAppsNames.slice(0, stepsAppsNames.length - 1).join(', ') +
        ` and ${stepsAppsNames[stepsAppsNames.length - 1]}`;
    } else {
      this.tooltipText = stepsAppsNames.join(', ') + ' and others';
    }
  }
}
