import {
  AppConnectionValue,
  DelayPauseMetadata,
  ExecutionType,
  FlowId,
  FlowRunId,
  FlowVersion,
  FlowVersionId,
  PauseMetadata,
  ProjectId,
  ResumePayload,
  StopResponse,
  TriggerPayload,
  WebhookPauseMetadata,
} from '@upbrains/shared';
import {
  InputPropertyMap,
  AgentPropValueSchema,
  StaticPropsValue,
} from './property';
import { AgentAuthProperty } from './property/authentication';
import { TriggerStrategy } from './trigger/trigger';

type BaseContext<
  AgentAuth extends AgentAuthProperty,
  Props extends InputPropertyMap
> = {
  auth: AgentPropValueSchema<AgentAuth>;
  propsValue: StaticPropsValue<Props>;
  store: Store;
  project: {
    id: ProjectId;
    externalId: () => Promise<string | undefined>;
  };
};

type AppWebhookTriggerHookContext<
  AgentAuth extends AgentAuthProperty,
  TriggerProps extends InputPropertyMap
> = BaseContext<AgentAuth, TriggerProps> & {
  webhookUrl: string;
  payload: TriggerPayload;
  app: {
    createListeners({
      events,
      identifierValue,
    }: {
      events: string[];
      identifierValue: string;
    }): void;
  };
};

type PollingTriggerHookContext<
  AgentAuth extends AgentAuthProperty,
  TriggerProps extends InputPropertyMap
> = BaseContext<AgentAuth, TriggerProps> & {
  setSchedule(schedule: { cronExpression: string; timezone?: string }): void;
};

type WebhookTriggerHookContext<
  AgentAuth extends AgentAuthProperty,
  TriggerProps extends InputPropertyMap
> = BaseContext<AgentAuth, TriggerProps> & {
  webhookUrl: string;
  payload: TriggerPayload;
  flowVersion: FlowVersion;
  serverUrl: string;
  flowRunId: FlowRunId;
  workerToken: string;
};

export type TriggerHookContext<
  AgentAuth extends AgentAuthProperty,
  TriggerProps extends InputPropertyMap,
  S extends TriggerStrategy
> = S extends TriggerStrategy.APP_WEBHOOK
  ? AppWebhookTriggerHookContext<AgentAuth, TriggerProps>
  : S extends TriggerStrategy.POLLING
  ? PollingTriggerHookContext<AgentAuth, TriggerProps>
  : S extends TriggerStrategy.WEBHOOK
  ? WebhookTriggerHookContext<AgentAuth, TriggerProps>
  : never;

export type TestOrRunHookContext<
  AgentAuth extends AgentAuthProperty,
  TriggerProps extends InputPropertyMap,
  S extends TriggerStrategy
> = TriggerHookContext<AgentAuth, TriggerProps, S> & {
  files: FilesService;
};

export type StopHookParams = {
  response: StopResponse;
};

export type StopHook = (params: StopHookParams) => void;

export type PauseHookParams = {
  pauseMetadata: PauseMetadata;
};

export type PauseHook = (params: {
  pauseMetadata: DelayPauseMetadata | Omit<WebhookPauseMetadata, 'requestId'>
}) => void;

export type PropertyContext = {
  server: ServerContext;
  project: {
    id: ProjectId;
    externalId: () => Promise<string | undefined>;
  };
  flowId: FlowId
  flowRunId?: FlowRunId
};

export type ServerContext = {
  apiUrl: string;
  publicUrl: string;
  token: string;
  frontEndUrl: string;
};
export type BaseActionContext<
  ET extends ExecutionType,
  AgentAuth extends AgentAuthProperty,
  ActionProps extends InputPropertyMap
> = BaseContext<AgentAuth, ActionProps> & {
  executionType: ET;
  connections: ConnectionsManager;
  tags: TagsManager;
  server: ServerContext;
  files: FilesService;
  serverUrl: string;
  flow: {
    id: FlowId
  }
  flowVersion: {
    id: FlowVersionId
  }
  run: {
    id: FlowRunId;
    stop: StopHook;
    pause: PauseHook;
  };
  generateResumeUrl: (params: {
    queryParams: Record<string, string>
  }) => string;
};

type BeginExecutionActionContext<
  AgentAuth extends AgentAuthProperty = AgentAuthProperty,
  ActionProps extends InputPropertyMap = InputPropertyMap
> = BaseActionContext<ExecutionType.BEGIN, AgentAuth, ActionProps>;

type ResumeExecutionActionContext<
  AgentAuth extends AgentAuthProperty = AgentAuthProperty,
  ActionProps extends InputPropertyMap = InputPropertyMap
> = BaseActionContext<ExecutionType.RESUME, AgentAuth, ActionProps> & {
  resumePayload: ResumePayload;
};

export type ActionContext<
  AgentAuth extends AgentAuthProperty = AgentAuthProperty,
  ActionProps extends InputPropertyMap = InputPropertyMap
> =
  | BeginExecutionActionContext<AgentAuth, ActionProps>
  | ResumeExecutionActionContext<AgentAuth, ActionProps>;

export interface FilesService {
  write({
    fileName,
    data,
    url,
    header,
    customType
  }: {
    fileName: string;
    data: Buffer;
    url?: string;
    header?: string;
    customType?: string;
  }): Promise<string>;
}

export interface ConnectionsManager {
  get(
    key: string
  ): Promise<AppConnectionValue | Record<string, unknown> | string | null>;
}

export interface TagsManager {
  add(params: { name: string }): Promise<void>;
}

export interface Store {
  put<T>(key: string, value: T, scope?: StoreScope): Promise<T>;
  get<T>(key: string, scope?: StoreScope): Promise<T | null>;
  delete(key: string, scope?: StoreScope): Promise<void>;
}

export enum StoreScope {
  // Collection were deprecated in favor of project
  PROJECT = 'COLLECTION',
  FLOW = 'FLOW',
}
