<div class="code-editor ap-h-full ap-flex ap-flex-col">
  <div class="ap-gap-2 ap-bg-header ap-p-3 ap-typography-headline-6 ap-flex ap-text-body  ap-items-center">
    <span i18n>Code Editor</span>

    <ap-button btnStyle="stroked" btnSize="small" (buttonClicked)="state.openCodeWriterDialog$.emit()"
      [disabled]="(generateCodeEnabled$ | async) === false"
      [tooltipText]="(generateCodeEnabled$ | async) ? codeGeneratorTooltip: disabledCodeGeneratorTooltip"
      i18n-tooltipText>
      <div class="ap-flex ap-gap-3 ap-items-center" i18n>
        <svg-icon src="assets/img/custom/AI.svg" class="ap-w-[20px] ap-h-[20px] ap-fill-primary"
          [applyClass]="true"></svg-icon>
        Ask AI
      </div>
    </ap-button>

    <div class="ap-flex-grow"></div>

    <ap-button *ngIf="!readOnly" btnStyle="basic" (buttonClicked)="openNpmPackageModal()" btnSize="small" i18n>
      Add npm package
    </ap-button>
    <ap-button [loading]="testLoading" btnStyle="flat" class="ap-ml-1" btnSize="small" (buttonClicked)="testCode()"
      i18n>Test code
    </ap-button>
    <ap-icon-button (click)="hide()" iconFilename="minimize.svg" i18n-matTooltip
      matTooltip="Minimize Editor"></ap-icon-button>
  </div>


  <div class="modal-body ap-flex-grow">
    <div class="ap-flex code-height-auto code-container">
      <div class="ap-p-2">
        <div class="ap-p-2">
          <div class="ap-flex ap-flex-col">
            <div class="ap-p-2 ap-items-center ap-flex flex-row file-container"
              [class.file-active]="SelectedFileInFullscreenCodeEditor.CONTENT === selectedFile"
              (click)="selectFile(SelectedFileInFullscreenCodeEditor.CONTENT)">
              <div>
                <svg-icon class="code-file-icon" [svgStyle]="{width:'16px' , height:'16px'}"
                  src="/assets/img/custom/files/js.svg">
                </svg-icon>
              </div>
              <div class="ap-ml-2">index.ts</div>
            </div>

            <div class="ap-p-2 ap-items-center ap-flex flex-row file-container ap-mt-2"
              [class.file-active]="SelectedFileInFullscreenCodeEditor.PACKAGE === selectedFile"
              (click)="selectFile(SelectedFileInFullscreenCodeEditor.PACKAGE)">
              <div>
                <svg-icon class="code-file-icon" [svgStyle]="{width:'16px' , height:'16px'}"
                  src="/assets/img/custom/files/package.svg">
                </svg-icon>
              </div>
              <div class="ap-ml-2">package.json</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ap-p-2 ap-flex-grow overflow-auto">
        <div class="ap-h-full">
          <!-- Use ngModel to update the editor outside the modal -->
          <ngx-monaco-editor (onInit)="focusEditor($event)" class="!ap-h-full"
            *ngIf="selectedFile === SelectedFileInFullscreenCodeEditor.CONTENT" #codeEditor
            [options]="codeEditorOptions" [ngModel]="codeFilesForm.controls.code.value"
            (ngModelChange)="codeFilesForm.controls.code.setValue($event)">

          </ngx-monaco-editor>


          <ngx-monaco-editor (onInit)="focusEditor($event)" class="!ap-h-full"
            *ngIf="selectedFile ===  SelectedFileInFullscreenCodeEditor.PACKAGE"
            [ngModel]="codeFilesForm.controls.packageJson.value"
            (ngModelChange)="codeFilesForm.controls.packageJson.setValue($event)"
            [options]="packageDotJsonOptions"></ngx-monaco-editor>
        </div>

      </div>
      <div class="ap-p-2 console-container ap-min-w-[18.75rem]">
        <div class="console-size code-height-auto ap-h-[98%]">
          <div class="ap-flex ap-flex-col ap-h-full">

            <mat-tab-group
              (selectedIndexChange)="$event === 0? selectTab(SelectedTabInFullscreenCodeEditor.OUTPUT): selectTab(SelectedTabInFullscreenCodeEditor.CONSOLE);  "
              dynamicHeight="true" animationDuration="0" class="!ap-typography-caption">
              <mat-tab>
                <ng-template mat-tab-label>
                  <div i18n class="ap-typography-caption">
                    Output
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div i18n class="ap-typography-caption">
                    Console
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>

            <div class="ap-mt-2 ap-flex-grow ap-relative">
              <form [formGroup]="testResultForm" class="ap-h-full">
                <div class="ap-absolute ap-top-2 ap-right-2 ap-z-40">
                  <ap-icon-button iconFilename="copy.svg" tooltipText="Copy"
                    *ngIf="testResultForm.get('outputResult')?.value" extraClasses="ap-fill-white"
                    (click)="selectedTab === SelectedTabInFullscreenCodeEditor.OUTPUT ? copyToClipboard(testResultForm.get('outputResult')?.value) : copyToClipboard(testResultForm.get('consoleResult')?.value)">
                  </ap-icon-button>
                </div>



                <ngx-codemirror *ngIf="selectedTab === SelectedTabInFullscreenCodeEditor.OUTPUT" class="ap-h-full"
                  formControlName="outputResult" [options]="outputResultEditorOptions"></ngx-codemirror>
                <ngx-codemirror *ngIf="selectedTab === SelectedTabInFullscreenCodeEditor.CONSOLE"
                  formControlName="consoleResult" [options]="consoleResultEditoroptions"
                  class="ap-h-full"></ngx-codemirror>
              </form>
              <div class="test-code-loading-container" *ngIf="testLoading">
                <div>
                  <ap-loading-icon [whiteLoader]="true"> </ap-loading-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="addPackageDialogClosed$ | async"></ng-container>
<ng-container *ngIf="executeCodeTest$ | async"></ng-container>