<div
  class="ap-border ap-border-[#E4E7EC] ap-bg-[#F9FAFB] ap-w-[186px] ap-h-[186px] ap-rounded-[20px] ap-p-6 ap-flex ap-flex-col ap-justify-center ap-items-center ap-cursor-pointer"
  [ngClass]="{ '!ap-border-[#175CD3] !ap-bg-[#EFF8FF]': agent.isSelected }"
  (click)="handleSelectionInbox()"
>
  <div class="ap-w-[48px] ap-h-[48px]">
    <img
      class="ap-flex ap-justify-center ap-items-center ap-w-full ap-h-full"
      [src]="agent.logoUrl"
      alt="{{ agent.displayName }} icon"
    />
  </div>

  <div
    class="ap-font-inter ap-font-semibold ap-text-xl ap-text-[#344054] ap-text-center"
  >
    {{ agent.displayName }}
  </div>

  <ng-container *ngIf="agent.connected">
    <div
      class="ap-font-inter ap-font-normal ap-text-sm ap-text-[#00AA52] ap-text-center ap-flex ap-flex-row ap-gap-2 ap-items-center ap-justify-center ap-mt-auto"
    >
      <div class="ap-bg-[#ECFDF3] ap-w-5 ap-h-5 ap-rounded-full"></div>
      Connected
    </div>
  </ng-container>
</div>

<div class="ap-hidden">
  <app-add-edit-connection-button
    *ngIf="agent"
    #addConnectionBtn
    btnSize="extraSmall"
    [isEditConnectionButton]="false"
    [authProperty]="agent.auth!"
    [agentName]="agent.name"
    [agentVersion]="agent.version"
    [propertyKey]="''"
    [agentDisplayName]="agent.displayName"
  ></app-add-edit-connection-button>
</div>
