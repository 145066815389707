<div
  class="ap-w-full ap-h-[500px] ap-overflow-x-hidden ap-overflow-y-auto ap-mt-3 ap-rounded-md"
>
  <div class="ap-relative pdf-height">
    <pdf-viewer
      [src]="pdfUrl"
      [render-text]="true"
      [original-size]="true"
      [fit-to-page]="true"
      [page]="currentPage"
      class="ap-w-full ap-block pdf-height"
      (after-load-complete)="onLoadComplete($event)"
      (error)="onError($event)"
    ></pdf-viewer>
    <div
      *ngIf="isError"
      class="ap-absolute ap-top-4 ap-left-1/2 ap-transform -ap-translate-x-1/2 -ap-translate-y-1/2 ap-text-red-500"
    >
      There was a problem in loading the PDF/Image
    </div>
    <div
      *ngIf="isLoading"
      class="ap-w-full ap-h-[50%] ap-absolute ap-flex ap-justify-center ap-items-center ap-top-7"
    >
      <ap-loading-icon
        height="50px"
        width="51px"
        [outlineLoader]="false"
        [whiteLoader]="false"
      >
      </ap-loading-icon>
    </div>
  </div>

  <div class="ap-flex ap-flex-row ap-items-center ap-justify-center ap-mt-3">
    <ap-icon-button
      [iconFilename]="'chevron-right-custom.svg'"
      (buttonClicked)="prevPage()"
      [buttonDisabled]="currentPage === 1"
    ></ap-icon-button>
    <span>{{ currentPage }} of {{ totalPages }}</span>
    <ap-icon-button
      [iconFilename]="'chevron-left-custom.svg'"
      (buttonClicked)="nextPage()"
      [buttonDisabled]="currentPage === totalPages"
    ></ap-icon-button>
  </div>
</div>
