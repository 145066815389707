<div
  class="box-shadow ap-p-8 ap-flex-1 ap-overflow-auto ap-rounded ap-shadow-step-container-ds ap-bg-white ap-w-full ap-h-full ap-m-auto"
>
  <div class="ap-flex ap-flex-row ap-items-center ap-justify-end ap-mb-2">
    <ap-icon-button
      [iconFilename]="'close.svg'"
      (click)="onClose()"
    ></ap-icon-button>
  </div>

  <div class="ap-w-full pdf-height ap-flex ap-flex-row">
    <div
      class="ap-max-w-[300px] ap-min-w-[300px] ap-overflow-y-scroll ap-pb-3"
      *ngIf="convertedFieldsObj.length"
    >
      <ul *ngFor="let field of convertedFieldsObj; index as i">
        <li *ngIf="field.index === 1 && field.itemNumber">
          <p class="ap-border-t ap-border-b ap-mt-3 ap-mb-1">
            Item {{ field.itemNumber }}
          </p>
        </li>
        <li *ngIf="!field.itemNumber && i === 0">
          <p class="ap-border-t ap-border-b ap-mt-3 ap-mb-1">Fields</p>
        </li>
        <li>
          <strong class="ap-text-sm">{{ field.key }}</strong
          >:
          <span [ngClass]="field.colorClass" class="highlight ap-text-sm">
            {{ field.value }}
          </span>
        </li>
      </ul>
    </div>
    <div *ngFor="let item of formPages" class="ap-overflow-auto">
      <pre
        [innerHTML]="sanitizeHtml(item.text)"
        class="ap-px-2 ap-text-sm ap-font-normal"
      ></pre>
    </div>
  </div>
</div>
