import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../environments/environment';
import { RemoteFilesResultType, RemoteFilesType } from '@upbrains/shared';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient, private auth: AuthenticationService) {}

  getRemoteFile({ url }: { url: string }): Observable<string> {
    let rawUrl: string = url;
    if (rawUrl.includes('signedUrl')) {
      const parsedUrl = JSON.parse(url);
      rawUrl = parsedUrl.signedUrl;
    }

    const token = this.auth.getToken();

    const formData = new FormData();
    formData.append('fileName', '');
    formData.append('url', rawUrl);
    formData.append('header', JSON.stringify({}));

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .post<{ url: string }>(`${environment.apiUrl}/remote-files`, formData, {
        headers,
      })
      .pipe(
        // Transform the response to return only the `url` string
        map((response) => response.url),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error?.message || 'Failed to store entry';
          return throwError(() => new Error(errorMessage));
        })
      );
  }

  postSignedWithType(
    payload: RemoteFilesType
  ): Observable<RemoteFilesResultType> {
    let rawUrl: string = payload.url;
    if (rawUrl.includes('signedUrl')) {
      const parsedUrl = JSON.parse(payload.url);
      rawUrl = parsedUrl.signedUrl;
    }

    const token = this.auth.getToken();

    const formData = new FormData();
    formData.append('fileName', payload.fileName);
    formData.append('url', rawUrl);
    formData.append('header', payload.header);
    formData.append('projectId', payload.projectId);
    formData.append('type', payload.type);

    if (payload.agentName) {
      formData.append('agentName', payload.agentName);
    }
    if (payload.connectionName) {
      formData.append('connectionName', payload.connectionName);
    }
    if (payload.accessToken) {
      formData.append('accessToken', payload.accessToken);
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .post<RemoteFilesResultType>(
        `${environment.apiUrl}/remote-files`,
        formData,
        { headers }
      )
      .pipe(
        // Transform the response to return only the `url` string
        map((response) => response),
        catchError((error: HttpErrorResponse) => {
          const errorMessage =
            error.error?.message ||
            'Failed to post remote files signed with type';
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
