import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FullXtractURL,
  PostPromptTemplateAPIType,
  PostPromptTemplatePayloadType,
  PromptExecutorResponse,
  PromptExecutorPayloadType,
  PromptTemplate,
} from '@upbrains/shared';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private snackbar: MatSnackBar
  ) {}

  getPromptTemplate(): Observable<PromptTemplate | null> {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
        'Content-Type': 'application/json',
      });

      return this.http
        .get<PromptTemplate>(
          `${FullXtractURL}/integration-apis/prompt-template`,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            // Log specific error details for better troubleshooting
            console.error('Failed to fetch user info:', {
              message: error.message,
              status: error.status,
              url: error.url,
            });

            // Displaying a more user-friendly message
            this.snackbar.open(
              'Unable to load user info. Please try again later.',
              'Dismiss',
              { duration: 5000 }
            );

            return of(null);
          })
        );
    }

    return of(null);
  }

  postPromptExecutor(
    payload: PromptExecutorPayloadType
  ): Observable<PromptExecutorResponse | null> {
    const token = this.auth.getDecodedIgniteToken();
    const formData = new FormData();
    formData.append('prompt_name', JSON.stringify(payload.prompt_name));
    formData.append('input_values', JSON.stringify(payload.input_values));

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
      });

      return this.http
        .post<PromptExecutorResponse>(
          `${FullXtractURL}/integration-apis/prompt-executor`,
          formData,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('failed to post prompt executor:', {
              message: error.message,
              status: error.status,
              url: error.url,
            });

            this.snackbar.open(
              'Unable to send the prompt executor. Please try again later.',
              'Dismiss',
              { duration: 5000 }
            );

            return of(null);
          })
        );
    }

    return of(null);
  }

  postPromptTemplate(
    payload: PostPromptTemplatePayloadType
  ): Observable<PostPromptTemplateAPIType | null> {
    const token = this.auth.getDecodedIgniteToken();

    if (token) {
      const headers = new HttpHeaders({
        Authorization: token,
      });

      return this.http
        .post<PostPromptTemplateAPIType>(
          `${environment.igniteUrl}/integration-apis/prompt-template`,
          payload,
          {
            headers,
          }
        )
        .pipe(
          catchError((error) => {
            console.error('failed to post prompt template:', {
              message: error.message,
              status: error.status,
              url: error.url,
            });

            this.snackbar.open(
              'Unable to send the prompt template. Please try again later.',
              'Dismiss',
              { duration: 5000 }
            );

            return of(null);
          })
        );
    }

    return of(null);
  }
}
