<div class="ap-w-full ap-flex ap-flex-col declare-steps ap-mt-[72px] ap-mb-24">
  <div
    class="ap-flex ap-flex-wrap ap-gap-[17px] ap-justify-center ap-items-center"
  >
    <ap-step-card
      stepNum="1"
      description="Tell us about yourself"
      stepIcon="assets/img/NewLayout/onboarding/buildings.svg"
    />

    <ap-step-card
      stepNum="2"
      description="Select & configure your Agents"
      stepIcon="assets/img/NewLayout/onboarding/ai.svg"
    />

    <ap-step-card
      stepNum="3"
      description="Deploy and Relax"
      stepIcon="assets/img/NewLayout/onboarding/gear.svg"
    />
  </div>

  <div class="ap-w-[230px] ap-mt-7 ap-mx-auto">
    <ap-button
      actionButton
      btnSize="large"
      i18n
      class="ap-rounded-lg"
      [fullWidthOfContainer]="true"
      (buttonClicked)="onConfirm()"
    >
      <div
        class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
      >
        Let's Get Started
      </div>
    </ap-button>
  </div>
</div>
