<div class="ap-w-full ap-h-full ap-px-[30px] ap-pt-[50px]">
  <div class="ap-max-h-[calc(100vh-110px)] ap-overflow-y-auto">
    <ap-page-title title="My Agents" i18n-title>
      <p
        underTitle
        class="ap-text-description ap-typography-body-2 ap ap-mt-2"
        i18n
      >
        This is where you can install agents made by yourself or contributors.
      </p>
    </ap-page-title>
    <div class="ap-h-full ap-overflow-y-auto">
      <table
        mat-table
        [dataSource]="dataSource"
        class="ap-w-[100%]"
        aria-label="Sessions"
        i18n-aria-label
      >
        <ng-container matColumnDef="app">
          <th mat-header-cell *matHeaderCellDef i18n>App</th>
          <td mat-cell *matCellDef="let agent">
            <img
              appImgFallback="/assets/img/custom/generic_code.svg "
              class="ap-w-[24px] ap-h-[24px] ap-object-contain"
              [matTooltip]="agent.appName"
              [src]="agent.logoUrl"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Name</th>
          <td mat-cell *matCellDef="let agent">{{ agent.name }}</td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef i18n>Version</th>
          <td mat-cell *matCellDef="let agent">{{ agent.version }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="!ap-text-center">
            <ap-button
              btnColor="primary"
              btnSize="small"
              (click)="installAgent()"
              i18n
            >
              Add Agent
            </ap-button>
          </th>
          <td mat-cell *matCellDef="let agent" class="!ap-text-center">
            <ap-icon-button
              tooltipText="Delete Agent"
              iconFilename="trash.svg"
              color="warn"
              [width]="20"
              [height]="20"
              [height]="16"
              (buttonClicked)="deleteMetadata(agent)"
            ></ap-icon-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [class.ap-hidden]="dataSource.isLoading$ | async"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <ng-container *ngIf="(dataSource.isLoading$ | async) === true">
        <div
          class="ap-flex ap-items-center ap-justify-center ap-flex-grow ap-h-[500px]"
        >
          <ap-loading-icon
            height="50px"
            width="51px"
            [outlineLoader]="true"
            [whiteLoader]="false"
          >
          </ap-loading-icon>
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          dataSource.data.length === 0 &&
          (dataSource.isLoading$ | async) === false
        "
      >
        <div
          class="ap-flex ap-items-center ap-justify-center ap-h-full ap-h-[500px]"
          i18n
        >
          No agents installed
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-container *ngIf="addPackageDialogClosed$ | async"></ng-container>
<ng-container *ngIf="deleteDialogClosed$ | async"></ng-container>
