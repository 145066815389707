import { Component, Input, OnInit } from '@angular/core';

type Option = {
  label: string;
  value: number;
  checked: boolean;
};

@Component({
  selector: 'app-what-to-do',
  templateUrl: './what-to-do.component.html',
  styleUrls: ['./what-to-do.component.scss'],
})
export class WhatToDOComponent implements OnInit {
  @Input() onNextStep!: () => void;
  @Input() onPrevStep!: () => void;

  listOfOptions: Option[] = [];
  showError = false;

  ngOnInit() {
    this.listOfOptions = [
      { label: 'Automate order to cash process', value: 0, checked: false },
      { label: 'Automate procure to pay process', value: 1, checked: false },
      {
        label: 'Automate quality and compliance workflows',
        value: 2,
        checked: false,
      },
      {
        label: 'Automate email responses in team inbox',
        value: 3,
        checked: false,
      },
      { label: 'Automate document OCR workflows', value: 4, checked: false },
      {
        label: 'Automate Client Onboarding (Sales and/or Data Gathering)',
        value: 5,
        checked: false,
      },
      { label: 'Create custom AI agent', value: 6, checked: false },
      {
        label: 'Automate custom workflows',
        value: 7,
        checked: false,
      },
    ];
  }

  onConfirm() {
    const selectedOptions = this.listOfOptions.filter(
      (option) => option.checked
    );

    if (selectedOptions.length === 0) {
      this.showError = true;
      return;
    }

    this.showError = false;
    const emailHtml = `
    <h2>What do you want to automate?</h2>
          <p>Here are the automation processes selected:</p>
          <ul>
            ${selectedOptions
              .map((option) => `<li>${option.label}</li>`)
              .join('')}
          </ul>`;

    const existingEmailData = localStorage.getItem('emailData') || '';
    const updatedEmailHtml = existingEmailData + emailHtml;
    localStorage.setItem('emailData', updatedEmailHtml);

    this.onNextStep();
  }
}
