import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuItems } from '@upbrains/shared';

@Component({
  selector: 'side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent implements OnInit {
  @Input() sideMenuItem: SideMenuItems;
  @Input() isCollapsed: boolean = false;
  @Input() isActive: boolean = false;
  @Output() onSelectItem = new EventEmitter<void>();
  expandedItems: { [key: string]: boolean } = {};

  constructor(private router: Router) {}

  ngOnInit() {
    this.expandedItems['nestedItem'] = this.isActive;
  }

  toggleItemExpand(itemKey: string): void {
    this.expandedItems[itemKey] = !this.expandedItems[itemKey];
  }

  navigateTo(route: string): void {
    if (route) {
      this.onSelectItem.emit();
      this.router.navigate([route]);
    }
  }

  get fullPath(): string {
    return this.router.url;
  }
}
