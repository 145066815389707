import { createSelector } from '@ngrx/store';
import { selectCommonState } from '../common.selector';
import { CommonStateModel } from '../common-state.model';
import { SubscriptionPlansState } from './subscription-plan-state.model';

const selectSubscriptionPlansState = createSelector(
  selectCommonState,
  (state: CommonStateModel): SubscriptionPlansState => {
    return state.subscriptionPlanState;
  }
);

const selectSubscriptionPlan = createSelector(
  selectSubscriptionPlansState,
  (state: SubscriptionPlansState) => {
    return state.subscriptionPlan;
  }
);

export const SubscriptionPlansSelectors = {
  selectSubscriptionPlan,
};
