<div class="ap-h-[100vh] ap-overflow-auto">
  <ng-container *ngIf="!error && formProps">
    <ng-container
      *ngIf="
        formType === 'form_submission' ||
        formType === 'file_submission' ||
        formType === 'form-builder'
      "
    >
      <lib-main-form
        [form]="form"
        [title]="title"
        [inputs]="inputs"
        (formSubmit)="submit()"
        [submitAction]="submitAction"
        [markdownResponse]="markdownResponse"
        [loading]="loading"
        [hasApprovalOrDisapproval]="hasApprovalOrDisapproval"
        [approvalButtons]="approvalButtons"
        [showSubmitButton]="showSubmitButton"
        [selectedRun$]="selectedRun$"
      ></lib-main-form>
    </ng-container>
    <ng-container
      *ngIf="
        formType === 'rfq_form' ||
        formType === 'document_review' ||
        formType === 'order_entry'
      "
    >
      <app-custom-forms
        [selectedRun$]="selectedRun$"
        [staticFormsData]="staticFormsData"
        [title]="title"
        [continueUrl]="continueUrl"
        [formType]="formType"
      ></app-custom-forms>
    </ng-container>
  </ng-container>

  <!-- Error Display -->
  <ng-container *ngIf="error">
    {{ error }}
  </ng-container>
</div>

<ng-container *ngIf="flow$ | async as flow"></ng-container>
<ng-container *ngIf="submitForm$ | async"></ng-container>
