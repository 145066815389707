import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  SubscriptionCheckRequest,
  SubscriptionPlansType,
} from '@upbrains/shared';
import { map, Observable } from 'rxjs';
import { setSubscriptionPlan, UpbrainsUserSelectors } from '../../store';
import { Router } from '@angular/router';
import { getLeftDaysOfSubscription } from '../../utils/utils';

@Component({
  selector: 'ap-subscription-banner',
  templateUrl: './subscription-banner.html',
  styleUrls: ['./subscription-banner.scss'],
})
export class SubscriptionBanner {
  subscription$: Observable<SubscriptionCheckRequest | undefined | null>;
  planName: string | undefined | null = '';
  planDuration: number | undefined | null = 0;
  upgradeContent: string = '';
  subscriptionId: string | undefined | null;
  credits: number | undefined | null = 0;
  userSeats: number | undefined | null = 1;

  constructor(private store: Store, public router: Router) {
    this.subscription$ = this.store
      .select(UpbrainsUserSelectors.selectUpbrainsUserSubscriptionCheck)
      .pipe(
        map((subscriptionCheck) => {
          if (subscriptionCheck?.status === 200) {
            subscriptionCheck?.user_subscriptions
              ?.filter(
                ({ subscription_status }) =>
                  subscription_status === 'active' ||
                  subscription_status?.toLocaleLowerCase()?.includes('trial')
              )
              ?.map(
                ({
                  subscription_renewal_date_timestamp,
                  plan_name,
                  subscription_status,
                  subscription_quantity,
                  stripe_subscription_id,
                  additional_credits,
                }) => {
                  const daysLeft = getLeftDaysOfSubscription(
                    subscription_renewal_date_timestamp
                  );

                  if (
                    subscription_status?.toLocaleLowerCase()?.includes('trial')
                  ) {
                    this.upgradeContent = 'Upgrade';
                    this.planName = 'Free Trial Plan';
                  } else if (daysLeft > 0) {
                    this.planName = `${plan_name} Plan`;
                    this.upgradeContent = 'Upgrade';
                  } else {
                    this.planName = 'No Subscription';
                    this.upgradeContent = 'Upgrade';
                  }

                  this.planDuration = daysLeft;
                  this.subscriptionId = stripe_subscription_id;
                  this.credits = subscription_quantity;
                  this.userSeats = additional_credits;
                }
              );
          } else if (subscriptionCheck?.status === 401) {
            this.planName = 'No Subscription';
            this.upgradeContent = 'Get Started';
          }

          const subscriptionPlan: Partial<SubscriptionPlansType> = {
            subscription_period: this.planDuration,
            subscription_id: this.subscriptionId,
            subscription_quantity: this.userSeats,
            subscription_credit: this.credits,
            plan_name: this.planName?.replace(' Plan', ''),
          };

          this.store.dispatch(setSubscriptionPlan({ subscriptionPlan }));
          return subscriptionCheck;
        })
      );
  }

  handleSubscription() {
    this.router.navigate(['/subscriptions']);
  }

  handleUpgrade(event: any) {
    event.stopPropagation();
    this.router.navigate([`/subscriptions/plans/${this.planName}`]);
  }
}
