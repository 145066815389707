import { Static, Type } from '@sinclair/typebox'

export const UpsertRemoteFile = Type.Object({
    fileName: Type.String(),
    url: Type.String(),
    header: Type.String(),
})

export type UpsertRemoteFile = Static<typeof UpsertRemoteFile>


export const RemoteFileGet = Type.Object({
    fileName: Type.String(),
    url: Type.String(),
    header: Type.Record(Type.String(), Type.Any()),
    data: Type.Unknown(),
})

export type RemoteFileGet = Static<typeof RemoteFileGet>

export const RemoteFileWithUrl = Type.Composite([UpsertRemoteFile, Type.Object({
    url: Type.String(),
})])

export type RemoteFileWithUrl = Static<typeof RemoteFileWithUrl>
