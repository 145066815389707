import { ActionType, TriggerType } from '@upbrains/shared';

export function getDisplayNameForTrigger(triggerType: TriggerType) {
  switch (triggerType) {
    case TriggerType.WEBHOOK: {
      return $localize`Webhook Trigger`;
      break;
    }
    case TriggerType.EMPTY: {
      return $localize`Empty Trigger`;
    }
  }
  return $localize`Trigger`;
}

export function getDefaultDisplayNameForAgent(
  agentType: ActionType,
  agentName: string
) {
  switch (agentType) {
    case ActionType.CODE: {
      return $localize`Code`;
    }
    case ActionType.LOOP_ON_ITEMS: {
      return $localize`Loop on Items`;
    }
    case ActionType.AGENT: {
      return agentName;
    }
    case ActionType.BRANCH: {
      return $localize`Binary Choice`;
    }
    case ActionType.ROUTER: {
      return $localize`Router`;
    }
  }
}

export function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export function getLeftDaysOfSubscription(
  timestamp: number | null | undefined
): number {
  if (timestamp) {
    const targetDate: Date = new Date(timestamp * 1000);
    const currentDate: Date = new Date();
    const timeDifference: number = targetDate.getTime() - currentDate.getTime();
    let daysLeft: number = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysLeft > 0) {
      return daysLeft;
    }
    return 0;
  }
  return 0;
}

export function base64ToFile(
  base64String: string,
  filename: string
): { file: File; fileURL: string } {
  let rawBase64Code = base64String;

  // Detect and remove the MIME prefix
  const mimePrefixMatch = base64String.match(/^data:(.*?);base64,/);
  if (mimePrefixMatch) {
    rawBase64Code = base64String.replace(mimePrefixMatch[0], '');
  }

  try {
    // Validate base64 string before decoding
    if (!isValidBase64(rawBase64Code)) {
      throw new Error('Invalid base64 string');
    }

    const byteCharacters = atob(rawBase64Code);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], filename, {
      type: 'application/octet-stream',
    });

    const fileURL = URL.createObjectURL(file);

    return { file, fileURL };
  } catch (error) {
    console.error('Error decoding base64 string:', error);
    throw error;
  }
}

// Helper function to validate base64
function isValidBase64(str: string): boolean {
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
  return base64Regex.test(str);
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const result = reader.result as string;

      // Ensure the MIME type prefix is included
      if (result.startsWith('data:')) {
        resolve(result);
      } else {
        reject(new Error('Invalid FileReader result'));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file); // Ensures MIME type is included
  });
}

export function formatTimestamp(timestamp: number): string {
  return new Date(timestamp * 1000)
    .toLocaleString('en-GB', {
      weekday: 'short', // EEE
      day: '2-digit', // dd
      month: '2-digit', // MM
      year: '2-digit', // yy
      hour: '2-digit', // HH
      minute: '2-digit', // mm
    })
    .replace(',', '');
}
