<div [class.has-mention]="hasMention">
  <quill-editor #quill="elementDirective" apElementDirective
    [class.editor-allows-only-one-mention-as-value]="readOnly && !disabled" [formControl]="editorFormControl"
    [style]="{width:'100%'}" format="object" [modules]="modules" [readOnly]="readOnly" [preserveWhitespace]="true"
    trackChanges="all" (onBlur)="onBlur()" (click)="focusEditor()"
    [placeholder]="!insideMatField || shouldLabelFloat? placeholder: ''" (onFocus)="onFocus()"
    (onEditorCreated)="editorCreated()">
  </quill-editor>
</div>

<ng-container *ngIf="valueChanges$|async"></ng-container>