import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  Platform,
  PromptExecutorPayloadType,
  PromptExecutorResponse,
} from '@upbrains/shared';
import {
  AuthenticationService,
  onboardingActions,
  OnboardingService,
  PlatformService,
} from '@upbrains/ui/common';
import { catchError, map, Observable, of, tap } from 'rxjs';

@Component({
  selector: 'app-company-url',
  templateUrl: './company-url.component.html',
  styleUrls: ['./company-url.component.scss'],
})
export class CompanyUrlComponent implements OnInit, AfterViewInit {
  @Input() onNextStep: () => void = () => null;
  @ViewChild('companyUrlInput') companyUrlInput!: ElementRef;

  form: FormGroup<{
    companyUrl: FormControl<string | null>;
  }>;
  isLoading = false;
  platformName: string | null = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private platformService: PlatformService,
    private onboardingService: OnboardingService,
    private store: Store,
    private snackbar: MatSnackBar
  ) {
    this.form = this.fb.group({
      companyUrl: new FormControl<string | null>('', {
        validators: [Validators.required, this.urlPatternValidator],
      }),
    });
  }

  ngOnInit() {
    this.getPlatformName().subscribe();
  }

  ngAfterViewInit(): void {
    this.companyUrlInput.nativeElement.focus();
  }

  onNextButtonClick() {
    this.form.markAllAsTouched();

    if (this.form.valid && this.form.controls.companyUrl.value) {
      this.isLoading = true;
      this.router.navigate([], { queryParams: { isManual: false } });

      const payload: PromptExecutorPayloadType = {
        prompt_name: 'Business Profile',
        input_values: {
          business_url: this.form.controls.companyUrl.value,
          business_name: this.platformName ?? '',
        },
      };

      this.onboardingService
        .postPromptExecutor(payload)
        .pipe(
          tap((response: PromptExecutorResponse | null) => {
            if (response && response.status === 200) {
              this.store.dispatch(
                onboardingActions.setBusinessData({ businessData: response })
              );
              this.isLoading = false;
              this.onNextStep();
            } else {
              this.isLoading = false;
              this.onManualButtonClick();
            }
          }),
          catchError((err) => {
            this.isLoading = false;
            console.error('Error posting data:', err);
            this.snackbar.open('There was an error to sending data', 'Close', {
              duration: 3000,
            });
            this.onManualButtonClick();
            return of(null);
          })
        )
        .subscribe();
    }
  }

  async onManualButtonClick() {
    this.router.navigate([], { queryParams: { isManual: true } });
    setTimeout(() => this.onNextStep());
  }

  private urlPatternValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const url = control.value;

    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

    if (url) {
      const isValid = urlPattern.test(url);
      return isValid ? null : { invalidUrl: true };
    } else {
      return null;
    }
  }

  private getPlatformName(): Observable<Platform | null> {
    const { platformId } = this.authenticationService.currentUser;
    if (!platformId) {
      this.platformName = null;
      this.store.dispatch(
        onboardingActions.setBusinessName({ businessName: this.platformName })
      );
      return of(null);
    }

    return this.platformService.getPlatform(platformId).pipe(
      map((platform) => {
        this.platformName = platform.name ?? null;
        this.store.dispatch(
          onboardingActions.setBusinessName({ businessName: this.platformName })
        );
        return platform ?? null;
      })
    );
  }
}
