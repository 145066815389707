import {
  Component,
  Input,
  ViewChild,
  ViewContainerRef,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AuthenticationService,
  EachSliderStep,
  StepSliderConfig,
} from '@upbrains/ui/common';

@Component({
  selector: 'app-step-slider',
  templateUrl: './step-slider.component.html',
  styleUrls: ['./step-slider.component.scss'],
})
export class StepSliderComponent implements OnInit {
  _config: StepSliderConfig = { onClose: () => null, steps: {} };
  @ViewChild('dynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  })
  dynamicComponentContainer!: ViewContainerRef;
  container!: ViewContainerRef;
  currentStepIndex = 0;
  currentStep: EachSliderStep = {};
  displayedText = '';
  typingSpeed = 40;
  revealComponent = false;
  isOverflowed = false;
  isGoingBack = false;
  canSkip = false;
  noTitle = false;
  isBackNavigationAllowed = false;
  customTitle = '';
  customIcon = '';
  showProfile = false;
  profile: { email: string; name: string } = { email: '', name: '' };
  healthAgentSkipClicked = false;
  selfServiceModeClicked = false;

  @Input()
  set config(value: StepSliderConfig) {
    this._config = { ...value };
  }

  get config(): StepSliderConfig {
    return this._config;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.getUserInformation();
  }

  ngOnInit() {
    this.container = this.dynamicComponentContainer;
    this.route.queryParams.subscribe((params) => {
      const index = params['currentIndex'];
      if (index !== undefined && !isNaN(index) && index >= 0) {
        this.currentStepIndex = Number(index);
      }
      this.loadStep(this.currentStepIndex);
    });
  }

  getUserInformation() {
    this.profile = {
      email: this.authenticationService.currentUser.email,
      name: `${this.authenticationService.currentUser.firstName} ${this.authenticationService.currentUser.lastName}`,
    };
  }

  loadStep(index: number) {
    this.currentStep = this._config.steps[index];
    this.displayedText = '';
    this.revealComponent = false;
    this.cdr.detectChanges();
    this.typeText(this.currentStepIndex);
  }

  async typeText(stepIndex: number) {
    const titleText = this._config.steps[stepIndex]?.message ?? '';
    if (!this.isGoingBack) {
      let i = 0;

      const typeCharacter = () => {
        if (i < titleText.length) {
          if (
            this.currentStepIndex === 6 &&
            localStorage.getItem('onboarding-selected-package')
          ) {
            const customTitleText = `Book a time for a ${localStorage.getItem(
              'onboarding-selected-package'
            )} Onboarding Consultation`;
            this.displayedText += customTitleText.charAt(i);
          } else {
            this.displayedText += titleText.charAt(i);
          }
          this.cdr.detectChanges();
          i++;
          const nextCharDelay = this.typingSpeed + Math.random() * 20 - 10;
          setTimeout(typeCharacter, Math.max(nextCharDelay, 20));
        } else {
          Promise.resolve().then(() => {
            this.revealComponent = true;
            this.cdr.detectChanges();
            this.loadDynamicComponentWithAnimation();
          });
        }
      };

      typeCharacter();
    } else {
      Promise.resolve().then(() => {
        this.displayedText = titleText;
        this.revealComponent = true;
        this.cdr.detectChanges();
        this.loadDynamicComponentWithAnimation();
      });
    }
  }

  loadDynamicComponentWithAnimation() {
    if (
      this.revealComponent &&
      this.currentStep &&
      this.currentStep.component &&
      this.container
    ) {
      this.container.clear();
      const componentRef = this.container.createComponent<
        typeof this.currentStep.component
      >(this.currentStep.component);

      componentRef.instance.onNextStep = this.nextStep.bind(this);
      componentRef.instance.onPrevStep = this.prevStep.bind(this);
      this.canSkip = this.currentStep.canSkip ?? false;
      this.noTitle = this.currentStep.noTitle ?? false;
      this.customTitle = this.currentStep?.customTitle ?? '';
      this.customIcon = this.currentStep?.customIcon ?? '';
      this.isBackNavigationAllowed =
        this.currentStep.isBackNavigationAllowed ?? false;

      if (this.currentStep.data) {
        componentRef.instance.data = this.currentStep.data;
        Object.assign(componentRef.instance, this.currentStep.data);
      }

      this.cdr.detectChanges();
    }
  }

  prevStep() {
    this.isGoingBack = true;
    if (this.currentStepIndex > 0) {
      if (this.healthAgentSkipClicked && this.currentStepIndex === 4) {
        this.currentStepIndex--;
      }
      if (this.selfServiceModeClicked && this.currentStepIndex === 8) {
        this.currentStepIndex -= 2;
      }
      this.currentStepIndex--;
      this.loadStep(this.currentStepIndex);
      if (this._config.onPrevStep) {
        this._config.onPrevStep();
      }
    }
  }

  nextStep({ index }: { index?: number } = {}) {
    this.isGoingBack = false;
    if (index) {
      this.selfServiceModeClicked = true;
      this.currentStepIndex += 2;
    }

    if (this.currentStepIndex < Object.keys(this._config.steps).length - 1) {
      this.currentStepIndex++;
      this.loadStep(this.currentStepIndex);
    }
  }

  handleSkip() {
    if (this.currentStepIndex === 2) {
      this.healthAgentSkipClicked = true;
      this.currentStepIndex++;
    }
    this.nextStep();
    /// TODO: handle this localstorage
    // localStorage.setItem('has-health-connection', 'true');
    // this.router.navigate(['/templates']);
  }
}
