import { Component, EventEmitter, Input, Output } from '@angular/core';

type Option = {
  label: string;
  value: number;
  checked: boolean;
};

@Component({
  selector: 'app-checkbox-button-option',
  templateUrl: './checkbox-button-option.component.html',
  styleUrls: ['./checkbox-button-option.component.scss'],
})
export class CheckboxButtonOptionComponent {
  @Input() optionsList: Option[];
  @Output() optionsListChange = new EventEmitter<Option[]>();

  onCheckboxChange() {
    this.optionsListChange.emit(this.optionsList);
  }
}
