import { Routes } from '@angular/router';
import { SubscriptionPlansComponent } from './pages/subscription-plans/subscription-plans.component';
import {
  SUBSCRIPTION_PLANS_RESOLVER_FLAG,
  SubscriptionPlansResolver,
} from './resolver/subscription-plans.resolver';
import { SubscribePlanComponent } from '@upbrains/ui/common';

export const SubscriptionLayoutRouting: Routes = [
  {
    path: 'subscriptions/plans/:plan',
    component: SubscriptionPlansComponent,
    resolve: { [SUBSCRIPTION_PLANS_RESOLVER_FLAG]: SubscriptionPlansResolver },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'subscriptions/plans/:plan/subscribe',
    component: SubscribePlanComponent,
    runGuardsAndResolvers: 'always',
  },
];
