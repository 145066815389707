import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, StepSliderConfig } from '@upbrains/ui/common';
import { Subscription } from 'rxjs';
import {
  BookATimeComponent,
  ChooseOnboardingPackageComponent,
  CompanyUrlComponent,
  DeclareStepsComponent,
  HealthAgentConnectionComponent,
  TypeOfInboxComponent,
  WhatToDOComponent,
} from '../../steps';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
})
export class OnboardingComponent implements OnInit, OnDestroy {
  stepsConfig: StepSliderConfig;
  isManual = false;
  private queryParamSubscription: Subscription | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.stepsConfig = {
      title: '',
      subTitle: '',
      onClose: () => undefined,
      onPrevStep: () => undefined,
      onNextStep: () => undefined,
      steps: {},
    };
  }

  ngOnInit() {
    const { firstName } = this.authenticationService.currentUser;

    this.queryParamSubscription = this.route.queryParamMap.subscribe(
      (params) => {
        this.isManual = params.get('isManual') === 'true';

        this.stepsConfig = {
          title: 'Onboarding Agent',
          onClose: () => undefined,
          onPrevStep: () => undefined,
          steps: {
            0: {
              message: `Hi there, ${firstName}.\n Let's complete these steps together`,
              component: DeclareStepsComponent,
            },
            1: {
              message: `Provide your company URL.\n This gives me useful information for workspace`,
              component: CompanyUrlComponent,
              isBackNavigationAllowed: true,
            },
            2: {
              message: `Let me know which inbox you use`,
              component: HealthAgentConnectionComponent,
              isBackNavigationAllowed: true,
              canSkip: true,
            },
            3: {
              message: `What type of inbox is it?`,
              component: TypeOfInboxComponent,
              isBackNavigationAllowed: true,
              canSkip: true,
            },
            4: {
              message: `What do you want to do?`,
              component: WhatToDOComponent,
              isBackNavigationAllowed: true,
            },
            5: {
              message: `We can help you with professional onboarding.\n Choose your onboarding package`,
              component: ChooseOnboardingPackageComponent,
              isBackNavigationAllowed: true,
            },
            6: {
              message: `Book a time for a free Onboarding Consultation`,
              component: BookATimeComponent,
              isBackNavigationAllowed: true,
            },
          },
        };
      }
    );
  }

  ngOnDestroy() {
    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }
}
