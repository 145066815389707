<div class="ap-w-full ap-h-full ap-overflow-y-auto">
  <div class="ap-flex ap-flex-col ap-items-center ap-justify-center ap-w-full ap-min-h-full lg:ap-flex-row-reverse lg:ap-h-full">
    <div
      class="ap-bg-[#ffffff] ap-w-full ap-flex ap-flex-col ap-justify-center ap-items-center lg:ap-max-w-[840px] ap-px-11 ap-py-10"
    >
      <div class="ap-flex ap-flex-row ap-gap-2 ap-items-center ap-justify-center">
        <img
          alt="UpBrains Website"
          [src]="'assets/img/custom/logo/automation-logo.svg'"
          class="ap-h-8 ap-w-8"
        />
        <p class="ap-font-inter ap-text-2xl ap-font-normal">UpBrains AI</p>
      </div>
  
      <router-outlet></router-outlet>
    </div>
  

      <div
      class="ap-hidden ap-h-full ap-w-full ap-bg-[#f9fafb] lg:ap-flex lg:ap-flex-col lg:ap-justify-center lg:ap-items-center ap-px-20"
      >
        <div
          class="ap-max-w-[750px] ap-max-h-[720px] ap-rounded-lg ap-overflow-hidden"
        >
          <img
            alt="Automate your Inbox"
            [src]="'assets/img/custom/upbrains-background.png'"
            class="ap-w-full ap-h-full ap-object-contain ap-rounded-lg"
          />
        </div>
    
        <div
          class="ap-flex ap-flex-col ap-justify-center ap-items-start ap-mt-8"
        >
          <p class="ap-font-inter ap-font-normal ap-text-base ap-mb-6">
            UpBrains AI Copilot helps you automate your inbox and helpdesk workflows
            in minutes.
          </p>
          <p class="ap-font-inter ap-font-normal ap-text-base">
            Sign up to get 14 days trial subscription for free.
          </p>
        </div>
      </div>
  </div>
</div>
