import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema, Nullable } from '../common/base-model'
import { ApId } from '../common/id-generator'

export type AgentId = ApId

export enum AgentProviderStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
}

export const AgentProvider = Type.Object({
    ...BaseModelSchema,
    displayName: Type.String(),
    projectId: Type.String(),
    folderId: Nullable(Type.String()),
    flowId: Nullable(Type.String()),
    toolsId: Nullable(Type.String()),
    status: Type.Enum(AgentProviderStatus),
    publishedVersionId: Type.Optional(Type.Union([Type.String(), Type.Null()])),
})

export type AgentProvider = Static<typeof AgentProvider>
