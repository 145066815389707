import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  AbstractControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Platform } from '@upbrains/shared';
import {
  AuthenticationService,
  industryList,
  onboardingActions,
  PlatformService,
} from '@upbrains/ui/common';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'app-company-url',
  templateUrl: './company-url.component.html',
  styleUrls: ['./company-url.component.scss'],
})
export class CompanyUrlComponent implements OnInit, AfterViewInit {
  @Input() onNextStep: () => void = () => null;
  @ViewChild('companyUrlInput') companyUrlInput!: ElementRef;

  form: FormGroup<{
    companyUrl: FormControl<string | null>;
    selectedIndustry: FormControl<string | null>;
  }>;
  isLoading = false;
  platformName: string | null = null;
  industryList: string[] = industryList;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private platformService: PlatformService,
    private store: Store
  ) {
    this.form = this.fb.group({
      companyUrl: this.fb.control<string | null>(null, {
        validators: [this.optionalUrlPatternValidator],
      }),
      selectedIndustry: this.fb.control<string | null>(null, {
        validators: [Validators.required],
      }),
    });
  }

  ngOnInit() {
    this.getPlatformName().subscribe();
  }

  ngAfterViewInit(): void {
    this.companyUrlInput.nativeElement.focus();
  }

  onNextButtonClick() {
    if (this.form.controls.companyUrl.value?.trim() === '') {
      this.form.controls.companyUrl.setValue(null);
    }

    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.isLoading = true;

      const emailHtml = `
      <h3>Provide your company URL</h3>
      <h5>This gives me useful information for workspace </h5>
      <p><strong>Question:</strong> Business Name</p>
      <p><strong>Answer:</strong> ${this.platformName || ''}</p>
      <p><strong>Question:</strong> Company Website</p>
      <p><strong>Answer:</strong> ${
        this.form.controls.companyUrl.value || ''
      }</p>
      <p><strong>Question:</strong> Company Industry</p>
      <p><strong>Answer:</strong> ${
        this.form.controls.selectedIndustry.value || ''
      }</p>
      <br>
      <br>
    `;
      localStorage.removeItem('emailData');
      localStorage.setItem('emailData', emailHtml);
      this.isLoading = false;
      this.onNextStep();
    }
  }

  private optionalUrlPatternValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const url = control.value?.trim();

    if (!url) return null;

    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

    return urlPattern.test(url) ? null : { invalidUrl: true };
  }

  private getPlatformName(): Observable<Platform | null> {
    const { platformId } = this.authenticationService.currentUser;
    if (!platformId) {
      this.platformName = null;
      this.store.dispatch(
        onboardingActions.setBusinessName({ businessName: this.platformName })
      );
      return of(null);
    }

    return this.platformService.getPlatform(platformId).pipe(
      map((platform) => {
        this.platformName = platform.name ?? null;
        this.store.dispatch(
          onboardingActions.setBusinessName({ businessName: this.platformName })
        );
        return platform ?? null;
      })
    );
  }
}
