import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import '@upbrains/shared';
import { InboxToolsNextButton } from '@upbrains/shared';
import {
  AgentMetadataModelSummary,
  AppConnectionsService,
  AuthenticationService,
  SnackbarService,
} from '@upbrains/ui/common';
import { AgentMetadataService } from '@upbrains/ui/feature-agents';
import { AddEditConnectionButtonComponent } from '@upbrains/ui/feature-connections';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  finalize,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';

const agentsConnectionList = [
  '@upbrains/agent-microsoft-outlook',
  '@upbrains/agent-frontapp',
  '@upbrains/agent-zendesk',
];

@Component({
  selector: 'app-health-agent-connection',
  templateUrl: './health-agent-connection.component.html',
  styleUrls: ['./health-agent-connection.component.scss'],
})
export class HealthAgentConnectionComponent implements OnInit {
  newConnectionAgent?: AgentMetadataModelSummary;

  @Input() onNextStep!: () => void;
  agentTemplate$: Observable<any> = of(null);
  agents$: Observable<AgentMetadataModelSummary[]>;
  private agentsWithAuthSubject = new BehaviorSubject<
    (AgentMetadataModelSummary & { connected: boolean; isSelected: boolean })[]
  >([]);
  agentsWithAuth$ = this.agentsWithAuthSubject.asObservable();
  allAgentsConnected = false;
  loading = false;
  agentId: string | null = null;
  selectedStatus = {
    isSelected: false,
    triggerId: 1,
  };
  addConnectionBtns: QueryList<AddEditConnectionButtonComponent> | null = null;
  selectedAgentName = '';
  connectText = InboxToolsNextButton.CONNECT;
  skeletonItems = Array(3);

  constructor(
    private agentMetadataService: AgentMetadataService,
    private connectionsService: AppConnectionsService,
    private authenticationService: AuthenticationService,
    private connectionService: AppConnectionsService,
    private snackbarService: SnackbarService,
    private cdr: ChangeDetectorRef
  ) {
    this.agents$ = this.agentMetadataService
      .getAgentsManifestFromServer({})
      .pipe(map((agents) => agents.filter((p) => !!p.auth)));
  }

  ngOnInit() {
    this.agentTemplate$ = of({
      agents: agentsConnectionList,
    });

    this.loading = true;

    this.agentTemplate$
      .pipe(
        switchMap((template) => {
          if (!template?.agents?.length) {
            return of([]);
          }

          return combineLatest([
            this.agents$,
            this.connectionsService.list({
              projectId: this.authenticationService.getProjectId(),
              limit: 50,
            }),
          ]).pipe(
            map(([agents, connections]) => {
              const connectedAgentNames =
                connections?.data?.map((c) => c.agentName) || [];

              const mappedAgents = agents
                .filter((agent) => template.agents.includes(agent.name))
                .map((agent) => ({
                  ...agent,
                  connected: connectedAgentNames.includes(agent.name),
                  isSelected: false,
                }));

              this.agentsWithAuthSubject.next(mappedAgents);
              return mappedAgents;
            }),
            catchError((error) => {
              console.error('Error fetching agents:', error);
              return of([]);
            })
          );
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: (agents) => {
          this.loading = false;
          if (!agents.length) {
            console.warn('No agents loaded.');
          }
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.error('Error:', err);
          this.loading = false;
        },
      });
  }

  handleTriggerSelectedInbox(
    agentTrigger: number,
    agentName: string,
    button?: any
  ) {
    localStorage.removeItem('onboarding-selected-inbox-tool');
    localStorage.setItem('onboarding-selected-inbox-tool', agentName);

    this.selectedAgentName = agentName;
    const updatedAgents = this.agentsWithAuthSubject
      .getValue()
      .map((agent) => ({
        ...agent,
        isSelected: agent.triggers === agentTrigger,
      }));

    this.agentsWithAuthSubject.next(updatedAgents);
    this.addConnectionBtns = button;

    if (!button) {
      this.connectText = InboxToolsNextButton.CONTINUE;
    } else {
      this.connectText = InboxToolsNextButton.CONNECT;
    }
  }

  openNewConnectionDialog() {
    if (
      !this.addConnectionBtns &&
      this.connectText !== InboxToolsNextButton.CONTINUE
    ) {
      this.snackbarService.showError(
        'You have to select one of the inbox tools or skip this step.'
      );
      return null;
    } else if (
      !this.addConnectionBtns &&
      this.connectText === InboxToolsNextButton.CONTINUE
    ) {
      this.onNextStep();
      return null;
    }

    setTimeout(() => {
      const btn = this.addConnectionBtns?.find(
        (button) => button.agentName === this.selectedAgentName
      );
      if (btn) {
        btn.buttonClicked();

        if (btn.updateOrAddConnectionDialogClosed$) {
          btn.updateOrAddConnectionDialogClosed$.subscribe(() => {
            this.getConnectionAndUpdateAgents();
          });
        } else {
          console.error('updateOrAddConnectionDialogClosed$ is undefined');
        }
      } else {
        console.error('Button for this agent is not found');
      }
    });

    return null;
  }

  getConnectionAndUpdateAgents() {
    this.connectionService
      .list({ projectId: this.authenticationService.getProjectId(), limit: 50 })
      .pipe(
        switchMap((connections) => {
          const connectedAgentNames = connections.data.map(
            (connection) => connection.agentName
          );

          const updatedAgents = this.agentsWithAuthSubject
            .getValue()
            .map((agent) => ({
              ...agent,
              connected: connectedAgentNames.includes(agent.name),
            }));

          this.agentsWithAuthSubject.next(updatedAgents);
          const agent = updatedAgents.find(
            (a) => a.name === this.selectedAgentName
          );

          if (agent?.connected) {
            this.onNextStep();
          } else {
            this.snackbarService.showError('No connected agents found.');
          }

          return of(updatedAgents);
        }),
        catchError((error) => {
          this.snackbarService.showError('Error updating agents.');
          console.error('Error updating agents:', error);
          return of([]);
        })
      )
      .subscribe();
  }
}
