import { Component, Input } from '@angular/core';
import { FlowTemplateWithIcons } from '@upbrains/shared';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss'],
})
export class AgentCardComponent {
  @Input() template: FlowTemplateWithIcons;
  @Input() action: (template: FlowTemplateWithIcons) => void;

  handleConfigure() {
    if (this.action) {
      this.action(this.template);
    }
  }
}
