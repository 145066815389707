<div class="ap-inline-block" [matTooltip]="tooltipText">
  <ng-container *ngFor="let url$ of urlsToLoad$; let isFirst = first">
    <ap-agent-icon-container [url]="(url$ | async) || ''" [class.ap-ml-[5px]]="!isFirst">
    </ap-agent-icon-container>
  </ng-container>
  <ap-agent-icon-container *ngIf="numberOfStepsLeft > 0" [moreIconsNumber]="numberOfStepsLeft">
  </ap-agent-icon-container>
</div>
<ng-container *ngFor="let metaData$ of agentsMetadata$">
  <ng-container *ngIf="metaData$ | async"></ng-container>
</ng-container>