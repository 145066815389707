import { Type, Static } from '@sinclair/typebox'
import { PackageType, agentType, VersionType } from '../../agents'
import { SampleDataSettingsObject } from '../sample-data'
import { Memory } from '../../memory/index'

export const AUTHENTICATION_PROPERTY_NAME = 'auth'

export enum TriggerType {
    EMPTY = 'EMPTY',
    WEBHOOK = 'WEBHOOK',
    AGENT = 'AGENT_TRIGGER',
}



const commonProps = {
    name: Type.String({}),
    valid: Type.Boolean({}),
    displayName: Type.String({}),
    nextAction: Type.Optional(Type.Any()),
    memories: Type.Optional(Type.Record(Type.String({}), Memory)),
}

export const EmptyTrigger = Type.Object({
    ...commonProps,
    type: Type.Literal(TriggerType.EMPTY),
    settings: Type.Any(),
})

export type EmptyTrigger = Static<typeof EmptyTrigger>

export const WebhookTrigger = Type.Object({
    ...commonProps,
    type: Type.Literal(TriggerType.WEBHOOK),
    settings: Type.Object({
        inputUiInfo: SampleDataSettingsObject,
    }),
})

export type WebhookTrigger = Static<typeof WebhookTrigger>

export const AgentTriggerSettings = Type.Object({
    agentName: Type.String({}),
    agentVersion: VersionType,
    agentType: Type.Enum(agentType),
    packageType: Type.Enum(PackageType),
    triggerName: Type.String({}),
    input: Type.Record(Type.String({}), Type.Any()),
    inputUiInfo: SampleDataSettingsObject,
})

export type AgentTriggerSettings = Static<typeof AgentTriggerSettings>

export const AgentTrigger = Type.Object({
    ...commonProps,
    type: Type.Literal(TriggerType.AGENT),
    settings: AgentTriggerSettings,
})

export type AgentTrigger = Static<typeof AgentTrigger>

export const Trigger = Type.Union([
    WebhookTrigger,
    AgentTrigger,
    EmptyTrigger,
])

export type Trigger = Static<typeof Trigger>
