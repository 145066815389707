import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

type ExtractorPage = {
  page_number: number;
  text: string;
};

@Component({
  templateUrl: './extractor-text-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./extractor-text-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExtractorTextDialogComponent implements OnInit {
  formPages: ExtractorPage[];
  fieldsObj: any = {};
  itemsObj: any = [];
  convertedFieldsObj: {
    key: string;
    value: string;
    colorClass: string;
    index?: number;
    itemNumber?: number;
  }[] = [];

  // Define 15 light colors for highlighting, avoiding light yellows
  lightColors = [
    'highlight-color-1',
    'highlight-color-2',
    'highlight-color-3',
    'highlight-color-4',
    'highlight-color-5',
    'highlight-color-6',
    'highlight-color-7',
    'highlight-color-8',
    'highlight-color-9',
    'highlight-color-10',
    'highlight-color-11',
    'highlight-color-12',
    'highlight-color-13',
    'highlight-color-14',
    'highlight-color-15',
  ];
  colorIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ExtractorTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formFields: object;
      formItems: object;
      formPages: ExtractorPage[];
    },
    private sanitizer: DomSanitizer
  ) {
    this.fieldsObj = data.formFields;
    this.itemsObj = data.formItems;
    this.formPages = data.formPages;
  }

  ngOnInit(): void {
    this.fieldsObj = Object.keys(this.fieldsObj)
      .filter((field) => this.fieldsObj[field].content)
      .map((field) => ({
        key: field,
        value: this.fieldsObj[field].content,
        colorClass: this.getNextColorClass(),
      }));

    this.itemsObj = this.itemsObj.reduce(
      (acc: any, item: any, itemIndex: number) => {
        Object.keys(item)
          .filter((key) => key !== 'page_number')
          .forEach((key, index) => {
            if (item[key]?.content) {
              acc.push({
                index,
                key: key,
                value: item[key].content,
                colorClass: this.getNextColorClass(),
                itemNumber: itemIndex + 1,
              });
            }
          });
        return acc;
      },
      []
    );

    this.convertedFieldsObj = [...this.fieldsObj, ...this.itemsObj];

    this.formPages = this.highlightText();
  }

  onClose() {
    this.dialogRef.close();
  }

  getNextColorClass(): string {
    const colorClass = this.lightColors[this.colorIndex];
    this.colorIndex = (this.colorIndex + 1) % this.lightColors.length;
    return colorClass;
  }

  isHtml(str: string): boolean {
    return /<\/?[a-z][\s\S]*>/i.test(str);
  }

  highlightText(): { text: string; page_number: number }[] {
    return this.formPages.map((originalTextObj) => {
      let modifiedText = originalTextObj.text;

      this.convertedFieldsObj.forEach((target) => {
        if (target.value) {
          const regex = new RegExp(
            `(?<=\\s|^|[^\\w])${target.value.replace(
              /[-/\\^$*+?.()|[\]{}]/g,
              '\\$&'
            )}(?=\\s|$|[^\\w])`,
            'g'
          );
          modifiedText = modifiedText.replace(
            regex,
            `<span class="${target.colorClass} highlight">${target.value}</span>`
          );
        }
      });

      return { text: modifiedText, page_number: originalTextObj.page_number };
    });
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
