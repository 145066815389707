<ng-container
  *ngIf="!showVerificationNote; else verificationTemplate"
>
  <div
    class="ap-text-3xl ap-font-semibold ap-mt-6 ap-font-inter ap-text-center ap-mt-6"
    i18n
  >
    Reset your password
  </div>
  <form
    (ngSubmit)="sendPasswordReset()"
    class="ap-flex ap-flex-col ap-gap-2 ap-mt-6 ap-font-inter custom-sign-up"
  >
    <div class="ap-text-sm ap-font-medium">Email</div>
    <mat-form-field class="ap-w-full" appearance="outline">
      <input
        matInput
        placeholder="john@doe.com"
        [formControl]="emailFormControl"
      />
      <mat-error *ngIf="emailFormControl.invalid" i18n>
        <ng-container *ngIf="emailFormControl.hasError('required')">
          Email is required
        </ng-container>
        <ng-container *ngIf="emailFormControl.hasError('email')">
          Email is invalid
        </ng-container>
      </mat-error>
    </mat-form-field>

    <div class="text-center ap-mt-2 ap-flex ap-flex-col ap-gap-2">
      <ap-button
        class="ap-text-lg ap-font-semibold"
        [loading]="loading"
        (buttonClicked)="sendPasswordReset()"
        btnColor="primary"
        [fullWidthOfContainer]="true"
        i18n
      >
        Send reset link
      </ap-button>
      <mat-error class="ap-text-center ap-mt-2" *ngIf="errorMessage">
        {{ errorMessage }}
      </mat-error>
    </div>
  </form>

  <div
    class="ap-text-sm ap-font-normal ap-mt-3 ap-font-inter ap-flex ap-flex-row ap-justify-center ap-items-center"
  >
    <a
      class="ap-ml-1 ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
      (click)="goBackToSignIn()"
      class="ap-cursor-pointer"
      i18n
      >Back to sign in</a
    >
  </div>
</ng-container>

<ng-template #verificationTemplate>
  <app-send-email-for-auth-action
    [email]="emailFormControl.value"
    [otpType]="OtpType.PASSWORD_RESET"
  ></app-send-email-for-auth-action>
</ng-template>
<ng-container *ngIf="sendPasswordReset$ | async"></ng-container>
