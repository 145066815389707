import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PdfViewerComponent as Ng2PdfViewerComponent } from 'ng2-pdf-viewer';

@Component({
  templateUrl: './extractor-file-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./extractor-file-dialog.component.scss'],
})
export class ExtractorFileDialogComponent implements OnInit {
  fileUrl: string;
  keyword = 'the';
  @ViewChild(Ng2PdfViewerComponent)
  private pdfComponent!: Ng2PdfViewerComponent;
  isLoading = true;
  isError = false;
  isPdf = true;

  constructor(
    public dialogRef: MatDialogRef<ExtractorFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { fileUrl: string }
  ) {
    this.fileUrl = data.fileUrl;
  }

  ngOnInit(): void {
    if (this.fileUrl.split('.').pop()?.toLowerCase() === 'png') {
      this.isPdf = false;
      this.isLoading = false;
    } else {
      this.isPdf = true;
    }
  }

  onLoadComplete() {
    this.isLoading = false;
  }

  onError(event: any) {
    this.isLoading = false;
    this.isError = true;
  }

  onClose() {
    this.dialogRef.close();
  }

  search(stringToSearch: string) {
    this.pdfComponent.eventBus.dispatch('find', {
      query: stringToSearch,
      type: 'again',
      caseSensitive: false,
      findPrevious: undefined,
      highlightAll: true,
      phraseSearch: true,
    });
  }
}
