import { Static, Type } from '@sinclair/typebox'
import { ConversationIdentifier } from './conversation-identifier'
import { PaginatedResult } from '../../search-engine'
import { CaseItem } from '../../cases'

const StringOrStringArray = Type.Union([Type.String(), Type.Array(Type.String())])

export const ConversationItemDocumentAllFields = Type.Object({
    _id: Type.String(),
    caseIdWithFunctionId: Type.Array(Type.String()),
    caseIds: StringOrStringArray,
    channel: Type.String(),
    childConversationIds: StringOrStringArray,
    conversationId: Type.String(),
    conversationSummary: Type.String(),
    conversationType: Type.String(),
    creationTimestamp: Type.Date(),
    eventType: Type.String(),
    from: Type.String(),
    identifiers: Type.Union([ConversationIdentifier, Type.Array(ConversationIdentifier)]),
    inboxId: Type.String(),
    lastUpdateTimestamp: Type.Date(),
    parentMessageId: Type.String(),
    projectId: Type.String(),
    subject: Type.String(),
    tags: Type.Array(Type.String()),
    teamId: Type.String(),
    vectorField: Type.Unknown(),
})

export const ConversationItemDocumentPartialFields = Type.Intersect([
    Type.Partial(ConversationItemDocumentAllFields), 
    Type.Pick(ConversationItemDocumentAllFields, [
        '_id',
        'conversationId',
        'creationTimestamp',
        'lastUpdateTimestamp',
        'projectId',
        'teamId',
    ]),
])
export type ConversationItemDocumentAllFields = Static<typeof ConversationItemDocumentAllFields>
export type ConversationItemDocumentPartialFields = Static<typeof ConversationItemDocumentPartialFields>


export const ConversationFunction = Type.Object({
    functionId: Type.String(),
    functionName: Type.String(),
    caseId: Type.String(),
})
export type ConversationFunction = Static<typeof ConversationFunction>

export const ConversationItem = Type.Object({
    casesInfo: Type.Array(CaseItem),
    functions: Type.Array(ConversationFunction),
    channel: Type.String(),
    childConversationIds: StringOrStringArray,
    conversationId: Type.String(),
    conversationSummary: Type.String(),
    conversationType: Type.String(),
    creationTimeStamp: Type.Date(),
    eventType: Type.String(),
    from: Type.String(),
    inboxId: Type.String(),
    lastUpdateTimeStamp: Type.Date(),
    parentMessageId: Type.String(),
    projectId: Type.String(),
    subject: Type.String(),
    tags: Type.Array(Type.String()),
    teamId: Type.String(),
    identifiers: Type.Array(ConversationIdentifier),
})


export type ConversationItem = Static<typeof ConversationItem>


export const PaginatedConversationItemDocumentPartialFields = PaginatedResult(ConversationItemDocumentPartialFields)
export type PaginatedConversationItemDocumentPartialFields = Static<typeof PaginatedConversationItemDocumentPartialFields>

export const PaginatedConversationItems = PaginatedResult(ConversationItem)

export type PaginatedConversationItems = Static<typeof PaginatedConversationItems>
