import { Static, Type } from '@sinclair/typebox'

export enum UpbrainMessageEventTypes {
    INBOUND = 'inbound',
    OUT_BOUND = 'outbound',
    INTERNAL = 'internal',
}

export enum AttachmentType {
    PDF = 'PDF',
    TEXT = 'TEXT',
    DOC = 'DOC',
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
}

export const MessageRecipientInfo = Type.Object({
    name: Type.String(),
    email: Type.String(),
})

export type MessageRecipientInfo = Static<typeof MessageRecipientInfo>

export const MessageAttachment = Type.Object({
    id: Type.String(),
    integration_name: Type.String(),
    name: Type.String(),
    size: Type.Number(),
    content_url: Type.String(),
    content_type: Type.Enum(AttachmentType),
    is_inline: Type.Boolean(),
})

export const UpbrainMessageFormat = Type.Object({
    event_type: Type.Enum(UpbrainMessageEventTypes),
    integration_name: Type.String(),
    ticket_or_conversation_id: Type.String(),
    comment_or_message_id: Type.String(),
    inbox_or_group_id: Type.String(),
    channel_type: Type.String(),
    channel_address: Type.String(),
    from: MessageRecipientInfo,
    to: Type.Array(MessageRecipientInfo),
    subject: Type.String(),
    body: Type.String(),
    html_body: Type.String(),
    attachments: Type.Array(MessageAttachment),
    timestamp: Type.Number(),
    tags: Type.Array(Type.String()),
})