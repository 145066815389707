import { Static, Type } from '@sinclair/typebox'
import { EmailType } from '../../user/user'

export const UpbrainsResetPassword = Type.Object({
    email: EmailType,
    newPassword: Type.String(),
    teamId: Type.String(),
})

export type UpbrainsResetPassword = Static<typeof UpbrainsResetPassword>
