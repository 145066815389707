<ap-dialog-title-template>
    <ng-container *ngIf="!dialogData.connectionToUpdate; else editConnectionHeader" i18n>New
        {{dialogData.agentDisplayName}} Connection</ng-container>
    <ng-template #editConnectionHeader><span i18n>Reconnect</span>
        {{dialogData!.connectionToUpdate?.name}}</ng-template>
</ap-dialog-title-template>

<mat-dialog-content>
    <ap-markdown *ngIf="dialogData.agentAuthProperty.description"
        [data]="dialogData.agentAuthProperty.description"></ap-markdown>
    <form class="ap-pt-4 ap-flex ap-flex-col ap-gap-2 ap-max-w-[720px] ap-min-w-[420px]" [formGroup]="settingsForm"
        (keyup.enter)="submit()" (submit)="submit()">
        <mat-form-field class="ap-w-full" appearance="outline">
            <mat-label i18n>Name</mat-label>
            <input cdkFocusInitial [matTooltip]="keyTooltip" formControlName="name" matInput type="text" />
            <mat-error *ngIf="settingsForm.controls['name'].invalid">
                <ng-container *ngIf="settingsForm.get('name')!.getError('required'); else patternErrorOrAlreadyUsed"
                    i18n>
                    Name is required
                </ng-container>
                <ng-template #patternErrorOrAlreadyUsed>
                    <ng-container *ngIf="settingsForm.get('name')!.getError('pattern');" i18n>
                        Name can only contain letters, numbers and underscores
                    </ng-container>
                    <ng-container *ngIf="settingsForm.get('name')!.getError('nameUsed');" i18n>
                        Name is already used
                    </ng-container>
                </ng-template></mat-error>
        </mat-form-field>

        <ng-container *ngFor="let prop of dialogData.agentAuthProperty.props |objectToArray">
            <mat-form-field class="ap-w-full" appearance="outline"
                *ngIf="prop.value.type === PropertyType.SECRET_TEXT || prop.value.type === PropertyType.SHORT_TEXT || prop.value.type === PropertyType.NUMBER ">
                <mat-label>{{prop.value.displayName}} {{prop.value.required?'': '(Optional)'}}</mat-label>
                <input [matTooltip]="prop.value.description||''" [formControlName]="prop.key" matInput
                    [type]="prop.value.type === PropertyType.SECRET_TEXT? 'password':'text'" />
                <mat-error *ngIf="settingsForm.get(prop.key)!.invalid">
                    {{prop.value.displayName}} <span i18n>is required</span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="ap-w-full" appearance="outline" *ngIf="prop.value.type === PropertyType.LONG_TEXT ">
                <mat-label>{{prop.value.displayName}} {{prop.value.required?'': '(Optional)'}}</mat-label>
                <textarea [matTooltip]="prop.value.description||''" [formControlName]="prop.key" matInput></textarea>
                <mat-error *ngIf="settingsForm.get(prop.key)!.invalid">
                    {{prop.value.displayName}} <span i18n>is required</span>
                </mat-error>
            </mat-form-field>
            <ng-container *ngIf="prop.value.type === PropertyType.STATIC_DROPDOWN">
                <mat-form-field class="ap-w-full" appearance="outline">
                    <mat-label> {{prop.value.displayName}}</mat-label>
                    <mat-select [formControlName]="prop.key" [matTooltip]="prop.value.description || ''"
                        [compareWith]="dropdownCompareWithFunction">
                        <mat-option *ngFor="let opt of prop.value.options!.options" [value]="opt.value">
                            {{opt.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="settingsForm.controls[prop.key]!.invalid">
                        {{prop.value.displayName}} <span i18n>is required</span>
                    </mat-error>
                </mat-form-field>
            </ng-container>
            <mat-checkbox *ngIf="prop.value.type === PropertyType.CHECKBOX" [formControlName]="prop.key" color="primary"
                [matTooltip]="prop.value.description || ''">
                {{ prop.value.displayName }}
            </mat-checkbox>
        </ng-container>
        <mat-error *ngIf="settingsForm.getError('message')"
            class="ap-text-left">{{settingsForm.getError('message')}}</mat-error>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="ap-flex ap-gap-2.5">
        <ap-button btnColor="basic" mat-dialog-close btnSize="default" i18n>
            Cancel
        </ap-button>
        <ap-button cdkFocusInitial btnSize="default" (click)="submit()" btnColor="primary" type="submit"
            [loading]="loading" i18n>
            Save
        </ap-button>
    </div>
</mat-dialog-actions>
<ng-container *ngIf="upsert$|async"></ng-container>