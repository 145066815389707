<ng-container *ngIf="isTrigger">
  <div
    class="ap-h-screen ap-w-full ap-flex ap-items-center ap-justify-center ap-bg-center ap-bg-cover ap-flex-col"
  >
    <ng-container *ngIf="!error && formProps">
      <form
        [formGroup]="form"
        (ngSubmit)="submit()"
        class="ap-flex ap-flex-col ap-gap-2"
      >
        <mat-card
          class="md:ap-min-w-[480px] md:ap-max-w-initial ap-min-w-[350px] ap-max-w-[350px] auth-card ap-max-h-[80vh] ap-overflow-scroll"
        >
          <div class="ap-typography-headline-5 ap-text-center ap-mb-6">
            {{ title }}
          </div>
          <ng-container *ngFor="let input of inputs">
            <ng-container [ngSwitch]="input.type">
              <!-- Short Text input -->
              <ng-container *ngSwitchCase="PropertyType.SHORT_TEXT">
                <mat-form-field
                  class="ap-w-full ap-mb-4"
                  appearance="outline"
                  subscriptSizing="dynamic"
                >
                  <mat-label>{{ input.displayName }}</mat-label>
                  <input
                    matInput
                    type="text"
                    [formControlName]="input.uniqueKey"
                  />
                  <mat-hint>{{ input.description }}</mat-hint>
                  <mat-error *ngIf="form.controls[input.uniqueKey]?.invalid">
                    {{ input.displayName }} is required
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <!-- File input -->
              <ng-container *ngSwitchCase="PropertyType.FILE">
                <mat-form-field class="ap-w-full" subscriptSizing="dynamic">
                  <mat-label i18n>{{ input.displayName }}</mat-label>
                  <ap-file-upload
                    [required]="input.required"
                    #flowInput
                    [formControlName]="input.uniqueKey"
                    [extensions]="['.*']"
                  ></ap-file-upload>
                  <ap-icon-button
                    [height]="25"
                    [width]="25"
                    iconFilename="attach.svg"
                    matSuffix
                    (buttonClicked)="flowInput.fileInput.nativeElement.click()"
                  ></ap-icon-button>
                  <mat-hint>{{ input.description }}</mat-hint>
                  <mat-error *ngIf="form.controls[input.uniqueKey]?.invalid">
                    {{ input.displayName }} is required
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <!-- Checkbox input -->
              <ng-container *ngSwitchCase="PropertyType.CHECKBOX">
                <div class="app-w-full ap-mb-4">
                  <mat-slide-toggle
                    [formControlName]="input.uniqueKey"
                    color="primary"
                    i18n
                  >
                    {{ input.displayName }}
                  </mat-slide-toggle>
                  <markdown class="ap-typography-caption">
                    {{ input.description }}
                  </markdown>
                  <mat-error
                    *ngIf="form.controls[input.uniqueKey]?.invalid"
                    class="ap-mt-2"
                  >
                    {{ input.displayName }} is required
                  </mat-error>
                </div>
              </ng-container>

              <!-- Long Text input -->
              <ng-container *ngSwitchCase="PropertyType.LONG_TEXT">
                <mat-form-field
                  class="ap-w-full ap-mb-4"
                  appearance="outline"
                  subscriptSizing="dynamic"
                >
                  <mat-label>{{ input.displayName }}</mat-label>
                  <textarea
                    matInput
                    [formControlName]="input.uniqueKey"
                  ></textarea>
                  <mat-hint>{{ input.description }}</mat-hint>
                  <mat-error *ngIf="form.controls[input.uniqueKey]?.invalid">
                    {{ input.displayName }} is required
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <!-- Display Text input -->
              <ng-container *ngSwitchCase="PropertyType.DISPLAY_TEXT">
                <div class="ap-w-full ap-mb-2">
                  <div
                    *ngIf="(input?.value?.length ?? 0) <= 20; else longText"
                    class="ap-typography-body-1"
                  >
                    <strong class="ap-font-bold"
                      >{{ input.displayName }}:</strong
                    >
                    {{ input.value }}
                  </div>
                </div>
                <ng-template #longText>
                  <div class="ap-w-full ap-mb-4">
                    <div class="ap-typography-body-1">
                      <strong class="ap-font-bold"
                        >{{ input.displayName }}:</strong
                      >
                    </div>
                    <div class="ap-typography-body-1">
                      <ap-markdown
                        [fullWidth]="true"
                        [data]="convertToString(input.value)"
                        class="ap-mt-4 ap-mb-4"
                      ></ap-markdown>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <!-- Select input -->
              <ng-container *ngSwitchCase="'SELECT'">
                <mat-form-field class="ap-w-full ap-mb-4">
                  <mat-label>{{ input.displayName }}</mat-label>
                  <mat-select [formControlName]="input.uniqueKey">
                    <!-- Ensure input.value is defined and a string before splitting -->
                    <mat-option
                      *ngFor="let option of getOptions(input.value)"
                      [value]="option"
                      i18n
                      >{{ option }}</mat-option
                    >
                  </mat-select>
                  <mat-hint>{{ input.description }}</mat-hint>
                  <mat-error *ngIf="form.controls[input.uniqueKey]?.invalid">
                    {{ input.displayName }} is required
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <!-- Custom Action Button -->
              <ng-container *ngSwitchCase="PropertyType.CUSTOM_ACTION">
                <div class="ap-flex ap-justify-between ap-mb-4">
                  <ap-button
                    [loading]="loading"
                    (buttonClicked)="openLinkInNewTab(input.value)"
                    btnColor="primary"
                    btnSize="large"
                    [fullWidthOfContainer]="true"
                    i18n
                  >
                    {{ input.displayName }}
                  </ap-button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              hasApprovalOrDisapproval && approvalAction && disapprovalAction
            "
          >
            <div
              class="ap-flex ap-items-center ap-justify-center ap-gap-2 ap-w-full"
            >
              <div class="ap-flex ap-justify-between ap-mb-4 ap-w-full">
                <ap-button
                  (buttonClicked)="openLinkInNewTab(disapprovalAction.value)"
                  btnColor="warn"
                  btnSize="large"
                  [fullWidthOfContainer]="true"
                  class="ap-w-full"
                >
                  {{ disapprovalAction.displayName }}
                </ap-button>
              </div>
              <div class="ap-flex ap-justify-between ap-mb-4 ap-w-full">
                <ap-button
                  (buttonClicked)="openLinkInNewTab(approvalAction.value)"
                  btnColor="primary"
                  btnSize="large"
                  [fullWidthOfContainer]="true"
                  class="ap-w-full"
                >
                  {{ approvalAction.displayName }}
                </ap-button>
              </div>
            </div>
          </ng-container>

          <!-- Submit Button -->
          <div class="text-center ap-mt-6 ap-flex ap-flex-col ap-gap-2">
            <ap-button
              [loading]="loading"
              (buttonClicked)="submit()"
              btnColor="primary"
              btnSize="large"
              [fullWidthOfContainer]="true"
              i18n
            >
              {{ submitAction?.displayName || 'Submit' }}
            </ap-button>
          </div>

          <!-- Markdown Response -->
          <ng-container *ngIf="markdownResponse | async as mkResponse">
            <ap-markdown
              [fullWidth]="true"
              [data]="mkResponse"
              class="ap-mt-4 ap-mb-4"
            ></ap-markdown>
          </ng-container>
        </mat-card>
      </form>
    </ng-container>

    <!-- Error Display -->
    <ng-container *ngIf="error">
      {{ error }}
    </ng-container>

    <!-- Powered By ActivePieces -->
    <!-- <ap-powered-by-activepieces class="ap-mt-4"></ap-powered-by-activepieces> -->
  </div>
</ng-container>

<ng-container *ngIf="!isTrigger">
  <div class="ap-h-[100vh] ap-overflow-auto">
    <ng-container *ngIf="!error && formProps">
      <ng-container
        *ngIf="
          formType === 'form_submission' ||
          formType === 'file_submission' ||
          formType === 'form-builder'
        "
      >
        <lib-main-form
          [form]="form"
          [title]="title"
          [inputs]="inputs"
          (formSubmit)="submit()"
          [submitAction]="submitAction"
          [markdownResponse]="markdownResponse"
          [loading]="loading"
          [hasApprovalOrDisapproval]="hasApprovalOrDisapproval"
          [approvalButtons]="approvalButtons"
          [showSubmitButton]="showSubmitButton"
          [selectedRun$]="selectedRun$"
        ></lib-main-form>
      </ng-container>
      <ng-container
        *ngIf="
          formType === 'rfq_form' ||
          formType === 'document_review' ||
          formType === 'order_entry'
        "
      >
        <app-custom-forms
          [selectedRun$]="selectedRun$"
          [staticFormsData]="staticFormsData"
          [title]="title"
          [continueUrl]="continueUrl"
          [formType]="formType"
        ></app-custom-forms>
      </ng-container>
    </ng-container>

    <!-- Error Display -->
    <ng-container *ngIf="error">
      {{ error }}
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="flow$ | async as flow"></ng-container>
<ng-container *ngIf="submitForm$ | async"></ng-container>
