<div
  class="ap-flex ap-flex-row ap-flex-wrap ap-items-center ap-justify-center md:ap-w-[640px] lg:ap-w-[850px] xl:ap-w-full ap-mt-[72px] ap-gap-4"
>
  <ng-container *ngIf="isLoading; else loadingIsFinished">
    <ng-container *ngFor="let item of [1, 2, 3, 4]">
      <app-onboarding-package-skeleton />
    </ng-container>
  </ng-container>

  <ng-template #loadingIsFinished>
    <ng-container *ngFor="let option of listOfOptions">
      <app-onboarding-package
        [title]="option.label"
        [features]="option.features"
        [price]="option.price"
        [isRecommended]="option.isRecommended"
        [onConfirm]="onConfirm.bind(this)"
      />
    </ng-container>
  </ng-template>
</div>

<div class="ap-my-7 ap-mx-auto ap-w-fit">
  <ap-button
    actionButton
    btnSize="large"
    i18n
    btnStyle="basic"
    [fullWidthOfContainer]="true"
    (buttonClicked)="onSelfConfirm()"
  >
    <div
      class="ap-flex ap-items-center ap-justify-center ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter ap-text-[#344054]"
    >
      Or, let me continue using self-service mode
    </div>
  </ap-button>
</div>
