import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ap-user-avatar-icon',
  templateUrl: './user-avatar-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarIconComponent {
  @Input() imageUrl: string | undefined = undefined;
  fallbackImageUrl: string = 'assets/img/custom/user-avatar.png';

  constructor() {}

  handleImageError(event: Event): void {
    (event.target as HTMLImageElement).src = this.fallbackImageUrl;
  }
}
