export const environment = {
  igniteUrl: 'https://portal-de.ai-copilot.dev/',
  production: false,
  apiUrl: 'https://frontend.ai-copilot.dev/api/v1',
  jwtTokenName: 'token',
  redirectUrl: 'http://localhost:4200/redirect',
  userPropertyNameInLocalStorage: 'currentUser',
  extractUrl: 'https://xtract-de.ai-copilot.dev/',
  emailSenderFlowUrl:
    'https://backend.ai-copilot.dev/v1/webhooks/wkG579UTwbKfiapb0ylzU',
  calendlyScriptUrl: 'https://assets.calendly.com/assets/external/widget.js',
  calendlyDataUrl: 'https://calendly.com/upbrains-ai/30min',
  onboarding_email_recipient: 'info@upbrains.ai',
};
