<ng-container *ngIf="agentsWithAuth$ | async as agentsWithAuth; else loading">
  <ng-container *ngIf="agentTemplate$ | async as agentTemplate">
    <div
      class="ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-w-full ap-max-w-[892px] ap-max-h-full ap-overflow-auto ap-p-6 ap-flex ap-flex-col ap-justify-center ap-items-center ap-mx-auto custom-agent-connection ap-mt-10 ap-mb-24"
    >
      <div class="ap-flex ap-flex-row">
        <ng-container *ngFor="let icon of agentTemplate.agentsIcons">
          <ng-container *ngIf="icon?.iconUrl as iconUrl">
            <div
              class="ap-h-10 ap-w-10 ap-flex ap-items-center ap-justify-center ap-p-[5px]"
            >
              <img
                class="ap-w-full ap-h-full ap-object-contain"
                [src]="iconUrl"
                alt="Agent Icon"
              />
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div
        class="ap-text-[30px] ap-font-medium ap-text-[#475467] ap-font-inter ap-mt-4"
      >
        {{ agentTemplate.name }}
      </div>

      <div
        class="ap-text-base ap-font-normal ap-text-[#667085] ap-font-inter ap-mt-1"
      >
        {{ agentTemplate.description }}
      </div>

      <div
        class="ap-mx-auto ap-flex ap-flex-row ap-flex-wrap ap-gap-3 ap-mt-6 ap-max-w-full scroll-container"
        *ngIf="agentsWithAuth$ | async as agentsWithAuth"
      >
        <ng-container *ngFor="let agent of agentsWithAuth">
          <app-agent-connection-card
            [agent]="agent"
            [getConnections]="getConnectionAndUpdateAgents.bind(this)"
          />
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <ap-connection-skeleton />
</ng-template>
