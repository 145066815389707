import { Static, Type } from '@sinclair/typebox'

export const PromptExecutorPayload = Type.Object({
    prompt_name: Type.String(),
    input_values: Type.Record(Type.String(), Type.String()),
})

export const PromptExecutorResult = Type.Object({
    Description: Type.String(),
    Sectors: Type.Array(Type.String()),
})

export const PromptExecutorResponse = Type.Object({
    result: PromptExecutorResult,
    status: Type.Number(),
    metadata: Type.Object({}),
    status_summary: Type.String(),
})

export const PromptTemplateOutputStructure = Type.Object({
    many: Type.String(),
    name: Type.String(),
    optional: Type.Boolean(),
    type: Type.String(),
})

export const PromptTemplateResult = Type.Object({
    body: Type.String(),
    id: Type.Number(),
    input_parameters: Type.Array(Type.String()),
    model_name: Type.String(),
    name: Type.String(),
    output_structure: Type.Array(PromptTemplateOutputStructure),
    team_id: Type.Number(),
    usage_info: Type.Object({
        'gpt-4o-mini': Type.Object({
            input_tokens: Type.Number(),
            output_tokens: Type.Number(),
            total_calls: Type.Number(),
            total_tokens: Type.Number(),
        }),
    }),
})

export const PromptTemplateAPI = Type.Object({
    result: PromptTemplateResult,
    status: Type.Number(),
    status_summary: Type.String(),
})

export const PostPromptTemplatePayload = Type.Object({
    name: Type.String(),
    industry: Type.String(),
    description: Type.String(),
    sectors: Type.Array(Type.String()),
})

export const PostPromptTemplateResult = Type.Object({
    name: Type.String(),
    message: Type.String(),
})

export const PostPromptTemplateAPI = Type.Object({
    status: Type.Number(),
    result: Type.Optional(PostPromptTemplateResult),
    error: Type.Optional(Type.String()),
})

export type PromptExecutorPayloadType = Static<typeof PromptExecutorPayload>
export type PromptExecutorResultType = Static<typeof PromptExecutorResult>
export type PromptExecutorResponse = Static<typeof PromptExecutorResponse>

export type PromptTemplateOutputStructureType = Static<
    typeof PromptTemplateOutputStructure
>
export type PromptTemplateResultType = Static<typeof PromptTemplateResult>
export type PromptTemplate = Static<typeof PromptTemplateAPI>

export type PostPromptTemplatePayloadType = Static<
    typeof PostPromptTemplatePayload
>
export type PostPromptTemplateResultType = Static<
    typeof PostPromptTemplateResult
>
export type PostPromptTemplateAPIType = Static<typeof PostPromptTemplateAPI>
