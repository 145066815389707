import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../common/base-model'
import { ApId } from '../common/id-generator'
export type MemberId = ApId

export enum MemberRole {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER',
    PLATFORM_ADMIN = 'PLATFORM_ADMIN',
}

export enum MemberStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
}

export const MemberInviter = Type.Object({
    id: Type.String(),
    email: Type.String(),
    firstName: Type.String(),
    lastName: Type.String(),
})

export type MemberInviter = Static<typeof MemberInviter>

export const Member = Type.Object({
    ...BaseModelSchema,
    email: Type.String(),
    role: Type.Enum(MemberRole),
    invitedAt: Type.String(),
    submittedAt: Type.Union([Type.String(), Type.Null()]),
    platformId: Type.Union([Type.String(), Type.Null()]),
    projectId: Type.String(),
    userId: Type.Union([Type.String(), Type.Null()]),
    inviteSecret: Type.Optional(Type.String()),
    registryDate: Type.String(),
    status: Type.Enum(MemberStatus),
    inviterName: Type.Union([Type.String(), Type.Null()]),
    inviterEmail: Type.Union([Type.String(), Type.Null()]),
    inviterId: Type.Union([ApId, Type.Null()]),
    name: Type.Union([Type.String(), Type.Null()]),
})

export type Member = Static<typeof Member>

export const MemberColumns = Type.Object({
    email: Type.String(),
    name: Type.String(),
    role: Type.Enum(MemberRole),
    status: Type.Enum(MemberStatus),
    invitedAt: Type.String(),
    invitedBy: Type.String(),
})

export type MemberColumns = Static<typeof MemberColumns>
