import { Static, Type } from '@sinclair/typebox'
import { ApEdition } from '../../flag/flag'
import { PackageType, AgentCategory, agentType } from '../agent'

export const EXACT_VERSION_PATTERN = /^[0-9]+\.[0-9]+\.[0-9]+$/
export const VERSION_PATTERN = /^([~^])?[0-9]+\.[0-9]+\.[0-9]+$/

export const ExactVersionType = Type.RegEx(EXACT_VERSION_PATTERN)
export const VersionType = Type.RegEx(VERSION_PATTERN)

export enum SuggestionType {
    ACTION = 'ACTION',
    TRIGGER = 'TRIGGER',
    ACTION_AND_TRIGGER = 'ACTION_AND_TRIGGER',
}
export enum AgentSortBy {
    NAME = 'NAME',
    DATE = 'DATE',
}

export enum AgentOrderBy {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const GetAgentRequestWithScopeParams = Type.Object({
    name: Type.String(),
    scope: Type.String(),
})

export type GetAgentRequestWithScopeParams = Static<typeof GetAgentRequestWithScopeParams>

export const GetAgentListRequest = Type.Object({
    name: Type.String(),
})

export type GetAgentListRequest = Static<typeof GetAgentListRequest>


export const GetAgentRequestParams = Type.Object({
    name: Type.String(),
})

export type GetAgentRequestParams = Static<typeof GetAgentRequestParams>

export const ListAgentsRequestQuery = Type.Object({
    release: Type.Optional(ExactVersionType),
    includeHidden: Type.Optional(Type.Boolean()),
    edition: Type.Optional(Type.Enum(ApEdition)),
    searchQuery: Type.Optional(Type.String()),
    sortBy: Type.Optional(Type.Enum(AgentSortBy)),
    orderBy: Type.Optional(Type.Enum(AgentOrderBy)),
    categories: Type.Optional(Type.Array(Type.Enum(AgentCategory))),
    suggestionType: Type.Optional(Type.Enum(SuggestionType)),
})

export type ListAgentsRequestQuery = Static<typeof ListAgentsRequestQuery>


export const GetAgentRequestQuery = Type.Object({
    version: Type.Optional(VersionType),
})

export type GetAgentRequestQuery = Static<typeof GetAgentRequestQuery>

export const AgentOptionRequest = Type.Object({
    packageType: Type.Enum(PackageType),
    agentType: Type.Enum(agentType),
    agentName: Type.String({}),
    agentVersion: VersionType,
    stepName: Type.String({}),
    propertyName: Type.String({}),
    flowId: Type.String(),
    flowVersionId: Type.String(),
    input: Type.Any({}),
})

export type AgentOptionRequest = Static<typeof AgentOptionRequest>

export enum AgentScope {
    PROJECT = 'PROJECT',
    PLATFORM = 'PLATFORM',
}

export const AddAgentRequestBody = Type.Union([
    Type.Object({
        packageType: Type.Literal(PackageType.ARCHIVE),
        scope: Type.Enum(AgentScope),
        agentName: Type.String(),
        agentVersion: ExactVersionType,
        agentArchive: Type.Unknown(),
        agentType: Type.Optional(Type.Enum(agentType)),
    }, {
        title: 'Private Agent',
    }),
    Type.Object({
        packageType: Type.Literal(PackageType.REGISTRY),
        scope: Type.Enum(AgentScope),
        agentName: Type.String(),
        agentVersion: ExactVersionType,
        agentType: Type.Optional(Type.Enum(agentType)),
    }, {
        title: 'NPM Agent',
    }),
])

export type AddAgentRequestBody = Static<typeof AddAgentRequestBody>
