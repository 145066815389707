import { DataSource } from '@angular/cdk/collections';
import { Params } from '@angular/router';
import { ConversationBody, FlowRun } from '@upbrains/shared';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ApPaginatorComponent } from '../pagination/ap-paginator.component';
import { ConversationService } from '../../service';

const REFRESH_TABLE_DELAY = 10000;
/**
 * Data source for the LogsTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */

export type FlowRunTable = FlowRun;

export class ConversationTableDataSource extends DataSource<ConversationBody> {
  data: ConversationBody[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  refreshForExecutingRuns$: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  refreshTimer: NodeJS.Timeout | undefined;
  currentPageIndex: number = 1;
  limit: string | undefined = undefined;

  constructor(
    private queryParams$: Observable<Params>,
    private paginator: ApPaginatorComponent,
    private conversationService: ConversationService,
    private refreshForReruns$: Observable<boolean>
  ) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<ConversationBody[]> {
    return combineLatest({
      queryParams: this.queryParams$,
      refresh: this.refreshForExecutingRuns$.asObservable(),
      refreshForReruns: this.refreshForReruns$,
    }).pipe(
      tap(() => this.isLoading$.next(true)),
      switchMap((res) => {
        return this.conversationService
          .list({
            searchValue: res.queryParams['searchValue'] || '',
            functionId: res.queryParams['functionId'] || undefined,
            caseId: res.queryParams['caseId'] || undefined,
            pageNumber: res.queryParams['cursor'] || 1,
            pageSize: res.queryParams['limit'] || 10,
          })
          .pipe(
            map((response) => {
              // Apply additional filtering if needed based on queryParams
              const status = res.queryParams['status'];
              return {
                ...response,
                data: response.data.filter((conversation: ConversationBody) => {
                  return !status || conversation.eventType === status;
                }),
              };
            }),
            catchError((error) => {
              console.error('Error fetching conversations:', error);
              return of({ totalItems: 0, data: [] });
            })
          );
      }),
      tap((response) => {
        this.queryParams$.subscribe((params) => {
          this.limit = params?.['limit'] || '10';
          this.currentPageIndex = Number(params?.['cursor']) || 1;
        });

        const totalAvailablePages = this.limit
          ? Math.ceil(response.totalItems / Number(this.limit))
          : 0;

        const next =
          this.currentPageIndex !== totalAvailablePages ||
          this.currentPageIndex! > totalAvailablePages
            ? String(this.currentPageIndex + 1)
            : null;

        const prev =
          this.currentPageIndex !== 1 || this.currentPageIndex! < 1
            ? String(this.currentPageIndex - 1)
            : null;

        this.queryParams$.subscribe(
          (params) =>
            (this.currentPageIndex = params?.['cursor']
              ? Number(params?.['cursor'])
              : 1)
        );

        this.isLoading$.next(false);
        this.paginator?.setNextAndPrevious(next, prev);
        this.data = response.data;

        // Set up refresh for executing conversations
        if (this.refreshTimer) {
          clearTimeout(this.refreshTimer);
        }
        const hasRunningItems = response.data.some(
          (conversation: any) => conversation.eventType === 'RUNNING'
        );
        if (hasRunningItems) {
          this.refreshTimer = setTimeout(() => {
            this.refreshForExecutingRuns$.next(true);
          }, REFRESH_TABLE_DELAY);
        }
      }),
      map((response) => response.data)
    );
  }

  /**
   * Called when the table is being destroyed. Use this function to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
  }
}
