import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-declare-steps',
  templateUrl: './declare-steps.component.html',
  styleUrls: ['./declare-steps.component.scss'],
})
export class DeclareStepsComponent {
  @Input() onNextStep!: () => void;
  @Input() onPrevStep!: () => void;

  onConfirm() {
    this.onNextStep();
  }
}
