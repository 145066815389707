import { StepRunResult } from '@upbrains/ui/feature-builder-store';
import { PropertyType } from '@upbrains/agents-framework';
import {
  AuthenticationService,
  BuilderAutocompleteMentionsDropdownService,
  ConfirmationDialogComponent,
  NavigationService,
  environment,
} from '@upbrains/ui/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, catchError, map, of, take } from 'rxjs';
import {
  ExecutionState,
  FlowRun,
  StepOutputStatus,
  WebhookPauseMetadata,
} from '@upbrains/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

type InputElement = {
  displayName: string;
  required: boolean;
  description: string;
  type: InputTypes;
  value?: string | string[];
  uniqueKey: string;
  hide: boolean | string;
};

enum InputTypes {
  TEXT = 'text',
  FILE = 'file',
  TEXT_AREA = 'text_area',
  TOGGLE = 'toggle',
  DISPLAY_TEXT = 'display_text',
  SELECT = 'select',
  DISAPPROVAL_ACTION = 'DISAPPROVAL_ACTION',
  APPROVAL_ACTION = 'APPROVAL_ACTION',
  CUSTOM_ACTION = 'custom_action',
  SUBMIT_ACTION = 'submit_action',
}

type StepOutput = {
  [key: string]: any;
  status: string;
  output: {
    [key: string]: any;
    approvalLink?: string;
    disapprovalLink?: string;
  };
  input: unknown;
};
type ApprovalInputElement = Omit<InputElement, 'value'> & { value: string };

type ApprovalButton = {
  approve: ApprovalInputElement;
  disapprove: ApprovalInputElement;
  output: { [key: string]: any } | undefined;
  hide: boolean;
};

@Component({
  selector: 'lib-main-form',
  templateUrl: './main-form.component.html',
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainFormComponent implements OnInit {
  @Input() title = '';
  @Input() inputs: InputElement[] = [];
  @Input() approvalButtons: {
    approve: ApprovalInputElement;
    disapprove: ApprovalInputElement;
  }[] = [];
  @Input() form: FormGroup = new FormGroup({
    firstName: new FormControl(),
  });
  @Input() submitAction: InputElement | null = null;
  @Input() markdownResponse: Subject<string | null> = new Subject<
    string | null
  >();
  @Input() loading = false;
  @Input() hasApprovalOrDisapproval = false;
  @Input() showSubmitButton = true;
  @Input() selectedRun$: Observable<FlowRun | undefined> = of(undefined);

  @Output() formSubmit: EventEmitter<void> = new EventEmitter<void>();

  PropertyType = PropertyType;
  logoSrc = 'assets/img/custom/logo/upbrains-logo-2x.png';
  runId = '';

  buttonLoading: { [key: string]: boolean } = {};
  disabledButtons: string[] = [];
  disabledButtonsByClicked: string[] = [];

  links: { approvalLink?: string; disapprovalLink?: string } | null = {};
  runResults: StepRunResult[] = [];

  loadInitialData$: Observable<void> = new Observable<void>();
  setTitle$?: Observable<void>;

  previousSerializedApprovalButtons: any;
  error = '';

  constructor(
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public builderAutocompleteService: BuilderAutocompleteMentionsDropdownService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private matDialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.snackbar.dismiss();
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.runId = params['runId'];
    });

    this.selectedRun$?.subscribe((flowRun) => {
      if (flowRun?.executionOutput && flowRun.executionOutput.executionState) {
        this.links = this.findLinksBeforePaused(
          flowRun.executionOutput.executionState
        );
      }
    });

    this.approvalButtons.forEach((button) => {
      const approveKey = `${button.approve.uniqueKey}_comment`;
      this.form.addControl(approveKey, new FormControl(''));
    });
  }

  // Function to find approval and disapproval links before a paused step
  findLinksBeforePaused(executionState: ExecutionState) {
    let previousStep: StepOutput | null = null;
    for (const key in executionState.steps) {
      if (executionState.steps[key].status === 'PAUSED' && previousStep) {
        return {
          approvalLink: previousStep?.output?.['approvalLink'],
          disapprovalLink: previousStep?.output?.['disapprovalLink'],
        };
      }
      previousStep = executionState.steps[key] as StepOutput;
    }
    return null; // Return null if no PAUSED step is found or if PAUSED is the first step
  }

  onSubmit(): void {
    this.formSubmit.emit(); // Emit the event when the form is submitted
  }

  openLinkInNewTab(url: string | undefined | string[]) {
    if (typeof url === 'string') window.open(url, '_blank');
  }

  convertToString(value: string | string[] | undefined): string {
    if (typeof value === 'string') {
      return value;
    } else if (Array.isArray(value)) {
      // Join array values into a single string
      return value.join(', '); // Or any other appropriate format
    } else {
      return ''; // Handle undefined or other cases as needed
    }
  }

  getOptions(values: string[] | string | undefined): string[] {
    if (!values?.length && typeof values === 'string' && values === undefined) {
      return []; // Return empty array if value is undefined or falsy
    }
    // Split the value by '-' and trim each option
    return values as string[];
  }

  redirectHome(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  redirectToInbox(newWindow: boolean) {
    this.navigationService.navigate('/forms', newWindow);
  }

  approvalButtonOnClick(
    action: 'approve' | 'disapprove',
    uniqueKey: string,
    resumeRequestUrl: string
  ) {
    // Set the clicked button to loading and disable other buttons
    const commentControlName = `${uniqueKey}_comment`;
    // Get the value of the comment input
    const commentValue = this.form.get(commentControlName)?.value;
    const {
      email: userEmail,
      firstName,
      id: userId,
      lastName,
      teamId,
    } = this.authenticationService.currentUser;
    this.buttonLoading = { ...this.buttonLoading, [uniqueKey]: true };
    this.disabledButtons = this.inputs
      .map((input) => input.uniqueKey)
      .filter((key) => key !== uniqueKey);

    // Force Angular to detect changes
    this.cdr.detectChanges();

    this.selectedRun$.pipe(take(1)).subscribe((flowRun) => {
      if (!flowRun) {
        console.error('No flow run found.');
        this.error = 'No flow run found.';
        this.resetButtonStates(uniqueKey);
        return;
      }

      const waitForApprovalStep = Object.values(
        flowRun.executionOutput?.executionState.steps || {}
      ).find((step) => step.status === StepOutputStatus.PAUSED);
      if (
        !waitForApprovalStep ||
        (typeof waitForApprovalStep?.input !== 'object' &&
          waitForApprovalStep?.input === null)
      ) {
        console.error('No Wait for Approval Action found.');
        this.error = 'No Wait for Approval Action found.';
        this.resetButtonStates(uniqueKey);
        return;
      }

      const waitForApprovalStepInput = waitForApprovalStep.input as {
        approvalLinks: {
          approvalLink: string;
          disapprovalLink: string;
        };
      };
      const waitForApprovalExpectedUrl =
        waitForApprovalStepInput?.approvalLinks?.['approvalLink'];

      const _waitForApprovalExpectedUrl = new URL(
        waitForApprovalExpectedUrl as string
      );
      const waitForApprovalExpectedId =
        _waitForApprovalExpectedUrl.searchParams.get('id');

      const url = new URL(resumeRequestUrl as string);
      const resumeRequestUrlId = url.searchParams.get('id');

      if (resumeRequestUrlId !== waitForApprovalExpectedId) {
        console.error('No step with approval link found.');
        this.error = 'No step with approval link found.';
        this.resetButtonStates(uniqueKey);
        return;
      }

      if (!resumeRequestUrlId) {
        console.error('No id parameter found in approval link.');
        this.error = 'No id parameter found in approval link.';
        this.resetButtonStates(uniqueKey);
        return;
      }

      const requestId = (flowRun.pauseMetadata as WebhookPauseMetadata)
        .requestId;
      const postUrl = `${environment.apiUrl}/flow-runs/${this.runId}/requests/${requestId}?action=${action}&id=${resumeRequestUrl}`;

      this.http
        .post(postUrl, {
          approvalId: resumeRequestUrlId,
          id: uniqueKey,
          timestamp: Date.now(),
          comment: commentValue,
          user: {
            userEmail,
            firstName,
            userId,
            lastName,
            teamId,
          },
        })
        .pipe(
          catchError((error) => {
            console.error('Error:', error);
            this.resetButtonStates(uniqueKey);
            return of(null);
          })
        )
        .subscribe(() => {
          this.resetButtonStates(uniqueKey);

          this.disabledButtonsByClicked.push(uniqueKey);
          this.cdr.detectChanges();
          this.snackbar.open('Form Submitted Successfully');

          setTimeout(() => {
            if (this.showSubmitButton && this.approvalButtons?.length <= 1) {
              this.onSubmit();
              this.snackbar.open('Approval Action Submitted Successfully');
            }
            this.router.navigate(['/forms']);
          }, 1000);
        });
    });
  }

  // Helper method to reset button states in case of error
  resetButtonStates(uniqueKey: string) {
    this.disabledButtons = [];
    this.buttonLoading = { ...this.buttonLoading, [uniqueKey]: false };
    this.cdr.detectChanges(); // Force Angular to detect changes
  }

  getSerializedApprovalButtons(): Observable<ApprovalButton[]> {
    return this.selectedRun$.pipe(
      map((flowRun) => {
        if (!flowRun) {
          console.error('No flow run found.');
          this.error = 'No flow run found.';
          return [];
        }

        const steps: StepOutput[] = Object.values(
          flowRun.executionOutput?.executionState.steps as Record<
            string,
            StepOutput
          >
        );

        // NOTE: steps which have body key in their output object
        const filteredStepsByOutput = steps.filter((step) =>
          Object.prototype.hasOwnProperty.call(step.output || {}, 'body')
        );
        // NOTE: Generate an object where the keys are the output IDs waiting for approval action, and the values are the corresponding outputs.
        const stepsObject = filteredStepsByOutput.reduce(
          (acc: Record<string, StepOutput>, item) => {
            const id = item.output?.['body']?.id;
            if (id) {
              acc[id] = item;
            }
            return acc;
          },
          {}
        );
        // NOTE: Serialize approval buttons data with their corresponding output (can be undefined)
        const serializedApprovalButtons: ApprovalButton[] = this.approvalButtons
          .filter((_approval) => !_approval.approve.hide)
          .map((approval, index) => {
            return {
              ...approval,
              approve: {
                ...approval.approve,
                value: JSON.parse(approval.approve.value).approvalLink,
              },
              disapprove: {
                ...approval.disapprove,
                value: JSON.parse(approval.disapprove.value).disapprovalLink,
              },
              output: stepsObject[approval.approve.uniqueKey]?.output,
              // NOTE: Check the previous approval button.
              // if the previous one has output, hide is false otherwise it's true
              hide:
                index > 0 &&
                !stepsObject[this.approvalButtons[--index]?.approve?.uniqueKey]
                  ?.output?.['body']
                  ? true
                  : false,
            };
          });
        if (
          JSON.stringify(this.previousSerializedApprovalButtons) ===
          JSON.stringify(serializedApprovalButtons)
        ) {
          return this.previousSerializedApprovalButtons;
        }

        this.previousSerializedApprovalButtons = serializedApprovalButtons;
        return serializedApprovalButtons;
      })
    );
  }

  get gridColumns(): string {
    const length = this.inputs.length;
    if (length <= 5) return 'lg:ap-grid-cols-1';
    if (length >= 10) return 'lg:ap-grid-cols-2';
    if (length >= 20) return 'lg:ap-grid-cols-3';
    return 'lg:ap-grid-cols-1'; //
  }

  formatTimestamp(timestamp: number): string | null {
    return this.datePipe.transform(timestamp, 'yyyy/MM/dd HH:mm');
  }

  checkSubmitButtonCondition(): Observable<boolean> {
    return this.getSerializedApprovalButtons().pipe(
      map(
        (buttons) =>
          buttons.length > 0 && buttons.every((button) => button.output)
      )
    );
  }

  openConfirmationDialog(
    action: 'approve' | 'disapprove',
    uniqueKey: string,
    link: string
  ): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: action === 'approve' ? 'Approve' : 'Reject',
        description: `Are you sure you want to ${
          action === 'approve' ? 'Approve' : 'Reject'
        }?`,
        onClose: () => {
          console.log('Dialog was closed');
        },
        onConfirm: () => {
          console.log('Action confirmed');
          this.approvalButtonOnClick(action, uniqueKey, link);
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Confirmed');
      } else {
        console.log('Cancelled');
      }
    });
  }
}
