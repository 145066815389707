export const environment = {
  igniteUrl: 'https://portal-de.ai-copilot.dev/',
  extractUrl: "https://xtract-de.ai-copilot.dev/",
  hashSecretKey:"secret",
  production: false,
  apiUrl: 'https://frontend.ai-copilot.dev/api/v1',
  jwtTokenName: 'token',
  redirectUrl: 'https://frontend.ai-copilot.dev/redirect',
  userPropertyNameInLocalStorage: 'currentUser',
  emailSenderFlowUrl:
    'https://backend.ai-copilot.dev/v1/webhooks/wkG579UTwbKfiapb0ylzU',
  calendlyScriptUrl: "https://assets.calendly.com/assets/external/widget.js",
  calendlyDataUrl: "https://calendly.com/upbrains-ai/30min",
  onboarding_email_recipient: "info@upbrains.ai",
};
