import { Static, Type } from '@sinclair/typebox'


export const AskAssistantRequest = Type.Object({
    upbrainsToken: Type.String(),
    assistant: Type.String(),
    prompt: Type.String(),
    thread_id: Type.Optional(Type.String()),

})

export const GenerateImageRequest = Type.Object({
    upbrainsToken: Type.String(),
    model: Type.Optional(Type.String()),
    prompt: Type.String(),
    resolution: Type.Optional(
        Type.Union([
            Type.Literal('1024x1024'),
            Type.Literal('256x256'),
            Type.Literal('512x512'),
            Type.Literal('1792x1024'),
            Type.Literal('1024x1792'),
            Type.Null(),
        ]),
    ),
    quality: Type.Optional(Type.String()),

})

export const SendPromptRequest = Type.Object({
    upbrainsToken: Type.String(),
    model: Type.String(),
    prompt: Type.String(),
    maxTokens: Type.Optional(Type.Number()),
    topP: Type.Optional(Type.Number()),
    history: Type.Array(Type.Unknown()),
    frequencyPenalty: Type.Optional(Type.Number()),
    presencePenalty: Type.Optional(Type.Number()),
    temperature: Type.Optional(Type.Number()),

})

export const TextToSpeechRequest = Type.Object({
    upbrainsToken: Type.String(),
    model: Type.String(),
    text: Type.String(),
    speed: Type.Optional(Type.Number()),
    voice: Type.Optional(Type.Union([
        Type.Literal('alloy'),
        Type.Literal('echo'),
        Type.Literal('fable'),
        Type.Literal('onyx'),
        Type.Literal('nova'),
        Type.Literal('shimmer'),
    ])),
    format: Type.Optional(Type.Union([
        Type.Literal('mp3'),
        Type.Literal('opus'),
        Type.Literal('aac'),
        Type.Literal('flac'),

    ])),
})

export const TranscriptionRequest = Type.Object({
    upbrainsToken: Type.String(),
    audio: Type.Unknown(),
    language: Type.Optional(Type.String()),
})

export const TranslationRequest = Type.Object({
    upbrainsToken: Type.String(),
    audio: Type.Any(),

})
export const VisionPromptRequest = Type.Object({
    upbrainsToken: Type.String(),
    image: Type.Any(),
    prompt: Type.String(),
    maxTokens: Type.Optional(Type.Number()),
    topP: Type.Optional(Type.Number()),
    roles: Type.Array(Type.Any()),
    frequencyPenalty: Type.Optional(Type.Number()),
    presencePenalty: Type.Optional(Type.Number()),
    temperature: Type.Optional(Type.Number()),

})

export type AskAssistantRequest = Static<typeof AskAssistantRequest>
export type GenerateImageRequest = Static<typeof GenerateImageRequest>
export type SendPromptRequest = Static<typeof SendPromptRequest>
export type TextToSpeechRequest = Static<typeof TextToSpeechRequest>
export type TranscriptionRequest = Static<typeof TranscriptionRequest>
export type TranslationRequest = Static<typeof TranslationRequest>
export type VisionPromptRequest = Static<typeof VisionPromptRequest>
