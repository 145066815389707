import { Static, Type } from '@sinclair/typebox'
import { Cursor } from '../common/seek-page'

export const AgentRequest = Type.Object({
    displayName: Type.String(),
    projectId: Type.String(),
    folderId: Type.Optional(Type.String()),
    flowId: Type.Optional(Type.String()),
})

export type AgentRequest = Static<typeof AgentRequest>

export const DeleteRequest = Type.Object({
    id: Type.String(),
})

export type DeleteRequest = Static<typeof DeleteRequest>

export const GetAgentRequest = Type.Object({
    projectId: Type.String(),
})

export type GetAgentRequest = Static<typeof GetAgentRequest>

export const AddFlowRequest = Type.Object({
    id: Type.String(),
    flowId: Type.String(),
})

export type AddFlowRequest = Static<typeof AddFlowRequest>

export const ChangeStatusRequest = Type.Object({
    id: Type.String(),
    status: Type.Boolean(),
})

export type ChangeStatusRequest = Static<
    typeof ChangeStatusRequest
>

export const ChangeDisplayNameRequest = Type.Object({
    id: Type.String(),
    displayName: Type.String(),
})

export type ChangeDisplayNameRequest = Static<
    typeof ChangeDisplayNameRequest
>


export const ChangeDisplayNameByFlowIdRequest = Type.Object({
    flowId: Type.String(),
    displayName: Type.String(),
})

export type ChangeDisplayNameByFlowIdRequestContract = Static<
    typeof ChangeDisplayNameByFlowIdRequest
>


export const ListAgentRequest = Type.Object({
    folderId: Type.Optional(Type.String()),
    limit: Type.Optional(Type.Number({})),
    cursor: Type.Optional(Type.String({})),
    status: Type.Optional(Type.Boolean()),
    projectId: Type.String({}),
})

export type ListAgentRequest = Omit<
    Static<typeof ListAgentRequest>,
    'cursor'
> & { cursor: Cursor | undefined }

export const GetRequest = Type.Object({
    id: Type.String(),
})

export type GetRequest = Static<typeof GetRequest>

export const AddFolderRequest = Type.Object({
    id: Type.String(),
    folderId:Type.Optional(Type.String()),
})

export type AddFolderRequest = Static<typeof AddFolderRequest>
