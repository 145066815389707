import { Static, Type } from '@sinclair/typebox'
import { BaseModelSchema } from '../common'

export enum memoryType {
    LIST = 'list',
    OBJECT = 'object',
    STRING = 'string',
}

export const Memory = Type.Object({
    ...BaseModelSchema,
    name: Type.String(),
    flowRunId: Type.String(),
    type: Type.Enum(memoryType),
    shared: Type.Boolean(),
    data: Type.Union([Type.Record(Type.String({}), Type.Any()), Type.Array(Type.Any()), Type.String()]),
})

export type Memory = Static<typeof Memory>

